import { Component, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { timer } from 'rxjs';
import { BaseComponent } from '../../base.component';
import { MAX_INPUT_SPINNER_VALUE, MIN_INPUT_SPINNER_VALUE } from '../input.spinner/input.spinner';

@Component({
	selector: 'quantity-dropdown',
	templateUrl: './quantity.dropdown.html',
	styleUrls: ['./quantity.dropdown.css']
})

class QuantityDropdownComponent extends BaseComponent {
	@Output() public update: EventEmitter<number> = new EventEmitter<number>();
	@Input() public quantity: number;
	@Input() public updatingQuantity: boolean;

	@ViewChild('valueInput', {static: false}) public valueInput: ElementRef<HTMLInputElement>;

	public editingQuantity: boolean;
	public newQuantity: string;

	constructor () {
		super();
	}

	public selectQuantityFromDropdown (newQuantity: string) : void {
		if (newQuantity === '10+') {
			this.editingQuantity = true;
			this.newQuantity = this.quantity.toString();
			this.cleanup.push(timer(0).subscribe(() => {
				this.valueInput.nativeElement.select();
			}));
		} else {
			this.setQuantity(newQuantity, true);
		}
	}

	public setQuantity (newQuantity: string, fromClick?: boolean) : void {
		if (fromClick || this.validateQuantityInput() && this.quantity !== parseInt(newQuantity, 10)) {
			this.editingQuantity = false;
			this.quantity = parseInt(newQuantity, 10);
			this.update.emit(this.quantity);
		} else {
			this.editingQuantity = false;
		}
	}

	public cancelEdit () : void {
		this.editingQuantity = false;
	}

	public clickInput (event: Event) : void {
		event.stopPropagation();
	}

	private validateQuantityInput () : boolean {
		const numValue: number = parseInt(this.newQuantity, 10);
		return numValue >= MIN_INPUT_SPINNER_VALUE && numValue <= MAX_INPUT_SPINNER_VALUE;
	}
}

export { QuantityDropdownComponent };
