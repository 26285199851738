const GRADES: Array<any> = [
	{
		displayable: 'Pre-Kindergarten',
		value: 'PK'
	},
	{
		displayable: 'Kindergarten',
		value: 'K'
	},
	{
		displayable: '1st Grade',
		value: '1'
	},
	{
		displayable: '2nd Grade',
		value: '2'
	},
	{
		displayable: '3rd Grade',
		value: '3'
	},
	{
		displayable: '4th Grade',
		value: '4'
	},
	{
		displayable: '5th Grade',
		value: '5'
	},
	{
		displayable: '6th Grade',
		value: '6'
	},
	{
		displayable: '7th Grade',
		value: '7'
	},
	{
		displayable: '8th Grade',
		value: '8'
	}
];

enum GradeRange {
	Grade_PK_5 = 'PK-5',
	Grade_6_8 = '6-8',
}

enum SchoolwideGrade {
	Grade_PK_5 = GradeRange.Grade_PK_5,
	Grade_6_8 = GradeRange.Grade_6_8,
}

const SCHOOLWIDE_GRADES: Array<any> = [
	// {
	// 	displayable: 'K-3',
	// 	value: 'K-3'
	// },
	// {
	// 	displayable: 'K-6',
	// 	value: 'K-6'
	// },
	// {
	// 	displayable: 'K-8',
	// 	value: 'K-8'
	// },
	// {
	// 	displayable: '4-6',
	// 	value: '4-6'
	// },
	// {
	// 	displayable: 'Middle School',
	// 	value: 'MS'
	// },
	{
		displayable: GradeRange.Grade_PK_5,
		value: GradeRange.Grade_PK_5
	},
	{
		displayable: GradeRange.Grade_6_8,
		value: GradeRange.Grade_6_8
	},
];

const mapSingleGradeToGradeRange: Function = (grade: string) : string => {
	switch (grade) {
		case 'PK':
		case 'K':
		case '1':
		case '2':
		case '3':
		case '4':
		case '5':
			return GradeRange.Grade_PK_5;
			break;
		case '6':
		case '7':
		case '8':
			return GradeRange.Grade_6_8;
			break;
		default:
			return null;
	}
};

export { SCHOOLWIDE_GRADES, GRADES, mapSingleGradeToGradeRange, GradeRange, SchoolwideGrade};
