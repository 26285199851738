import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'loading',
	templateUrl: './loading.html',
	styleUrls: ['./loading.css']
})

class LoadingComponent implements OnInit {
	@Input() private customText: string;
	@Input() public noText: boolean;
	public loadingText: string;

	public ngOnInit () : void {
		this.loadingText = this.customText || 'Loading';
	}
}

export { LoadingComponent };
