import { Input } from '@angular/core';
import { Book } from '../../services/apis/book/book.service';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BaseComponent } from '../../base.component';

class ActionButtonsComponent extends BaseComponent {
	@Input() public book: Book;
	@Input() public big: boolean;
	@Input() public quickButton: boolean;
	@Input() public hideText: boolean;

	public inList: boolean;
	public adding: boolean;
	public showConfirmation: boolean;

	constructor (protected analyticsService: AnalyticsService) {
		super();
	}

	public hideConfirmation (event: Event) : void {
		this.stopProp(event);
		this.showConfirmation = false;
	}
}

export { ActionButtonsComponent };
