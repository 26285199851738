import { map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from '../services/apis/auth/auth.service';
import { UserService } from '../services/apis/user/user.service';
import { BookClubService, BookClub } from '../services/apis/book.club/book.club.service';
import { DialogService } from '../services/helpers/dialog.service';
import { CartService } from '../services/apis/cart/cart.service';
import { EditWishlistService, Wishlist } from '../services/apis/wishlist/edit.wishlist.service';
import { ViewWishlistService } from '../services/apis/wishlist/view.wishlist.service';
import { CART_ID_KEY, EFAIR_CODE, VIEW_WISHLIST_IDS_LOOKUP } from '../constants/local.storage';
import { CategoryImageService } from '../services/apis/category.image/category.image.service';
import { School, SchoolService } from '../services/apis/school/school.service';


@Injectable()
class GuestGuardService implements CanActivate {

	constructor (protected authService: AuthService, protected userService: UserService, protected bookClubService: BookClubService, protected dialogService: DialogService,
		protected cartService: CartService, protected editWishlistService: EditWishlistService, protected viewWishlistService: ViewWishlistService, private categoryImageService: CategoryImageService, protected schoolService: SchoolService) {}

	public lastEfairCode: string;

	public canActivate (route: ActivatedRouteSnapshot) : Observable<boolean> {
		const wishlistId: string = route.queryParams.wishlistId;
		const cart_id: string = route.queryParams.cart_id;

		if (this.authService.isTeacherSession() && !this.authService.isAuthenticated()) {
			this.authService.clearTeacherLocalStorage();
		}

		if (cart_id) {
			localStorage.setItem(CART_ID_KEY, cart_id);
		}
		if (wishlistId) {
			return this.loadNewWishlist(wishlistId);
		} else {
			let efairCode: string = route.queryParams.classCode || route.queryParams.efairCode;
			if (!efairCode) {
				efairCode = localStorage.getItem(EFAIR_CODE);
			} else {
				localStorage.setItem(EFAIR_CODE, efairCode);
			}
			return this.loadOldWishlists(efairCode);
		}
	}

	private loadNewWishlist (wishlistId: string) : Observable<boolean> {
		return this.viewWishlistService.get(wishlistId).pipe(
			catchError(() => {
				this.authService.logout();
				this.dialogService.alert('Oops! This wishlist is invalid', `Either the eFair has already ended or you've clicked on an invalid link.`);
				localStorage.removeItem(EFAIR_CODE);
				return of(false);
			}),
			mergeMap((wishlist: Wishlist) => {
				if (wishlist) {
					this.viewWishlistService.addWishlist(wishlist);
					localStorage.setItem(EFAIR_CODE, wishlist.efairCode);
					return this.loadOldWishlists(wishlist.efairCode);
				} else {
					return of(false);
				}
			}));
	}

	private loadOldWishlists (efairCode: string) : Observable<boolean> {
		if (this.lastEfairCode !== efairCode) {
			this.lastEfairCode = efairCode;
			const localStorageIds: Array<string> = JSON.parse(localStorage.getItem(VIEW_WISHLIST_IDS_LOOKUP));
			const wishlistIds: Set<string> = new Set(localStorageIds);
			const initialCount: number = localStorageIds ? localStorageIds.length : 0;

			let result: Observable<boolean> = of(true);

			if (localStorageIds && localStorageIds.length > 0) {
				const obsList: Array<Observable<any>> = [];
				for (const id of Array.from(wishlistIds.values())) {
					obsList.push(this.viewWishlistService.get(id).pipe(catchError(() => {
						return of(null);
					})));
				}
				result = forkJoin(obsList).pipe(mergeMap((wishlists: Array<Wishlist>) => {
					wishlists = wishlists.filter((wishlist: Wishlist) => wishlist !== null);
					wishlists = wishlists.filter((wishlist: Wishlist) => wishlist.efairCode === efairCode);
					if (wishlists.length < initialCount) {
						localStorage.removeItem(CART_ID_KEY);
						this.editWishlistService.reset();
					}
					this.viewWishlistService.cacheWishlists(wishlists);

					return of(true);
				}));
			}
			return result.pipe(mergeMap(() => {
				return this.loadUser();
			}));
		} else {
			return this.loadUser();
		}
	}

	private loadUser () : Observable<boolean> {
		const efairCode: string = localStorage.getItem(EFAIR_CODE);

		if (!this.userService.getUser()) {
			if (efairCode) {
				this.authService.login('guest');
				return this.cacheUserData(efairCode);
			} else {
				this.authService.logout();
				this.dialogService.alert('Error', 'Missing eFair code.');
				return of(false);
			}
		} else {
			const bookClub: BookClub = this.bookClubService.getCachedBookClub();
			if (bookClub) {
				return this.viewWishlistService.cacheTeacherWishlists(bookClub._id).pipe(map(() => {
					return true;
				}));
			} else {
				return of(true);
			}
		}
	}

	private cacheUserData (efairCode: string) : Observable<boolean> {
		return this.bookClubService.populateBookClub(null, efairCode).pipe(
			catchError(() => {
				this.authService.logout();
				this.dialogService.alert('Oops! There is no eFair with this code', `Please check that you've typed it in correctly.`);
				localStorage.removeItem(EFAIR_CODE);
				return of(false);
			}),
			mergeMap((bookClub: BookClub) => {
				if (bookClub) {
					const teacher: Observable<any> = this.userService.findAndCacheTeacher(bookClub.user_id);
					const school: Observable<School> = this.schoolService.cacheSchool(bookClub.school_id);
					const teacherWishlists: Observable<Array<Wishlist>> = this.viewWishlistService.cacheTeacherWishlists(bookClub._id);
					const cart: Observable<boolean> = this.cartService.cacheBooks();
					const wishlist: Observable<boolean> = this.editWishlistService.cacheWishlist();
					const categoryImages: Observable<void> = this.categoryImageService.cacheImages();
					const teacherRecs: Observable<boolean> = this.userService.cacheRecommendedBooks(bookClub.user_id);

					return teacher.pipe(mergeMap(() => {
						return forkJoin([school, teacherWishlists, cart, wishlist, teacherRecs, categoryImages]).pipe(map(() => {
							this.userService.onUserLoad.next();
							return true;
						}));
					}));
				} else {
					this.authService.logout();
					this.dialogService.alert('Oops! There is no eFair with this code', `Please check that you've typed it in correctly.`);
					localStorage.removeItem(EFAIR_CODE);
					return of(false);
				}
			})
		);
	}

}

export { GuestGuardService };
