import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { Observable, of, timer } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { School, SchoolService } from '../../../services/apis/school/school.service';
import { map, mergeMap } from 'rxjs/operators';
import { BookClub } from '../../../services/apis/book.club/book.club.service';

@Component({
	selector: 'click-to-edit-school',
	templateUrl: 'click.to.edit.school.html',
	styleUrls: ['click.to.edit.school.css']
})

class ClickToEditSchoolComponent extends BaseComponent implements OnInit {
	@ViewChild('fieldInput', {static: false}) public fieldInput: ElementRef;
	@ViewChild('fieldInputZipCode', {static: false}) public fieldInputZipCode: ElementRef;
	@ViewChild('schoolSelectionDropDown', {static: false, read: ElementRef }) public schoolSelectionDropDown: ElementRef;

	@Input() public bookClub: BookClub;
	@Input() public isNew: boolean;   // if viewing from efair creation
	@Input() public defaultSchool: School;
	@Input() public disabled: boolean;

	@Output() public updated: EventEmitter<any> = new EventEmitter();  // method to call when school is updated

	public editing: boolean;
	public typeaheadLoading: boolean = false;
	public currentZipCode: string;
	public schools: Array<School>;
	public schoolInput: School;
	public zipCodeDataSource: Observable<Array<any>>;
	public selectedSchool: School;

	constructor (private schoolService: SchoolService) {
		super();
	}

	public ngOnInit () : void {
		this.selectedSchool = this.defaultSchool;
		this.currentZipCode = this.defaultSchool ? this.defaultSchool.address.zip : '';
		this.cleanup.push(this.getSchools(this.currentZipCode).subscribe((schools: Array<School>) => {
			this.schools = schools;
		}));
		this.zipCodeDataSource = new Observable((observer: any) => observer.next(this.currentZipCode)).pipe(mergeMap((token: string) =>	{
			return this.getZipCodes(token);
		}));
	}

	public edit () : void {
		if (!this.disabled) {
			this.editing = true;
			this.cleanup.push(timer(0).subscribe(() => {
				this.fieldInputZipCode.nativeElement.focus();
			}));
		}
	}

	public changeTypeaheadLoading (loading: boolean) : void {
		this.typeaheadLoading = loading;
	}

	public schoolSelected (school_id: any) : void {
		this.cleanup.push(this.schoolService.get(school_id).subscribe((school: School) => {
			this.typeaheadLoading = false;
			this.selectedSchool = school;
			this.editing = false;
			if (this.updated) {
				this.updated.emit(this.selectedSchool);
			}
		}));
	}

	private getSchools (searchString: string) : Observable<Array<School>> {
		if (searchString) {
			return this.schoolService.search({searchTerm: searchString}).pipe(map((schools: Array<School>) => {
				return schools;
			}));
		} else {
			return of(null);
		}
	}

	public changeFocus (match: TypeaheadMatch) : void {
		this.currentZipCode = match.value;
		this.zipCodeSelected(match);
	}

	public zipCodeSelected (match: TypeaheadMatch) : void {
		this.typeaheadLoading = false;
		this.cleanup.push(this.getSchools(match.value).subscribe((schools: Array<School>) => {
			this.schools = schools;
			this.cleanup.push(timer(0).subscribe(() => {
				this.fieldInput.nativeElement.focus();
			}));
		}));
	}

	private getZipCodes (searchString: string) : Observable<Array<string>> {
		if (searchString) {
			return this.schoolService.searchByZip(searchString).pipe(map((schools: Array<string>) => {
				return schools;
			}));
		} else {
			return of(null);
		}
	}
}
export { ClickToEditSchoolComponent };
