import { Injectable } from '@angular/core';
import { ModalOptions, BsModalRef } from 'ngx-bootstrap';
import { AlertDialogComponent } from '../../shared/alert.dialog/alert.dialog';
import { ModalService } from './modal.service';

interface AlertModalOptions {
	title: string;
	message: string;
	okayLabel?: string;
	cancelLabel?: string;
	centered?: boolean;
	titleIconPath?: string;
}

@Injectable()
class DialogService {
	private modal: BsModalRef;
	constructor (private modalService: ModalService) {}

	public alert (title: string, message: string, items?: Array<string>, centered?: boolean, okayClicked?: Function, showCancel?: boolean) : void {
		if (this.modal) {
			this.modal.hide();
		}
		const config: ModalOptions = {
			animated: true,
			keyboard: true,
			ignoreBackdropClick: true,
			initialState: {
				title: title,
				message: message,
				showCancel: showCancel,
				items: items,
				okayClicked: okayClicked
			},
		};
		if (centered) {
			config.class = 'modal-dialog-centered';
		}
		this.modal = this.modalService.show(AlertDialogComponent, config);
	}

	public alertWithOptions (options: AlertModalOptions, okayClicked?: Function, cancelClicked?: Function) : void {
		if (this.modal) {
			this.modal.hide();
		}
		const config: ModalOptions = {
			animated: true,
			keyboard: true,
			ignoreBackdropClick: true,
			initialState: {
				title: options.title,
				message: options.message,
				okayLabel: options.okayLabel,
				cancelLabel: options.cancelLabel,
				showCancel: cancelClicked !== undefined || options.cancelLabel !== undefined,
				okayClicked: okayClicked,
				cancelClicked: cancelClicked,
				titleIconPath: options.titleIconPath
			},
		};
		if (options.centered) {
			config.class = 'modal-dialog-centered';
		}
		this.modal = this.modalService.show(AlertDialogComponent, config);
	}
}

export { DialogService, AlertModalOptions };
