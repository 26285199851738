import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { BookList } from '../../../services/apis/book.club/book.club.service';
import { BaseComponent } from '../../../base.component';
import { timer } from 'rxjs';

@Component({
	selector: 'actual-mobile-book-carousel',
	templateUrl: './actual.mobile.book.carousel.html',
	styleUrls: ['./actual.mobile.book.carousel.css']
})

class ActualMobileBookCarouselComponent extends BaseComponent implements AfterViewInit {
	@Input() public book_ids: Array<string>;
	@Input() public bookList: BookList;
	@Input() public showDelete: boolean;
	@Input() public showArchive: boolean;
	@Input() public isFilterApplied: boolean;
	@Output() public loaded: EventEmitter<string> = new EventEmitter<string>();

	constructor () {
		super();
	}

	public ngAfterViewInit () : void {
		this.cleanup.push(timer(0).subscribe(() => {
			this.loaded.emit();
		}));
	}

}

export { ActualMobileBookCarouselComponent };
