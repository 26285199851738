import * as moment from 'moment';
import { TITLEWAVE_SPRING_CUTOFF_MONTH } from '../constants/titlewave';
import * as momentBusiness from 'moment-business-days';

class DateHelper {
	public static BLACKOUT_DATES: Array<moment.Moment> = [
		moment('2021-03-23').startOf('day'),
		moment('2021-03-24').startOf('day'),
		moment('2021-03-25').startOf('day'),
		moment('2021-03-26').startOf('day'),
		moment('2021-03-27').startOf('day'),
		moment('2021-03-28').startOf('day'),
		moment('2021-03-29').startOf('day'),
		moment('2021-03-30').startOf('day'),
		moment('2021-03-31').startOf('day'),
	];

	public static MILLIS_IN_DAY: number = 1000 * 60 * 60 * 24;

	public static getYesterday () : Date {
		return DateHelper.addDays(new Date(), -1);
	}

	public static getLastWeek () : Date {
		return DateHelper.addDays(new Date(), -7);
	}

	public static getTwoWeeksAgo () : Date {
		return DateHelper.addDays(new Date(), -14);
	}

	public static getTomorrow () : Date {
		return DateHelper.addDays(new Date(), 1);
	}

	public static getNextWeek () : Date {
		return DateHelper.addDays(new Date(), 7);
	}

	public static getEndOfNextMonth () : Date {
		const today: Date = new Date();
		return new Date(today.getFullYear(), today.getMonth() + 2, 0);
	}

	public static setToStartOfDay (input: Date) : Date {
		const result: Date = new Date(input.getTime());
		result.setHours(0, 0, 0, 0);
		return result;
	}

	public static setToEndOfDay (input: Date) : Date {
		const result: Date = new Date(input.getTime());
		result.setHours(23, 59, 59, 999);
		return result;
	}

	public static addDays (startDate: Date, numDays: number) : Date {
		const result: Date = new Date(startDate.getTime());
		result.setDate(result.getDate() + numDays);
		return result;
	}

	public static addMinutes (startDate: Date, minutes: number) : Date {
		const result: Date = new Date(startDate.getTime());
		result.setMinutes(result.getMinutes() + minutes);
		return result;
	}

	public static addHours (startDate: Date, hours: number) : Date {
		const result: Date = new Date(startDate.getTime());
		result.setHours(result.getHours() + hours);
		return result;
	}

	public static addSeconds (startDate: Date, seconds: number) : Date {
		const result: Date = new Date(startDate.getTime());
		result.setSeconds(result.getSeconds() + seconds);
		return result;
	}

	public static getTimezone () : string {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	public static isWithinThreeWeeks (startDate: Date) : boolean {
		let threeWeeksBeforeStartDate: Date = new Date(startDate);
		threeWeeksBeforeStartDate = DateHelper.addDays(threeWeeksBeforeStartDate, -21);
		return moment().startOf('day').isSameOrAfter(threeWeeksBeforeStartDate);
	}

	public static calculateClassroomStartDate () : Date {
		const today: Date = new Date();
		const result: Date = today;
		const month: number = today.getMonth();
		const day: number = today.getDate();
		if (month === 5 && day > 15 || month > 5 && month < 10 || month === 10 && day < 15) {
			result.setMonth(11);
			result.setDate(31);
		} else {
			result.setMonth(6);
			result.setDate(31);
			if (month >= 10) {
				result.setFullYear(today.getFullYear() + 1);
			}
		}
		return result;
	}

	public static isBlackoutDate (date: moment.Moment) : boolean {
		return !!this.BLACKOUT_DATES.find((blackoutDate: moment.Moment) => date.isSame(blackoutDate));
	}

	public static getCurrentSeasonDateRange (date?: Date) : Array<Date> {
		let minDate: Date = date || new Date();
		let maxDate: Date = date || new Date();
		if (minDate.getMonth() <= TITLEWAVE_SPRING_CUTOFF_MONTH) {
			minDate = moment(minDate).startOf('year').toDate();
			maxDate = moment(new Date(maxDate.getFullYear() + '-' + (TITLEWAVE_SPRING_CUTOFF_MONTH + 1) + '-01')).endOf('month').toDate();
		} else {
			minDate = moment(new Date(minDate.getFullYear() + '-' + (TITLEWAVE_SPRING_CUTOFF_MONTH + 2) + '-01')).startOf('month').toDate();
			maxDate = moment(maxDate).endOf('year').toDate();
		}
		return [minDate, maxDate];
	}

	public static getDeliveryDate ( startDate: Date, startDays: number, endDays: number ) : string {
		const estimatedStart: momentBusiness.Moment = momentBusiness(startDate).businessAdd(startDays);
		const estimatedEnd: momentBusiness.Moment = momentBusiness(startDate).businessAdd(endDays);
		return `${estimatedStart.format( 'MMM Do' )} - ${estimatedEnd.format('MMM Do YYYY')}`;
	}

	public static getLastDayOfNextSchoolYear () : moment.Moment {
		const today: moment.Moment = moment(new Date());
		const endOfJune: moment.Moment = moment(`${today.year()}0630`, 'YYYYMMDD');
		if (today.isAfter(endOfJune)) {
			endOfJune.add(1, 'year');
		}
		endOfJune.add(1, 'year');
		return endOfJune.endOf('month');
	}

	/**
	 * Returns true if date is between Dec, 7 - Jan, 5
	 * @param date
	 */
	public static isInProhibitedPeriod (date: Date) : boolean {
		return (date.getMonth() === 11 && date.getDate() >= 7) || (date.getMonth() === 0 && date.getDate() <= 5);
	}

	/**
	 * Returns true if date is before planned start date (Sep, 3 2024)
	 * @param date
	 */
	public static isBeforePlannedStartDate (date: Date) : boolean {
		return date.getFullYear() <= 2024 && (date.getMonth() < 8 || (date.getMonth() === 8 && date.getDate() < 3));
	}

	/**
	 * Returns true if period includes prohibited period (Dec, 7 - Jan, 5)
	 * @param from
	 * @param to
	 */
	public static isIncludesProhibitedPeriod (from: Date, to: Date) : boolean {
		return from.getFullYear() !== to.getFullYear() && (from.getMonth() < 11 || (from.getMonth() === 11 && from.getDate() < 7)) && (to.getMonth() > 0 || (to.getMonth() === 0 && to.getDate() > 5));
	}

	/**
	 * Returns eFair start date, but not earlier than Sep, 3
	 */
	public static getEfairStartDate () : Date {
		const current: Date = new Date();
		if (current.getFullYear() < 2024 || (current.getFullYear() === 2024 && current.getMonth() < 8) ||  (current.getFullYear() === 2024 && current.getMonth() === 8 && current.getDate() < 3)) {
			return this.setToStartOfDay(new Date(2024, 8, 3));
		}
		return this.setToStartOfDay(new Date());
	}

	/**
	 * Returns eFair end date
	 * @param startDate
	 */
	public static getEfairEndDate (startDate: Date) : Date {
		let endDate: Date = DateHelper.setToEndOfDay(DateHelper.addDays(startDate, 12));
		while (DateHelper.isBlackoutDate(moment(DateHelper.setToStartOfDay(endDate)))) {
			endDate = DateHelper.addDays(endDate, 1);
		}
		return endDate;
	}
}

export { DateHelper };
