import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BookClub, BookClubService } from '../../../services/apis/book.club/book.club.service';
import { BaseComponent } from '../../../base.component';
import { GoalType } from '../../../constants/goal.types';
import { CartService } from '../../../services/apis/cart/cart.service';
import { interval, Observable, Subscription } from 'rxjs';
import { fadeAndHeightAndWidth } from '../../animations/fade';
import { EfairType } from '../../../constants/efairs';
import { formatNumber } from '@angular/common';

const TOTAL_INTERVAL_MS_SLOW: number = 1000;
const TOTAL_INTERVAL_MS_FAST: number = 200;
@Component({
	selector: 'goal-progress',
	templateUrl: './goal.progress.html',
	styleUrls: ['./goal.progress.css'],
	animations: [ fadeAndHeightAndWidth ]
})

class GoalProgressComponent extends BaseComponent implements OnInit {
	@Input() public primaryColor: string;
	@Input() public secondaryColor: string;
	@Input() public fromBanner: boolean;

	public bookClub: BookClub;
	public readonly GoalType: typeof GoalType = GoalType;
	public labelTooltip: string;
	public goalAmount: number;
	public goalType: GoalType;

	public preorderAmount: number;
	public preorderPercentage: number;

	public totalAmount: number;
	public totalPercentage: number;

	public animationInterval: number;
	public animationIntervalSub: Subscription;

	@ViewChild('progressBarWrapper', {static: true}) public progressBarWrapper: ElementRef;

	constructor (private bookClubService: BookClubService, private cartService: CartService) {
		super();
	}

	public ngOnInit () : void {
		this.bookClub = this.bookClubService.getCachedBookClub();
		if (this.bookClub.goal) {
			this.goalAmount = this.bookClub.goal.goalAmount || 0;
			this.goalType = this.bookClub.goal.goalType || GoalType.DOLLARS;

			this.labelTooltip = `Our ${this.bookClub.type === EfairType.CLASSROOM ? 'classroom' : 'school'} has a goal of ${this.goalType === GoalType.DOLLARS ? '$' : ''}${formatNumber(this.goalAmount, 'en-us')} ${this.goalType === GoalType.DOLLARS ? 'in sales' : 'books sold'} for this eFair. Watch your contributions get tallied in near real-time! Contributions only include processed orders. Thank you for your support.`;

			this.progressBarWrapper.nativeElement.style.setProperty('--primary-progress-bar-color', this.primaryColor || (this.goalType === GoalType.DOLLARS ? 'var(--fss-green)' : 'var(--fss-nav-blue)'));
			this.progressBarWrapper.nativeElement.style.setProperty('--secondary-progress-bar-color', this.secondaryColor || (this.goalType === GoalType.DOLLARS ? 'lightgreen' : '#9ABAD7'));
			this.progressBarWrapper.nativeElement.style.setProperty('--goal-label-color', this.fromBanner ? 'white' : 'black');

			this.preorderAmount = Math.round((this.bookClub.goal.goalType === GoalType.DOLLARS ? this.bookClub.totalSpent : this.bookClub.booksPurchased) || 0);
			this.totalAmount = this.preorderAmount;
			this.updateDisplay();
			if (this.cartService.getCachedCartItems()) {
				this.calculateGoalProgress();
			}
			this.cleanup.push(this.cartService.onCartUpdate.subscribe(() => {
				this.wrapUnsubscribe();
				this.calculateGoalProgress();
			}));
		}
	}

	public calculateGoalProgress () : void {
		let newtotalAmount: number;
		if (this.goalType === GoalType.DOLLARS) {
			newtotalAmount = this.preorderAmount + this.cartService.getCartTotal();
		} else {
			newtotalAmount = this.preorderAmount + this.cartService.getItemCount();
		}
		this.animate(Math.round(newtotalAmount));
	}

	public animate (newValue: number) : void {
		this.totalAmount = Math.round(this.totalAmount);
		const difference: number = Math.abs(newValue - this.totalAmount);
		if (difference > 0) {
			this.animationInterval = (difference <= 10 ? TOTAL_INTERVAL_MS_FAST : TOTAL_INTERVAL_MS_SLOW) / difference;
			const directionNumber: number = this.totalAmount <= newValue ? 1 : -1;
			let goalStep: number = Math.ceil(difference / 100) * directionNumber;

			this.animationIntervalSub = this.wrapInterval(this.animationInterval).subscribe(() => {
				if (Math.abs(goalStep) > Math.abs(newValue - this.totalAmount)) {
					goalStep = directionNumber;
				}
				this.totalAmount += goalStep;
				this.updateDisplay();
				if (Math.abs(Math.round(this.totalAmount) - newValue) === 0) {
					this.wrapUnsubscribe();
				}
			});
			this.cleanup.push(this.animationIntervalSub);
		}
	}

	private wrapInterval (time: number) : Observable<any> {
		return interval(time);
	}

	private wrapUnsubscribe () : void {
		if (this.animationInterval) {
			this.animationIntervalSub.unsubscribe();
		}
	}

	public updateDisplay () : void {
		this.preorderPercentage = Math.min(this.preorderAmount / this.goalAmount * 100, 100);
		this.totalPercentage = Math.min(this.totalAmount / this.goalAmount * 100, 100);
		this.progressBarWrapper.nativeElement.style.setProperty('--animation-interval', `${this.animationInterval}ms`);
		this.progressBarWrapper.nativeElement.style.setProperty('--starting-progress-width', `${this.preorderPercentage}%`);
		this.progressBarWrapper.nativeElement.style.setProperty('--total-progress-width', `${this.totalPercentage}%`);
	}

}

export { GoalProgressComponent, TOTAL_INTERVAL_MS_FAST, TOTAL_INTERVAL_MS_SLOW };
