import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { CartService, CartItemRecipient, CartItem } from '../../../services/apis/cart/cart.service';
import { RecommendedListService } from '../../../services/apis/recommended.list/recommended.list.service';
import { BookViewType } from '../../book/book';
import { ModalOptions } from 'ngx-bootstrap';
import { AddToCartModalComponent } from '../../add.to.cart.modal/add.to.cart.modal';
import { timer } from 'rxjs';
import { AddToCartFromTeacherWishlistModalComponent } from '../../../guest/view.wishlist/add.to.cart.from.teacher.wishlist.modal/add.to.cart.from.teacher.wishlist.modal';
import { BookClubService, BookClub } from '../../../services/apis/book.club/book.club.service';
import { ModalService } from '../../../services/helpers/modal.service';
import { UserService } from '../../../services/apis/user/user.service';
import { fade } from '../../animations/fade';
import { ActionButtonsComponent } from '../action.buttons';

@Component({
	selector: 'cart-buttons',
	templateUrl: './cart.buttons.html',
	styleUrls: ['../action.buttons.css'],
	animations: [fade]
})

class CartButtonsComponent extends ActionButtonsComponent implements OnInit, OnChanges {
	@Input() public viewType: BookViewType;
	@Input() public fromTeacherWishlist: boolean;
	@Input() public defaultCartItemRecipient: CartItemRecipient;
	@Output() public addedToCart: EventEmitter<void> = new EventEmitter();

	public preventQuantityChange: boolean;
	public useInvoicePayment: boolean;
	public donationOrgName: string;
	public preventRapidAddingOfBooks: boolean;

	private bookClub: BookClub;

	constructor (analyticsService: AnalyticsService, public cartService: CartService, private recommendedListService: RecommendedListService, private modalService: ModalService,
							 private bookClubService: BookClubService, private userService: UserService) {
		super(analyticsService);
	}

	public ngOnInit () : void {
		this.bookClub = this.bookClubService.getCachedBookClub();
		if (this.bookClub && this.bookClub.settings) {
			this.preventQuantityChange = this.bookClub.settings.preventQuantityChange;
			this.useInvoicePayment = this.bookClub.settings.useInvoicePayment;
			this.donationOrgName = this.bookClub.settings.donationOrgName;
			this.preventRapidAddingOfBooks = this.bookClub.settings.requiredNumberOfBooks > 0;
		}
		if (this.preventQuantityChange && !this.userService.isTeacher()) {
			this.inList = this.cartService.isInMyCart(this.book._id);
			this.cleanup.push(this.cartService.onCartUpdate.subscribe(() => {
				this.inList = this.cartService.isInMyCart(this.book._id);
			}));
		}
	}

	public ngOnChanges () : void {
		if (this.preventQuantityChange && !this.userService.isTeacher()) {
			this.inList = this.cartService.isInMyCart(this.book._id);
		}
	}

	public addToCart (event: Event) : void {
		this.stopProp(event);
		this.updateAddingToCart(true);
		const cartItems: Array<CartItem> = this.cartService.getCachedCartItems();
		if (this.donationOrgName) {
			this.cleanup.push(this.cartService.addToCart(this.book._id, this.donationOrgName, '', { firstName: this.donationOrgName, lastName: '' }, false, this.fromTeacherWishlist || false).subscribe(() => {
				this.cartService.onAddedToCart.next();
				if (this.addedToCart) {
					this.showConfirmation = true;
					this.addedToCart.emit();
				}
				this.updateAddingToCart(false);
			}));
		} else if (!this.useInvoicePayment || !cartItems || cartItems.length === 0) {
			if (this.addedToCart) {
				this.addedToCart.emit();
			}
			this.openAddToCartModal();
		} else {
			const firstCartItem: CartItem = cartItems[0];
			this.cleanup.push(this.cartService.addToCart(this.book._id, firstCartItem.recipient.firstName, firstCartItem.recipient.lastName, firstCartItem.recipient.teacherName, firstCartItem.gift, false).subscribe(() => {
				if (this.addedToCart) {
					this.showConfirmation = true;
					this.addedToCart.emit();
				}
				this.cartService.onAddedToCart.next();
				this.updateAddingToCart(false);
			}));
		}
		const recommended: boolean = this.recommendedListService.isInMyRecommendedList(this.book._id);
		this.analyticsService.sendEvent('Quick Button Add To Cart', `From: ${this.viewType} - ${this.book.title} - Recommended? ${recommended}`);
	}

	public openAddToCartModal () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg add-to-cart',
			initialState: {
				book: this.book,
				defaultCartItemRecipient: this.defaultCartItemRecipient,
				onSuccess: () => {
					this.cleanup.push(timer(500).subscribe(() => {
						this.showConfirmation = true;
						this.cartService.onAddedToCart.next();
					}));
				}
			}
		};
		if (this.fromTeacherWishlist) {
			this.modalService.show(AddToCartFromTeacherWishlistModalComponent, config);
		} else {
			this.modalService.show(AddToCartModalComponent, config);
		}
		this.cleanup.push(this.modalService.onHide.subscribe(() => {
			this.updateAddingToCart(false);
		}));
	}

	public updateAddingToCart (value: boolean) : void {
		this.adding = value;
		if (this.preventRapidAddingOfBooks) {
			this.cartService.addingToCart = value;
		}
	}
}

export { CartButtonsComponent };
