import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { timer } from 'rxjs';


@Component({
	selector: 'action-button-confirmation',
	templateUrl: './action.button.confirmation.html',
	styleUrls: ['./action.button.confirmation.css']
})

class ActionButtonConfirmationComponent extends BaseComponent implements AfterViewInit {

	@Input() public size: number;
	@Input() public color: string;
	@Input() public showText: boolean;
	@Output() public close: EventEmitter<void> = new EventEmitter();

	@ViewChild('wrapper', {static: true}) public wrapper: ElementRef;

	public ngAfterViewInit () : void {
		if (this.wrapper && this.wrapper.nativeElement && this.wrapper.nativeElement.style) {
			this.wrapper.nativeElement.style.setProperty('--size', this.size + 'px');
			this.wrapper.nativeElement.style.setProperty('--color', this.color);
			this.cleanup.push(timer(1500).subscribe(() => {
				this.dismiss();
			}));
		}
	}

	public dismiss (event?: Event) : void {
		if (event) {
			event.stopPropagation();
		}
		this.close.emit();
	}

}

export { ActionButtonConfirmationComponent };
