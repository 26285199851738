import { Component } from '@angular/core';
import { Address } from '../../../services/apis/school/school.service';
import { LiveChatService } from '../../../services/helpers/live.chat.service';
import { BaseSupportModalComponent } from '../../support.modals/base.support.modal';
import { BsModalRef } from 'ngx-bootstrap';
import { Assets } from '../../assets';

@Component({
	selector: 'po-box-modal',
	templateUrl: './po.box.modal.html',
	styleUrls: ['./po.box.modal.css']
})

class POBoxModalComponent extends BaseSupportModalComponent {
	public name: string;
	public address: Address;
	public SUPPORT_PHONE_NUMBER: string = Assets.SUPPORT_PHONE_NUMBER;

	constructor (liveChatService: LiveChatService, modal: BsModalRef) {
		super(liveChatService, modal);
	}
}

export { POBoxModalComponent };
