import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';

class ModalService extends BsModalService {

	private openModals: Array<BsModalRef> = [];

	public show (content: any, config: ModalOptions) : BsModalRef {
		const ref: BsModalRef = super.show(content, config);
		this.openModals.push(ref);
		return ref;
	}

	public hideAll () : void {
		for (const ref of this.openModals) {
			ref.hide();
		}
		this.openModals = [];
	}
}

export { ModalService };
