import { BaseComponent } from '../../base.component';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { UserService } from '../../services/apis/user/user.service';
import { Router } from '@angular/router';
import { ModalOptions } from 'ngx-bootstrap';
import { TopSearchSmallModalComponent } from './top.search.small.modal/top.search.small.modal';
import { WindowRef } from '../../utils/window.ref';
import { fade } from '../animations/fade';
import { SearchService, LAST_URL } from '../../services/helpers/search.service';
import { ModalService } from '../../services/helpers/modal.service';

@Component({
	selector: 'top-search',
	templateUrl: './top.search.html',
	styleUrls: ['./top.search.css'],
	animations: [fade]
})

class TopSearchComponent extends BaseComponent {
	@ViewChild('searchInput', {static: false}) public searchInput: ElementRef<HTMLInputElement>;
	public searchTerm: string;
	public showClear: boolean;

	constructor (protected analyticsService: AnalyticsService, protected userService: UserService, protected router: Router, protected modalService: ModalService,
							 private windowRef: WindowRef, private searchService: SearchService) {
		super();
	}

	public search () : void {
		const currentUrl: string = this.windowRef.getWindow().location.pathname;
		this.searchTerm = this.searchTerm ? this.searchTerm.trim() : null;
		if (this.searchTerm && this.searchTerm.length > 0) {
			this.toggleClear(false);
			this.searchInput.nativeElement.blur();
			if (currentUrl.endsWith('/search') ) {
				this.searchService.searchExecuted.next(this.searchTerm);
			} else {
				localStorage.setItem(LAST_URL, decodeURI(currentUrl));
				this.analyticsService.sendEvent(`Go To Search From Topbar`, this.searchTerm || '');
				this.router.navigate([ `/${this.userService.isTeacher() ? 'teacher' : 'guest'}/search`, { searchTerm: this.searchTerm || '' }]);
			}
		}
	}

	public openSmallSearchModal () : void {
		this.analyticsService.sendEvent(`Open (Mobile) Search Modal In Topbar`);
		const config: ModalOptions = {
			animated: true,
			class: 'modal-sm search-modal'
		};
		this.modalService.show(TopSearchSmallModalComponent, config);
	}

	public clearSearchTerm () : void {
		this.searchTerm = '';
		this.toggleClear(false);
	}

	public toggleClear (isFocusing: boolean) : void {
		this.showClear = isFocusing && !!this.searchTerm && this.searchTerm.length > 0;
	}

	public preventBlur (event: Event) : void {
		event.preventDefault();
	}

}

export { TopSearchComponent };
