import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { AbstractBookCarouselComponent } from '../abstract.book.carousel/abstract.book.carousel';
import { Book, BookService } from '../../../services/apis/book/book.service';
import { BookClubService, BookList } from '../../../services/apis/book.club/book.club.service';
import { TitleDetailsService } from '../../../services/helpers/title.details.service';
import { carouselSlide } from '../../animations/slide';
import { ActualBookCarouselComponent } from '../actual.book.carousel/actual.book.carousel';
import { BookFilterService } from '../../../services/helpers/book.filter.service';
import { BookViewType } from '../../book/book';
import { MobileService } from '../../../services/helpers/mobile.service';

@Component({
	selector: 'book-list-carousel',
	templateUrl: './book.list.carousel.html',
	styleUrls: ['../abstract.book.carousel/abstract.book.carousel.css'],
	animations: [carouselSlide]
})

class BookListCarouselComponent extends AbstractBookCarouselComponent implements OnInit {
	@Input() public bookList: BookList;
	@Input() public showDelete: boolean;
	@Output() public toggleBookRecommendation: EventEmitter<Book> = new EventEmitter<Book>();
	@ViewChild(ActualBookCarouselComponent, {static: false}) public carousel: ActualBookCarouselComponent;
	public BookViewType: typeof BookViewType = BookViewType;
	public isFilterApplied: boolean;

	constructor (private bookClubService: BookClubService, private titleDetailsService: TitleDetailsService, private bookService: BookService,
							 private bookFilterService: BookFilterService, protected mobileService: MobileService) {
		super(mobileService);
	}

	public ngOnInit () : void {
		this.cleanup.push(this.bookClubService.onBookListUpdate.subscribe((bookList: BookList) => {
			if (bookList._id === this.bookList._id) {
				this.bookList = bookList;
				this.fetchBooks();
			}
		}));

		this.cleanup.push(this.titleDetailsService.updatePositionInCarousel.subscribe((response: any) => {
			if (response.id === this.bookList._id && this.carousel) {
				this.carousel.goToCarouselPageFromTitleDetails(response);
			}
		}));

		this.cleanup.push(this.bookFilterService.onFilterChange.subscribe(() => {
			this.fetchBooks();
		}));

		super.ngOnInit();
	}

	protected fetchBooks () : void {
		this.isFilterApplied = this.bookFilterService.isFiltering();
		this.shouldShowCarousel = !!this.bookList.book_ids.find((book_id: string) => {
			const book: Book = this.bookService.getCachedBook(book_id);
			return this.bookFilterService.shouldShowBookByAvailabilityAndCoverUrl(book);
		});
		this.book_ids = this.bookList.book_ids.filter((book_id: string) => {
			const book: Book = this.bookService.getCachedBook(book_id);
			return this.bookFilterService.shouldShowBook(book);
		});
		if (this.titleDetailsService.getBookListId() === this.bookList._id) {
			this.titleDetailsService.setBook_ids(this.book_ids);
		}
		if (!this.shouldShowCarousel) {
			this.loaded.emit();
		}
	}
}

export { BookListCarouselComponent };
