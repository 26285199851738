import { environment } from '../../environments/environment';

class ConfigUtils {

	public static getApiHost () : string {
		let apiUrl: any = environment.apiUrl;
		if (apiUrl === undefined) {
			apiUrl = `${window.location.protocol}//${window.location.hostname}`;
		}
		return apiUrl;
	}

}

export { ConfigUtils };
