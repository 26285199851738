import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Book, BookService } from '../../services/apis/book/book.service';
import { BookClubService, BookClub } from '../../services/apis/book.club/book.club.service';
import { UserService } from '../../services/apis/user/user.service';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { TitleDetailsService } from '../../services/helpers/title.details.service';
import { TitleDetailsCarouselComponent } from '../title.details.carousel/title.details.carousel';
import { BaseComponent } from '../../base.component';
import { Displayables } from '../../utils/displayables';
import { RecommendedListService } from '../../services/apis/recommended.list/recommended.list.service';
import { TrailerModalComponent } from '../trailer.modal/trailer.modal';
import { fade } from '../animations/fade';
import { CartItemRecipient } from '../../services/apis/cart/cart.service';
import { BookDiscountService } from '../../services/apis/book.discount/book.discount.service';
import { Log } from '../../services/helpers/log.service';
import { ReadingLevelTypeDisplayable } from '../../constants/reading.level.types';
import { BOOK_LANGUAGES, Language } from '../../constants/book.languages';
import { ModalService } from '../../services/helpers/modal.service';
import { NotYetPublishedHelper } from '../../services/helpers/not.yet.published.helper';
import { BookClubStatus } from '../../constants/book.club.status';

const BOOK_WIDTH: number = 190;

enum BookViewType {
	DEFAULT = 'Default',
	SEARCH_RESULT = 'Search',
	ARCHIVE_LIST = 'Archive',
	BOOK_LIST = 'Book List',
	RECOMMENDED_LIST = 'Recommended List'
}
@Component({
	selector: 'book',
	templateUrl: './book.html',
	styleUrls: ['./book.css'],
	animations: [fade]
})

class BookComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public book_id: string;
	@Input() public bookListBook_ids: Array<string>;
	@Input() public bookList_id: string;
	@Input() public viewType?: BookViewType = BookViewType.DEFAULT;
	@Input() public bookInList: boolean = false;
	@Input() public showArchive: boolean;
	@Input() public showDelete: boolean;
	@Input() public showUnarchive: boolean;
	@Input() public fromTeacherWishlist: boolean;
	@Input() public fromWishlist: boolean;
	@Input() public defaultCartItemRecipient: CartItemRecipient;

	public tdModalRef: BsModalRef;
	public bookClubStatus: BookClubStatus;
	public loading: boolean;
	public displayables: Displayables = Displayables;
	public ReadingLevelTypeDisplayable: typeof ReadingLevelTypeDisplayable = ReadingLevelTypeDisplayable;
	public book: Book;
	public discountedPrice: string;
	public displayRecommendationTextArea: boolean;
	public recommended: boolean;
	public undoTimerStarted: boolean;
	public hideCart: boolean;
	public useInvoicePayment: boolean;
	public isTeacher: boolean;
	public disableAddToWishlist: boolean;

	public showAddToCart: boolean;
	public showWishlistButton: boolean;
	public startDateDisplayable: string;
	public language: Language;
	public bookClub: BookClub;

	constructor (protected bookService: BookService, protected analyticsService: AnalyticsService, protected userService: UserService, protected titleDetailsService: TitleDetailsService,
							 protected modalService: ModalService, protected bookClubService: BookClubService, protected recommendedListService: RecommendedListService,
							 protected bookDiscountService: BookDiscountService) {
		super();
	}
	public ngOnInit () : void {
		this.bookService.onClickedOutsideCoverRecommendation.subscribe(() => {
			this.displayRecommendationTextArea = false;
		});

		if (!this.book_id) {
			return;
		}

		const bookClub: BookClub = this.bookClubService.getCachedBookClub();
		this.bookClub = bookClub;
		this.updateBookData();
		this.hideCart = bookClub.settings && bookClub.settings.hideCart;
		this.useInvoicePayment = bookClub.settings && bookClub.settings.useInvoicePayment;
		this.disableAddToWishlist = bookClub.settings && bookClub.settings.disableAddToWishlist;
		this.bookClubStatus = bookClub.status;

		this.isTeacher = this.userService.isTeacher();

		this.showAddToCart = true;

		this.updateFlagsBasedOnUserTypeAndViewType();
		this.updateFlagsBasedOnBookClubStatus(bookClub);
	}

	private updateFlagsBasedOnUserTypeAndViewType () : void {
		if (this.isTeacher) {
			this.cleanup.push(this.recommendedListService.onListUpdate.subscribe(() => {
				this.recommended = this.recommendedListService.isInMyRecommendedList(this.book._id);
				this.displayRecommendationTextArea = this.recommended && this.displayRecommendationTextArea;
			}));
		} else {
			this.showAddToCart = !this.hideCart;
			this.showWishlistButton = !this.fromWishlist && !this.disableAddToWishlist;
		}
	}

	private updateFlagsBasedOnBookClubStatus (bookClub: BookClub) : void {
		if (this.bookClubStatus !== BookClubStatus.STARTED) {
			this.showAddToCart = false;
			if (bookClub) {
				this.startDateDisplayable = Displayables.getDateInTimezone(bookClub.startDate, bookClub.timezone, 'M/D');
			}
		}
	}

	public ngOnChanges () : void {
		if (this.book_id) {
			this.updateBookData();
		}
	}

	public ngOnDestroy () : void {
		if (this.tdModalRef) {
			this.tdModalRef.hide();
		}
		super.ngOnDestroy();
	}

	private updateBookData () : void {
		this.book = this.bookService.getCachedBook(this.book_id);
	 	this.book.isNYP = NotYetPublishedHelper.isNypBook(this.book);
		this.discountedPrice = this.bookDiscountService.getCachedBookDiscountedPrice(this.book.titlewave_id);
		this.language = BOOK_LANGUAGES.get(this.book.textLanguage);
		this.recommended = this.recommendedListService.isInMyRecommendedList(this.book._id);
	}

	public openTitleDetails (event: Event) : void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg title-details-modal',
			initialState: {
				fromTeacherWishlist: this.fromTeacherWishlist,
				fromWishlist: this.fromWishlist,
				defaultCartItemRecipient: this.defaultCartItemRecipient
			}
		};
		let found: boolean = false;
		const realBookIdList: Array<string> = this.bookListBook_ids ? this.bookListBook_ids.slice() : [];

		const bookIndex: number = realBookIdList.indexOf(this.book._id);
		if (bookIndex > -1) {
			this.analyticsService.sendEvent('See Details', '\'' + this.book.title + '\'');
			found = true;
			this.titleDetailsService.setIndex(bookIndex);
			this.titleDetailsService.setBook_ids(realBookIdList);
			this.titleDetailsService.setBookListId(this.bookList_id);
			this.tdModalRef = this.modalService.show(TitleDetailsCarouselComponent, config);
		}
		if (!found) {
			this.analyticsService.sendEvent('See Details Failed', '\'' + this.book.title + '\'');
			Log.log('Unable to open titledetails because it is not in this list: ' + this.book.title);
		}
	}

	public delete (event: any) : void {
		if (event) {
			event.stopPropagation();
		}
		this.undoTimerStarted = true;
	}

	public deleteBook () : void {
		this.loading = true;
		this.cleanup.push(this.bookClubService.removeBookFromList(this.bookList_id, this.book_id).subscribe(() => {
			this.loading = false;
		}, () => {
			this.loading = false;
		}));
	}

	public archive (event: any) : void {
		this.loading = true;
		if (event) {
			event.stopPropagation();
		}
		this.analyticsService.sendEvent('Archive Book', '\'' + this.book.title + '\'');
		this.cleanup.push(this.recommendedListService.archiveBook(this.book._id).subscribe(() => {
			this.loading = false;
		}));
	}

	public unarchive (event: any) : void {
		if (event) {
			event.stopPropagation();
		}
		this.loading = true;
		this.analyticsService.sendEvent('Unarchive Book');
		this.cleanup.push(this.recommendedListService.unarchiveBook(this.book._id).subscribe(() => {
			this.loading = false;
		}));
	}

	public showTrailer (event: any) : void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg trailer-modal',
			initialState: {
				book: this.book
			}
		};
		this.modalService.show(TrailerModalComponent, config);

	}

	public toggleRecommendationTextArea (inputValue: boolean, event?: Event) : void {
		if (event) {
			event.stopPropagation();
		}
		this.displayRecommendationTextArea = inputValue;
	}

}

export { BookComponent, BOOK_WIDTH, BookViewType };
