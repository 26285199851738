import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { UserService, User } from '../../services/apis/user/user.service';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { SchoolService, School, Staff } from '../../services/apis/school/school.service';
import { timer } from 'rxjs';
import { fade } from '../animations/fade';
import { BookClub } from '../../services/apis/book.club/book.club.service';
import { ModalOptions } from 'ngx-bootstrap';
import { SchoolwideStaffImportModalComponent } from '../schoolwide.staff.import.modal/schoolwide.staff.import.modal';
import { MobileService } from '../../services/helpers/mobile.service';
import { ModalService } from '../../services/helpers/modal.service';
import { ErrorHelper } from '../../utils/error.helper';
import { ErrorCode } from '../../constants/error.codes';
import { STAFF_NAMES_MAX_LENGTH } from '../../constants/staff.names';

@Component({
	selector: 'schoolwide-staff',
	templateUrl: './schoolwide.staff.html',
	styleUrls: ['./schoolwide.staff.css'],
	animations: [fade]
})

class SchoolwideStaffComponent extends BaseComponent implements OnInit {
	@ViewChild('lastNameElement', { static: false }) public lastNameElement: ElementRef;
	@Input() public school: School;
	@Output() public close: EventEmitter<BookClub> = new EventEmitter();

	public staff: Array<Staff>;
	public showInput: boolean;
	public showImport: boolean;
	public newFirstName: string;
	public newLastName: string;
	public staffToDelete: Staff;
	public loading: boolean;
	public saving: boolean;
	public importFailed: boolean;
	public invalidNames: Array<string>;
	public STAFF_NAMES_MAX_LENGTH: number = STAFF_NAMES_MAX_LENGTH;

	constructor (private analyticsService: AnalyticsService, private userService: UserService, private schoolService: SchoolService, private modalService: ModalService,
							 private mobileService: MobileService) {
		super();
	}

	public ngOnInit () : void {
		this.showImport = !(this.mobileService.isMobile() || this.mobileService.isIpad());
		this.newFirstName = '';
		this.newLastName = '';
		this.invalidNames = [];

		const user: User = this.userService.getUser();
		this.addCurrentUserToStaff(user);
		this.setSchool(this.school);
	}

	private staffSort () : Array<Staff> {
		return this.staff.sort((staff1: Staff, staff2: Staff) => {
			const name1: string = `${staff1.lastName} ${staff1.firstName}`;
			const name2: string = `${staff2.lastName} ${staff2.firstName}`;
			return name1.localeCompare(name2, 'en', { sensitivity: 'base'});
		});
	}

	public addCurrentUserToStaff (user: User ) : void {
		const userName: string = user.name ? user.name.trim() : '';

		if ((!this.staff || this.staff.length === 0) && user.name.length > 0) {
			this.newFirstName = userName.split(' ').slice(0, -1).join(' ');
			this.newLastName = userName.split(' ').slice(-1).join(' ');
			this.addStaffMember(true);
		}
	}

	private setSchool (school: School) : void {
		this.school = school;
		this.staff = school.efairDefaults.staff || [];
		this.staff = this.staffSort();
	}

	public addStaffMember (fromInit?: boolean) : void {
		this.showInput = !fromInit;
		this.cleanup.push(timer(0).subscribe(() => {
			if (this.lastNameElement) {
				this.lastNameElement.nativeElement.focus();
			}
		}));
		if (!this.saving && (this.newFirstName.trim().length > 0 || this.newLastName.trim().length > 0)) {
			this.analyticsService.sendEvent('Schoolwide Staff', 'Add Name');
			this.saving = true;
			this.cleanup.push(this.schoolService.addStaff(this.school._id, [{firstName: this.newFirstName.trim(), lastName: this.newLastName.trim()}]).subscribe((school: School) => {
				this.saving = false;
				this.newFirstName = '';
				this.newLastName = '';
				this.setSchool(school);
			}, (err: Error) => this.handleError(err)));
		}
	}

	public addStaffList (names: Array<{ firstName: string, lastName: string }>) : void {
		this.saving = true;
		this.cleanup.push(this.schoolService.addStaff(this.school._id, names).subscribe((school: School) => {
			this.saving = false;
			this.invalidNames = [];
			this.setSchool(school);
		}, (err: Error) => this.handleError(err, true)));
	}

	public pasteName (event: ClipboardEvent) : void {
		const clipboardData: DataTransfer = event.clipboardData;
		const name: string = clipboardData.getData('text').trim();
		if (name.indexOf(',') > -1) {
			const names: Array<string> = name.split(',');
			this.cleanup.push(timer(0).subscribe(() => {
				this.newLastName = names.splice(0, 1)[0].trim();
				this.newFirstName = names.join(' ').trim();
			}));
		} else if (name.search(/\s/) > -1) {
			const names: Array<string> = name.split(/\s/);
			this.cleanup.push(timer(0).subscribe(() => {
				this.newLastName = names.splice(names.length - 1, 1)[0].trim();
				this.newFirstName = names.join(' ').trim();
			}));
		}
	}

	public displayRemovalConfirm (staffMember: Staff) : void {
		this.analyticsService.sendEvent('Schoolwide Staff', 'Confirm Delete Name');
		this.staffToDelete = staffMember;
	}

	public cancelRemovalConfirm () : void {
		this.analyticsService.sendEvent('Schoolwide Staff', 'Cancel Delete Name');
		this.staffToDelete = null;
	}

	public removeStaffMember () : void {
		this.saving = true;
		this.cleanup.push(this.schoolService.deleteStaff(this.school._id, this.staffToDelete.firstName, this.staffToDelete.lastName).subscribe((school: School) => {
			this.saving = false;
			this.setSchool(school);
			this.cleanup.push(timer(0).subscribe(() => {
				if (this.lastNameElement) {
					this.lastNameElement.nativeElement.focus();
				}
			}));
		}, () => {
			this.saving = false;
		}));
		this.staffToDelete = null;
	}

	public openSchoolwideStaffImportModal () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg schoolwide-staff-import-modal',
			initialState: {
				onSuccess: (names: Array<{ firstName: string, lastName: string }>) => {
					this.addStaffList(names);
				}
			}
		};
		this.modalService.show(SchoolwideStaffImportModalComponent, config);
	}

	public saveNameAndCloseModal () : void {
		if (this.newFirstName || this.newLastName) {
			this.addStaffMember();
		}
		this.close.emit();
	}

	public handleError (err: Error, fromImport?: boolean) : void {
		const error: any = ErrorHelper.getRootError(err);
		this.invalidNames = [];
		if (error.code === ErrorCode.StaffNameTooLong) {
			this.importFailed = fromImport;
			for (const detail of error.details) {
				this.invalidNames.push(detail.message);
			}
		}
		this.saving = false;
	}

	public clearError () : void {
		this.invalidNames = [];
		this.importFailed = undefined;
	}

}

export { SchoolwideStaffComponent };
