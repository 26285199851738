import { Component, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from '../../../base.component';
import { Book, BookService } from '../../../services/apis/book/book.service';
import { Staff } from '../../../services/apis/school/school.service';
import { EditWishlistService, WishlistItem } from '../../../services/apis/wishlist/edit.wishlist.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { MAX_INPUT_SPINNER_VALUE, MIN_INPUT_SPINNER_VALUE } from '../../input.spinner/input.spinner';

@Component({
	selector: 'manage-wishlist-item',
	templateUrl: './manage.wishlist.item.html',
	styleUrls: ['./manage.wishlist.item.css']
})


class ManageWishlistItemComponent extends BaseComponent {
	@Output() public updating: EventEmitter<any> = new EventEmitter<boolean>();

	@Input() public book: Book;
	@Input() public item: WishlistItem;
	@Input() public teacherWishlistName: Staff;

	@ViewChild('valueInput', {static: false}) public valueInput: ElementRef<HTMLInputElement>;

	public updatingQuantity: boolean = false;
	public removing: boolean = false;
	public editingQuantity: boolean = false;
	public newQuantity: string;

	constructor (private analyticsService: AnalyticsService, public bookService: BookService, private editWishlistService: EditWishlistService) {
		super();
	}

	public clickRemove () : void {
		this.analyticsService.sendEvent('Wish List Remove Item', '\'' + this.book.title + '\'');
		this.removing = true;
		this.updating.emit(true);
		this.cleanup.push(this.editWishlistService.removeFromWishlist(this.book._id).subscribe(() => {
			this.removing = false;
			this.updating.emit(false);
		}));
	}

	public clickDecrementQuantity () : void {
		this.analyticsService.sendEvent('Wish List Decrement Count', '\'' + this.book.title + '\' - Before Quantity: ' + this.item.quantity);
		if (this.item.quantity > MIN_INPUT_SPINNER_VALUE) {
			this.updatingQuantity = true;
			this.updating.emit(true);
			this.item.quantity = this.item.quantity - 1;
			this.cleanup.push(this.editWishlistService.updateQuantity(this.item).pipe(finalize(() => {
				this.updatingQuantity = false;
				this.updating.emit(false);
			})).subscribe());
		}
	}

	public clickIncrementQuantity () : void {
		this.analyticsService.sendEvent('Wish List Increment Count', '\'' + this.book.title + '\' - Before Quantity: ' + this.item.quantity);
		if (this.item.quantity < MAX_INPUT_SPINNER_VALUE) {
			this.updatingQuantity = true;
			this.updating.emit(true);
			this.item.quantity = this.item.quantity + 1;
			this.cleanup.push(this.editWishlistService.updateQuantity(this.item).pipe(finalize(() => {
				this.updatingQuantity = false;
				this.updating.emit(false);
			})).subscribe());
		}
	}

	public setQuantity (newQuantity: number) : void {
		this.analyticsService.sendEvent('Wish List Set Quantity', '\'' + this.book.title + '\' - Before Quantity: ' + this.item.quantity + ' - After Quantity: ' + newQuantity);
		this.updatingQuantity = true;
		this.updating.emit(true);
		this.item.quantity = newQuantity;
		this.cleanup.push(this.editWishlistService.updateQuantity(this.item).pipe(finalize(() => {
			this.updatingQuantity = false;
			this.updating.emit(false);
		})).subscribe());
	}
}

export { ManageWishlistItemComponent };
