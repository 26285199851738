import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BookService, Book } from '../../services/apis/book/book.service';
import { BaseComponent } from '../../base.component';
import { WishlistItem, EditWishlistService } from '../../services/apis/wishlist/edit.wishlist.service';
import { Staff } from '../../services/apis/school/school.service';
import { Displayables } from '../../utils/displayables';

@Component({
	selector: 'manage-wishlist',
	templateUrl: './manage.wishlist.html',
	styleUrls: ['./manage.wishlist.css']
})


class ManageWishlistComponent extends BaseComponent implements OnInit {
	@Output() public clickShare: EventEmitter<any> = new EventEmitter<any>();

	public books: Map<string, Book>;
	public teacherWishlistName: Staff;
	public items: Array<WishlistItem>;
	public updating: boolean;
	public displayables: typeof Displayables = Displayables;

	constructor (private analyticsService: AnalyticsService, public bookService: BookService, private editWishlistService: EditWishlistService) {
		super();
	}

	public ngOnInit () : void {
		this.populateBooks(this.editWishlistService.getWishlistItems());

		this.cleanup.push(this.editWishlistService.onWishlistUpdate.subscribe(() => {
			this.populateBooks(this.editWishlistService.getWishlistItems());
		}));
	}

	public populateBooks (items: Array<WishlistItem>) : void {
		this.items = items;
		this.teacherWishlistName = this.editWishlistService.getTeacherWishlistName();
		this.books = new Map();
		for (const item of items) {
			this.books.set(item.book_id, this.bookService.getCachedBook(item.book_id));
		}
	}

	public share () : void {
		this.analyticsService.sendEvent('Wish List Share');
		this.clickShare.emit();
	}

	public onUpdate (isUpdating: boolean) : void {
		this.updating = isUpdating;
	}

}

export { ManageWishlistComponent };
