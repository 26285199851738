import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'unsupported-device-modal',
	templateUrl: './unsupported.device.modal.html',
	styleUrls: ['./unsupported.device.modal.css']
})

class UnsupportedDeviceModalComponent {

	constructor (private bsModalRef: BsModalRef) {}

	public close () : void {
		this.bsModalRef.hide();
	}
}

export { UnsupportedDeviceModalComponent };
