import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BookService, Book } from '../../services/apis/book/book.service';
import { CartItem, CartItemRecipient, CartService } from '../../services/apis/cart/cart.service';
import { BaseComponent } from '../../base.component';
import { Displayables } from '../../utils/displayables';
import { UserService } from '../../services/apis/user/user.service';
import { BookClub, BookClubService } from '../../services/apis/book.club/book.club.service';
import { MAX_INPUT_SPINNER_VALUE, MIN_INPUT_SPINNER_VALUE } from '../input.spinner/input.spinner';
import { LAST_URL } from '../../services/helpers/search.service';
import { Router } from '@angular/router';
import { ModalOptions } from 'ngx-bootstrap';
import { ModalService } from '../../services/helpers/modal.service';
import { UpdateCartRecipientModalComponent } from '../update.cart.recipient.modal/update.cart.recipient.modal';
import { BookClubStatus } from '../../constants/book.club.status';

@Component({
	selector: 'cart',
	templateUrl: './cart.html',
	styleUrls: ['./cart.css']
})

class CartComponent extends BaseComponent implements OnInit {
	@Input() public isLargeView: boolean;
	@Output() public clickCheckout: EventEmitter<any> = new EventEmitter<any>();

	public checkoutDisabled: boolean = false;
	public cartItems: Array<CartItem>;
	public displayables: Displayables = Displayables;
	public loading: boolean = false;
	public bookClub: BookClub;
	public giftPurchasesEnabled: boolean;
	public isDonationEfair: boolean;
	public useInvoicePayment: boolean;
	public isTeacher: boolean;
	public preventQuantityChange: boolean;
	public isShipToHomeEfair: boolean;
	public requiredNumberOfBooks: number;
	public hasRequiredNumberOfBooks: boolean;
	public cartItemCount: number;

	constructor (private analyticsService: AnalyticsService, public bookService: BookService, private cartService: CartService,
							 private userService: UserService, private bookClubService: BookClubService, private router: Router, private modalService: ModalService) {
		super();
	}

	public ngOnInit () : void {
		this.bookClub = this.bookClubService.getCachedBookClub();
		if (this.bookClub && this.bookClub.settings) {
			this.requiredNumberOfBooks = this.bookClub.settings.requiredNumberOfBooks;
			this.giftPurchasesEnabled = !this.bookClub.settings.disableGifts;
			this.isDonationEfair = !!this.bookClub.settings.donationOrgName;
			this.useInvoicePayment = this.bookClub.settings.useInvoicePayment;
			this.preventQuantityChange = this.bookClub.settings.preventQuantityChange;
			this.isShipToHomeEfair = this.bookClub.settings.shipToHome;
		}
		this.isTeacher = this.userService.isTeacher();
		if (!this.isLargeView && this.useInvoicePayment && !this.isShipToHomeEfair) {
			this.router.navigate(['/cart']);
		} else {
			this.cartItems = this.cartService.getCachedCartItems();
			this.cartItemCount = this.cartService.getItemCount();
			this.hasRequiredNumberOfBooks = !this.requiredNumberOfBooks || this.requiredNumberOfBooks === this.cartItemCount;
			this.checkoutDisabled = !this.userService.getTeacher() || !this.bookClub || this.bookClub.status !== BookClubStatus.STARTED;
			this.cleanup.push(this.cartService.onCartUpdate.subscribe((cartItems: Array<CartItem>) => {
				this.cartItems = cartItems;
				this.cartItemCount = this.cartService.getItemCount();
				this.hasRequiredNumberOfBooks = !this.requiredNumberOfBooks || this.requiredNumberOfBooks === this.cartItemCount;
			}));
		}
	}

	public getCartTotal () : number {
		return this.cartService.getCartTotal();
	}

	public getBook (book_id: string) : Book {
		return this.bookService.getCachedBook(book_id);
	}

	public clickRemove (cartItem: CartItem) : void {
		const book: Book = this.getBook(cartItem.book_id);
		this.analyticsService.sendEvent('Cart Remove Item', '\'' + book.title + '\' - Before Quantity: ' + cartItem.quantity);
		this.loading = true;
		this.cleanup.push(this.cartService.removeFromCart(cartItem).subscribe(() => {
			this.loading = false;
		}, () => {
			this.loading = false;
		}));
	}

	public clickDecrementQuantity (cartItem: CartItem) : void {
		const book: Book = this.getBook(cartItem.book_id);
		this.analyticsService.sendEvent('Cart Decrement Count', '\'' + book.title + '\' - Before Quantity: ' + cartItem.quantity);
		if (cartItem.quantity > MIN_INPUT_SPINNER_VALUE) {
			this.loading = true;
			cartItem.quantity = cartItem.quantity - 1;
			this.cleanup.push(this.cartService.updateCartItem(cartItem).subscribe(() => {
				this.loading = false;
			 }, () => {
				this.loading = false;
			}));
		}
	}

	public clickIncrementQuantity (cartItem: CartItem) : void {
		const book: Book = this.getBook(cartItem.book_id);
		this.analyticsService.sendEvent('Cart Increment Count', '\'' + book.title + '\' - Before Quantity: ' + cartItem.quantity);
		if (cartItem.quantity < MAX_INPUT_SPINNER_VALUE) {
			this.loading = true;
			cartItem.quantity = cartItem.quantity + 1;
			this.cleanup.push(this.cartService.updateCartItem(cartItem).subscribe(() => {
				this.loading = false;
			}, () => {
				this.loading = false;
			}));
		}
	}

	public setQuantity (cartItem: CartItem, newQuantity: number) : void {
		const book: Book = this.getBook(cartItem.book_id);
		this.analyticsService.sendEvent('Cart Set Quantity', '\'' + book.title + '\' - Before Quantity: ' + cartItem.quantity + ' - After Quantity: ' + newQuantity);
		this.loading = true;
		cartItem.quantity = newQuantity;
		this.cleanup.push(this.cartService.updateCartItem(cartItem).subscribe(() => {
			this.loading = false;
		}, () => {
			this.loading = false;
		}));
	}

	public checkout () : void {
		this.analyticsService.sendEvent('Cart Checkout');
		this.clickCheckout.emit();
	}

	public hasRecipient (recipient: CartItemRecipient) : boolean {
		let result: boolean = recipient.firstName && recipient.firstName.trim().length > 0;
		result = result || recipient.lastName && recipient.lastName.trim().length > 0;
		result = result || recipient.teacherName && recipient.teacherName.firstName &&  recipient.teacherName.firstName.trim().length > 0;
		result = result || recipient.teacherName && recipient.teacherName.lastName &&  recipient.teacherName.lastName.trim().length > 0;
		return result;
	}

	public navigateBack () : void {
		this.analyticsService.sendEvent('Search Back Button Clicked');
		const lastUrl: string = localStorage.getItem(LAST_URL);
		if (lastUrl) {
			localStorage.removeItem(LAST_URL);
			this.router.navigate([lastUrl]);
		} else {
			if (this.isTeacher) {
				this.router.navigate(['teacher/browse']);
			} else {
				this.router.navigate(['guest/home']);
			}
		}
	}

	public openUpdateCartRecipientModal () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg add-to-cart'
		};
		this.modalService.show(UpdateCartRecipientModalComponent, config);
	}
}

export { CartComponent };
