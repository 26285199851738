import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap';
import { BaseComponent } from '../../../base.component';
import { ErrorCode } from '../../../constants/error.codes';
import { SHOW_PREVIEWS } from '../../../constants/site.settings';
import { Book, BookService } from '../../../services/apis/book/book.service';
import { SiteSettings, SiteSettingsService } from '../../../services/apis/site.settings/site.settings.service';
import { ErrorHelper } from '../../../utils/error.helper';
import { WindowRef } from '../../../utils/window.ref';
import { TrailerModalComponent } from '../../trailer.modal/trailer.modal';

@Component({
	selector: 'extra-content-buttons',
	templateUrl: './extra.content.buttons.html',
	styleUrls: ['./extra.content.buttons.css']
})

class ExtraContentButtonsComponent extends BaseComponent implements OnInit {

	@Input() public book: Book;
	public previewNotAvailable: boolean;
	public allowPreview: boolean;

	constructor (private modalService: BsModalService, private bookService: BookService, private windowRef: WindowRef, private siteSettingsService: SiteSettingsService) {
		super();
	}

	public ngOnInit () : void {
		this.cleanup.push(this.siteSettingsService.getOrCreateSiteSettings(SHOW_PREVIEWS).subscribe((setting: SiteSettings) => {
			this.allowPreview = setting.value;
		}));
	}

	public showTrailer () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg trailer-modal',
			initialState: {
				book: this.book
			}
		};
		this.modalService.show(TrailerModalComponent, config);

	}

	public showPreview () : void {
		this.cleanup.push(this.bookService.getPreviewUrl(this.book._id).subscribe((previewUrl: string) => {
			this.windowRef.getWindow().open(previewUrl, '_blank');
		}, (err: any) => {
			const rootError: any = ErrorHelper.getRootError(err);
			if (rootError.code === ErrorCode.PreviewNotAvailable) {
				this.previewNotAvailable = true;
			} else {
				throw err;
			}
		}));

	}


}

export { ExtraContentButtonsComponent };
