import { Injectable, ApplicationRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AnalyticsService } from '../../helpers/analytics.service';
import { USER_EMAIL, USER_NAME, USER_SHIPPING_ADDRESS } from '../../../shared/cart.review/user.info.prompt/user.info.prompt';
import { ModalOptions } from 'ngx-bootstrap';
import { UserService, User } from '../user/user.service';
import { WindowRef } from '../../../utils/window.ref';
import { finalize, catchError } from 'rxjs/operators';
import { Subscription, Subject, Observable, of } from 'rxjs';
import { DialogService } from '../../helpers/dialog.service';
import { ErrorCode } from '../../../constants/error.codes';
import { HttpClient } from '@angular/common/http';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { ConfigService, Configuration } from '../config/config.service';
import { POBoxModalComponent } from '../../../shared/support.modals/po.box.modal/po.box.modal';
import { LoginErrorModalComponent } from '../../../shared/support.modals/login.error.modal/login.error.modal';
import { EditWishlistService } from '../wishlist/edit.wishlist.service';
import { CartService } from '../cart/cart.service';
import { ViewWishlistService } from '../wishlist/view.wishlist.service';
import { LAST_TEACHER_WISHLIST_FROM_NAME_KEY } from '../../../guest/view.wishlist/add.to.cart.from.teacher.wishlist.modal/add.to.cart.from.teacher.wishlist.modal';
import { LAST_URL } from '../../helpers/search.service';
import { ErrorHelper } from '../../../utils/error.helper';
import { REFRESH_TOKEN, ACCESS_TOKEN, CART_ID_KEY, CART_RECENT_RECIPIENT_KEY, CART_RECIPIENT_LIST_KEY, EFAIR_CODE, EDIT_WISHLIST_ID_LOOKUP, EXPIRES_AT, HIDE_BANNER, SELECTED_WISHLIST_ID, VIEW_WISHLIST_IDS_LOOKUP, VISITED_EFAIRS, ID_TOKEN_HINT, PROVIDER_PROFILE, ROLE, CURRENT_EFAIR } from '../../../constants/local.storage';
import { ModalService } from '../../helpers/modal.service';

interface IGetTokenResult {
	auth: any;
	profile?: any;
	id_token_hint: string;
	refresh_token: string;
}

@Injectable()
class AuthService extends BasicCrudService<any> {

	private authWorker: Worker;
	public onAuthenticated: Subject<boolean> = new Subject();
	public onGuestLogin: Subject<void> = new Subject();

	constructor (http: HttpClient, private router: Router, private route: ActivatedRoute, private applicationRef: ApplicationRef, private analyticsService: AnalyticsService,
							private modalService: ModalService, private userService: UserService, private windowRef: WindowRef, private dialogService: DialogService, private configService: ConfigService,
							private cartService: CartService, private editWishlistService: EditWishlistService, private viewWishlistService: ViewWishlistService) {
		super(http);
		this.setUrl('/server/api/auth');

		this.route.queryParamMap.subscribe((params: ParamMap) => {
			// const code: string = params.get('code');
			// const state: string = params.get('state');
			// const error: string =  params.get('error');
			// const description: string = params.get('error_description');
			const token: string = params.get('token');
			localStorage.setItem(ROLE, 'teacher');

			// if (code && state) {
			// 	this.handleAuthentication(code, state);
			// } else if (error && description && state) {
			// 	this.handleAuthError({error: error, errorDescription: description});
			// }

			if (token) {
				this.handleAuthentication(token);
			}
		});

	}

	public login (role: string) : void {
		if (role === 'guest') {
			this.userService.createGuestUser();
			this.onGuestLogin.next();
		} else {
			localStorage.setItem(ROLE, role);
			const window: Window = this.windowRef.getWindow();
			this.configService.getConfig().subscribe((config: Configuration) => {
				// const authRedirect: string = `${config.aaspHost}/oauth2/${config.aaspAuthTenant}/authorize`;
				const authRedirect: string = `${config.authUrl}/account/login`;
				const params: any = {
					// client_id: config.clientID,
					// response_type: 'code',
					// scope: 'openid',
					// nonce: this.createNonce(16),
					// grant_type: 'authorization_code',
					// redirect_uri: window.location.origin,
					// state: window.location.pathname,
					// prompt: 'login'
					callback: window.location.origin
				};
				const paramsStr: string = Object.keys(params).map((key: string) => key + '=' + encodeURIComponent(params[key])).join('&');
				window.open(authRedirect + '?' + paramsStr, '_self');
			});
		}
	}

	public handleAuthentication (code: string/*, state: string*/) : void {
		this.windowRef.getWindow().history.replaceState({}, 'Authenticating', '/');

		this.getToken(code).subscribe({
			next: (result: IGetTokenResult) => {
				const userServiceSubscription: Subscription = this.userService.onUserLoad.subscribe(() => {
					this.analyticsService.sendEvent('Authenticated');
					userServiceSubscription.unsubscribe();
				});
				// this.loadUser(result, state);
				this.loadUser(result);
			},
			error: (error: any) => {
				this.handleAuthError(error);
			}
		});
	}

	public handleAuthError (error: any) : void {
		this.analyticsService.sendEvent('Authentication Error', error);
		this.clearLocalStorage();
		this.resetApplication();
		const rootError: any = ErrorHelper.getRootError(error);
		if (rootError.code === ErrorCode.SchoolAddressPOBoxError) {
			this.analyticsService.sendEvent('PO Box Login', 'User tried to login but is associated with a school that has a PO Box shipping address.');
			this.showPOBoxErrorDialog(rootError.details[0].data);
		} else if (rootError.code === ErrorCode.NextBeeError) {
			this.showNextBeeErrorDialog();
		} else {
			this.showLoginErrorDialog();
		}
	}

	public navigateToMyEfairsPage () : void {
		let observer: Observable<any> = of({});
		if (!this.userService.getUser()) {
			observer = this.userService.getOrCreate();
		}
		observer.pipe(catchError(() => {
			this.analyticsService.sendEvent('Session expired', 'cannot navigate to myEfairs page');
			this.logout();
			return of();
		})).subscribe(() => {
			if (this.userService.isTeacher()) {
				this.router.navigate(['/teacher/myEfairs']);
			} else {
				this.analyticsService.sendEvent('Navigation Error', 'unknown role, cannot navigate to myEfairs page');
				this.logout();
			}
		});
	}

	private loadUser (tokenResult: IGetTokenResult, state: string = '/') : void {
		localStorage.setItem(ACCESS_TOKEN, tokenResult.auth.access_token); // must set token before calling any bookclubs APIs
		localStorage.setItem(EXPIRES_AT, tokenResult.auth.expires_at);
		localStorage.setItem(ID_TOKEN_HINT, tokenResult.id_token_hint);
		localStorage.setItem(REFRESH_TOKEN, tokenResult.refresh_token);

		const profile: any = tokenResult.profile;
		localStorage.setItem(PROVIDER_PROFILE, JSON.stringify(profile));

		this.onAuthenticated.next(true);

		const newUser: any = {
			providerId: profile.providerId,
			salesforceContactId: profile.contactId,
			email: profile.email,
			name: `${profile.firstName} ${profile.lastName}`,
			role: localStorage.getItem(ROLE),
			job: profile.job,
			school: profile.school
		};

		this.userService.getOrCreate(newUser).pipe(finalize(() => {
			localStorage.removeItem(ROLE);
		})).subscribe(() => {
			this.startAuthWorker(tokenResult.auth.expires_at);
			if (state === '/') {
				this.navigateToMyEfairsPage();
			} else {
				this.router.navigate([state]);
			}
		}, (error: any) => {
			this.handleAuthError(error);
		});
	}

	public getUserProfile () : any {
		return JSON.parse(localStorage.getItem(PROVIDER_PROFILE));
	}

	public clearLocalStorage () : void {
		if (this.userService.isGuest()) {
			localStorage.removeItem(VISITED_EFAIRS);
			localStorage.removeItem(VIEW_WISHLIST_IDS_LOOKUP);
			localStorage.removeItem(LAST_TEACHER_WISHLIST_FROM_NAME_KEY);
			localStorage.removeItem(SELECTED_WISHLIST_ID);
			localStorage.removeItem(HIDE_BANNER);
		}

		localStorage.removeItem(EFAIR_CODE);
		localStorage.removeItem(CURRENT_EFAIR);
		this.clearCartLocalStorage();
		this.clearTeacherLocalStorage();
	}

	public clearCartLocalStorage () : void {
		if (this.userService.isGuest()) {
			localStorage.removeItem(EDIT_WISHLIST_ID_LOOKUP);
		}
		localStorage.removeItem(CART_ID_KEY);
		localStorage.removeItem(LAST_URL);
		localStorage.removeItem(USER_NAME);
		localStorage.removeItem(USER_EMAIL);
		localStorage.removeItem(USER_SHIPPING_ADDRESS);
		localStorage.removeItem(CART_RECIPIENT_LIST_KEY);
		localStorage.removeItem(CART_RECENT_RECIPIENT_KEY);
	}

	public clearTeacherLocalStorage (clearSalesforceIdToken?: boolean) : void {
		localStorage.removeItem(ACCESS_TOKEN);
		localStorage.removeItem(EXPIRES_AT);
		localStorage.removeItem(PROVIDER_PROFILE);
		localStorage.removeItem(REFRESH_TOKEN);

		if (clearSalesforceIdToken) {
			localStorage.removeItem(ID_TOKEN_HINT);
		}
	}

	public async guestLogout () : Promise<void> {
		if (this.userService.isGuest()) {
			let cartMessage: string = '';
			let wishlistMessage: string = '';
			if (this.cartService.getCachedCartItems().length > 0 || (this.editWishlistService.getWishlistItems().length > 0 && !this.editWishlistService.isTeacherWishlist() && !this.editWishlistService.isWishlistShared())) {

				if (this.cartService.getCachedCartItems().length > 0) {
					cartMessage = 'cart';
				}
				if (this.editWishlistService.getWishlistItems().length > 0 && !this.editWishlistService.isTeacherWishlist() && !this.editWishlistService.isWishlistShared()) {
					wishlistMessage = `${cartMessage ? ' and ' : ''}wish list`;
				}

				this.dialogService.alert('Warning!', `Are you sure you want to exit? Your ${cartMessage}${wishlistMessage} will be cleared.`, null, true, () => {
					this.editWishlistService.reset();
					this.viewWishlistService.reset();
					this.cartService.reset();
					this.logout();
				}, true);
			} else {
				this.cartService.reset();
				this.editWishlistService.reset();
				this.viewWishlistService.reset();
				this.logout();
			}
		}
	}

	public async navToTeacherDashboard () : Promise<void> {
		await this.router.navigate(['/teacher/dashboard']);
	}

	public async logout () : Promise<void> {
		await this.router.navigate(['/loading']);
		this.clearLocalStorage();
		this.stopAuthWorker();

		// if (localStorage.getItem(ID_TOKEN_HINT)) {
		// 	await this.logoutOfNextbee();
		// 	this.logoutOfSalesforce();
		// } else {
		// 	this.resetApplication();
		// }
		this.resetApplication();
	}

	// private logoutOfNextbee () : Promise<void> {
	// 	return new Promise((resolve: Function, _reject: Function) => {
	// 		const document: Document = this.windowRef.getWindow().document;
	// 		const iframe: HTMLIFrameElement = document.createElement('iframe');
	// 		iframe.height = '1px';
	// 		iframe.width = '1px';
	// 		(<any>iframe).style = 'position: fixed;';
	// 		iframe.src = 'https://s3.amazonaws.com/follett.nextbee.com/logout.html';
	// 		iframe.onload = () => {
	// 			setTimeout(resolve, 100);  // HACK! Give the javascript a little time to run on the iframe.
	// 		};
	// 		iframe.onerror = () => {
	// 			this.analyticsService.sendException('Unable to load Nextbee logout page.', false);
	// 			resolve();  // just resolve this, nothing we can do
	// 		};
	//
	// 		document.body.appendChild(iframe);
	// 		setTimeout(resolve, 2000);  // automatically resolve after waiting for 2 seconds even if the iframe doesn't load
	// 	});
	// }

	// private logoutOfSalesforce () : void {
	// 	this.configService.getConfig().subscribe((config: Configuration) => {
	// 		const id_token_hint: string = localStorage.getItem(ID_TOKEN_HINT);
	// 		localStorage.removeItem(ID_TOKEN_HINT);
	//
	// 		const window: Window = this.windowRef.getWindow();
	// 		const logoutURL: string = `${config.aaspHost}/oauth2/${config.aaspAuthTenant}/logout`;
	// 		const params: any = {
	// 			id_token_hint: id_token_hint,
	// 			post_logout_redirect_uri: window.location.origin
	// 		};
	//
	// 		const paramsStr: string = Object.keys(params).map((key: string) => key + '=' + encodeURIComponent(params[key])).join('&');
	// 		window.open(logoutURL + '?' + paramsStr, '_self');
	// 	});
	// }

	private resetApplication () : void {
		this.onAuthenticated.next(false);
		this.userService.unloadUser();
		this.applicationRef.tick();
		this.router.navigate(['/']);
	}

	public isAuthenticated () : boolean {
		const expiresAt: any = JSON.parse(localStorage.getItem(EXPIRES_AT));
		return Math.floor(new Date().getTime() / 1000) < expiresAt;
	}

	public isTeacherSession () : boolean {
		return !!localStorage.getItem(ACCESS_TOKEN);
	}

	private buildAuthWorker () : Worker {
		const blob: Blob = new Blob([
			`onmessage = function (event) {
				let expiresAt = event.data;

				function checkExpiresAt () {
					let nowInSeconds = Math.floor(new Date().getTime() / 1000);
					if (nowInSeconds > expiresAt) {
						postMessage('session expired');
						close();
					} else {
						setTimeout(checkExpiresAt, 1000);
					}
				}
				checkExpiresAt();
			}`
		]);

		const blobURL: string = (<any>this.windowRef.getWindow()).URL.createObjectURL(blob);
		const authWorker: Worker = new Worker(blobURL);
		authWorker.addEventListener('message', () => {
			if (this.windowRef.getWindow().location.pathname !== '/') {
				this.refreshTokenAndStartAuthWorker();
			} else {
				this.logout();
			}
		});

		return authWorker;
	}

	private stopAuthWorker () : void {
		if (this.authWorker) {
			this.authWorker.terminate();
			this.authWorker = null;
		}
	}

	private startAuthWorker (expiresAt: string) : void {
		this.stopAuthWorker();
		this.authWorker = this.buildAuthWorker();
		this.authWorker.postMessage(expiresAt); // Start the worker.
	}

	public refreshTokenAndStartAuthWorker () : void {
		const refresh_token: string = localStorage.getItem(REFRESH_TOKEN);
		const user: User = this.userService.getUser();
		if (refresh_token) {
			this.refreshToken(refresh_token, user && user.email).subscribe((tokenResult: IGetTokenResult) => {

				localStorage.setItem(ACCESS_TOKEN, tokenResult.auth.access_token);
				localStorage.setItem(EXPIRES_AT, tokenResult.auth.expires_at);
				localStorage.setItem(ID_TOKEN_HINT, tokenResult.id_token_hint);

				if (tokenResult.auth.expires_at) {
					this.stopAuthWorker();
					this.startAuthWorker(tokenResult.auth.expires_at);
				}
			});
		}
	}

	private showLoginErrorDialog () : void {
		const config: ModalOptions = {
			animated: true,
			ignoreBackdropClick: true,
			keyboard: false,
			class: 'modal-lg login-error-modal',
			initialState: {
				onClose: this.logout.bind(this)
			}
		};
		this.modalService.show(LoginErrorModalComponent, config);
	}

	private showPOBoxErrorDialog (data: any) : void {
		const config: ModalOptions = {
			animated: true,
			ignoreBackdropClick: true,
			keyboard: false,
			class: 'modal-lg po-box-modal',
			initialState: {
				name: data.name,
				address: data.address,
				onClose: this.logout.bind(this)
			}
		};
		this.modalService.show(POBoxModalComponent, config);
	}

	private showNextBeeErrorDialog () : void {
		this.router.navigate(['/error']);
	}

	public getToken (code: string) : Observable<IGetTokenResult> {
		return this.http.post<IGetTokenResult>(`${this.url}/token`, { code });
	}

	public refreshToken (refresh_token: string, email?: string) : Observable<IGetTokenResult> {
		return this.http.post<IGetTokenResult>(`${this.url}/refresh`, { refresh_token: refresh_token, email: email });
	}

	// private createNonce (length: number) : string {  // adapted from https://auth0.com/docs/api-auth/tutorials/nonce
	// 	const charset: string = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
	// 	let result: string = '';
	//
	// 	while (length > 0) {
	// 		const random: Uint8Array = this.windowRef.getWindow().crypto.getRandomValues(new Uint8Array(16));
	//
	// 		random.forEach((c: number) => {
	// 			if (length === 0) {
	// 				return;
	// 			}
	// 			if (c < charset.length) {
	// 				result += charset[c];
	// 				length--;
	// 			}
	// 		});
	// 	}
	//
	// 	return result;
	// }

}

export { AuthService, IGetTokenResult };
