import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of, } from 'rxjs';
import { map } from 'rxjs/operators';

class SiteSettings {
	public _id?: string;
	public key?: string;
	public value?: boolean;
	public customValue?: string;
	public customMessage?: string;
}

@Injectable()
class SiteSettingsService extends BasicCrudService<SiteSettings> {
	private cache: Map<string, SiteSettings> = new Map();

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/siteSettings');
	}

	public getOrCreateSiteSettings (key: string, forceUpdate?: boolean) : Observable<SiteSettings> {
		const result: any = this.cache.get(key);
		if (result && !forceUpdate) {
			return of(result);
		} else {
			return this.http.post(`${this.url}/getOrCreate`, key ? { key: key } : undefined).pipe(map((returnValue: SiteSettings) => {
				this.cache.set(key, returnValue);
				return returnValue;
			}));
		}
	}

	public update (siteSettings: SiteSettings) : Observable<SiteSettings> {
		return super.update(siteSettings).pipe(map((settings: SiteSettings) => {
			this.cache.set(settings.key, settings);
			return settings;
		}));
	}

	public search () : Observable<Array<SiteSettings>> {
		return super.search().pipe(map((returnValue: Array<SiteSettings>) => {
			for (const setting of returnValue) {
				this.cache.set(setting.key, setting);
			}
			return returnValue;
		}));
	}

}

export { SiteSettingsService, SiteSettings };
