import { Injectable } from '@angular/core';
import { ModalOptions, BsModalRef } from 'ngx-bootstrap';
import { AppOutOfDateModalComponent } from '../../shared/app.out.of.date.modal/app.out.of.date.modal';
import { ModalService } from './modal.service';

@Injectable()
class AppVersionService {
	private modalRef: BsModalRef;
	private _version: string;
	constructor (private modalService: ModalService) {}

	get version () : string {
		return this._version;
	}

	set version (newVersion: string) {
		if (!this._version) {
			this._version = newVersion;
		}
	}

	public showAppOutOfDateModal () : void {
		if (!this.modalRef) {
			const config: ModalOptions = {
				animated: true,
				backdrop: true,
				ignoreBackdropClick: true,
				class: 'modal-md'
			};
			this.modalRef = this.modalService.show(AppOutOfDateModalComponent, config);
		}
	}

}

export { AppVersionService };
