import { LiveChatService } from '../../services/helpers/live.chat.service';
import { BaseComponent } from '../../base.component';
import { BsModalRef } from 'ngx-bootstrap';


class BaseSupportModalComponent extends BaseComponent {
	private onClose: Function;

	constructor (private liveChatService: LiveChatService, private modal: BsModalRef) {
		super();
	}

	public contactSupport () : void {
		this.liveChatService.contactSupport('Contact Support (Support Modal)');
	}

	public close () : void {
		this.modal.hide();
		this.onClose();
	}

}

export { BaseSupportModalComponent };
