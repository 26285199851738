import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { LiveChatService } from '../../services/helpers/live.chat.service';
import { AnalyticsService } from '../../services/helpers/analytics.service';

@Component({
	selector: 'live-chat',
	templateUrl: './live.chat.html',
	styleUrls: ['./live.chat.css']
})

class LiveChatComponent extends BaseComponent implements OnInit {
	public config: any;

	public constructor (private liveChatService: LiveChatService, private analyticsService: AnalyticsService) {
		super();
	}

	public ngOnInit () : void {
		this.config = this.liveChatService.config;
	}

	public startChat (isOnline: boolean) : void {
		this.analyticsService.sendEvent('Live Chat Clicked (Online: ' + isOnline + ')');
		if (isOnline) {
			this.liveChatService.startChat();
		}
	}
}

export { LiveChatComponent };
