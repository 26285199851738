enum FulfillmentCenter {
	MC = 'MC',
	DL = 'DL',
	IL = 'IL',
	NE = 'NE',
	OR = 'OR',
	RD = 'RD'
}

export { FulfillmentCenter };
