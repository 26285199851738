import { Component, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { timer } from 'rxjs';
import { BaseComponent } from '../../base.component';

const MIN_INPUT_SPINNER_VALUE: number = 1;
const MAX_INPUT_SPINNER_VALUE: number = 999;
@Component({
	selector: 'input-spinner',
	templateUrl: './input.spinner.html',
	styleUrls: ['./input.spinner.css']
})

class InputSpinnerComponent extends BaseComponent {
	@Input() public value: number = 0;
	@Output() public increment: EventEmitter<any> = new EventEmitter<any>();
	@Output() public decrement: EventEmitter<any> = new EventEmitter<any>();
	@Output() public setQuantity: EventEmitter<number> = new EventEmitter<number>();

	@ViewChild('valueInput', {static: false}) public valueInput: ElementRef<HTMLInputElement>;

	public editingQuantity: boolean;
	public newValue: string;
	public MIN_INPUT_SPINNER_VALUE: number = MIN_INPUT_SPINNER_VALUE;
	public MAX_INPUT_SPINNER_VALUE: number = MAX_INPUT_SPINNER_VALUE;

	public editQuantity () : void {
		this.newValue = this.value.toString();
		this.editingQuantity = true;
		this.cleanup.push(timer(0).subscribe(() => {
			this.valueInput.nativeElement.select();
		}));
	}

	public saveQuantity () : void {
		if (this.validateQuantityInput() && this.value !== parseInt(this.newValue, 10)) {
			this.value = parseInt(this.newValue, 10);
			this.setQuantity.emit(this.value);
		}
		this.editingQuantity = false;
	}

	public cancelEdit () : void {
		this.editingQuantity = false;
	}

	private validateQuantityInput () : boolean {
		const numValue: number = parseInt(this.newValue, 10);
		return numValue >= MIN_INPUT_SPINNER_VALUE && numValue <= MAX_INPUT_SPINNER_VALUE;
	}
}

export { InputSpinnerComponent, MIN_INPUT_SPINNER_VALUE, MAX_INPUT_SPINNER_VALUE };
