import { Injectable } from '@angular/core';
import { Book } from '../apis/book/book.service';
import { Subject } from 'rxjs';
import { BookDiscountService } from '../apis/book.discount/book.discount.service';
import { NotYetPublishedHelper } from './not.yet.published.helper';

const AUDIENCE_LEVEL_GRADE_MAP: any = {
	'PK': { min: 0, max: 0 },
	'K-3': { min: 0, max: 3 },
	'3-6': { min: 3, max: 6},
	'5-8': { min: 5, max: 8},
	'YA': { min: 7, max: 12 },
	'AD': { min: 12, max: 12 },
	'PF': { min: 12, max: 12 },
};
@Injectable()
class BookFilterService {
	private _priceMin: number;
	private _priceMax: number;

	private _priceAbsMin: number;
	private _priceAbsMax: number;

	private _gradeMin: number;
	private _gradeMax: number;

	private _gradeAbsMin: number;
	private _gradeAbsMax: number;

	public onFilterChange: Subject<void> = new Subject();

	constructor (private bookDiscountService: BookDiscountService) {}

	get priceMin () : number {
		return this._priceMin;
	}

	get priceMax () : number {
		return this._priceMax;
	}

	get priceAbsMin () : number {
		return this._priceAbsMin;
	}

	get priceAbsMax () : number {
		return this._priceAbsMax;
	}

	get gradeMin () : number {
		return this._gradeMin;
	}

	get gradeMax () : number {
		return this._gradeMax;
	}

	get gradeAbsMin () : number {
		return this._gradeAbsMin;
	}

	get gradeAbsMax () : number {
		return this._gradeAbsMax;
	}

	public isFilteringPrice () : boolean {
		return this._priceMin !== this._priceAbsMin || this._priceMax !== this._priceAbsMax;
	}

	public isFilteringGrade () : boolean {
		return this._gradeMin !== this._gradeAbsMin || this._gradeMax !== this._gradeAbsMax;
	}

	public isFiltering () : boolean {
		return this.isFilteringPrice() || this.isFilteringGrade();
	}

	public setPriceFilter (min?: number, max?: number) : void {
		if (min || min === 0) {
			this._priceMin = min;
		}
		if (max || max === 0) {
			this._priceMax = max;
		}
	}

	public setPriceAbs (min: number, max: number) : void {
		this._priceAbsMin = min;
		this._priceAbsMax = max;
	}

	public setGradeFilter (min?: number, max?: number) : void {
		if (min || min === 0) {
			this._gradeMin = min;
		}
		if (max || max === 0) {
			this._gradeMax = max;
		}
	}

	public setGradeAbs (min: number, max: number) : void {
		this._gradeAbsMin = min;
		this._gradeAbsMax = max;
	}

	public applyFilters () : void {
		this.onFilterChange.next();
	}

	public shouldShowBook (book: Book) : boolean {
		return this.shouldShowBookByFilters(book) && this.shouldShowBookByAvailabilityAndCoverUrl(book);
	}

	public shouldShowBookByFilters (book: Book) : boolean {
		let result: boolean = false;
		if (book) {
			const priceNum: number = this.bookDiscountService.getPrice(book);
			result = (priceNum >= this._priceMin) && (priceNum <= this._priceMax);
			if (result) {
				if (book.readingLevel) {
					const gradeNum: number = Math.floor(parseFloat(book.readingLevel));
					result = (gradeNum >= this._gradeMin) && (gradeNum <= this._gradeMax);
				} else if (book.audienceLevel) {
					const bookAudienceLevel: { min: number, max: number } = AUDIENCE_LEVEL_GRADE_MAP[book.audienceLevel];

					result = !(bookAudienceLevel.min > this._gradeMax || bookAudienceLevel.max < this.gradeMin);

				} else {
					result = (this._gradeAbsMin === this._gradeMin) && (this._gradeAbsMax === this._gradeMax);
				}
			}
		}
		return result;
	}

	public shouldShowBookByAvailabilityAndCoverUrl (book: Book) : boolean {
		let result: boolean = false;
		if (book) {
			result = book.availableQuantity > 0 || book.advertised || NotYetPublishedHelper.isNypBook(book);
			if (result) {
				result = !!book.coverUrl;
			}
		}
		return result;
	}
}

export { BookFilterService };
