import { Component, OnInit } from '@angular/core';
import { School, SchoolService } from '../../services/apis/school/school.service';
import { of, Subject, timer } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { GRADES, SCHOOLWIDE_GRADES } from '../../constants/grades';
import { fade } from '../animations/fade';
import { EfairDefaults, SalesforceLoginHistoryItem, User, UserService } from '../../services/apis/user/user.service';
import { BaseComponent } from '../../base.component';
import { ModalOptions } from 'ngx-bootstrap';
import { SchoolwideStaffModalComponent } from '../schoolwide.staff/schoolwide.staff.modal/schoolwide.staff.modal';
import * as moment from 'moment';
import { Assets } from '../assets';
import { RewardsHelper } from '../../services/helpers/rewards.helper';
import { ReadingLevelType, ReadingLevelTypeDisplayable } from '../../constants/reading.level.types';
import { EfairType } from '../../constants/efairs';
import { ModalService } from '../../services/helpers/modal.service';
import { RewardsType } from '../../constants/user';
import { Displayables } from '../../utils/displayables';

const MIN_STUDENTS: number = 1;
const MAX_STUDENTS: number = 150;
const SCHOOL_MAX_STUDENTS: number = 2000;

@Component({
	selector: 'settings',
	templateUrl: './settings.html',
	styleUrls: ['./settings.css'],
	animations: [fade]
})

class SettingsComponent extends BaseComponent implements OnInit {
	public moment: any = moment;
	public TITLEWAVE_LOGO: string = Assets.TITLEWAVE_LOGO;
	public FOLLETT_REWARDS_STORE_LOGO: string = Assets.FOLLETT_REWARDS_STORE_LOGO;
	public grades: Array<any> = GRADES;
	public schoolwideGrades: Array<any> = SCHOOLWIDE_GRADES;
	public minStudents: number = MIN_STUDENTS;
	public maxStudents: number = MAX_STUDENTS;
	public schoolMaxStudents: number = SCHOOL_MAX_STUDENTS;
	public nicknameChanged: Subject<string> = new Subject<string>();
	public schoolNicknameChanged: Subject<string> = new Subject<string>();
	public numberOfStudentsChanged: Subject<number> = new Subject<number>();
	public schoolwideNumberOfStudentsChanged: Subject<number> = new Subject<number>();

	public userEfairDefaults: EfairDefaults;
	public user: User;
	public nickname: string;
	public schoolNickname: string;
	public numberOfStudents: number;
	public schoolwideNumberOfStudents: number;
	public school: School;
	public loadingSchool: boolean;
	public savingCount: number = 0;
	public savedCount: number = 0;
	public numStudentsValid: boolean;
	public showManageStaff: boolean;

	public loginHistory: Array<SalesforceLoginHistoryItem>;
	public fetchingHistory: boolean;
	public historyFetchError: boolean;
	public showAccountInfo: boolean;

	public ReadingLevelTypeDisplayable: typeof ReadingLevelTypeDisplayable = ReadingLevelTypeDisplayable;
	public Displayables: typeof Displayables = Displayables;
	public READING_LEVEL_KEYS: Array<string> = Object.keys(ReadingLevelTypeDisplayable);
	public EfairType: typeof EfairType = EfairType;
	public SUPPORT_PHONE_NUMBER: string = Assets.SUPPORT_PHONE_NUMBER;

	public rewardsPercentage: number;

	constructor ( private analyticsService: AnalyticsService, private userService: UserService, private schoolService: SchoolService, private modalService: ModalService ) {
		super();
	}

	public ngOnInit () : void {
		this.userEfairDefaults = this.userService.getEfairDefaults();
		this.rewardsPercentage = RewardsHelper.getRewardsPercentage(this.userEfairDefaults.preferredRewardsType, this.userEfairDefaults.preferShipToHome);
		this.numStudentsValid = true;

		if (this.userEfairDefaults.preferredRewardsType === undefined) {
			this.userEfairDefaults.preferredRewardsType = RewardsType.TITLEWAVE;
		}
		if (this.userEfairDefaults.shouldSendShareToParentsEmail === undefined) {
			this.userEfairDefaults.shouldSendShareToParentsEmail = true;
		}
		if (this.userEfairDefaults.shouldSendTeacherOrderNotification === undefined) {
			this.userEfairDefaults.shouldSendTeacherOrderNotification = true;
		}
		if (this.userEfairDefaults.shouldSendParentEfairLaunchNotification === undefined) {
			this.userEfairDefaults.shouldSendParentEfairLaunchNotification = true;
		}
		// if (this.userEfairDefaults.flyersEnabled === undefined) {
		// 	this.userEfairDefaults.flyersEnabled = true;
		// }
		if (this.userEfairDefaults.preferShipToHome === undefined) {
			this.userEfairDefaults.preferShipToHome = false;
		}

		this.user = this.userService.getUser();
		this.showAccountInfo = true;
		this.setShowManageStaff();

		this.loadingSchool = true;
		this.cleanup.push(this.schoolService.get(this.user.efairDefaults.school_id).subscribe((school: School) => {
			this.school = school;
			this.loadingSchool = false;
			this.schoolwideNumberOfStudents = this.school.efairDefaults.numberOfStudents;
			this.schoolNickname = this.user.schoolNickname || this.school.name;

			if (!this.school.efairDefaults.grade) {
				this.school.efairDefaults.grade = this.schoolwideGrades[0].value;
			}
		}));

		this.nickname = this.user.teacherNickName;
		this.cleanup.push(this.nicknameChanged.pipe(
			map((newVal: any) => {
				return newVal;
			}))
			.pipe(debounceTime(1000))
			.subscribe((newNick: string) => {
				this.updateNickname(newNick);
		}));

		this.cleanup.push(this.schoolNicknameChanged.pipe(
			map((newVal: any) => {
				return newVal;
			}))
			.pipe(debounceTime(1000))
			.subscribe((newNick: string) => {
				this.updateSchoolNickname(newNick);
		}));

		this.cleanup.push(this.numberOfStudentsChanged.pipe(
			map((newVal: any) => {
				this.validateNumberOfStudents(newVal);
				return newVal;
			}))
			.pipe(debounceTime(1000))
			.subscribe((newNumberOfStudents: number) => {
				this.updateNumberOfStudents(newNumberOfStudents);
		}));

		this.cleanup.push(this.schoolwideNumberOfStudentsChanged.pipe(
			map((newVal: any) => {
				this.validateNumberOfStudents(newVal);
				return newVal;
			}))
			.pipe(debounceTime(1000))
			.subscribe((newNumberOfStudents: number) => {
				this.updateSchoolNumberOfStudents(newNumberOfStudents);
		}));
	}

	public onNumberOfStudentsChange (newNumStudents: number) : void {
		this.numberOfStudentsChanged.next(newNumStudents);
	}

	public onSchoolwideNumberOfStudentsChange (newNumStudents: number) : void {
		this.schoolwideNumberOfStudentsChanged.next(newNumStudents);
	}

	public onNickChange (newNickname: string) : void {
		this.nicknameChanged.next(newNickname);
	}

	public onSchoolNickChange (newNickname: string) : void {
		this.schoolNicknameChanged.next(newNickname);
	}

	public validateNumberOfStudents (inputValue: number) : boolean {
		if (inputValue || inputValue === 0) {
			// If we have an inputValue, then validate it using MIN and MAX_STUDENTS.
			const prefersSchoolwideEfair: boolean = this.user.efairDefaults.preferredEfairType === EfairType.SCHOOLWIDE;
			this.numStudentsValid = inputValue >= MIN_STUDENTS && inputValue <= (prefersSchoolwideEfair ? SCHOOL_MAX_STUDENTS : MAX_STUDENTS);
			return this.numStudentsValid;
		} else {
			// If we do not have an inputValue, then assume it is valid.
			// We don't want to display an error message to the user before the user has had a chance to input anything.
			return true;
		}
	}

	public updateNumberOfStudents (numberOfStudents: number) : void {
		if (numberOfStudents && this.numStudentsValid) {
			this.savingCount++;
			this.analyticsService.sendEvent('Settings Update Number of Students', String(numberOfStudents));
			this.cleanup.push(this.userService.updateUserEfairDefaults(this.user._id, { numberOfStudents: numberOfStudents }).subscribe((user: User) => {
				this.user = user;
				this.userEfairDefaults = user.efairDefaults;
				this.showSavedIndicator();
			}));
		} else {
			this.analyticsService.sendEvent('Tried to Change to invalid numberOfStudents', String(numberOfStudents));
		}
	}

	public updateSchoolNumberOfStudents (numberOfStudents: number) : void {
		if (numberOfStudents && this.numStudentsValid) {
			this.savingCount++;
			this.analyticsService.sendEvent('Settings Update Number of Students In School', String(numberOfStudents));
			this.school.efairDefaults.numberOfStudents = numberOfStudents;
			this.cleanup.push(
				this.schoolService.updateSchool(this.school).subscribe(() => {
					this.showSavedIndicator();
				})
			);
		} else {
			this.analyticsService.sendEvent('Tried to Change to invalid schoolwideNumberOfStudents', String(numberOfStudents));
		}
	}

	public updateNickname (newNickname: string) : void {
		newNickname = Displayables.trimString(newNickname);
		if (newNickname.length > 0) {
			this.savingCount++;
			this.analyticsService.sendEvent('Settings Update Nickname', newNickname);
			this.cleanup.push(
				this.userService.updateUser(<User>{ teacherNickName: newNickname }).subscribe(() => {
					this.showSavedIndicator();
				})
			);
		} else {
			this.analyticsService.sendEvent('Tried to Change to Blank Nickname', newNickname);
		}
	}

	public updateSchoolNickname (newNickname: string) : void {
		newNickname = Displayables.trimString(newNickname);
		if (newNickname.length > 0) {
			this.savingCount++;
			this.analyticsService.sendEvent('Settings Update School Nickname', newNickname);
			this.cleanup.push(
				this.userService.updateUser(<User>{ schoolNickname: newNickname }).subscribe(() => {
					this.showSavedIndicator();
				})
			);
		} else {
			this.analyticsService.sendEvent('Tried to Change School to Blank Nickname', newNickname);
		}
	}

	public updateGrade (newGrade: string) : void {
		this.savingCount++;
		this.analyticsService.sendEvent('Settings Update Grade', newGrade);
		this.cleanup.push(this.userService.updateUserEfairDefaults(this.user._id, { grade: newGrade }).subscribe((user: User) => {
			this.user = user;
			this.userEfairDefaults = user.efairDefaults;
			this.showSavedIndicator();
		}));
	}

	public updateSchoolGrade (newGrade: string) : void {
		this.savingCount++;
		this.analyticsService.sendEvent('Settings Update School Grade', newGrade);
		this.school.efairDefaults.grade = newGrade;
		this.cleanup.push(this.schoolService.updateSchool(this.school).subscribe(() => {
			this.showSavedIndicator();
		}));
	}

	public updateEfairType (newEfairType: EfairType) : void {
		this.savingCount++;
		this.analyticsService.sendEvent('Settings Update eFair Type', newEfairType);
		const value: EfairDefaults = new EfairDefaults();
		value.preferredEfairType = newEfairType;
		this.cleanup.push(this.userService.updateUserEfairDefaults(this.user._id, { preferredEfairType: newEfairType }).subscribe((user: User) => {
			this.user = user;
			this.userEfairDefaults = user.efairDefaults;
			this.showSavedIndicator();
			this.setShowManageStaff();
			this.rewardsPercentage = RewardsHelper.getRewardsPercentage(this.userEfairDefaults.preferredRewardsType, this.userEfairDefaults.preferShipToHome);
		}));
	}

	public updateReadingLevel (newLevel: ReadingLevelType) : void {
		this.saveEfairDefault(
			{ readingLevelType: newLevel },
			'Settings Update Reading Level Type',
			newLevel
		);
	}

	public updateRewardsType (type: RewardsType) : void {
		this.saveEfairDefault(
			{ preferredRewardsType: type },
			'Settings Update Rewards Type',
			type
		);
	}

	public updateShipToHome (value: boolean) : void {
		this.saveEfairDefault(
			{ preferShipToHome: value },
			'Settings Update Ship to Home',
			value ? 'Enable' : 'Disable'
		);
	}

	public updateFlyers (newFlyersEnabled: boolean) : void {
		this.userEfairDefaults.flyersEnabled = newFlyersEnabled;
		this.saveEfairDefault(
			{ flyersEnabled: newFlyersEnabled },
			'Settings Update Flyers',
			newFlyersEnabled ? 'Enable' : 'Disable'
		);
	}

	public updateShareToParents (newshouldSendShareToParentsEmail: boolean) : void {
		this.userEfairDefaults.shouldSendShareToParentsEmail = newshouldSendShareToParentsEmail;
		this.saveEfairDefault(
			{ shouldSendShareToParentsEmail: newshouldSendShareToParentsEmail },
			'Settings Update Share to parents email',
			newshouldSendShareToParentsEmail ? 'Enable' : 'Disable'
		);
	}

	public updateTeacherOrderNotification (newteacherOrderNotification: boolean) : void {
		this.userEfairDefaults.shouldSendTeacherOrderNotification = newteacherOrderNotification;
		this.saveEfairDefault(
			{ shouldSendTeacherOrderNotification: newteacherOrderNotification },
			'Settings Update Teacher Order Notifications',
			newteacherOrderNotification ? 'Enable' : 'Disable'
		);
	}

	public updateShouldSendParentEfairLaunchNotification (newShouldSendParentEfairLaunchNotification: boolean) : void {
		this.userEfairDefaults.shouldSendParentEfairLaunchNotification = newShouldSendParentEfairLaunchNotification;
		this.saveEfairDefault(
			{ shouldSendParentEfairLaunchNotification: newShouldSendParentEfairLaunchNotification },
			'Settings Update Send Parent Efair Launch Notification',
			newShouldSendParentEfairLaunchNotification ? 'Enable' : 'Disable',
		);
	}

	public saveEfairDefault (settingObject: Partial<EfairDefaults>, settingName: string, settingValue: string) : void {
		this.savingCount++;
		this.analyticsService.sendEvent(settingName, settingValue);
		this.cleanup.push(this.userService.updateUserEfairDefaults(this.user._id, settingObject).subscribe((user: User) => {
			this.user = user;
			this.userEfairDefaults = user.efairDefaults;
			this.showSavedIndicator();
			this.rewardsPercentage = RewardsHelper.getRewardsPercentage(this.userEfairDefaults.preferredRewardsType, this.userEfairDefaults.preferShipToHome);
		}));
	}

	public showSavedIndicator () : void {
		this.savingCount--;
		this.savedCount++;
		this.cleanup.push(timer(2000).subscribe(() => {
			this.savedCount--;
		}));
	}

	public openSchoolwideStaffModal () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg schoolwide-staff-modal',
			ignoreBackdropClick: true,
			keyboard: false,
			initialState: {
				school: this.school
			}
		};
		this.modalService.show(SchoolwideStaffModalComponent, config);
	}

	private setShowManageStaff () : void {
		this.showManageStaff = !!( this.user.efairDefaults && this.user.efairDefaults.preferredEfairType === EfairType.SCHOOLWIDE);
	}

	public showLoginHistory () : void {
		this.fetchingHistory = true;
		this.cleanup.push(this.userService.getLoginHistory().pipe(catchError(() => {
			this.fetchingHistory = false;
			this.historyFetchError = true;
			return of({});
		})).subscribe((history: Array<SalesforceLoginHistoryItem>) => {
			this.loginHistory = history;
			this.fetchingHistory = false;
		}));
	}
}

export { MIN_STUDENTS, MAX_STUDENTS, SCHOOL_MAX_STUDENTS, SettingsComponent };
