import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoadingComponent } from './shared/loading/loading';

const ROUTES: Routes = [
	{ path: 'loading', component: LoadingComponent, data: {title: 'Loading'} },
	{ path: 'guest', loadChildren: './guest/guest.module#GuestModule' },
	{ path: 'teacher', loadChildren: './teacher/teacher.module#TeacherModule' },
	{ path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
	{ path: '**',  redirectTo: '' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(ROUTES, {onSameUrlNavigation: 'reload'})
	],
	exports: [
		RouterModule
	]
})
class AppRoutingModule {}

export { AppRoutingModule, ROUTES };
