import { state, style, animate, trigger, transition, AnimationTriggerMetadata } from '@angular/animations';

const expand: AnimationTriggerMetadata = trigger('expand', [
	state('in', style({'transform': 'scale(0)'})),
	transition(':leave', [
		style({'transform': 'scale(1)'}),
		animate('1000ms ease-in-out', style({'transform': 'scale(0)'}))
	]),
	transition(':enter', [
		style({'transform': 'scale(0)'}),
		animate('1000ms ease-in-out', style({'transform': 'scale(1)'}))
	])
]);

const grow: AnimationTriggerMetadata = trigger('grow', [
	state('collapsed', style({
		height: '300px'
	})),
	state('expanded', style({
		height: '*'
	})),
	transition('* <=> *', animate('250ms ease-in-out')),
]);

export { grow, expand };
