import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { BookClubService } from '../book.club/book.club.service';
import { BookService } from '../book/book.service';
import { Address, Staff } from '../school/school.service';
import { EDIT_WISHLIST_ID_LOOKUP } from '../../../constants/local.storage';
class WishlistItem {
	public book_id?: string;
	public quantity?: number;
	public quantityPurchased?: number;
}

class Wishlist {
	public _id?: string;
	public bookClub_id?: string;
	public studentName?: string;
	public teacherName?: Staff;
	public efairCode?: string;
	public code?: string;
	public items?: Array<WishlistItem>;
	public npoAddress?: Address;

	public teacherWishlistName?: Staff;

	public lastSharedDate?: Date;
	public lastViewedDate?: Date;
	public lastPurchasedDate?: Date;
}

@Injectable()
class EditWishlistService extends BasicCrudService<Wishlist> {
	public onWishlistUpdate: Subject<Array<WishlistItem>> = new Subject();
	public editingWishlist: Wishlist;
	public onForceWishlistState: Subject<boolean> = new Subject();

	constructor (http: HttpClient, private bookClubService: BookClubService, private bookService: BookService) {
		super(http);
		this.setUrl(`/server/api/wishlists`);
	}

	public isWishlistShared () : boolean {
		return this.editingWishlist && !!this.editingWishlist.lastSharedDate;
	}

	public isTeacherWishlist () : boolean {
		return this.editingWishlist && !!this.editingWishlist.teacherWishlistName;
	}

	public cacheWishlist () : Observable<boolean> {
		const editingWishlistId: string = localStorage.getItem(EDIT_WISHLIST_ID_LOOKUP);
		if (editingWishlistId) {
			return this.get(editingWishlistId).pipe(mergeMap((wishlist: Wishlist) => {
				return this.updateWishlistCache(wishlist);
			})).pipe(catchError(() => {
				localStorage.removeItem(EDIT_WISHLIST_ID_LOOKUP);
				return of(false);
			}));
		} else {
			return of(false);
		}
	}

	private updateWishlistCache (newWishlist: Wishlist) : Observable<boolean> {
		this.editingWishlist = newWishlist;
		const book_ids: Array<string> = [];
		for (const item of this.editingWishlist.items) {
			book_ids.push(item.book_id);
		}
		this.onWishlistUpdate.next();
		return this.bookService.cacheMissingBooks(book_ids);
	}

	public getWishlistItems () : Array<WishlistItem> {
		return this.editingWishlist && this.editingWishlist.items ? this.editingWishlist.items : [];
	}

	public getCachedWishlist () : Wishlist {
		return this.editingWishlist;
	}

	public createWishlist () : Observable<void> {
		return this.create({ bookClub_id: this.bookClubService.getCachedBookClub()._id }).pipe(map((wishlist: Wishlist) => {
			this.editingWishlist = wishlist;
			localStorage.setItem(EDIT_WISHLIST_ID_LOOKUP, this.editingWishlist._id);
			this.onWishlistUpdate.next();
		}));
	}

	public reset () : void {
		localStorage.removeItem(EDIT_WISHLIST_ID_LOOKUP);
		this.editingWishlist = null;
		this.onWishlistUpdate.next();
	}

	public isInMyWishlist (book_id: string) : boolean {
		return this.editingWishlist && this.editingWishlist.items && !!this.editingWishlist.items.find((item: WishlistItem) => {
			return item.book_id === book_id;
		});
	}

	public addToWishlist (book_id: string) : Observable<void> {
		let obs: Observable<any> = of({});
		if (!this.editingWishlist) {
			obs = this.createWishlist();
		}
		return obs.pipe(mergeMap(() => {
			return this.http.post(`${this.url}/${this.editingWishlist._id}`, { book_id: book_id }).pipe(map((wishlist: Wishlist) => {
				this.editingWishlist = wishlist;
				this.onWishlistUpdate.next();
			}));
		}));
	}

	public removeFromWishlist (book_id: string) : Observable<void> {
		return this.http.delete(`${this.url}/${this.editingWishlist._id}/${book_id}`).pipe(map((wishlist: Wishlist) => {
			this.editingWishlist = wishlist;
			this.onWishlistUpdate.next();
		}));
	}

	public update (update: Wishlist, _id: string) : Observable<Wishlist> {
		return super.update(update, _id).pipe(mergeMap((updated: Wishlist) => {
			return this.updateWishlistCache(updated).pipe(map(() => updated));
		}));
	}

	public getTeacherWishlist (bookClub_id: string, teacherName: Staff ) : Observable<Wishlist> {
		return this.http.put(`${this.url}/teacher`, { bookClub_id: bookClub_id, teacherName: teacherName }).pipe(map((wishlist: Wishlist) => {
			this.editingWishlist = wishlist;
			this.onWishlistUpdate.next();
			localStorage.setItem(EDIT_WISHLIST_ID_LOOKUP, this.editingWishlist._id);
			return wishlist;
		}));
	}

	public getTeacherWishlistName () : Staff {
		return this.editingWishlist ? this.editingWishlist.teacherWishlistName : null;
	}

	public updateQuantity (wishlistItem: WishlistItem) : Observable<Wishlist> {
		const updateItem: WishlistItem = this.editingWishlist.items.find((item: WishlistItem) => {
			return item.book_id === wishlistItem.book_id;
		});

		if (updateItem) {
			updateItem.quantity = wishlistItem.quantity;
			return this.update(this.editingWishlist, this.editingWishlist._id);
		} else {
			return of(this.editingWishlist);
		}

	}

}

export { EditWishlistService, WishlistItem, Wishlist };
