import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { School } from '../school/school.service';
import { EfairType } from '../../../constants/efairs';
import { BookClubSettings, Goal } from '../book.club/book.club.service';
import { ReadingLevelType } from '../../../constants/reading.level.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../user/user.service';

class ScheduledBookClub {
	public _id?: string;
	public user_id: string;
	public school_id: string;
	public schoolNickname?: string;
	public name?: string;
	public startDate: Date;
	public endDate: Date;
	public type: EfairType;
	public shipToSchool?: School;
	public goal?: Goal;
	public timezone: string;
	public settings: BookClubSettings;
	public readingLevelType: ReadingLevelType;

	[key: string]: string | Date | User | number | School | Goal | BookClubSettings;
}

interface ScheduledBookClubWithExtras extends ScheduledBookClub {
	school?: School;
	user?: User;
}

@Injectable()
class ScheduledBookClubService extends BasicCrudService<ScheduledBookClub> {

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/scheduledbookclubs');
	}

	public cancelBookClub (id: string, canceledByFollett: boolean = false) : Observable<void> {
		return this.http.delete(`${this.url}/${encodeURIComponent(id)}?byFollett=${canceledByFollett}`).pipe(map(() => {}));
	}

	public adminSearch (searchTerm?: any) : Observable<Array<ScheduledBookClub>> {
		return this.http.get<Array<ScheduledBookClub>>(`${this.url}/adminSearch${ searchTerm ? '?searchTerm=' + encodeURIComponent(searchTerm) : '' }`);
	}

}

export { ScheduledBookClubService, ScheduledBookClub, ScheduledBookClubWithExtras };
