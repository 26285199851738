import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../services/apis/auth/auth.service';
import { UserService } from '../services/apis/user/user.service';
import { SidebarToggleService } from '../services/helpers/sidebar.toggle.service';
import { VERSION } from '../../environments/version';
import { SidebarElement, TeacherElements, GuestSWEElements, AdminElements, TeacherAdminModeElement } from './sidebar.elements';
import { AnalyticsService } from '../services/helpers/analytics.service';
import { BaseComponent } from '../base.component';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { Displayables } from '../utils/displayables';
import { BookClubService, BookClub } from '../services/apis/book.club/book.club.service';
import { timer } from 'rxjs';
import { BookClubStatus } from '../constants/book.club.status';

@Component({
	selector: 'sidebar',
	templateUrl: './sidebar.html',
	styleUrls: ['./sidebar.css']
})

class SidebarComponent extends BaseComponent implements OnInit {
	public commitSha: string;
	public sidebarElements: Array<SidebarElement>;
	public isViewingAdminMode: boolean;
	public isAdmin: boolean;
	public isGuest: boolean;
	public isTeacher: boolean;
	public isOpen: boolean = false;
	public isOpenViaHamburger: boolean = false;
	public displayables: any = Displayables;
	public bookClub: BookClub;
	public isHovering: boolean;
	public disableViewWishlists: boolean;
	public hideOrderStatus: boolean;
	@ViewChild('sidebar', {static: true}) public sidebar: ElementRef;

	constructor (
		private authService: AuthService, private sidebarToggleService: SidebarToggleService, private userService: UserService,
		private analyticsService: AnalyticsService, private router: Router, private bookClubService: BookClubService) {
		super();
	}

	public ngOnInit () : void {
		this.isViewingAdminMode = this.sidebarToggleService.isAdmin();
		this.isGuest = this.userService.isGuest();
		this.isTeacher = this.userService.isTeacher() && !this.isViewingAdminMode;
		this.isAdmin = this.userService.isAdmin();

		this.bookClub = this.bookClubService.getCachedBookClub();
		if (this.bookClub && !!this.bookClub.settings) {
			this.disableViewWishlists = this.bookClub.settings.disableViewWishlists;
			this.hideOrderStatus = this.bookClub.settings.hideOrderStatus;
		}
		this.updateElements();

		this.cleanup.push(this.bookClubService.onBookClubLoaded.subscribe((bookClub: BookClub) => {
			this.bookClub = bookClub;
			if (bookClub && !!bookClub.settings) {
				this.disableViewWishlists = this.bookClub.settings.disableViewWishlists;
				this.hideOrderStatus = this.bookClub.settings.hideOrderStatus;
			}
			this.updateElements();
		}));

		this.commitSha = VERSION.hash;
		this.cleanup.push(this.router.events.subscribe((event: RouterEvent) => {
			if (event instanceof NavigationEnd) {
				this.closeSidebar(true);
				this.isViewingAdminMode = this.sidebarToggleService.isAdmin();
				this.isGuest = this.userService.isGuest();
				this.isTeacher = this.userService.isTeacher() && !this.isViewingAdminMode;
				this.updateElements();
			}
		}));

		this.cleanup.push(this.sidebarToggleService.sidebarToggled.subscribe((isOpen: boolean) => {
			this.isOpen = isOpen;
			this.isOpenViaHamburger = this.sidebarToggleService.isOpenViaHamburger;
			this.updateElements();
		}));
	}

	public openSidebar () : void {
		this.isHovering = true;
		this.sidebarToggleService.openSidebar();
	}

	public closeSidebar (forceClose?: boolean, delay?: number) : void {
		this.isHovering = false;
		this.cleanup.push(timer(delay || 0).subscribe(() => {
			if (!this.isHovering && !this.sidebarToggleService.isOpenViaHamburger || forceClose) {
				this.sidebarToggleService.closeSidebar();
			}
		}));
	}

	public clickLink (element: SidebarElement) : void {
		this.analyticsService.sendEvent('Sidebar Click ' + element.title);
		this.sidebarToggleService.closeSidebar();
		if (element.title === 'Sign Out' || element.title === 'Exit') {
			if (this.userService.isGuest()) {
				this.authService.guestLogout();
			} else {
				this.authService.logout();
			}
		}
	}

	public updateElements () : void {
		if (this.isViewingAdminMode) {
			this.sidebarElements = AdminElements;
		} else if (this.isTeacher) {
			this.sidebarElements = Object.assign([], TeacherElements);
			if (this.isAdmin && !this.sidebarElements.includes(TeacherAdminModeElement)) {
				this.sidebarElements.splice(this.sidebarElements.length - 1, 0, TeacherAdminModeElement);
			}
		} else {
			this.sidebarElements = Object.assign([], GuestSWEElements);

			if (this.disableViewWishlists || !(this.bookClub && (this.bookClub.status === BookClubStatus.CREATED || this.bookClub.status === BookClubStatus.STARTED))) {
				this.sidebarElements = this.sidebarElements.filter((element: SidebarElement) => {
					return element.title !== 'View Wish Lists';
				});
			}

			if (this.hideOrderStatus) {
				this.sidebarElements = this.sidebarElements.filter((element: SidebarElement) => {
					return element.title !== 'Order Status';
				});
			}
		}

		this.sidebar.nativeElement.style.setProperty('--numElements', this.sidebarElements.length);
	}
}

export { SidebarComponent };
