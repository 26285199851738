import { Component, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BaseComponent } from '../../base.component';
import * as XLSX from 'xlsx';

@Component({
	selector: 'schoolwide-staff-import-modal',
	templateUrl: './schoolwide.staff.import.modal.html',
	styleUrls: ['./schoolwide.staff.import.modal.css']
})

class SchoolwideStaffImportModalComponent extends BaseComponent {

	public onCancel: Function;
	public onSuccess: Function;
	public showInvalidData: boolean;

	@ViewChild('fileInput', {static: false}) public fileInput: ElementRef;

	constructor (private bsModalRef: BsModalRef, private analyticsService: AnalyticsService) {
		super();
	}

	public clickCancel () : void {
		this.analyticsService.sendEvent('Schoolwide Staff Upload Cancel');
		this.bsModalRef.hide();
		if (this.onCancel) {
			this.onCancel();
		}
	}

	public onFileChange (event: any) : void {
		let reader: FileReader;
		let file: Blob;

		if (event.target.files) {
			file = event.target.files[0];
		}

		if (file && (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv')) {
			reader = new FileReader();
			reader.readAsArrayBuffer(file);
			reader.addEventListener('load', () => {
				const data: string | ArrayBuffer = reader.result;
				const workbook: XLSX.WorkBook = XLSX.read(data, {type: 'array'});
				const names: Array<{ firstName: string, lastName: string }> = this.parseSpreadsheetData(workbook);
				if (!names) {
					this.showInvalidData = true;
				} else {
					this.showInvalidData = false;
					this.bsModalRef.hide();
					this.onSuccess(names);
				}
			});
		} else {
			this.showInvalidData = true;
		}
	}

	public parseSpreadsheetData (workbook: XLSX.WorkBook) : Array<{ firstName: string, lastName: string }> {
		const sheetName: string = workbook.SheetNames[0];
		const sheet: XLSX.Sheet = workbook.Sheets[sheetName];
		const range: Array<string> = sheet['!ref'].split(':');
		const minRow: string = range[0].replace(/\D/g, '');
		let firstNameCol: string;
		let lastNameCol: string;

		const firstRow: Array<string> = Object.keys(sheet).filter((key: string) => minRow === key.replace(/\D/, ''));

		for (const cell of firstRow) {
			const cellContents: string = (sheet[cell].w || sheet[cell].v || '').toString().trim();
			if (cellContents === 'FIRST_NAME') {
				firstNameCol = cell.replace(/\d/g, '');
			} else if (cellContents === 'LAST_NAME') {
				lastNameCol = cell.replace(/\d/g, '');
			}
		}

		if (!firstNameCol || !lastNameCol) {
			return null;
		}

		const cellMap: Map<string, { firstName: string, lastName: string }> = new Map();

		Object.keys(sheet).map((cell: string) => {
			const colName: string = cell.replace(/\d/g, '');
			const rowNumber: string = cell.replace(/\D/g, '');
			const row: { firstName: string, lastName: string } = cellMap.get(rowNumber) || { firstName: '', lastName: '' };
			const cellContents: string = sheet[cell].w || sheet[cell].v;
			if (colName === firstNameCol && rowNumber !== minRow) {
				row.firstName = cellContents;
				cellMap.set(rowNumber, row);
			} else if (colName === lastNameCol && rowNumber !== minRow) {
				row.lastName = cellContents;
				cellMap.set(rowNumber, row);
			}
		});
		return Array.from(cellMap.values());
	}

	public submit () : void {
		this.fileInput.nativeElement.click();
	}

}

export { SchoolwideStaffImportModalComponent };
