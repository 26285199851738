enum ReadingLevelType {
	lexileLevel = 'lexileLevel',
	fpGuidedReadingLevel = 'fpGuidedReadingLevel',
	readingLevel = 'readingLevel',
	audienceLevel = 'audienceLevel',
	arReadingLevel = 'arReadingLevel',
	arInterestLevel = 'arInterestLevel',
	guidedReadingLevel = 'guidedReadingLevel',
	readingCountsReadingLevel = 'readingCountsReadingLevel'
}

// The order of these reading level types have been determined by S-47890
enum ReadingLevelTypeDisplayable {
	lexileLevel = 'Lexile',
	fpGuidedReadingLevel = 'Fountas & Pinnell',
	// readingLevel = 'Follett Reading Level',
	audienceLevel = 'Follett Audience Level',
	arReadingLevel = 'AR Reading Level',
	arInterestLevel = 'AR Interest Level',
	guidedReadingLevel = 'Guided Reading Level',
	readingCountsReadingLevel = 'Reading Counts'
}

enum ReadingLevelType_Abbreviated {
	lexileLevel = 'Lexile',
	fpGuidedReadingLevel = 'Fountas & Pinnell',
	readingLevel = 'Follett Reading Lvl',
	audienceLevel = 'Follett Audience Lvl',
	arReadingLevel = 'AR Reading Lvl',
	arInterestLevel = 'AR Interest Lvl',
	guidedReadingLevel = 'Guided Reading Lvl',
	readingCountsReadingLevel = 'Reading Counts'
}

const LESS_IMPORTANT_READING_LEVELS: Set<string> = new Set(
	[
		ReadingLevelType.arReadingLevel,
		ReadingLevelType.arInterestLevel,
		ReadingLevelType.guidedReadingLevel,
		ReadingLevelType.readingCountsReadingLevel
	]);

export { ReadingLevelType, ReadingLevelTypeDisplayable, ReadingLevelType_Abbreviated, LESS_IMPORTANT_READING_LEVELS };
