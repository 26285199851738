import { Injectable } from '@angular/core';
import { WindowRef } from '../../utils/window.ref';

const PROD: string = 'efairs.follettsoftware.com';

@Injectable()
class UtilsService {

	constructor ( private windowRef: WindowRef ) {}

	public envIsProd () : boolean {
		return this.windowRef.getWindow().location.host === PROD;
	}

}

export { UtilsService };
