import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../user/user.service';
import { Address, School } from '../school/school.service';
import { BookClub } from '../book.club/book.club.service';
import { ShipmentInfo } from '../book.club.archive/book.club.archive.service';
import { BookClubStatus } from '../../../constants/book.club.status';

class OrderItem {
	public _id?: string;
	public book_id: string;
	public quantity: number;
	public price: number;
	public subtotal: number;
	public title: string;
	public author?: string;
	public coverUrl: string;
	public isbn?: string;
	public titlewave_id: string;
	public subjects?: Array<string>;
	public studentName: string;
	public teacherName: string;
	public gift?: boolean;
	public fromTeacherWishlist?: boolean;
	public isNYP?: boolean;
	public nypDate?: Date;
	public nypEstimatedDelivery?: string;
}

class Order {
	public _id?: string;
	public bookClub_id: string;
	public user_id: string;
	public userName: string;
	public userEmail: string;
	public orderStatus: string;
	public subtotal: number;
	public tax: number;
	public total: number;
	public bookClubName: string;
	public bookClubGrade: string;
	public bookClubStartDate: Date;
	public bookClubEndDate: Date;
	public items: Array<OrderItem>;
	public paymentScenario_id: string;
	public payments: Array<any>;
	public twResponse: any;
	public shippingAddress?: Address;
	public shipmentInfo?: Array<ShipmentInfo>;
	public shippingCharge?: number;
	public created?: Date;
	public discountCode: string;
	public isTeacher: boolean;
}

class OrderWithExtras extends Order {
	public school?: School;
	public owner?: User;
	public bookclub?: BookClub;
	public error?: any;
}

class IOrderItemStatus {
	public title: string;
	public quantity: number;
	public price: number;
	public subtotal: number;
	public studentName: string;
	public teacherName: string;
	public gift: boolean;
	public fromTeacherWishlist: boolean;
	public isNYP?: boolean;
	public nypDate?: Date;
}
class IOrderStatus {
	public items: Array<IOrderItemStatus>;
	public reqid: string;
	public subtotal: number;
	public shippingCharge: number;
	public tax: number;
	public total: number;
	public shipmentInfo: Array<ShipmentInfo>;
	public created: Date;
	public shipToHome: boolean;
	public bookClubEndDate: Date;
	public bookClubStatus: BookClubStatus;
	public bookClubTimezone?: string;
}


@Injectable()
class OrderService extends BasicCrudService<Order> {

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/orders');
	}

	public completeOrder (_id: string, status: string) : Observable<Partial<Order>> {
		return this.http.get<Partial<Order>>(`${this.url}/adminComplete/${_id}?status=${status}`);
	}

	public adminSearch (query: any) : Observable<Array<OrderWithExtras>> {
		if (query) {
			return this.http.get<Array<OrderWithExtras>>(`${this.url}/adminSearch`, {params: query});
		} else {
			return this.http.get<Array<OrderWithExtras>>(`${this.url}/adminSearch`);
		}
	}

	public findStatusByOrderNumber (orderNumber: string) : Observable<IOrderStatus> {
		return this.http.get<IOrderStatus>(`${this.url}/byOrderNumber`, { params: { orderNumber: orderNumber } });
	}

	public advancedSearch (searchTerm: string) : Observable<Array<OrderWithExtras>> {
		return this.http.get<Array<OrderWithExtras>>(`${this.url}/advancedSearch${ searchTerm ? '?searchTerm=' + encodeURIComponent(searchTerm) : '' }`);
	}

	public createAndComplete (model: any) : Observable<Order> {
		return this.http.post<Order>(`${this.url}/invoiceOrder`, model);
	}

	public getCountForEfairId (bookClub_id: string) : Observable<number> {
		return this.http.get<number>(`${this.url}/getCountForEfairId?bookClub_id=${bookClub_id}`);
	}

}

export { OrderService, OrderItem, Order, OrderWithExtras, IOrderStatus, IOrderItemStatus };
