import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';


@Component({
	selector: 'checkmark-animation',
	templateUrl: './checkmark.animation.html',
	styleUrls: ['./checkmark.animation.css']
})

class CheckmarkAnimationComponent extends BaseComponent implements OnInit {

	@Input() public hideCircle: boolean;
	@Input() public size: number;
	@Input() public checkmarkDelay: number;
	@ViewChild('wrapper', {static: true}) public wrapper: ElementRef;

	constructor () {
		super();
	}

	public ngOnInit () : void {
		this.wrapper.nativeElement.style.setProperty('--size', this.size + 'px');
		this.wrapper.nativeElement.style.setProperty('--checkmark-delay', this.checkmarkDelay + 's');
		this.wrapper.nativeElement.style.setProperty('--checkmark-zoom-delay', (this.checkmarkDelay + .1) + 's');
	}

}

export { CheckmarkAnimationComponent };
