import { Component } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { BsModalRef } from 'ngx-bootstrap';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { School } from '../../../services/apis/school/school.service';

@Component({
	selector: 'schoolwide-staff-modal',
	templateUrl: './schoolwide.staff.modal.html',
	styleUrls: ['../schoolwide.staff.css']
})

class SchoolwideStaffModalComponent extends BaseComponent {

	public school: School;

	constructor (public modal: BsModalRef, private analyticsService: AnalyticsService) {
		super();
	}

	public closeModal () : void {
		this.analyticsService.sendEvent('Schoolwide Staff', 'Name Prompt Closed');
		this.modal.hide();
	}
}

export { SchoolwideStaffModalComponent };
