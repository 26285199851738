import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Flyer } from '../../../shared/assets';
import { BookClubService } from '../book.club/book.club.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

class MarketingMaterialOverride {
	public _id?: string;
	public spring: boolean;
	public fall: boolean;
	public grades: Array<string>;
	public assetUrl?: string;
	public assetFile_id?: string;
	public thumbnailUrl?: string;
	public isHidden?: boolean;
}

class MarketingMaterial {
	public _id?: string;
	public name: string;
	public defaultAssetUrl: string;
	public defaultThumbnailUrl: string;
	public defaultAssetFile_id?: string;
	public isCustomAsset: boolean;
	public marketingMaterialCategory_id: string;
	public order?: number;
	public overrides?: Array<MarketingMaterialOverride>;
	public description?: string;
	public language?: string;
}

@Injectable()
class MarketingMaterialService extends BasicCrudService<MarketingMaterial> {

	private materials: Map<string, Array<Flyer>> = new Map();

	constructor (http: HttpClient, private bookClubService: BookClubService) {
		super(http);
		this.setUrl('/server/api/marketingMaterials');

		this.bookClubService.onBookClubLoaded.subscribe(() => {
			this.resetMaterials();
		});
	}

	public searchAssets (query?: any) : Observable<Array<Flyer>> {
		const category_id: string = query && query.marketingMaterialCategory_id;
		if (category_id) {
			if (!this.materials.get(category_id)) {
				return this.http.get(`${this.url}/assets`, {params: query}).pipe(map((materials: Array<Flyer>) => {
					this.materials.set(category_id, materials);
					return materials;
				}));
			} else {
				return of(this.materials.get(category_id));
			}
		} else {
			return of();
		}
	}

	public getCustomFlyer (_id: string, name: string, season: string, gradeRange: string, efairCode: string, schoolName: string, startDate: Date, endDate: Date, timezone: string) : Observable<any> {
		const headers: HttpHeaders = new HttpHeaders();
		headers.set('Accept', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
		return this.http.get(`${this.url}/customFlyer/${_id}`, { params: {
			season: season,
			gradeRange: gradeRange,
			efairCode: efairCode,
			schoolName: schoolName,
			startDate: moment(startDate.toString()).format('YYYY-MM-DDTHH:mm:ss'),
			endDate: moment(endDate.toString()).format('YYYY-MM-DDTHH:mm:ss'),
			timezone: timezone
		}, headers: headers, responseType: 'blob' }).pipe(
			map((data: any) => {
			const blob: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
			saveAs(blob, `${name}.docx`);
		}));
	}

	public resetMaterials () : void {
		this.materials.clear();
	}

}

export { MarketingMaterialService, MarketingMaterial, MarketingMaterialOverride  };
