import { Component, Input } from '@angular/core';
import { Book } from '../../../services/apis/book/book.service';
import { Displayables } from '../../../utils/displayables';

@Component({
	selector: 'description-and-teacher-recommendation',
	templateUrl: './description.and.teacher.recommendation.html',
	styleUrls: ['./description.and.teacher.recommendation.css']
})

class DescriptionAndTeacherRecommendationComponent {
	@Input() public book: Book;
	@Input() public isTeacher: boolean;
	@Input() public recommended: boolean;
	@Input() public clubClosed: boolean;
	@Input() public showTeacherNote: boolean;
	@Input() public teacherName: string;
	@Input() public recNote: string;
	@Input() public expandable: boolean;

	public displayables: typeof Displayables = Displayables;
}

export { DescriptionAndTeacherRecommendationComponent };
