import { OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { AnimationEvent } from '@angular/animations';
import { EditWishlistService, WishlistItem } from '../../../services/apis/wishlist/edit.wishlist.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';

abstract class AbstractWishlistComponent extends BaseComponent implements OnInit {
	public itemCount: number;
	public showMyWishlist: boolean;
	public pulse: string = 'down';

	constructor (protected editWishlistService: EditWishlistService, protected analyticsService: AnalyticsService) {
		super();
	}

	public ngOnInit () : void {
		this.itemCount = this.getItemCount();

		this.cleanup.push(this.editWishlistService.onWishlistUpdate.subscribe(() => {
			const afterCount: number = this.getItemCount();
			if (afterCount > this.itemCount) {
				this.pulse = 'up';
			}
			this.itemCount = afterCount;
		}));
	}

	public getItemCount () : number {
		let count: number = 0;
		const cartItems: Array<WishlistItem> = this.editWishlistService.getWishlistItems();
		for (const item of cartItems) {
			count += item.quantity;
		}
		return count;
	}

	public animationDone (event: AnimationEvent) : void {
		if (event.toState === 'up') {
			this.pulse = 'down';
		}
	}
}

export { AbstractWishlistComponent };
