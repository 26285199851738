import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { interval, Subject } from 'rxjs';

class SystemMessage {
	public message: string;
	public startDate?: Date;
	public endDate?: Date;
}

@Injectable()

class SystemMessageService extends BasicCrudService<SystemMessage> {
	private _message: string;
	private _dismissed: boolean;
	public onMessageUpdated: Subject<string> = new Subject();

	public get message () : string {
		return this._message;
	}

	public get dismissed () : boolean {
		return this._dismissed;
	}

	public set dismissed (dismissed: boolean) {
		this._dismissed = dismissed;
	}

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/systemMessage');

		this.populateMessage();

		interval(5 * 60 * 1000).subscribe(() => {
			this.populateMessage();
		});
	}

	public populateMessage () : void {
		this.search().subscribe((messages: Array<SystemMessage>) => {
			const message: SystemMessage = messages && messages.length ? messages[0] : undefined;
			if (!message || !message.message) {
				this._dismissed = true;
			} else if (message && message.message !== this._message) {
				this._message = message.message;
				this._dismissed = false;
				this.onMessageUpdated.next(this._message);
			}
		});
	}
}

export { SystemMessageService, SystemMessage };
