// library imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule, TypeaheadModule, PopoverModule, AlertModule, TabsModule, CollapseModule } from 'ngx-bootstrap';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { QRCodeModule } from 'angular2-qrcode';
import { InlineSVGModule } from 'ng-inline-svg';

// component imports
import { ActualBookCarouselComponent } from './carousels/actual.book.carousel/actual.book.carousel';
import { BookListCarouselComponent } from './carousels/book.list.carousel/book.list.carousel';
import { RecommendedListCarouselComponent } from './carousels/recommended.list.carousel/recommended.list.carousel';
import { TitleDetailsLargeComponent } from './title.details/title.details.lg/title.details.lg';
import { TitleDetailsMediumComponent } from './title.details/title.details.md/title.details.md';
import { TitleDetailsSmallComponent } from './title.details/title.details.sm/title.details.sm';
import { TitleDetailsCarouselComponent } from './title.details.carousel/title.details.carousel';
import { TitleDetailsCarouselLargeComponent } from './title.details.carousel/title.details.carousel.lg/title.details.carousel.lg';
import { TitleDetailsCarouselSmallComponent } from './title.details.carousel/title.details.carousel.sm/title.details.carousel.sm';
import { CartComponent } from './cart/cart';
import { InputSpinnerComponent } from './input.spinner/input.spinner';
import { QuantityDropdownComponent } from './quantity.dropdown/quantity.dropdown';
import { OrderSummaryComponent } from './order.summary/order.summary';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './settings/settings';
import { CartReviewComponent } from './cart.review/cart.review';
import { CartReviewItemLgComponent } from './cart.review/cart.review.item.lg/cart.review.item.lg';
import { CartReviewItemSmComponent } from './cart.review/cart.review.item.sm/cart.review.item.sm';
import { ListBookComponent } from './book/list.book/list.book';
import { EfairCodeComponent } from './efair.code/efair.code';
import { UserInfoPromptComponent } from './cart.review/user.info.prompt/user.info.prompt';
import { EmailWishlistPromptComponent } from './share.wishlist/email.wishlist.prompt/email.wishlist.prompt';
import { UnsupportedDeviceModalComponent } from './unsupported.device.modal/unsupported.device.modal';
import { LoadingComponent } from './loading/loading';
import { BookSearchComponent } from './book.search/book.search';
import { ErrorComponent } from './error/error';
import { SharedRoutingModule } from './shared.routes';
import { MatNativeDateModule, MatInputModule, MatFormFieldModule, MatRadioModule } from '@angular/material';
import { Ng5SliderModule } from 'ng5-slider';
import { BookFiltersComponent } from './book.filters/book.filters';
import { BookFilterModalComponent } from './book.filters/book.filter.modal/book.filter.modal';
import { BookComponent } from './book/book';
import { CartButtonsComponent } from './action.buttons/cart.buttons/cart.buttons';
import { WishlistButtonsComponent } from './action.buttons/wishlist.buttons/wishlist.buttons';
import { EmailValidatorDirective } from '../validators/email.validator/email.validator';
import { GuestGuardService } from '../guest/guest.guard.service';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { TrailerModalComponent } from './trailer.modal/trailer.modal';
import { AppOutOfDateModalComponent } from './app.out.of.date.modal/app.out.of.date.modal';
import { LoginErrorModalComponent } from './support.modals/login.error.modal/login.error.modal';
import { BottomSearchComponent } from './bottom.search/bottom.search';
import { DaysRemainingComponent } from './days.remaining/days.remaining';
import { TopSearchComponent } from './top.search/top.search';
import { TopSearchSmallModalComponent } from './top.search/top.search.small.modal/top.search.small.modal';
import { SystemMessageComponent } from './system.message/system.message';
import { ActualMobileBookCarouselComponent } from './carousels/actual.mobile.book.carousel/actual.mobile.book.carousel';
import { CartUnavailableItemsModalComponent } from './cart.review/cart.unavailable.items.modal/cart.unavailable.items.modal';
import { ManageWishlistComponent } from './manage.wishlist/manage.wishlist';
import { ManageWishlistItemComponent } from './manage.wishlist/manage.wishlist.item/manage.wishlist.item';
import { ShareWishlistComponent } from './share.wishlist/share.wishlist';
import { AddToCartModalComponent } from './add.to.cart.modal/add.to.cart.modal';
import { UpdateCartRecipientModalComponent } from './update.cart.recipient.modal/update.cart.recipient.modal';
import { CoverRecommendationComponent } from './cover.recommendation/cover.recommendation';
import { ViewRedirectComponent } from './view.redirect/view.redirect';
import { ActionButtonConfirmationComponent } from './action.button.confirmation/action.button.confirmation';
import { CartIsReadyPopupComponent } from './cart.is.ready.popup/cart.is.ready.popup';
import { CheckmarkAnimationComponent } from './checkmark.animation/checkmark.animation';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StaffNameSelectComponent } from './staff.name.select/staff.name.select';
import { AddToCartFromTeacherWishlistModalComponent } from '../guest/view.wishlist/add.to.cart.from.teacher.wishlist.modal/add.to.cart.from.teacher.wishlist.modal';
import { OrderStatusComponent } from './order.status/order.status';
import { OrderStatusLinkComponent } from './order.status.link/order.status.link';
import { HelpMenuDropdownComponent } from './help.menu.dropdown/help.menu.dropdown';
import { SupportPhoneNumberComponent } from './support.phone.number/support.phone.number';
import { WishlistItemsAlreadyPurchasedModalComponent } from './cart.review/wishlist.items.already.purchased.modal/wishlist.items.already.purchased.modal';
import { UndoTimerComponent } from './undo.timer/undo.timer';
import { RecommendButtonsComponent } from './action.buttons/recommend.buttons/recommend.buttons';
import { POBoxModalComponent } from './support.modals/po.box.modal/po.box.modal';
import { SchoolwideStaffModalComponent } from './schoolwide.staff/schoolwide.staff.modal/schoolwide.staff.modal';
import { SchoolwideStaffComponent } from './schoolwide.staff/schoolwide.staff';
import { ExpandableTextComponent } from './expandable.text/expandable.text';
import { GoalProgressComponent } from './goal.progress.wrapper/goal.progress/goal.progress';
import { GoalProgressWrapperComponent } from './goal.progress.wrapper/goal.progress.wrapper';
import { RecommendedRibbonComponent } from './recommended.ribbon/recommended.ribbon';
import { DiscountCodeEntryComponent } from './cart.review/discount.code.entry/discount.code.entry';
import { DescriptionAndTeacherRecommendationComponent } from './title.details/description.and.teacher.recommendation/description.and.teacher.recommendation';
import { TeacherRecommendationNoteInputComponent } from './title.details/teacher.recommendation.note.input/teacher.recommendation.note.input';
import { ExtraContentButtonsComponent } from './title.details/extra.content.buttons/extra.content.buttons';
import { ReviewsComponent } from './title.details/reviews/reviews';
import { PreorderRibbonComponent } from './preorder.ribbon/preorder.ribbon';
import { ClickToEditTextFieldComponent } from '../admin/shared/click.to.edit.text.field/click.to.edit.text.field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClickToEditSelectFieldComponent } from '../admin/shared/click.to.edit.select.field/click.to.edit.select.field';
import { ClickToEditSchoolComponent } from '../admin/shared/click.to.edit.school/click.to.edit.school';


const components: Array<any> = [
	ActualBookCarouselComponent,
	AddToCartModalComponent,
	UpdateCartRecipientModalComponent,
	BookListCarouselComponent,
	BookComponent,
	BottomSearchComponent,
	CartButtonsComponent,
	WishlistButtonsComponent,
	CartComponent,
	HelpMenuDropdownComponent,
	SettingsComponent,
	InputSpinnerComponent,
	QuantityDropdownComponent,
	TitleDetailsLargeComponent,
	TitleDetailsMediumComponent,
	TitleDetailsSmallComponent,
	TitleDetailsCarouselComponent,
	TitleDetailsCarouselLargeComponent,
	TitleDetailsCarouselSmallComponent,
	RecommendedListCarouselComponent,
	CartReviewComponent,
	OrderSummaryComponent,
	EfairCodeComponent,
	UserInfoPromptComponent,
	EmailWishlistPromptComponent,
	UnsupportedDeviceModalComponent,
	CartReviewItemLgComponent,
	CartReviewItemSmComponent,
	ListBookComponent,
	LoadingComponent,
	ErrorComponent,
	BookSearchComponent,
	BookFilterModalComponent,
	BookFiltersComponent,
	EmailValidatorDirective,
	TrailerModalComponent,
	AppOutOfDateModalComponent,
	LoginErrorModalComponent,
	DaysRemainingComponent,
	TopSearchComponent,
	TopSearchSmallModalComponent,
	SystemMessageComponent,
	ActualMobileBookCarouselComponent,
	CartUnavailableItemsModalComponent,
	ManageWishlistComponent,
	ManageWishlistItemComponent,
	ShareWishlistComponent,
	ViewRedirectComponent,
	ActionButtonConfirmationComponent,
	CartIsReadyPopupComponent,
	CheckmarkAnimationComponent,
	StaffNameSelectComponent,
	AddToCartFromTeacherWishlistModalComponent,
	OrderStatusComponent,
	OrderStatusLinkComponent,
	SupportPhoneNumberComponent,
	UndoTimerComponent,
	RecommendButtonsComponent,
	CoverRecommendationComponent,
	POBoxModalComponent,
	SchoolwideStaffModalComponent,
	SchoolwideStaffComponent,
	WishlistItemsAlreadyPurchasedModalComponent,
	ExpandableTextComponent,
	GoalProgressComponent,
	GoalProgressWrapperComponent,
	RecommendedRibbonComponent,
	DiscountCodeEntryComponent,
	DescriptionAndTeacherRecommendationComponent,
	TeacherRecommendationNoteInputComponent,
	ExtraContentButtonsComponent,
	ReviewsComponent,
	PreorderRibbonComponent,
	ClickToEditTextFieldComponent,
	ClickToEditSelectFieldComponent,
	ClickToEditSchoolComponent
];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ClipboardModule,
		MatFormFieldModule,
		MatInputModule,
		MatSlideToggleModule,
		MatNativeDateModule,
		Ng5SliderModule,
		MatRadioModule,
		AlertModule,
		CollapseModule.forRoot(),
		TabsModule.forRoot(),
		PopoverModule.forRoot(),
		TypeaheadModule.forRoot(),
		BsDropdownModule.forRoot(),
		InlineSVGModule.forRoot(),
		QRCodeModule,
		SwiperModule,
		SharedRoutingModule,
		MatAutocompleteModule,
		ReactiveFormsModule
	],
	entryComponents: [
		TitleDetailsLargeComponent,
		TitleDetailsMediumComponent,
		TitleDetailsSmallComponent,
		TitleDetailsCarouselComponent,
		TitleDetailsCarouselLargeComponent,
		TitleDetailsCarouselSmallComponent,
		UserInfoPromptComponent,
		CoverRecommendationComponent,
		EmailWishlistPromptComponent,
		UnsupportedDeviceModalComponent,
		BookSearchComponent,
		BookFilterModalComponent,
		TrailerModalComponent,
		AppOutOfDateModalComponent,
		LoginErrorModalComponent,
		TopSearchSmallModalComponent,
		CartUnavailableItemsModalComponent,
		AddToCartModalComponent,
		UpdateCartRecipientModalComponent,
		AddToCartFromTeacherWishlistModalComponent,
		POBoxModalComponent,
		SchoolwideStaffModalComponent,
		WishlistItemsAlreadyPurchasedModalComponent
	],
	providers: [
		ClipboardService,
		GuestGuardService,
		NgNavigatorShareService
	],
	exports: components
})

class SharedModule { }

export { SharedModule };
