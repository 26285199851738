import { Injectable } from '@angular/core';
import { WindowRef } from '../../utils/window.ref';

@Injectable()
class MobileService {
	constructor (private windowRef: WindowRef) {}

	public isMobile () : boolean {
		return this.windowRef.getWindow().navigator.userAgent.includes('Mobile') && !this.windowRef.getWindow().navigator.userAgent.includes('iPad');
	}
	public isIpad () : boolean {
		return this.windowRef.getWindow().navigator.userAgent.includes('iPad');
	}
	public isIos () : boolean {
		return this.windowRef.getWindow().navigator.userAgent.includes('iPhone') || this.windowRef.getWindow().navigator.userAgent.includes('iPad');
	}
}

export { MobileService };
