// modules
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app.routes';
import { ModalModule, BsDropdownModule, CollapseModule, TypeaheadModule, BsDatepickerModule, AlertModule } from 'ngx-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMatDrpModule } from 'ngx-mat-daterange-picker';
import { ServiceModule } from './services/services.module';
import { InlineSVGModule } from 'ng-inline-svg';

// components
import { AppComponent } from './app.component';
import { TopbarComponent } from './topbar/topbar';
import { SidebarComponent } from './sidebar/sidebar';
import { ShoppingCartLgComponent } from './topbar/shopping.cart/shopping.cart.lg/shopping.cart.lg';
import { ShoppingCartSmComponent } from './topbar/shopping.cart/shopping.cart.sm/shopping.cart.sm';
import { SplashComponent } from './splash/splash';
import { CopyrightFooterComponent } from './shared/copyright.footer/copyright.footer';
import { LiveChatComponent } from './topbar/live.chat/live.chat';
import { WishlistLgComponent } from './topbar/wishlist/wishlist.lg/wishlist.lg';
import { WishlistSmComponent } from './topbar/wishlist/wishlist.sm/wishlist.sm';
import { HelpMenuComponent } from './topbar/help.menu/help.menu';

// other
import { AlertDialogComponent } from './shared/alert.dialog/alert.dialog';
import { GlobalErrorHandler } from './global.error.handler';
import { WindowRef } from './utils/window.ref';
import { AuthTokenInterceptor } from './shared/interceptors/auth.token.interceptor';
import { UnauthorizedInterceptor } from './shared/interceptors/unauthorized.interceptor';
import { AppVersionInterceptor } from './shared/interceptors/app.version.interceptor';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [
		AppComponent,
		TopbarComponent,
		SidebarComponent,
		SplashComponent,
		CopyrightFooterComponent,
		ShoppingCartLgComponent,
		ShoppingCartSmComponent,
		AlertDialogComponent,
		LiveChatComponent,
		HelpMenuComponent,
		WishlistLgComponent,
		WishlistSmComponent
	],
	imports: [
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		NgxMatDrpModule,
		SwiperModule,
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		TypeaheadModule.forRoot(),
		BsDatepickerModule.forRoot(),
		AlertModule.forRoot(),
		InlineSVGModule.forRoot(),
		ServiceModule,
		SharedModule,
		MatTooltipModule,
		AppRoutingModule  // <-- must be last import
	],
	providers: [
		WindowRef,
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthTokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppVersionInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true
		}
	],
	entryComponents: [
		AlertDialogComponent
	],
	bootstrap: [AppComponent]
})
class AppModule {}

export { AppModule };
