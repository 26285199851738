import { BaseComponent } from '../../base.component';
import { Output, EventEmitter, Input, Component } from '@angular/core';
import { fade } from '../animations/fade';
import { WindowRef } from '../../utils/window.ref';
import { LAST_URL } from '../../services/helpers/search.service';

@Component({
	selector: 'bottom-search',
	templateUrl: './bottom.search.html',
	styleUrls: ['./bottom.search.css'],
	animations: [fade]
})
class BottomSearchComponent extends BaseComponent {
	@Input() public headerText: string;
	@Input() public searchTerm: string;
	@Output()	public search: EventEmitter<string> = new EventEmitter<string>();
	public showClear: boolean;

	constructor (private windowRef: WindowRef) {
		super();
		this.headerText = this.headerText || 'Looking for More Books?';
	}

	public clickSearch () : void {
		const currentUrl: string = this.windowRef.getWindow().location.pathname;
		localStorage.setItem(LAST_URL, decodeURI(currentUrl));
		this.search.next(this.searchTerm);
	}

	public clearSearchTerm () : void {
		this.searchTerm = '';
		this.toggleClear(false);
	}

	public toggleClear (isFocusing: boolean) : void {
		this.showClear = isFocusing && !!this.searchTerm && this.searchTerm.length > 0;
	}

	public preventBlur (event: Event) : void {
		event.preventDefault();
	}
}

export { BottomSearchComponent };
