import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { BaseComponent } from '../../../base.component';
import { Displayables } from '../../../utils/displayables';

@Component({
	selector: 'click-to-edit-text-field',
	templateUrl: 'click.to.edit.text.field.html',
	styleUrls: ['click.to.edit.text.field.css']
})

class ClickToEditTextFieldComponent extends BaseComponent {

	@Input() public preventNull: boolean;
	@Input() public isNumber: boolean;
	@Input() public fromAdmin: string;
	@Input() public fieldName: string;
	@Input() public fieldValue: string;
	@Input() public disabled: boolean;
	@Input() public placeholder: string;
	@Input() public isPrice: boolean;
	@Input() public maxLength: string;
	@Input() public fieldInfoText: string;
	@Output() public updated: EventEmitter<any> = new EventEmitter();

	@ViewChild('fieldInput', {static: false}) public fieldInput: ElementRef;

	public editing: boolean;
	public oldValue: string;

	public edit () : void {
		this.editing = true;
		this.cleanup.push(timer(0).subscribe(() => {
			this.fieldInput.nativeElement.focus();
		}));
		this.oldValue = this.fieldValue;
	}

	public save () : void {
		this.fieldValue = Displayables.trimString(this.fieldValue);
		this.editing = false;
		if (this.preventNull && (!this.fieldValue || this.fieldValue === '' || parseFloat(this.fieldValue) === 0)) {
			this.fieldValue = this.oldValue;
		}
		if (this.updated) {
			this.updated.emit(this.fieldValue);
		}
	}

	public removeNonNumericChars (input: string) : void {
		if (this.isNumber && input) {
			this.cleanup.push(timer(0).subscribe(() => {
				const fieldNumericValue: number = parseInt(input.replace(/[^0-9]/g, ''), 10);
				if (isNaN(fieldNumericValue)) {
					this.fieldValue = null;
				} else {
					this.fieldValue = fieldNumericValue.toString();
				}
			}));
		}
	}

}

export { ClickToEditTextFieldComponent };
