class ErrorHelper {

	public static getRootError (error: any) : any {
		if (error) {
			if (error.error) {
				return this.getRootError(error.error);
			} else if (error.message && error.message.error) {
				return this.getRootError(error.message.error);
			} else {
				return error;
			}
		} else {
			return {};
		}
	}

}
export { ErrorHelper };
