import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { timer } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { BaseComponent } from '../../../base.component';

@Component({
	selector: 'email-wishlist-prompt',
	templateUrl: './email.wishlist.prompt.html',
	styleUrls: ['./email.wishlist.prompt.css']
})

class EmailWishlistPromptComponent extends BaseComponent implements AfterViewInit {
	@ViewChild('emailElement', {static: false}) public emailElement: ElementRef;
	public email: string;
	public onContinue: Function;

	constructor (public modal: BsModalRef, private analyticsService: AnalyticsService) {
		super();
	}

	public ngAfterViewInit () : void {
		this.cleanup.push(timer(0).subscribe(() => {
			this.emailElement.nativeElement.focus();
		}));
	}

	public continue () : void {
		this.analyticsService.sendEvent('Email Wishlist Sent');
		this.modal.hide();
		this.onContinue(this.email);
	}

	public cancel () : void {
		this.analyticsService.sendEvent('Email Wishlist Prompt Canceled');
		this.modal.hide();
	}
}

export { EmailWishlistPromptComponent };
