import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Review } from '../../../services/apis/book.review/book.review.service';

@Component({
	selector: 'reviews',
	templateUrl: './reviews.html',
	styleUrls: ['./reviews.css']
})

class ReviewsComponent {
	@Input() public reviews: Array<Review>;
	@Input() public expandable: boolean;
	@Input() public isExpanded: boolean;

	@Output() public expanded: EventEmitter<void> = new EventEmitter();

	public triggerFocus () : void {
		this.expanded.emit();
	}
}

export { ReviewsComponent };
