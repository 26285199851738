import { OnInit } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { CartService } from '../../../services/apis/cart/cart.service';
import { BaseComponent } from '../../../base.component';

abstract class AbstractShoppingCartComponent extends BaseComponent implements OnInit {
	public cartItemCount: number;
	public showMyCart: boolean;
	public pulse: string = 'down';
	public checkoutDisabled: boolean;

	constructor (protected analyticsService: AnalyticsService, private cartService: CartService) {
		super();
	}

	public ngOnInit () : void {
		this.cartItemCount = this.cartService.getItemCount();

		this.cleanup.push(this.cartService.onCartUpdate.subscribe(() => {
			const afterCount: number = this.cartService.getItemCount();
			if (afterCount > this.cartItemCount) {
				this.pulse = 'up';
			}
			this.cartItemCount = afterCount;
		}));
	}

	public animationDone (event: AnimationEvent) : void {
		if (event.toState === 'up') {
			this.pulse = 'down';
		}
	}

}

export { AbstractShoppingCartComponent };
