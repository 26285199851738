import { DEBUGGING } from '../../constants/local.storage';

class Log {

	private static logOutput: boolean = localStorage.getItem(DEBUGGING) === 'true';

	public static log (text: string) : void {
		if (this.logOutput) {
			console.log(text);
		}
	}
}

export { Log };
