import { Component } from '@angular/core';
import { BookService } from '../../../services/apis/book/book.service';
import { CartService } from '../../../services/apis/cart/cart.service';
import { TitleDetailsService } from '../../../services/helpers/title.details.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { AbstractCartReviewItemComponent } from '../abstract.cart.review.item/abstract.cart.review.item';
import { UserService } from '../../../services/apis/user/user.service';
import { MAX_INPUT_SPINNER_VALUE, MIN_INPUT_SPINNER_VALUE } from '../../input.spinner/input.spinner';
import { ModalService } from '../../../services/helpers/modal.service';

@Component({
	selector: 'cart-review-item-lg',
	templateUrl: './cart.review.item.lg.html',
	styleUrls: ['./cart.review.item.lg.css']
})

class CartReviewItemLgComponent extends AbstractCartReviewItemComponent {

	constructor (bookService: BookService, titleDetailsService: TitleDetailsService, modalService: ModalService,
							 cartService: CartService, analyticsService: AnalyticsService, userService: UserService) {
		super(bookService, titleDetailsService, modalService, cartService, analyticsService, userService);
	}

	public clickDecrementQuantity () : void {
		if (this.item.quantity > MIN_INPUT_SPINNER_VALUE) {
			this.updatingQuantity = true;
			this.item.quantity = this.item.quantity - 1;
			this.analyticsService.sendEvent('Cart Review Decrement Count', 'bookId: ' + this.item.book_id + ', title: ' + this.book.title +  ', quantity: ' + (this.item.quantity));
			this.cleanup.push(this.cartService.updateCartItem(this.item).subscribe(() => {
				this.updatingQuantity = false;
			}, () => {
				this.updatingQuantity = false;
			}));
		}
	}

	public clickIncrementQuantity () : void {
		if (this.item.quantity < MAX_INPUT_SPINNER_VALUE) {
			this.updatingQuantity = true;
			this.item.quantity = this.item.quantity + 1;
			this.analyticsService.sendEvent('Cart Review Increment Count', 'bookId: ' + this.item.book_id + ', title: ' + this.book.title + ', quantity: ' + (this.item.quantity));
			this.cleanup.push(this.cartService.updateCartItem(this.item).subscribe(() => {
				this.updatingQuantity = false;
			}, () => {
				this.updatingQuantity = false;
			}));
		}
	}

	public setQuantity (newQuantity: number) : void {
		this.updatingQuantity = true;
		this.analyticsService.sendEvent('Cart Review Set Quantity', 'bookId: ' + this.item.book_id + ', title: ' + this.book.title + ', old quantity: ' + (this.item.quantity) + ', new quantity: ' + newQuantity);
		this.item.quantity = newQuantity;
		this.cleanup.push(this.cartService.updateCartItem(this.item).subscribe(() => {
			this.updatingQuantity = false;
		}, () => {
			this.updatingQuantity = false;
		}));
	}
}

export { CartReviewItemLgComponent };
