import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BaseComponent } from '../../../base.component';
import { Book } from '../../../services/apis/book/book.service';
import { RecommendedListService } from '../../../services/apis/recommended.list/recommended.list.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { fade } from '../../animations/fade';

@Component({
	selector: 'teacher-recommendation-note-input',
	templateUrl: './teacher.recommendation.note.input.html',
	styleUrls: ['./teacher.recommendation.note.input.css'],
	animations: [fade]
})

class TeacherRecommendationNoteInputComponent extends BaseComponent implements OnInit {
	public recNoteSaving: boolean;
	public recNoteSaved: boolean;
	public recommendationNoteChange: Subject<string> = new Subject<string>();

	@Input() public recNote: string;
	@Input() public recommended: boolean;
	@Input() public book: Book;

	constructor (private recommendedListService: RecommendedListService, private analyticsService: AnalyticsService) {
		super();
	}

	public ngOnInit () : void {
		this.cleanup.push(this.recommendationNoteChange.pipe(
			map((newVal: any) => {
				return {newNote: newVal, book: Object.assign({}, this.book)};
			}))
			.pipe(debounceTime(1000))
			.subscribe((saveObject: { newNote: string, book: Book }) => {
				this.updateRecommendationNote(saveObject.newNote, saveObject.book);
			}));
	}

	public updateRecommendationNote (newNote: string, book: Book) : void {
		this.recNoteSaving = true;
		const sub: Subscription = this.persistBookRecommendationNote(book, newNote)
			.subscribe(() => {
				this.recNoteSaving = false;
				this.cleanup.push(timer(501).subscribe(() => {
					this.recNoteSaved = true;
				}));
				sub.unsubscribe();	// Clean this up here so we don't cancel the request if we close the modal
				this.cleanup.push(timer(2000).subscribe(() => {
					this.recNoteSaved = false;
				}));
			});
	}

	public saveBookRecommendationNote (newRecommendationNote: string) : void {
		this.recommendationNoteChange.next(newRecommendationNote);
	}

	public persistBookRecommendationNote (book: Book, note: string) : Observable<any> {
		this.analyticsService.sendEvent('Recommend Text', '\'' + this.book.title + '\' Recommended?:' + this.recommended);
		return this.recommendedListService.updateRecommendedItem(book._id, {note: note});
	}

}

export { TeacherRecommendationNoteInputComponent };
