import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { BookFilterService } from '../../services/helpers/book.filter.service';
import { Options } from 'ng5-slider';
import { ModalOptions } from 'ngx-bootstrap';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BookFilterModalComponent } from './book.filter.modal/book.filter.modal';
import { BookService, Book } from '../../services/apis/book/book.service';
import { BookClub } from '../../services/apis/book.club/book.club.service';
import { Displayables } from '../../utils/displayables';
import { RecommendedListService, RecommendedItem } from '../../services/apis/recommended.list/recommended.list.service';
import { BookDiscountService } from '../../services/apis/book.discount/book.discount.service';
import { ModalService } from '../../services/helpers/modal.service';

@Component({
	selector: 'book-filters',
	templateUrl: './book.filters.html',
	styleUrls: ['./book.filters.css']
})

class BookFiltersComponent extends BaseComponent implements OnInit {
	@Input() public bookClub: BookClub;
	@Input() public searchResults: Array<Book>;
	@Input() public includeRecommendations: boolean;
	@Input() public isGuest: boolean;

	public priceMin: number = 99;
	public priceMax: number = 0;
	public gradeMin: number = 0.0;
	public gradeMax: number = 12.0;
	public filterText: string;
	public filterCount: number;

	public priceOptions: Options = {
		floor: 0,
		ceil: 100,
		step: 1,
		translate: Displayables.getPriceDisplayable,
		animate: false
	};

	public gradeOptions: Options = {
		floor: 0,
		ceil: 100,
		step: 1,
		translate: Displayables.getGradeDisplayable,
		animate: false
	};

	constructor (public bookFilterService: BookFilterService, private modalService: ModalService, private analyticsService: AnalyticsService,
							 private bookService: BookService, private recommendedListService: RecommendedListService, private bookDiscountService: BookDiscountService) {
		super();
	}

	public ngOnInit () : void {
		if (this.bookClub) {
			this.buildRangesForClub();
		} else if (this.searchResults) {
			this.buildRangesForSearchResults();
		}

		this.priceMin = Math.floor(this.priceMin);
		this.priceMax = Math.ceil(this.priceMax);
		this.gradeMin = Math.floor(this.gradeMin);
		this.gradeMax = Math.ceil(this.gradeMax);

		this.filterText = 'Filter';
		this.bookFilterService.setPriceFilter(this.priceMin, this.priceMax);
		this.bookFilterService.setGradeFilter(this.gradeMin, this.gradeMax);
		this.bookFilterService.setPriceAbs(this.priceMin, this.priceMax);
		this.bookFilterService.setGradeAbs(this.gradeMin, this.gradeMax);
		this.bookFilterService.applyFilters();

		this.priceOptions.floor = this.priceMin;
		this.priceOptions.ceil = this.priceMax;
		this.gradeOptions.floor = this.gradeMin;
		this.gradeOptions.ceil = this.gradeMax;

		this.cleanup.push(this.bookFilterService.onFilterChange.subscribe(() => {
			this.filterText = 'Filter';
			this.filterCount = 0;
			if (this.bookFilterService.isFilteringPrice()) {
				this.filterText = `Price: $${this.bookFilterService.priceMin} - $${this.bookFilterService.priceMax}`;
				this.filterCount += 1;
			}
			if (this.bookFilterService.isFilteringGrade()) {
				this.filterText = this.bookFilterService.isFilteringPrice() ? `${this.filterText}&nbsp;&nbsp;|&nbsp;&nbsp;` : '';
				this.filterText += `Grade: ${Displayables.getGradeDisplayable(this.bookFilterService.gradeMin)} - ${Displayables.getGradeDisplayable(this.bookFilterService.gradeMax)}`;
				this.filterCount += 1;
			}
		}));
	}

	private setMinMax (book: Book) : void {
		if (book) {
			const price: number = this.bookDiscountService.getPrice(book);
			if (price < this.priceMin) {
				this.priceMin = price;
			}
			if (price > this.priceMax) {
				this.priceMax = price;
			}
		}
	}

	private buildRangesForClub () : void {
		for (const category of this.bookClub.categories) {
			for (const bookList of category.bookLists) {
				for (const book_id of bookList.book_ids) {
					this.setMinMax(this.bookService.getCachedBook(book_id));
				}
			}
		}

		if (this.includeRecommendations) {
			const recommendedItems: Array<RecommendedItem> = this.recommendedListService.getRecommendedList();
			for (const item of recommendedItems) {
				this.setMinMax(this.bookService.getCachedBook(item.book_id));
			}
		}
	}

	private buildRangesForSearchResults () : void {
		for (const book of this.searchResults) {
			this.setMinMax(book);
		}
	}

	public openModal () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-sm',
			initialState: {
				priceOptions: this.isGuest && (this.bookClub.settings && this.bookClub.settings.useInvoicePayment) ? undefined : this.priceOptions,
				gradeOptions: this.gradeOptions
			},
			backdrop: 'static'
		};
		this.analyticsService.sendEvent('Filters Open Modal');
		this.modalService.show(BookFilterModalComponent, config);
	}
}

export { BookFiltersComponent };
