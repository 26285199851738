import { Component, Input, OnInit } from '@angular/core';
import { ModalOptions } from 'ngx-bootstrap';
import { TitleDetailsService } from '../../../services/helpers/title.details.service';
import { TitleDetailsCarouselComponent } from '../../title.details.carousel/title.details.carousel';
import { Book, BookService } from '../../../services/apis/book/book.service';
import { Displayables } from '../../../utils/displayables';
import { BaseComponent } from '../../../base.component';
import { RecommendedListService } from '../../../services/apis/recommended.list/recommended.list.service';
import { BookClub } from '../../../services/apis/book.club/book.club.service';
import { CartItemRecipient } from '../../../services/apis/cart/cart.service';
import { BookDiscountService } from '../../../services/apis/book.discount/book.discount.service';
import { BOOK_LANGUAGES, Language } from '../../../constants/book.languages';
import { ModalService } from '../../../services/helpers/modal.service';
import { NotYetPublishedHelper } from '../../../services/helpers/not.yet.published.helper';

@Component({
	selector: 'list-book',
	templateUrl: './list.book.html',
	styleUrls: ['./list.book.css']
})

class ListBookComponent extends BaseComponent implements OnInit {
	@Input() public book: Book;
	@Input() public book_id: string;
	@Input() public fromSearch: boolean;
	@Input() public bookClub: BookClub;
	@Input() public fromTeacherWishlist: boolean;
	@Input() public fromWishlist: boolean;
	@Input() public defaultCartItemRecipient: CartItemRecipient;
	public displayables: any = Displayables;
	public recommended: boolean;
	public language: Language;
	public discountedPrice: string;
	public hideNypDate: boolean;

	constructor (private titleDetailsService: TitleDetailsService, private modalService: ModalService, private recommendedListService: RecommendedListService, private bookService: BookService,
							 private bookDiscountService: BookDiscountService) {
		super();
	}

	public ngOnInit () : void {

		if (!this.book && this.book_id) {
			this.book = this.bookService.getCachedBook(this.book_id);
		}

		if (!this.fromSearch && !this.fromWishlist) {
			this.hideNypDate = !NotYetPublishedHelper.isNypBook(this.book, this.bookClub);
		}

		this.language = BOOK_LANGUAGES.get(this.book.textLanguage);

		this.discountedPrice = this.bookDiscountService.getCachedBookDiscountedPrice(this.book.titlewave_id);
		this.recommended = this.recommendedListService.isInMyRecommendedList(this.book._id);
		this.cleanup.push(this.recommendedListService.onListUpdate.subscribe(() => {
			this.recommended = this.recommendedListService.isInMyRecommendedList(this.book._id);
		}));
	}

	public openTitleDetails (event: Event, fullRowClicked: boolean) : void {
		if (!fullRowClicked || this.fromSearch) {
			event.stopPropagation();
			const config: ModalOptions = {
				animated: true,
				class: 'modal-lg title-details-modal',
				initialState: {
					fromTeacherWishlist: this.fromTeacherWishlist,
					fromWishlist: this.fromWishlist,
					defaultCartItemRecipient: this.defaultCartItemRecipient
				}
			};
			this.titleDetailsService.setIndex(0);
			this.titleDetailsService.setBook_ids([this.book._id]);
			this.modalService.show(TitleDetailsCarouselComponent, config);
		}
	}

}

export { ListBookComponent };
