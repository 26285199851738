import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ACCESS_TOKEN } from '../../constants/local.storage';

@Injectable()
class AuthTokenInterceptor implements HttpInterceptor {
	constructor () {}
	public intercept (request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
		const token: string = localStorage.getItem(ACCESS_TOKEN);
		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			});
		}
		return next.handle(request);
	}
}

export { AuthTokenInterceptor };
