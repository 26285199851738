enum UserRole {
	TEACHER = 'teacher',
	GUARDIAN = 'guardian',
	GUEST = 'guest'
}

enum RewardsType {
	TITLEWAVE = 'titlewave',
	NEXTBEE = 'nextbee'
}

export { UserRole, RewardsType };
