import { Component} from '@angular/core';
import { CartItemRecipient } from '../../services/apis/cart/cart.service';

@Component({
	selector: 'title-details-carousel',
	templateUrl: './title.details.carousel.html',
	styleUrls: ['./title.details.carousel.css']
})

class TitleDetailsCarouselComponent  {
	public fromTeacherWishlist: boolean;
	public fromWishlist: boolean;
	public defaultCartItemRecipient: CartItemRecipient;
}

export { TitleDetailsCarouselComponent };
