import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BasicCrudService } from '../basic.crud/basic.crud.service';

class RecipientData {
	public id?: string;
	public email: string;
	public data: any;
}
enum EmailStatus {
	STATUS_SUCCESS = 'success',
	STATUS_ERROR = 'error'
}
@Injectable()
class EmailService extends BasicCrudService<any> {
	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/email');
	}

	public send (senderId: string, templateName: string, subject: string, recipients: Array<RecipientData>) : Observable<any> {
		const options: any = {};
		options.headers = {'Content-Type': 'application/json'};
		const body: any = {
			senderId: senderId,
			recipients: recipients,
			subject: subject,
			templateName: templateName
		};
		options.body = body;
		return this.http.post(this.url, JSON.stringify(body), options);
	}

	public sendWishlist (subject: string, recipients: Array<RecipientData>) : Observable<any> {
		const options: any = {};
		options.headers = {'Content-Type': 'application/json'};
		const body: any = {
			recipients: recipients,
			subject: subject
		};
		options.body = body;
		return this.http.post(this.url + '/shareWishlist', JSON.stringify(body), options);
	}
}

export { EmailService, EmailStatus, RecipientData };
