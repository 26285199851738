import { Component } from '@angular/core';
import { LiveChatService } from '../../../services/helpers/live.chat.service';
import { BaseSupportModalComponent } from '../../support.modals/base.support.modal';
import { BsModalRef } from 'ngx-bootstrap';
import { Assets } from '../../assets';

@Component({
	selector: 'login-error-modal',
	templateUrl: './login.error.modal.html',
	styleUrls: ['./login.error.modal.css']
})

class LoginErrorModalComponent extends BaseSupportModalComponent {

	public SUPPORT_PHONE_NUMBER: string = Assets.SUPPORT_PHONE_NUMBER;

	constructor (liveChatService: LiveChatService, modal: BsModalRef) {
		super(liveChatService, modal);
	}
}

export { LoginErrorModalComponent };
