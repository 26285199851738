import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { BookAvailabilityService } from '../book.availability/book.availability.service';
import { BookDiscountService } from '../book.discount/book.discount.service';

class Book {
	public _id?: string;
	public titlewave_id: string;
	public title: string;
	public author?: string;
	public price?: string;
	public bindingFormat?: string;
	public chokingHazards?: Array<string>;
	public coverUrl?: string;
	public description?: string;
	public isbn: string;
	public isbn10?: string;
	public isbn13?: string;
	public subjects?: Array<string>;
	public classifications?: Array<string>;
	public arReadingLevel?: string;
	public arInterestLevel?: string;
	public guidedReadingLevel?: string;
	public fpGuidedReadingLevel?: string;
	public readingCountsReadingLevel?: string;
	public audienceLevel?: string;
	public readingLevel?: string;
	public lexileLevel?: string;
	public recommended?: boolean;
	public inWishlist?: boolean;
	public recommendationNote?: string;
	public updated?: Date;
	public availableQuantity?: number;
	public publicationYear?: string;
	public trailerEmbedString?: string;
	public orphaned?: boolean;
	public textLanguage?: string;
	public advertised?: boolean;
	public previewableISBN?: string;
	public isNYP?: boolean;
	public nypDate?: Date;
	[key: string]: string|Array<string>|boolean|Date|number;
}

@Injectable()
class BookService extends BasicCrudService<Book> {

	public onClickedOutsideCoverRecommendation: Subject<void> = new Subject();

	private books: Map<string, Book> = new Map();

	constructor (http: HttpClient, private bookAvailabilityService: BookAvailabilityService, private bookDiscountService: BookDiscountService) {
		super(http);
		this.setUrl('/server/api/books');
	}

	public cacheBooks (books: Array<Book>) : Observable<boolean> {
		const twIds: Array<string> = [];
		for (const book of books) {
			twIds.push(book.titlewave_id);
			if (book.price && book.price.substring(0, 1) === '$') { // TODO: temporary display fix for TW prices coming in as $x.xx instead of x.xx. ask TW to fix this and then remove this code
				book.price = book.price.substring(1);
			}
			this.books.set(book._id, book);
			this.bookDiscountService.calculateBookDiscount(book);
		}
		return this.bookAvailabilityService.fetchAvailability(twIds).pipe(map(() => {
			for (const book of books) {
				book.availableQuantity = this.bookAvailabilityService.getAvailability(book.titlewave_id);
				this.books.set(book._id, book);
			}
			return true;
		}));
	}

	public getCachedBook (id: string) : Book {
		return this.books.get(id);
	}

	public cacheMissingBooks (book_ids: Array<string>) : Observable<boolean> {
		const booksToFetch: Array<string> = [];
		for (const book_id of book_ids) {
			if (!this.books.get(book_id)) {
				booksToFetch.push(book_id);
			}
		}

		if (booksToFetch.length > 0) {
			return this.search({book_ids: booksToFetch}).pipe(mergeMap((books: Array<Book>) => {
				return this.cacheBooks(books);
			}));
		} else {
			return of(true);
		}
	}

	public getOrphanedBooks () : Observable<Array<Book>> {
		return this.http.get<Array<Book>>(this.url + '/orphanedBooks');
	}

	public getCache () : any {
		return this.books;
	}

	public getAll () : Observable<Array<Book>> {
		return this.http.get<Array<Book>>(this.url + '/all');
	}

	public reset () : any {
		this.books = new Map();
	}

	public textSearch (searchTerm: string, hideOrphanedBooks: boolean) : Observable<Array<Book>> {
		return this.http.get<Array<Book>>(`${this.url}/search?searchTerm=${encodeURIComponent(searchTerm)}&hideOrphanedBooks=${hideOrphanedBooks}`);
	}

	public cachedSearch (searchTerm: string) : Array<Book> {
		const searchLowered: string = searchTerm.toLowerCase();
		let result: Array<Book> = Array.from(this.books.values());
		result = result.filter((book: Book) => {
			let bookMatches: boolean = (book.title && book.title.toLowerCase().indexOf(searchLowered) > -1);
			bookMatches = bookMatches || (book.author && book.author.toLowerCase().indexOf(searchLowered) > -1);
			bookMatches = bookMatches || (book.description && book.description.toLowerCase().indexOf(searchLowered) > -1);
			return bookMatches;
		});
		return result;
	}

	public getPreviewUrl (_id: string) : Observable<string> {
		return this.http.get<string>(`${this.url}/preview/${_id}`);
	}
}

export { BookService, Book };
