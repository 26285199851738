import { Injectable } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { Subject } from 'rxjs';

@Injectable()
class TitleDetailsService {
	private index: number;
	private book_ids: Array<string>;
	private bookListId: string;
	public updatePositionInCarousel: Subject<any> = new Subject<any>();

	constructor (private analyticsService: AnalyticsService) {
		this.book_ids = [];
		this.index = 0;
	}

	public setBookListId (bookListId: string) : void {
		this.bookListId = bookListId;
	}

	public getBookListId () : string {
		return this.bookListId;
	}

	public setIndex (index: number) : void {
		this.index = index;
	}

	public getIndex () : number {
		return this.index;
	}

	public updateCarousel () : void {
		this.updatePositionInCarousel.next({index: this.index, id: this.bookListId});
	}

	public getBook_id () : string {
		return this.book_ids[this.index];
	}

	public getNextBook_id () : string {
		let tempIndex: number = this.index + 1;
		if (tempIndex >= this.book_ids.length) {
			tempIndex = 0;
		}
		return this.book_ids[tempIndex];
	}

	public getPrevBook_id () : string {
		let tempIndex: number = this.index - 1;
		if (tempIndex < 0) {
			tempIndex =  this.book_ids.length - 1;
		}
		return this.book_ids[tempIndex];
	}

	public setBook_ids (bookArray: Array<string>) : void {
		this.book_ids = bookArray;
	}

	public getBook_ids () : Array<string> {
		return this.book_ids;
	}

	public next () : void {
		if (++this.index >= this.book_ids.length) {
			this.index = 0;
		}

		this.analyticsService.sendEvent('Title Details Next', 'New Index: ' + this.index);
	}

	public prev () : void {
		if (--this.index < 0) {
			this.index = this.book_ids.length - 1;
		}
		this.analyticsService.sendEvent('Title Details Prev', 'New Index: ' + this.index);
	}

	public getListLength () : number {
		return this.book_ids.length;
	}
}

export { TitleDetailsService };
