import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigUtils } from '../../../utils/config';

@Injectable()
abstract class BasicCrudService<T> {

	protected url: string;

	constructor (protected http: HttpClient) { }

	protected setUrl (url: string) : void {
		this.url = ConfigUtils.getApiHost() + url;
	}

	public search (query?: any) : Observable<Array<T>> {
		if (query) {
			return this.http.get<Array<T>>(this.url, {params: query});
		} else {
			return this.http.get<Array<T>>(this.url);
		}
	}

	public get (id: string) : Observable<T> {
		return this.http.get<T>(this.url + '/' + encodeURIComponent(id));
	}

	public delete (id: string) : Observable<T> {
		return this.http.delete<T>(this.url + '/' + encodeURIComponent(id));
	}

	public create (model: any) : Observable<T> {
		return this.http.post<T>(this.url, model);
	}

	public update (model: any, id?: string) : Observable<T> {
		return this.http.patch<T>(this.url + '/' + encodeURIComponent(id ? id : model._id), model);
	}
}

export { BasicCrudService };
