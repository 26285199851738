import { state, style, animate, trigger, transition, AnimationTriggerMetadata } from '@angular/animations';

const pulse: AnimationTriggerMetadata = trigger('pulse', [
	state('void', style({transform: 'scale(0)'})),
	state('up', style({transform: 'scale(1.5)'})),
	state('down', style({transform: 'scale(1)'})),
	transition('void => up', animate('.4s ease-in')),
	transition('down => up', animate('.2s ease-in')),
	transition('up => down', animate('.2s ease-out')),
	transition('down => void', animate('.4s ease-out'))
]);

export { pulse };
