import { Component, HostListener, OnInit } from '@angular/core';
import { pulse } from '../../../shared/animations/pulse';
import { AbstractWishlistComponent } from '../abstract.wishlist/abstract.wishlist';
import { EditWishlistService } from '../../../services/apis/wishlist/edit.wishlist.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';

@Component({
	selector: 'wishlist-lg',
	templateUrl: './wishlist.lg.html',
	styleUrls: ['./wishlist.lg.css', '../abstract.wishlist/abstract.wishlist.css'],
	animations: [pulse]
})

class WishlistLgComponent extends AbstractWishlistComponent implements OnInit {
	constructor (editWishlistService: EditWishlistService, analyticsService: AnalyticsService) {
		super(editWishlistService, analyticsService);
	}

	public ngOnInit () : void {
		super.ngOnInit();
		this.cleanup.push(this.editWishlistService.onForceWishlistState.subscribe((state: boolean) => {
			this.showMyWishlist = state;
		}));
	}

	@HostListener('document:click', ['$event']) public clickedOutside (event: any) : void {
		const found: boolean = event.composedPath().find((item: any) => {
			return item.localName === 'wishlist-lg';
		});
		if (!found) {
			this.showMyWishlist = false;
		}
	}

	public toggleWishlist () : void {
		this.showMyWishlist = !this.showMyWishlist;
		this.analyticsService.sendEvent('Wishlist Toggle', this.showMyWishlist ? 'Open' : 'Close');
	}

	public clickShare () : void {
		this.analyticsService.sendEvent('Wishlist Share');
		this.showMyWishlist = false;
	}
}

export { WishlistLgComponent };
