import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { pulse } from '../../../shared/animations/pulse';
import { Router } from '@angular/router';
import { AbstractWishlistComponent } from '../abstract.wishlist/abstract.wishlist';
import { EditWishlistService } from '../../../services/apis/wishlist/edit.wishlist.service';

@Component({
	selector: 'wishlist-sm',
	templateUrl: './wishlist.sm.html',
	styleUrls: ['../abstract.wishlist/abstract.wishlist.css'],
	animations: [pulse]
})

class WishlistSmComponent extends AbstractWishlistComponent implements OnInit {

	constructor (analyticsService: AnalyticsService, editWishlistService: EditWishlistService, private router: Router) {
		super(editWishlistService, analyticsService);
	}

	public clickShare () : void {
		this.analyticsService.sendEvent('Wishlist Share');
		this.router.navigate(['/manageWishlist']);
	}
}

export { WishlistSmComponent };
