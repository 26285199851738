const MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING: any = {
	CONTINENTAL_US: 25,
	ALASKA_HAWAII: 65
};

const SHIPPING_CHARGE_BASE_AMOUNT: any = {
	CONTINENTAL_US: 3.95,
	ALASKA_HAWAII: 12
};

const SHIPPING_CHARGE_PER_BOOK: any = {
	CONTINENTAL_US: .50,
	ALASKA_HAWAII: .99
};

enum ALASKA_ZIP_RANGE {
	MIN = 99500,
	MAX = 99999
}

enum HAWAII_ZIP_RANGE {
	MIN = 96700,
	MAX = 96899
}

enum CompleteOrderStatus {
	Complete = 'complete',
	Cancel = 'cancel'
}

enum OrderStatus {
	Created = 'created',
	PaymentReceived = 'paymentReceived',
	SentToTitlewave = 'sentToTitlewave',
	Complete = 'complete',
	FailedToSend = 'failedToSend',
	FailedToVerifyPayment = 'failedToVerifyPayment',
	PaymentDataMismatch = 'paymentDataMismatch',
	NeverVerifiedEfairEnded = 'neverVerifiedEfairEnded',
	Cancelled = 'cancelled',
	SendingToTitlewave = 'sendingToTitlewave',
}

export { MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING, SHIPPING_CHARGE_BASE_AMOUNT, SHIPPING_CHARGE_PER_BOOK, ALASKA_ZIP_RANGE, HAWAII_ZIP_RANGE, OrderStatus, CompleteOrderStatus};
