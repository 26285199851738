import { trigger, state, style, transition, animate, AnimationTriggerMetadata } from '@angular/animations';

enum SLIDE_STATE {
	CURRENT = 'current',
	NEXT = 'next',
	PREVIOUS = 'prev'
}

const carouselSlide: AnimationTriggerMetadata = trigger('carouselSlide', [
	state(SLIDE_STATE.PREVIOUS, style({
		transform: 'translate({{slide_px}}px)'
	}), {params: {slide_px: 780}}),
	state(SLIDE_STATE.NEXT, style({
		transform: 'translate({{slide_px}}px)'
	}), {params: {slide_px: -780}}),
	transition(`${SLIDE_STATE.CURRENT} => ${SLIDE_STATE.PREVIOUS}, ${SLIDE_STATE.CURRENT} => ${SLIDE_STATE.NEXT}`, animate('300ms ease-out')),
]);

const titleDetailsSlide: AnimationTriggerMetadata = trigger('titleDetailsSlide', [
	state(SLIDE_STATE.PREVIOUS, style({
		transform: 'translate({{slide_px}}px)'
	}), {params: {slide_px: 1130}}),
	state(SLIDE_STATE.NEXT, style({
		transform: 'translate({{slide_px}}px)'
	}), {params: {slide_px: -1130}}),
	transition(`${SLIDE_STATE.CURRENT} => ${SLIDE_STATE.PREVIOUS}, ${SLIDE_STATE.CURRENT} => ${SLIDE_STATE.NEXT}`, animate('300ms ease-out')),
]);

export { titleDetailsSlide, carouselSlide, SLIDE_STATE };
