
const CART_RECIPIENT_LIST_KEY: string = 'cartRecipientList';
const CART_RECENT_RECIPIENT_KEY: string = 'cartRecentRecipient';
const CART_ITEMS_KEY: string = 'cartItems';
const CART_ID_KEY: string = 'cart_id';
const EDIT_WISHLIST_ID_LOOKUP: string = 'editingWishlistId';
const VIEW_WISHLIST_IDS_LOOKUP: string = 'viewingWishlistIds';
const SELECTED_WISHLIST_ID: string = 'selectedWishlistId';
const VISITED_EFAIRS: string = 'visited_efairs';
const EFAIR_CODE: string = 'efairCode';
const ACCESS_TOKEN: string = 'access_token';
const HIDE_BANNER: string = 'hideBanner';
const EXPIRES_AT: string = 'expires_at';
const ID_TOKEN_HINT: string = 'id_token_hint';
const REFRESH_TOKEN: string = 'refresh_token';
const PROVIDER_PROFILE: string = 'provider_profile';
const ROLE: string = 'role';
const DEBUGGING: string = 'debugging';
const CURRENT_EFAIR: string = 'current_efair';

export { CART_RECIPIENT_LIST_KEY, CART_RECENT_RECIPIENT_KEY, CART_ITEMS_KEY, CART_ID_KEY, EDIT_WISHLIST_ID_LOOKUP, VIEW_WISHLIST_IDS_LOOKUP,
	SELECTED_WISHLIST_ID, VISITED_EFAIRS, EFAIR_CODE, ACCESS_TOKEN, HIDE_BANNER, EXPIRES_AT, ID_TOKEN_HINT, REFRESH_TOKEN, PROVIDER_PROFILE, ROLE, DEBUGGING, CURRENT_EFAIR };
