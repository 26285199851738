import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { BookClubService } from '../book.club/book.club.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EfairType } from '../../../constants/efairs';

class MarketingMaterialCategory {
	public _id?: string;
	public efairType: EfairType;
	public name: string;
	public order: number;
}

@Injectable()
class MarketingMaterialCategoryService extends BasicCrudService<MarketingMaterialCategory> {

	private categories: Array<MarketingMaterialCategory> = [];

	constructor (http: HttpClient, private bookClubService: BookClubService) {
		super(http);
		this.setUrl('/server/api/marketingMaterialCategories');

		this.bookClubService.onBookClubLoaded.subscribe(() => {
			this.resetCategories();
		});
	}

	public search (query?: any, forceUpdate?: boolean) : Observable<Array<MarketingMaterialCategory>> {
		if (this.categories.length === 0 || forceUpdate) {
			return super.search(query).pipe(map((categories: Array<MarketingMaterialCategory>) => {
				this.categories = categories;
				return this.categories;
			}));
		} else {
			return of(this.categories);
		}
	}

	public resetCategories () : void {
		this.categories = [];
	}

}

export { MarketingMaterialCategoryService, MarketingMaterialCategory };
