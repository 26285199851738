import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { BaseComponent } from '../../../base.component';

@Component({
	selector: 'click-to-edit-select-field',
	templateUrl: 'click.to.edit.select.field.html',
	styleUrls: ['../click.to.edit.text.field/click.to.edit.text.field.css']
})

class ClickToEditSelectFieldComponent extends BaseComponent implements OnInit {

	@Input() public fromAdmin: boolean;
	@Input() public fieldName: string;
	@Input() public fieldValue: string;
	@Input() public options: Array<string>;
	@Input() public optionsDisplayable: Array<string>;
	@Input() public disabled: boolean;
	@Output() public updated: EventEmitter<any> = new EventEmitter();

	@Input() public fieldValueDisplayable: string;

	@ViewChild('fieldInput', {static: false}) public fieldInput: ElementRef;

	public editing: boolean;

	public ngOnInit () : void {
		if (this.optionsDisplayable) {
			this.fieldValueDisplayable = this.optionsDisplayable[this.options.indexOf(this.fieldValue)];
		}
	}

	public edit () : void {
		this.editing = true;
		this.cleanup.push(timer(0).subscribe(() => {
			this.fieldInput.nativeElement.focus();
		}));
	}

	public save () : void {
		this.editing = false;
		if (this.updated) {
			this.updated.emit(this.fieldValue);
			if (this.optionsDisplayable) {
				this.fieldValueDisplayable = this.optionsDisplayable[this.options.indexOf(this.fieldValue)];
			}
		}
	}

	public cancel () : void {
		this.editing = false;
	}

}

export { ClickToEditSelectFieldComponent };
