import { Component, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { timer } from 'rxjs';
import { fade } from '../animations/fade';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BaseComponent } from '../../base.component';
import { WindowRef } from '../../utils/window.ref';
@Component({
	selector: 'efair-code',
	templateUrl: './efair.code.html',
	styleUrls: ['./efair.code.css'],
	animations: [fade]
})

class EfairCodeComponent extends BaseComponent implements OnInit {
	@Input() public code: string;
	public linkCopied: boolean;
	public codeCopied: boolean;
	public shareLink: string;

	constructor (private clipboardService: ClipboardService, private analyticsService: AnalyticsService, private windowRef: WindowRef) {
		super();
	}

	public ngOnInit () : void {
		this.shareLink = this.windowRef.getWindow().location.origin + '/guest/home?efairCode=' + this.code;
	}

	public copyCode () : void {
		this.analyticsService.sendEvent('eFair Code Copy Code');
		this.clipboardService.copyFromContent(this.code);
		this.codeCopied = true;
		this.cleanup.push(timer(2000).subscribe(() => {
			this.codeCopied = false;
		}));
	}

	public copyLink () : void {
		this.analyticsService.sendEvent('eFair Code Copy Link');
		this.clipboardService.copyFromContent(this.shareLink);
		this.linkCopied = true;
		this.cleanup.push(timer(2000).subscribe(() => {
			this.linkCopied = false;
		}));
	}

}

export { EfairCodeComponent };
