import { Component, HostListener, OnInit } from '@angular/core';
import { pulse } from '../../../shared/animations/pulse';
import { AbstractShoppingCartComponent } from '../abstract.shopping.cart/abstract.shopping.cart';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { CartService } from '../../../services/apis/cart/cart.service';
import { UserService } from '../../../services/apis/user/user.service';

@Component({
	selector: 'shopping-cart-lg',
	templateUrl: './shopping.cart.lg.html',
	styleUrls: ['./shopping.cart.lg.css', '../abstract.shopping.cart/abstract.shopping.cart.css'],
	animations: [pulse]
})

class ShoppingCartLgComponent extends AbstractShoppingCartComponent implements OnInit {
	public isGuest: boolean;

	constructor (analyticsService: AnalyticsService, cartService: CartService, private userService: UserService) {
		super(analyticsService, cartService);
	}

	@HostListener('document:click', ['$event']) public clickedOutside (event: any) : void {
		const found: boolean = event.composedPath().find((item: any) => {
			return item.localName === 'shopping-cart-lg';
		});
		if (!found) {
			this.showMyCart = false;
		}
	}

	public ngOnInit () : void {
		super.ngOnInit();
		if (this.userService.getUser()) {
			this.isGuest = this.userService.isGuest();
		} else {
			this.userService.onUserLoad.subscribe(() => {
				this.isGuest = this.userService.isGuest();
			});
		}
	}

	public toggleCart () : void {
		this.showMyCart = !this.showMyCart;
		this.analyticsService.sendEvent('Cart Toggle', this.showMyCart ? 'Open' : 'Close');
	}

	public clickCheckout () : void {
		this.showMyCart = false;
	}
}

export { ShoppingCartLgComponent };
