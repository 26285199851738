import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Book } from '../book/book.service';

class BookDiscount {
	public _id?: string;
	public titlewave_id: string;
	public discountPercentage?: number;
	public type: string;
}

@Injectable()
class BookDiscountService extends BasicCrudService<BookDiscount> {

	private bookDiscountPrices: Map<string, string> = new Map();
	private bookDiscounts: Map<string, BookDiscount> = new Map();

	public onDiscountUpdated: Subject<string> = new Subject();

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/bookdiscounts');
	}

	public cacheBookDiscounts () : Observable<void> {
		return this.search().pipe(map(((bookDiscounts: Array<BookDiscount>) => {
			for (const bookDiscount of bookDiscounts) {
				this.bookDiscounts.set(bookDiscount.titlewave_id, bookDiscount);
			}
		})));
	}

	public getCachedBookDiscountedPrice (titlewave_id: string) : string {
		return this.bookDiscountPrices.get(titlewave_id);
	}

	public getPrice (book: Book) : number {
		const discountedPrice: string = this.getCachedBookDiscountedPrice(book.titlewave_id);
		return parseFloat((discountedPrice || book.price).replace('$', ''));
	}

	public getDiscountPercentage (titlewave_id: string) : number {
		const discount: BookDiscount = this.bookDiscounts.get(titlewave_id);
		return discount ? discount.discountPercentage : 0;
	}

	public calculateBookDiscount (book: Book) : void {
		if (!this.bookDiscountPrices.get(book.titlewave_id)) {
			const bookDiscount: BookDiscount = this.bookDiscounts.get(book.titlewave_id);
			if (bookDiscount && bookDiscount.discountPercentage > 0) {
				this.bookDiscountPrices.set(book.titlewave_id, (parseFloat(book.price.replace('$', '')) * (1 - bookDiscount.discountPercentage)).toFixed(2));
			}
		}
	}

	public reset () : any {
		this.bookDiscountPrices = new Map();
		this.bookDiscounts = new Map();
	}

	public createOrUpdateMany (titlewave_ids: Array<string>, discountPercentage: number) : Observable<Array<BookDiscount>> {
		return this.http.post<Array<BookDiscount>>(this.url, { titlewave_ids: titlewave_ids, discountPercentage: discountPercentage }).pipe(map((results: Array<BookDiscount>) => {
			for (const result of results) {
				this.bookDiscounts.set(result.titlewave_id, result);
				this.onDiscountUpdated.next(result.titlewave_id);
			}
			return results;
		}));
	}
}

export { BookDiscountService, BookDiscount };
