import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';

class Whitelist {
	public _id?: string;
	public email: string;
	public type: string;
}

@Injectable()
class WhitelistService extends BasicCrudService<Whitelist> {


	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/whitelist');
	}

	public isWhitelisted (type: string) : Observable<boolean> {
		return this.http.get<boolean>(this.url + `/${type}`);
	}

}

export { WhitelistService, Whitelist };
