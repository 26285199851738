import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Book } from '../../services/apis/book/book.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'trailer-modal',
	templateUrl: './trailer.modal.html',
	styleUrls: ['./trailer.modal.css']
})

class TrailerModalComponent implements OnInit {
	public book: Book;
	public videoUrl: SafeResourceUrl;
	constructor (private bsModalRef: BsModalRef, private sanitizer: DomSanitizer) {}

	public close () : void {
		this.bsModalRef.hide();
	}

	public ngOnInit () : void {
		if (this.book) {
			this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.book.trailerEmbedString);
		}
	}
}

export { TrailerModalComponent };
