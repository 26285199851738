import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { CartService, CartItemRecipient, CartItem } from '../../services/apis/cart/cart.service';
import { BaseComponent } from '../../base.component';
import { Book } from '../../services/apis/book/book.service';
import { BookClubService, BookClub } from '../../services/apis/book.club/book.club.service';
import { Staff } from '../../services/apis/school/school.service';
import { Displayables } from '../../utils/displayables';
import { NgForm, Validators } from '@angular/forms';
import { RequiredNonWhiteSpaceValidator } from '../../shared/required.non.whitespace.validator/required.non.whitespace.validator';
import { timer } from 'rxjs';
import { EfairType } from '../../constants/efairs';
import { CART_RECENT_RECIPIENT_KEY, CART_RECIPIENT_LIST_KEY } from '../../constants/local.storage';

@Component({
	selector: 'add-to-cart-modal',
	templateUrl: './add.to.cart.modal.html',
	styleUrls: ['./add.to.cart.modal.css']
})

class AddToCartModalComponent extends BaseComponent implements OnInit {
	@ViewChild('firstNameInput', { static: true }) public firstNameInput: ElementRef;
	@ViewChild('addToCartForm', {static: false}) public addToCartForm: NgForm;

	public book: Book;
	public cartItem: CartItem;
	public gift: boolean;

	public onCancel: Function;
	public onSuccess: Function;

	public bookClub: BookClub;
	public firstName: string;
	public lastName: string;
	public selectedStaffMember: Staff;
	public staffList: Array<Staff>;
	public selectedRecipientIndex: number;
	public currentCartItemRecipientIndex: number;
	public recipientList: Array<CartItemRecipient>;
	public inCartForRecipientIndexArray: Array<boolean>;
	public recipientHasChanged: boolean;
	public giftPurchasesEnabled: boolean;
	public addingToCart: boolean;
	public defaultCartItemRecipient: CartItemRecipient;
	public isDonationEfair: boolean;
	public isShipToHomeEfair: boolean;
	public isDisableGifts: boolean;

	public readonly displayables: typeof Displayables = Displayables;
	public readonly EfairType: typeof EfairType = EfairType;

	constructor (private bsModalRef: BsModalRef, private cartService: CartService, private analyticsService: AnalyticsService, private bookclubService: BookClubService) {
		super();
	}

	public ngOnInit () : void {
		this.bookClub = this.bookclubService.getCachedBookClub();
		if (this.bookClub.settings) {
			this.isDonationEfair = !!this.bookClub.settings.donationOrgName;
			this.isShipToHomeEfair = this.bookClub.settings.shipToHome;
			this.isDisableGifts = this.bookClub.settings.disableGifts;
		}

		this.giftPurchasesEnabled = !this.isDisableGifts && !this.isDonationEfair && !this.isShipToHomeEfair;

		this.gift = this.cartItem ? this.cartItem.gift : false;

		this.selectedRecipientIndex = 0;
		this.recipientList = JSON.parse(localStorage.getItem(CART_RECIPIENT_LIST_KEY)) || [];

		if (this.cartItem && this.cartItem.recipient) {
			this.selectedRecipientIndex = this.findRecipientIndex(this.cartItem.recipient);
			if (this.selectedRecipientIndex === -1) {
				this.recipientList.unshift(this.cartItem.recipient);
				this.selectedRecipientIndex = 0;
				localStorage.setItem(CART_RECIPIENT_LIST_KEY, JSON.stringify(this.recipientList));
			}
		} else if (this.defaultCartItemRecipient) {
			this.selectedRecipientIndex = this.findRecipientIndex(this.defaultCartItemRecipient);
			if (this.selectedRecipientIndex === -1) {
				this.recipientList.unshift(this.defaultCartItemRecipient);
				this.selectedRecipientIndex = 0;
				localStorage.setItem(CART_RECIPIENT_LIST_KEY, JSON.stringify(this.recipientList));
			}
		} else {
			const lastRecipient: CartItemRecipient = JSON.parse(localStorage.getItem(CART_RECENT_RECIPIENT_KEY));
			if (lastRecipient) {
				this.selectedRecipientIndex = this.findRecipientIndex(lastRecipient);
			}
		}

		this.selectRecipient();

		this.inCartForRecipientIndexArray = [];
		for (let i: number = 0; i < this.recipientList.length; i++) {
			this.inCartForRecipientIndexArray.push(!!this.cartService.getCartItem(this.book._id, this.recipientList[i]));
			if (this.cartItem && this.cartService.cartItemsAreTheSameItem(this.cartItem, {book_id: this.cartItem.book_id, recipient: this.recipientList[i]})) {
				this.currentCartItemRecipientIndex = i;
			}
		}

		this.cleanup.push(timer(0).subscribe(() => {
			if (this.firstNameInput) {
				this.firstNameInput.nativeElement.focus();
			}
		}));

	}

	public clickCancel () : void {
		this.analyticsService.sendEvent('Add To Cart Cancel');
		this.bsModalRef.hide();
		if (this.onCancel) {
			this.onCancel();
		}
	}

	public clickAddToCart () : void {
		this.addingToCart = true;
		this.analyticsService.sendEvent('Add To Cart');

		this.updateSelectedRecipient();

		this.cleanup.push(this.cartService.addToCart(this.book._id, this.firstName, this.lastName, this.selectedStaffMember, this.gift, false).subscribe(() => {
			this.addingToCart = false;
			this.bsModalRef.hide();
			if (this.onSuccess) {
				this.onSuccess();
			}
		}, () => {
			this.addingToCart = false;
			this.bsModalRef.hide();
		}));
	}

	public setUpRecipientForm () : void {
		this.recipientHasChanged = true;
		this.cleanup.push(timer(0).subscribe(() => {
			this.addToCartForm.controls['firstName'].setValidators([Validators.required, RequiredNonWhiteSpaceValidator]);
			this.addToCartForm.controls['lastName'].setValidators([Validators.required, RequiredNonWhiteSpaceValidator]);
		}));
	}

	public updateSelectedRecipient () : void {
		if (this.selectedRecipientIndex === this.recipientList.length) {
			this.addRecipientToList();
		} else {
			const recipient: CartItemRecipient = this.recipientList[this.selectedRecipientIndex];
			this.firstName = recipient.firstName;
			this.lastName = recipient.lastName;
			this.selectedStaffMember = recipient.teacherName;
		}
		localStorage.setItem(CART_RECENT_RECIPIENT_KEY, JSON.stringify({ firstName: this.firstName, lastName: this.lastName, teacherName: this.selectedStaffMember, fromTeacherWishlist: false }));
	}

	public updateCartItem () : void {
		this.addingToCart = true;
		this.updateSelectedRecipient();

		this.cartItem.recipient = { firstName: this.firstName, lastName: this.lastName, teacherName: this.selectedStaffMember, fromTeacherWishlist: false };

		this.cleanup.push(this.cartService.updateCartItem(this.cartItem).subscribe(() => {
			this.addingToCart = false;
			this.bsModalRef.hide();
			if (this.onSuccess) {
				this.onSuccess();
			}
		}, () => {
			this.addingToCart = false;
			this.bsModalRef.hide();
		}));
	}

	private addRecipientToList () : void {
		const newRecipient: CartItemRecipient = { firstName: this.firstName, lastName: this.lastName, teacherName: this.selectedStaffMember, fromTeacherWishlist: false };
		const list: Array<CartItemRecipient> = JSON.parse(localStorage.getItem(CART_RECIPIENT_LIST_KEY)) || [];

		const exists: CartItemRecipient = list.find((recipient: CartItemRecipient) => {
			return recipient.firstName === this.firstName && recipient.lastName === this.lastName && recipient.teacherName.firstName === this.selectedStaffMember.firstName && recipient.teacherName.lastName === this.selectedStaffMember.lastName;
		});

		if (!exists) {
			list.push(newRecipient);
			localStorage.setItem(CART_RECIPIENT_LIST_KEY, JSON.stringify(list));
		}
	}

	public selectRecipient () : void {
		this.firstName = '';
		this.lastName = '';
		this.selectedStaffMember = undefined;
		if (this.cartItem) {
			this.recipientHasChanged = !this.cartService.cartItemsAreTheSameItem(this.cartItem, {book_id: this.cartItem.book_id, recipient: this.recipientList[ this.selectedRecipientIndex ]});
		}
	}

	private findRecipientIndex (recipient: CartItemRecipient) : number {
		return this.recipientList.findIndex((existing: CartItemRecipient) => {
			return existing.firstName === recipient.firstName &&
				existing.lastName === recipient.lastName &&
				existing.teacherName.firstName === recipient.teacherName.firstName &&
				existing.teacherName.lastName === recipient.teacherName.lastName &&
				existing.fromTeacherWishlist === recipient.fromTeacherWishlist;
			});
	}

	public updateStaffMember (teacherName: Staff) : void {
		this.selectedStaffMember = teacherName;
	}
}

export { AddToCartModalComponent };
