import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { School, SchoolService, Staff } from '../../services/apis/school/school.service';
import { BookClub, BookClubService } from '../../services/apis/book.club/book.club.service';
import { UserService } from '../../services/apis/user/user.service';
import { Displayables } from '../../utils/displayables';
import { EfairType } from '../../constants/efairs';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
	selector: 'staff-name-select',
	templateUrl: './staff.name.select.html',
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

class StaffNameSelectComponent extends BaseComponent implements OnInit {
	public staffList: Array<Staff> = [];
	public readonly Displayables: typeof Displayables = Displayables;
	public readonly EfairType: typeof EfairType = EfairType;

	@Output() public teacherSelected: EventEmitter<Staff> = new EventEmitter<Staff>();
	@Input() public selectedStaffMember: Staff;
	@Input() public disabled: boolean;
	@Input() public lastFirst: boolean;
	@Input() public bookClub: BookClub;

	private school: School;
	constructor (private bookClubService: BookClubService, private userService: UserService, private schoolService: SchoolService	) {
		super();
	}

	public ngOnInit () : void {
		if (!this.bookClub) {
			this.bookClub = this.bookClubService.getCachedBookClub();
		}
		this.cleanup.push(this.schoolService.get(this.bookClub.school_id).subscribe((school: School) => {
			this.school = school;
			this.finishInit();
		}));
	}

	public finishInit () : void {
		if ( this.bookClub.type !== EfairType.CLASSROOM ) {
			this.staffList = this.school.efairDefaults.staff ? [...this.school.efairDefaults.staff] : [];
			this.staffList.sort((staff1: Staff, staff2: Staff) => {
				return `${staff1.lastName}, ${staff1.firstName}`.localeCompare(`${staff2.lastName}, ${staff2.firstName}`, 'en', { sensitivity: 'base' });
			});

			if (this.selectedStaffMember) {
				this.selectedStaffMember = this.staffList.find((staff: Staff) => staff.firstName === this.selectedStaffMember.firstName && staff.lastName === this.selectedStaffMember.lastName);
			}
		}

		if (this.staffList.length === 0) {
			const names: Array<string> = this.userService.getTeacher().name.split(/\s/);
			const last: string = names.splice(names.length - 1, 1)[0].trim();
			const first: string = names.join(' ').trim();

			this.staffList.push({ firstName: first, lastName: last });
		}

		if (this.staffList.length === 1) {
			this.selectedStaffMember = this.staffList[0];
			this.teacherSelected.next(this.selectedStaffMember);
		}
	}

	public selectName (teacherName: Staff) : void {
		this.teacherSelected.next(teacherName);
	}
}

export { StaffNameSelectComponent };
