import { mergeMap, filter, map } from 'rxjs/operators';
import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { AuthService } from './services/apis/auth/auth.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AnalyticsService } from './services/helpers/analytics.service';
import { UserService } from './services/apis/user/user.service';
import { BaseComponent } from './base.component';
import { WindowRef } from './utils/window.ref';
import { MobileService } from './services/helpers/mobile.service';
import { BookService } from './services/apis/book/book.service';
import { ModalService } from './services/helpers/modal.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

class AppComponent extends BaseComponent implements OnInit {
	@ViewChild('wrapper', {static: false}) public wrapper: ElementRef;
	@ViewChild('splash', {static: false}) public splash: ElementRef;

	public title: string = 'app';
	public userLoaded: boolean;
	public isAuthenticated: boolean;
	public loading: boolean;
	public isMobile: boolean;
	public isIframe: boolean;
	public isIos: boolean;
	public isErrorRoute: boolean;
	public isViewRoute: boolean;
	public isOrderRoute: boolean;
	public isTeacherRoute: boolean;

	constructor (private authService: AuthService, public router: Router, private analyticsService: AnalyticsService, private activatedRoute: ActivatedRoute,
							 public userService: UserService, private windowRef: WindowRef, private mobileService: MobileService, private bookService: BookService,
							 private modalService: ModalService) {
		super();
	}

	@HostListener('document:click', ['$event']) public clickedOutside (event: any) : void {
		const found: boolean = event.composedPath().find((item: any) => {
			return item.localName === 'cover-recommendation';
		});
		if (!found) {
			this.bookService.onClickedOutsideCoverRecommendation.next();
		}
	}

	public ngOnInit () : void {
		if (!this.detectIframe()) {
			this.updateRoute();

			this.isMobile = this.mobileService.isMobile();
			this.isIos = this.mobileService.isIos();
			this.setInitialLoadingState();

			this.cleanup.push(this.userService.onUserLoad.subscribe(() => {
				this.userLoaded = true;
				this.loading = false;
			}));

			this.cleanup.push(this.userService.onUserUnLoad.subscribe(() => {
				this.userLoaded = false;
			}));

			this.isAuthenticated = this.authService.isAuthenticated();
			this.cleanup.push(this.authService.onAuthenticated.subscribe((authenticated: boolean) => {
				this.isAuthenticated = authenticated;
				this.loading = false;
			}));

			this.cleanup.push(this.authService.onGuestLogin.subscribe(() => {
				this.isAuthenticated = true;
				this.userLoaded = true;
				if (!this.isOrderRoute) {
					this.loading = true;
				}
			}));

			this.cleanup.push(this.router.events.pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd), mergeMap((event: NavigationEnd) : Observable<any> => {
				return this.getTitle().pipe(map((title: string) => {
					return { title: title, event: event };
				}));
			}), map((result: any) => {
				const navEvent: NavigationEnd = result.event as NavigationEnd;
				this.updateRoute();

				if (this.wrapper) {
					this.wrapper.nativeElement.scrollTo(0, 0);
				}

				this.modalService.hideAll();

				this.analyticsService.sendPageView(navEvent.urlAfterRedirects, result.title);
				if (navEvent.urlAfterRedirects === '/') {
					if (this.isAuthenticated) {
						this.authService.navigateToMyEfairsPage();
					} else {
						this.loading = false;
					}
				}
			})).subscribe());
		}

		}

	private detectIframe () : boolean {
		const win: any = this.windowRef.getWindow();
		if (win !== win.top) {
			this.isIframe = true;
		}
		return this.isIframe;
	}

	private setInitialLoadingState () : void {
		const location: Location = this.windowRef.getWindow().location;

		const isRoot: boolean = location.pathname === '/';
		const hasHash: boolean = !!location.hash;
		const hasCodeParam: boolean = !!location.search && location.search.includes('?code=');

		const isAppRoute: boolean = !this.isViewRoute && !isRoot && !this.isOrderRoute;

		this.loading = isAppRoute || hasHash || hasCodeParam;

	}

	private updateRoute () : void {
		const pathName: string = this.windowRef.getWindow().location.pathname;
		this.isErrorRoute = pathName === '/error';
		this.isViewRoute = pathName.startsWith('/view/');
		this.isOrderRoute = pathName.startsWith('/order/') || pathName.endsWith('teacher/orders/status') || pathName === '/orderStatus';
		this.isTeacherRoute = pathName.includes('/teacher/');
	}

	private getTitle () : Observable<string> {
		let route: ActivatedRoute = this.activatedRoute;
		while (route.firstChild) {
			route = route.firstChild;
		}
		return route.data.pipe(map((data: any) => {
			return data.title;
		}));
	}

}

export { AppComponent };
