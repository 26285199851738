import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, flatMap, mergeMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { BookService, Book } from '../book/book.service';
import { DateHelper } from '../../../utils/date.helper';
import { ShipmentInfo } from '../book.club.archive/book.club.archive.service';
import { School, SchoolService } from '../school/school.service';
import { User } from '../user/user.service';
import { FulfillmentCenter } from '../../../constants/fulfillment.centers';
import { BookAvailabilityService } from '../book.availability/book.availability.service';
import { BookDiscountService } from '../book.discount/book.discount.service';
import { BookClubStatus } from '../../../constants/book.club.status';
import { EfairType } from '../../../constants/efairs';
import { EmailType } from '../../../constants/email.types';
import { RewardsType } from '../../../constants/user';
import { GoalType } from '../../../constants/goal.types';
import moment from 'moment';
import { ReadingLevelType } from '../../../constants/reading.level.types';
import { CURRENT_EFAIR } from '../../../constants/local.storage';

class Goal {
	public goalAmount: number;
	public goalType: GoalType;
}

class BookClubReminderEmails {
	public twoWeekReminderSentDate?: Date;
	public oneDayReminderSentDate?: Date;
	public automatedReminderSentDate?: Date;
	public noSalesAfterThreeDaysReminderSentDate?: Date;
	public threeDaysBeforeEndReminderSentDate?: Date;
	public remindersSent?: Array<Date>;
}

class BookClubSettings {
	public billToCustomerNumber?: string;
	public disableGifts?: boolean;
	public disableAddToWishlist?: boolean;
	public disableViewWishlists?: boolean;
	public donationOrgName?: string;
	public customBannerUrl?: string;
	public customMobileBannerUrl?: string;
	public customBannerClickUrl?: string;

	public isNPO?: boolean;
	public npoOrgName?: string;
	public requiredNumberOfBooks?: number;
	public pricePerOrder?: number;
	public taxAmount?: number;
	public preferredRewardsType?: RewardsType;
	public flyersEnabled?: boolean;
	public shipToHome?: boolean;
	public onlySearchCuration?: boolean;

	public useInvoicePayment?: boolean;
	public taxExempt?: boolean;
	public hideCart?: boolean;
	public hideOrderStatus?: boolean;
	public preventQuantityChange?: boolean;
	public hideBottomSearch?: boolean;
	public hideDaysRemainingForGuest?: boolean;

	public grade?: string;
	public numberOfStudents?: number;
	public shouldSendShareToParentsEmail?: boolean;
	public shouldSendParentEfairLaunchNotification?: boolean;
	public shouldSendTeacherOrderNotification?: boolean;

	public customCurationSeason?: string;
	public customCurationYear?: string;
	public customCurationGrade?: string;

	public settingsGroupName?: string;
	[key: string]: boolean | string | RewardsType | number;
}

class BookClub {
	public _id?: string;
	public user_id?: string;
	public school_id?: string;
	public schoolNickname?: string;
	public name?: string;
	public description?: string;
	public startDate?: Date;
	public bookFairStartDate?: Date;
	public endDate?: Date;
	public publishedDate?: Date; // @TODO: Rip out after refactor
	public timezone?: string;
	public setupStep?: number; // @TODO: Rip out after refactor
	public created?: Date;
	public categories?: Array<Category>;
	public shipmentInfo?: Array<ShipmentInfo>;
	public status?: BookClubStatus;
	public type?: EfairType;
	public shipToSchool?: School;
	public physicalFairId?: string;
	public settings?: BookClubSettings;
	public salesforceEfairNumber?: string;
	public salesforceFlrCustomerNumber?: string;
	public reminderEmails?: BookClubReminderEmails;
	public flyersSentDates?: Array<Date>;
	public flyerRequestsSent?: Array<Date>;
	public fulfillmentCenter?: FulfillmentCenter;
	public goal?: Goal;
	public booksPurchased?: number;
	public totalSpent?: number;
	public discountCode?: string;
	public efairCode?: string;
	public readingLevelType?: ReadingLevelType;
}

interface BookClubWithExtras extends BookClub {
	school?: School;
	user?: User;
	numOrders?: number; // only populated on BookClubArchiveWithExtras
}

class BookList {
	public _id?: string;
	public name?: string;
	public description?: string;
	public grade?: string;
	public type?: string;
	public book_ids?: Array<string>;
}

class Category {
	public _id?: string;
	public name?: string;
	public bookLists?: Array<BookList>;
	public description?: string;
	public hidden?: boolean;
}

interface BookClubStats {
	numOrders: number;
	numBooks: number;
	subtotal: number;
	shipping: number;
	tax: number;
	total: number;
	conversionRate?: string;
	numberOfStudents?: number;
	trendingTopic?: string;
	popularBookTitle?: string;
	popularBookId?: string;
}

@Injectable()
class BookClubService extends BasicCrudService<BookClub> {
	private bookClub: BookClub;
	public onBookListUpdate: Subject<BookList> = new Subject();
	public onBookClubLoaded: Subject<BookClub> = new Subject();
	public onDoneLoading: Subject<boolean> = new Subject();
	public onDatesUpdated: Subject<BookClub> = new Subject();
	public closePreview: Subject<BookClub> = new Subject();

	constructor (http: HttpClient, private bookService: BookService, private bookAvailabilityService: BookAvailabilityService, private bookDiscountService: BookDiscountService, private schoolService: SchoolService) {
		super(http);
		this.setUrl('/server/api/bookclubs');
	}

	public getCachedBookClub () : BookClub {
		return this.bookClub;
	}

	public update (payload: BookClub) : Observable<BookClub> {
		return super.update(payload).pipe(map((bookClub: BookClub) => {
			if (this.bookClub && bookClub._id === this.bookClub._id) {
				this.bookClub = bookClub;
			}
			return bookClub;
		}));
	}

	/** @deprecated use cancelBookClub instead */
	public delete (id: string) : Observable<any> {
		return super.delete(id).pipe(map(() => {
			this.reset();
		}));
	}

	public cancelBookClub (id: string, canceledByFollett: boolean) : Observable<void> {
		return this.http.delete(`${this.url}/${encodeURIComponent(id)}?byFollett=${canceledByFollett}`).pipe(map(() => {
			if (this.bookClub && this.bookClub._id === id) {
				this.reset();
			}
		}));
	}

	public populateBookClub (user_id?: string, efairCode?: string) : Observable<BookClub> {
		if (this.bookClub) {
			this.onBookClubLoaded.next(this.bookClub);
			return of(this.bookClub);
		} else {
			const currentFairId: string = localStorage.getItem(CURRENT_EFAIR);
			const query: any = {};
			if (efairCode) {
				query.efairCode = efairCode;
			} else if (currentFairId) {
				query._id = currentFairId;
			} else if (user_id) {
				query.user_id = user_id;
			}
			if (Object.keys(query).length === 0) {
				return of(undefined);
			} else {
				return this.search(query).pipe(flatMap((bookClubs: Array<BookClub>) => {
					if (bookClubs && bookClubs.length > 0) {
						this.bookClub = bookClubs[0];
					}
					if (this.bookClub) {
						this.bookClub.categories = this.bookClub.categories.filter((category: Category) => !category.hidden);
						localStorage.setItem(CURRENT_EFAIR, this.bookClub._id);
						return this.getBooks().pipe(map(() => {
							this.onBookClubLoaded.next(this.bookClub);
							return this.bookClub;
						}));
					} else {
						localStorage.removeItem(CURRENT_EFAIR);
						this.onBookClubLoaded.next(this.bookClub);
						return of(this.bookClub);
					}
				}));
			}
		}
	}

	public isBookClubLoaded () : boolean {
		return !!this.bookClub;
	}

	public daysRemaining (bookClub: BookClub) : number {
		if (bookClub && bookClub.endDate) {
			const endDate: moment.Moment = moment(bookClub.endDate);
			const now: moment.Moment = moment(Date.now());
			return endDate.diff(now, 'days');
		} else {
			return 0;
		}
	}

	public createBookClub (user_id: string, startDate: Date, endDate: Date) : Observable<BookClub> {
		return this.create({user_id: user_id, startDate: startDate, endDate: endDate, timezone: DateHelper.getTimezone()}).pipe(flatMap((bookClub: BookClub) => {
			this.bookClub = bookClub;

			const bookSub: Observable<boolean> = this.getBooks();
			const schoolSub: Observable<School> = this.schoolService.cacheSchool(this.bookClub.school_id);

			return forkJoin([bookSub, schoolSub]).pipe(map(() => {
				this.onBookClubLoaded.next(this.bookClub);
				return this.bookClub;
			}));
		}));
	}

	public reset () : void {
		this.bookClub = null;
		localStorage.removeItem(CURRENT_EFAIR);
	}

	public removeBookFromList (bookList_id: string, book_id: string) : Observable<BookClub> {
		// for now, just use the cached book club
		return this.http.delete(`${this.url}/${encodeURIComponent(this.bookClub._id)}/bookLists/${encodeURIComponent(bookList_id)}/books/${encodeURIComponent(book_id)}`).pipe(map((bookClub: BookClub) => {
			this.bookClub = bookClub;
			let bookList: BookList;
			this.bookClub.categories.map((category: Category) => {
				return category.bookLists.map((categoryBookList: BookList) => {
					if (categoryBookList._id === bookList_id) {
						bookList = categoryBookList;
					}
				});
			});
			this.onBookListUpdate.next(bookList);
			return this.bookClub;
		}));
	}

	public getBooks () : Observable<boolean> {
		const booksCalls: Array<Observable<any>> = [];
		booksCalls.push(this.http.get(`${this.url}/${encodeURIComponent(this.bookClub._id)}/books/1`));
		booksCalls.push(this.http.get(`${this.url}/${encodeURIComponent(this.bookClub._id)}/books/2`));

		this.bookAvailabilityService.setFulfillmentCenter(this.bookClub.fulfillmentCenter || FulfillmentCenter.MC);
		return forkJoin(booksCalls).pipe(mergeMap((books: Array<Array<Book>>) => {
			return this.bookDiscountService.cacheBookDiscounts().pipe(mergeMap(() => {
				return this.bookService.cacheBooks((books[0] || []).concat(books[1] || []));
			}));
		}));
	}

	public getStats (bookclub_id: string) : Observable<BookClubStats> {
		return this.http.get<BookClubStats>(`${this.url}/${encodeURIComponent(bookclub_id)}/stats`);
	}

	public sendBookClubEmail (bookclub_id: string, emailType: EmailType) : Observable<any> {
		return this.http.post(`${this.url}/${encodeURIComponent(bookclub_id)}/email/${encodeURIComponent(emailType)}`, {});
	}

	public resetReminders (bookclub_id: string) : Observable<any> {
		return this.http.post(`${this.url}/${encodeURIComponent(bookclub_id)}/reminders/reset`, {}).pipe(map((bookClub: BookClub) => {
			if (bookClub._id === this.bookClub._id) {
				this.bookClub = bookClub;
			}
			return bookClub;
		}));
	}

	public adminSearch (searchTerm?: any) : Observable<Array<BookClub>> {
		return this.http.get<Array<BookClub>>(`${this.url}/adminSearch${ searchTerm ? '?searchTerm=' + encodeURIComponent(searchTerm) : '' }`);
	}

	public getCustomFlyer (bookclub_id: string, language: string) : Observable<any> {
		const headers: HttpHeaders = new HttpHeaders();
		headers.set('Accept', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
		return this.http.get(`${this.url}/${encodeURIComponent(bookclub_id)}/customFlyer/${language}`, { headers: headers, responseType: 'blob' }).pipe(
			map((data: any) => {
			const blob: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
			saveAs(blob, 'Family Letter.docx');
		}));
	}

	public getCommunitySponsorshipLetter (bookclub_id: string) : Observable<any> {
		const headers: HttpHeaders = new HttpHeaders();
		headers.set('Accept', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
		return this.http.get(`${this.url}/${encodeURIComponent(bookclub_id)}/communitySponsorshipLetter`, { headers: headers, responseType: 'blob' }).pipe(
			map((data: any) => {
			const blob: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
			saveAs(blob, 'Community Sponsorship Letter.dotx');
		}));
	}

	public updateSettings (id: string, settings: BookClubSettings) : Observable<BookClub> {
		return this.http.patch<BookClub>(this.url + '/' + encodeURIComponent(id) + '/settings', settings).pipe(map((updatedBookClub: BookClub) => {
			if (this.bookClub && updatedBookClub._id === this.bookClub._id) {
				this.bookClub = updatedBookClub;
			}
			return updatedBookClub;
		}));
	}

	public populateCustomCuration (id: string) : Observable<BookClub> {
		return this.http.get<BookClub>(this.url + '/' + encodeURIComponent(id) + '/populateCustomCuration').pipe(mergeMap((updatedBookClub: BookClub) => {
			if (this.bookClub && updatedBookClub._id === this.bookClub._id) {
				this.bookClub = updatedBookClub;
				return this.getBooks().pipe(map(() => {
					this.onBookClubLoaded.next(this.bookClub);
					return this.bookClub;
				}));
			} else {
				return of(updatedBookClub);
			}
		}));
	}

	public generateOrdersReport (bookClub_id: string, schoolName: string) : Observable<any> {
		const headers: HttpHeaders = new HttpHeaders();
		headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		return this.http.get(`${this.url}/report/${bookClub_id}`, { headers: headers, responseType: 'blob' }).pipe(
			map((data: any) => {
				const blob: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(blob, `Orders for ${schoolName} - ${moment().format('MM-DD-YYYY hh.mm A')}.xlsx`);
			}));
	}

	public getReadingLevelType () : string {
		return this.bookClub.readingLevelType;
	}

}

export { BookClubService, BookClub, BookClubWithExtras, BookList, Category, BookClubStats, BookClubSettings, Goal };
