import { NEXTBEE_REWARD_PERCENTAGE, NEXTBEE_SHIP_TO_HOME_REWARD_PERCENTAGE, TW_REWARD_PERCENTAGE, TW_SHIP_TO_HOME_REWARD_PERCENTAGE } from '../../constants/rewards.amounts';
import { RewardsType } from '../../constants/user';

class RewardsHelper {
	public static getRewardsPercentage (rewardsType: RewardsType, shipToHome: boolean) : number {
		let result: number;
		if (rewardsType === RewardsType.TITLEWAVE) {
			result = shipToHome ? TW_SHIP_TO_HOME_REWARD_PERCENTAGE : TW_REWARD_PERCENTAGE;
		} else {
			result = shipToHome ? NEXTBEE_SHIP_TO_HOME_REWARD_PERCENTAGE : NEXTBEE_REWARD_PERCENTAGE;
		}
		return result;
	}
}

export { RewardsHelper };
