import { BookClub } from '../apis/book.club/book.club.service';
import { Book } from '../apis/book/book.service';
import moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
class NotYetPublishedHelper {

	constructor () {}

	public static isNypBook (book: Book, bookClub?: BookClub) : boolean {
		let isNyp: boolean = !!(book && book.isNYP && book.nypDate);
		if (isNyp) {
				const nypDate: moment.Moment = moment(book.nypDate);
				const endOfDay: moment.Moment = moment().endOf('day');
				const releasedAfterToday: boolean  = nypDate.isAfter(endOfDay);
				if (bookClub) {
					const isShipToHome: boolean = bookClub && bookClub.settings && bookClub.settings.shipToHome;
					const releasedAfterEndEfair: boolean = bookClub && bookClub.endDate && nypDate.isAfter(moment(bookClub.endDate));
					isNyp = (isShipToHome && releasedAfterToday) || releasedAfterEndEfair;
				} else {
					isNyp = releasedAfterToday;
				}
		}
		return isNyp;
	}
}
export { NotYetPublishedHelper };
