import { Component } from '@angular/core';
import { TitleDetailsComponent } from '../title.details';
import { RecommendedListService } from '../../../services/apis/recommended.list/recommended.list.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { UserService } from '../../../services/apis/user/user.service';
import { BookClubService } from '../../../services/apis/book.club/book.club.service';
import { BookService } from '../../../services/apis/book/book.service';
import { BookDiscountService } from '../../../services/apis/book.discount/book.discount.service';
import { ReadingLevelTypeDisplayable } from '../../../constants/reading.level.types';
import { BookReviewService } from '../../../services/apis/book.review/book.review.service';

@Component({
	selector: 'title-details-lg',
	templateUrl: './title.details.lg.html',
	styleUrls: ['../title.details.css', './title.details.lg.css']
})

class TitleDetailsLargeComponent extends TitleDetailsComponent {

	public expandedState: string = 'collapsed';
	public fullyExpanded: boolean = false;
	protected showExpandLinks: boolean = false;

	constructor (recommendedListService: RecommendedListService,
		analyticsService: AnalyticsService, userService: UserService, bookClubService: BookClubService,
		bookService: BookService, bookDiscountService: BookDiscountService, bookReviewService: BookReviewService) {

		super(recommendedListService, analyticsService, userService, bookClubService, bookService, ReadingLevelTypeDisplayable, bookDiscountService, bookReviewService);
	}

}

export { TitleDetailsLargeComponent };
