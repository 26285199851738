import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class Address {
	public city: string;
	public state: string;
	public zip: string;
	public street1: string;
	public street2?: string;
	public isValidated?: boolean;
}

class Staff {
	public firstName: string;
	public lastName: string;
}

class SchoolEfairDefaults {
	public defaultSchoolwideEfairCode?: string;
	public staff: Array<Staff>;
	public grade?: string;
	public numberOfStudents?: number;
}

class School {
	public _id?: string;
	public name: string;
	public address: Address;
	public party_id?: string;
	public staff?: Array<Staff>; // @TODO: Rip out after refactor
	public efairDefaults: SchoolEfairDefaults;
	public customerNumber?: string;

	[key: string]: string|Address|SchoolEfairDefaults|number
											 |Array<Staff>; // @TODO: Rip out after refactor
}

@Injectable()
class SchoolService extends BasicCrudService<School> {

	public onSchoolUpdated: Subject<School> = new Subject();
	private cachedSchool: School;

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/schools');
	}

	public getCachedSchool () : School {
		return this.cachedSchool;
	}

	public cacheSchool (id: string) : Observable<School> {
		return super.get(id).pipe(map((school: School) => {
			this.cachedSchool = school;
			return school;
		}));
	}

	public updateSchool (school: School) : Observable<School> {
		return this.update(school).pipe(map((updatedSchool: any) => {
			this.onSchoolUpdated.next();
			return updatedSchool;
		}));
	}

	public addStaff (school_id: string, names: Array<{ firstName: string, lastName: string }>) : Observable<School> {
		return this.http.post<School>(`${this.url}/${encodeURIComponent(school_id)}/staff`, { names: names });
	}

	public deleteStaff (school_id: string, firstName: string, lastName: string) : Observable<School> {
		return this.http.delete<School>(`${this.url}/${encodeURIComponent(school_id)}/staff`, { params: { firstName: firstName, lastName: lastName } });
	}

	public searchByZip (searchTerm?: string) : Observable<Array<string>> {
		return this.http.get<Array<string>>(this.url + '/zip', { params: { searchTerm: searchTerm } });
	}
}

export { SchoolService, School, Staff, Address, SchoolEfairDefaults };
