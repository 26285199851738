import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BookService, Book } from '../../services/apis/book/book.service';
import { BaseComponent } from '../../base.component';
import { Staff, SchoolService, School } from '../../services/apis/school/school.service';
import { BookClub, BookClubService } from '../../services/apis/book.club/book.club.service';
import { UserService } from '../../services/apis/user/user.service';
import { WindowRef } from '../../utils/window.ref';
import { ClipboardService } from 'ngx-clipboard';
import { EditWishlistService, WishlistItem, Wishlist } from '../../services/apis/wishlist/edit.wishlist.service';
import { ModalOptions } from 'ngx-bootstrap';
import { EmailWishlistPromptComponent } from './email.wishlist.prompt/email.wishlist.prompt';
import { EmailService } from '../../services/apis/email/email.service';
import { timer, Observable } from 'rxjs';
import { fade } from '../animations/fade';
import { Assets } from '../assets';
import { Displayables } from '../../utils/displayables';
import { map } from 'rxjs/operators';
import { TitlewaveService } from '../../services/apis/titlewave/titlewave.service';
import { Address } from '../../services/apis/school/school.service';
import { STATES_ABBREV } from '../../constants/states';
import { RewardsHelper } from '../../services/helpers/rewards.helper';
import { EfairType } from '../../constants/efairs';
import { ModalService } from '../../services/helpers/modal.service';

@Component({
	selector: 'share-wishlist',
	templateUrl: './share.wishlist.html',
	styleUrls: ['./share.wishlist.css'],
	animations: [fade]
})

class ShareWishlistComponent extends BaseComponent implements OnInit {
	public loading: boolean = false;
	public books: Array<Book>;
	public studentName: string;
	public studentNameDisplayable: string;
	public teacherWishlistNameDisplayable: string;
	public teacherName: Staff;
	public schoolwideStaff: Array<Staff>;
	public bookClub: BookClub;
	public wishlist: Wishlist;
	public schoolName: string;
	public schoolNickname: string;
	public schoolAddress: Address;
	public shareLink: string;
	public isClassroom: boolean;
	public canPrint: boolean;
	public canShare: boolean;
	public copied: boolean;
	public submitted: boolean;
	public emailing: boolean;
	public emailed: boolean;
	public printIcon: string;
	public emailIcon: string;
	public shareIcon: string;
	public isTeacherWishlist: boolean;
	public displayables: Displayables = Displayables;
	public datesDisplayable: string;
	public bookFairsLogoUrl: string;
	public headerBackgroundUrl: string;
	public total: number;
	public totalDisplayable: string;
	public tax: number;
	public subtotal: number;
	public subtotalDisplayable: string;
	public rewardPercentage: number;
	public STATES_ABBREV: typeof STATES_ABBREV = STATES_ABBREV;
	public shipToHome: boolean;
	public EfairType: typeof EfairType = EfairType;

	constructor (private analyticsService: AnalyticsService, public bookService: BookService, private editWishlistService: EditWishlistService, private bookClubService: BookClubService, private schoolService: SchoolService,
							 private userService: UserService, private windowRef: WindowRef, private clipboardService: ClipboardService, private modalService: ModalService,
							 private emailService: EmailService, private titlewaveService: TitlewaveService) {
		super();
	}

	public ngOnInit () : void {
		this.printIcon = Assets.WISHLIST_SHARE_ICONS.get('PRINT');
		this.emailIcon = Assets.WISHLIST_SHARE_ICONS.get('EMAIL');
		this.shareIcon = Assets.WISHLIST_SHARE_ICONS.get('SHARE');
		this.bookFairsLogoUrl = Assets.BOOK_FAIRS_LOGO;
		this.headerBackgroundUrl = Assets.PRINTED_WISHLIST_HEADER_BACKGROUND;
		this.canPrint = !!this.windowRef.getWindow().print;
		this.canShare = (<any>this.windowRef.getWindow().navigator).canShare && (<any>this.windowRef.getWindow().navigator).canShare();
		this.wishlist = this.editWishlistService.getCachedWishlist();
		this.isTeacherWishlist = this.wishlist && !!this.wishlist.teacherWishlistName;
		this.teacherWishlistNameDisplayable = this.isTeacherWishlist && Displayables.getStaffNameDisplayable(this.wishlist.teacherWishlistName);
		this.studentName = this.wishlist.studentName;
		this.shareLink = this.getShareLink();

		this.bookClub = this.bookClubService.getCachedBookClub();
		this.shipToHome = this.bookClub.settings && this.bookClub.settings.shipToHome;
		this.isClassroom = this.bookClub.type === EfairType.CLASSROOM;

		this.rewardPercentage = RewardsHelper.getRewardsPercentage(this.bookClub.settings.preferredRewardsType, this.bookClub.settings.shipToHome);

		const school: School = this.schoolService.getCachedSchool();
		this.schoolAddress = school.address;
		this.populateBooks(this.editWishlistService.getWishlistItems());

		this.cleanup.push(this.editWishlistService.onWishlistUpdate.subscribe(() => {
			this.populateBooks(this.editWishlistService.getWishlistItems());
		}));

		if (this.isClassroom) {
			const names: Array<string> = this.userService.getTeacher().teacherNickName.split(/\s/);
			const lastName: string = names.splice(names.length - 1, 1)[0].trim();
			const firstName: string = names.join(' ').trim();

			this.teacherName = { firstName: firstName, lastName: lastName };
		} else {
			this.populateSchoolwideStaff();
			this.schoolNickname = this.userService.getTeacher().schoolNickname || this.schoolName;
			this.teacherName = this.wishlist.teacherName;
		}

		this.datesDisplayable = Displayables.getDateRangeDisplayable(this.bookClub.startDate, this.bookClub.endDate, this.bookClub.timezone);
	}

	public populateBooks (items: Array<WishlistItem>) : void {
		this.books = [];
		this.subtotal = 0;
		for (const item of items) {
			const book: Book = this.bookService.getCachedBook(item.book_id);
			this.books.push(Object.assign({ quantity: item.quantity }, book));
			this.subtotal += parseFloat(book.price) * item.quantity;
		}
		this.subtotalDisplayable = this.subtotal.toFixed(2);
		this.subtotal = parseFloat(this.subtotalDisplayable);
		if (this.books.length > 0) {
			const address: Address = this.bookClub.type === EfairType.COMPANION ? this.bookClub.shipToSchool.address : this.schoolAddress;
			this.cleanup.push(this.titlewaveService.getSalesTax(address, this.subtotal).subscribe((salesTax: any) => {
				this.tax = Number(salesTax);
				this.totalDisplayable = (this.subtotal + this.tax).toFixed(2);
				this.total = parseFloat(this.totalDisplayable);
			}));
		}
	}

	public populateSchoolwideStaff () : void {
		this.schoolwideStaff = [];
		const bookClub: BookClub = this.bookClubService.getCachedBookClub();
		if (bookClub && bookClub.school_id) {
			const school: School = this.schoolService.getCachedSchool();
			this.schoolName = school.name;
			this.schoolwideStaff = school.efairDefaults && school.efairDefaults.staff ? school.efairDefaults.staff : [];
			this.schoolwideStaff.sort((staff1: Staff, staff2: Staff) => {
				const name1: string = `${staff1.lastName} ${staff1.firstName}`;
				const name2: string = `${staff2.lastName} ${staff2.firstName}`;
				return name1.localeCompare(name2, 'en', { sensitivity: 'base'});
			});
			if (this.schoolwideStaff.length === 0) {
				const names: Array<string> = this.userService.getTeacher().name.split(/\s/);
				const lastName: string = names.splice(names.length - 1, 1)[0].trim();
				const firstName: string = names.join(' ').trim();

				this.teacherName = { firstName: firstName, lastName: lastName };
			} else if (this.schoolwideStaff.length === 1) {
				this.teacherName = this.schoolwideStaff[0];
			}
		}
	}

	private saveWishlistData () : Observable<any> {
		const payload: any = {studentName: this.studentName, teacherName: this.teacherName, lastSharedDate: new Date()};
		return this.editWishlistService.update(payload, this.wishlist._id).pipe(map((updated: Wishlist) => {
			this.studentNameDisplayable = updated.studentName;
			this.teacherName = updated.teacherName;
			return updated;
		}));
	}

	public printWishlist () : void {
		this.analyticsService.sendEvent('Wishlist Print Button Clicked');

		this.cleanup.push(this.saveWishlistData().subscribe(() => {
			this.cleanup.push(timer(0).subscribe(() => {
				this.windowRef.getWindow().print();
			}));
		}));
	}

	public emailWishlist () : void {
		this.analyticsService.sendEvent('Wishlist Email Button Clicked');

		this.cleanup.push(this.saveWishlistData().subscribe(() => {
			const recipients: Array<any> = [];

			const config: ModalOptions = {
				animated: true,
				class: 'modal-md',
				ignoreBackdropClick: true,
				keyboard: false,
				initialState: {
					onContinue: (emailAddress: string) => {
						this.emailing = true;
						const data: string = JSON.stringify({
							studentName: this.wishlist.teacherWishlistName ? `${this.teacherWishlistNameDisplayable}` : this.studentNameDisplayable,
							teacherName: this.wishlist.teacherWishlistName ? `${this.teacherWishlistNameDisplayable}` : this.teacherName,
							bookClub: this.bookClub,
							schoolNickname: this.schoolNickname,
							books: this.books,
							efairUrl: this.getShareLink()
						});
						recipients.push({
							id: '_ID',
							email: emailAddress,
							data: JSON.parse(data)
						});
						this.cleanup.push(this.emailService.sendWishlist(`${this.wishlist.teacherWishlistName ? (this.teacherWishlistNameDisplayable) : this.studentNameDisplayable}'s Wish List`, recipients).subscribe(() => {
							this.emailing = false;
							this.emailed = true;
							this.cleanup.push(timer(2000).subscribe(() => {
								this.emailed = false;
							}));
						}, () => {
							this.emailing = false;
						}));
					}
				}
			};
			this.modalService.show(EmailWishlistPromptComponent, config);
		}));
	}

	public copyWebAddress () : void {
		this.analyticsService.sendEvent('Wishlist Copy Web Address Button Clicked');

		this.cleanup.push(this.saveWishlistData().subscribe());
		this.clipboardService.copyFromContent(this.shareLink);
		this.copied = true;
		this.cleanup.push(timer(2000).subscribe(() => {
			this.copied = false;
		}));
	}

	public shareWithApp () : void {
		this.analyticsService.sendEvent('Wishlist Share Using an App Button Clicked');

		this.cleanup.push(this.saveWishlistData().subscribe());
		(<any>this.windowRef.getWindow().navigator).share({
			title: 'Follett Book eFairs',
			text: 'View My Wish List',
			url: this.getShareLink()
		});
	}

	private getShareLink () : string {
		const origin: string = this.windowRef.getWindow().location.origin;
		return `${origin}/view/${this.wishlist.code}`;
	}

	public staffCompare (staff1: Staff, staff2: Staff) : boolean {
		return staff1 && staff2 ? (staff1.firstName === staff2.firstName && staff1.lastName === staff2.lastName) : staff1 === staff2;
	}

}

export { ShareWishlistComponent };
