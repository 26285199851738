import { Component } from '@angular/core';
import { BookService } from '../../../services/apis/book/book.service';
import { CartService } from '../../../services/apis/cart/cart.service';
import { TitleDetailsService } from '../../../services/helpers/title.details.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { AbstractCartReviewItemComponent } from '../abstract.cart.review.item/abstract.cart.review.item';
import { UserService } from '../../../services/apis/user/user.service';
import { ModalService } from '../../../services/helpers/modal.service';

@Component({
	selector: 'cart-review-item-sm',
	templateUrl: './cart.review.item.sm.html',
	styleUrls: ['./cart.review.item.sm.css']
})

class CartReviewItemSmComponent extends AbstractCartReviewItemComponent {
	constructor (bookService: BookService, titleDetailsService: TitleDetailsService, modalService: ModalService,
							 cartService: CartService, analyticsService: AnalyticsService, userService: UserService) {
		super(bookService, titleDetailsService, modalService, cartService, analyticsService, userService);
	}

	public updateQuantity (quantity: number) : void {
		if (quantity !== this.item.quantity) {
			this.updatingQuantity = true;
			this.item.quantity = quantity;
			this.analyticsService.sendEvent('Cart Review Updated Count', 'bookId: ' + this.item.book_id + ', title: ' + this.book.title +  ', quantity: ' + (this.item.quantity));
			this.cleanup.push(this.cartService.updateCartItem(this.item).subscribe(() => {
				this.updatingQuantity = false;
			}, () => {
				this.updatingQuantity = false;
			}));
		}
	}

}

export { CartReviewItemSmComponent };
