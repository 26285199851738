import { Component, ElementRef, ViewChild } from '@angular/core';
import { TitleDetailsComponent } from '../title.details';
import { RecommendedListService } from '../../../services/apis/recommended.list/recommended.list.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { UserService } from '../../../services/apis/user/user.service';
import { BookClubService } from '../../../services/apis/book.club/book.club.service';
import { BookService } from '../../../services/apis/book/book.service';
import { BookDiscountService } from '../../../services/apis/book.discount/book.discount.service';
import { ReadingLevelType_Abbreviated } from '../../../constants/reading.level.types';
import { BookReviewService } from '../../../services/apis/book.review/book.review.service';

@Component({
	selector: 'title-details-sm',
	templateUrl: './title.details.sm.html',
	styleUrls: ['../title.details.css', './title.details.sm.css']
})

class TitleDetailsSmallComponent extends TitleDetailsComponent {

	public isReviewsExpanded: boolean;
	public isAwardsExpanded: boolean;

	@ViewChild('reviewsElem', { static: false }) public reviewsElem: ElementRef;
	@ViewChild('awardsElem', { static: false }) public awardsElem: ElementRef;

	constructor (recommendedListService: RecommendedListService,
		analyticsService: AnalyticsService, userService: UserService, bookClubService: BookClubService,
		bookService: BookService, bookDiscountService: BookDiscountService, bookReviewService: BookReviewService) {

		super(recommendedListService, analyticsService, userService, bookClubService, bookService, ReadingLevelType_Abbreviated, bookDiscountService, bookReviewService);
	}

	public scrollIntoView (elemName: string) : void {
		if (elemName === 'reviews' && this.reviewsElem) {
			this.reviewsElem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		} else if (elemName === 'awards' && this.awardsElem) {
			this.awardsElem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	public expand (elemName: string) : void {
		if (elemName === 'reviews' && !this.isReviewsExpanded) {
			this.isReviewsExpanded = true;
		} else if (elemName === 'awards' && !this.isAwardsExpanded) {
			this.isAwardsExpanded = true;
		} else {
			this.scrollIntoView(elemName);
		}
	}
}

export { TitleDetailsSmallComponent };
