import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { RecommendedItem } from '../recommended.list/recommended.list.service';
import { BookService, Book } from '../book/book.service';
import { BookClubService } from '../book.club/book.club.service';
import { BookAvailabilityService } from '../book.availability/book.availability.service';
import { BookDiscountService } from '../book.discount/book.discount.service';
import { EfairType } from '../../../constants/efairs';
import { PROVIDER_PROFILE } from '../../../constants/local.storage';
import { RewardsType, UserRole } from '../../../constants/user';
import { ReadingLevelType } from '../../../constants/reading.level.types';

interface SalesforceLoginHistoryItem {
	providerId: string;
	loginTime: string;
	platform: string;
	ip: string;
	country: string;
	city: string;
	browser: string;
	status: string;
}

class EfairDefaults {
	public preferredEfairType?: EfairType;
	public grade?: string;
	public numberOfStudents?: number;
	public readingLevelType?: ReadingLevelType;
	public shouldSendParentEfairLaunchNotification?: boolean;
	public flyersEnabled?: boolean;
	public shouldSendShareToParentsEmail?: boolean;
	public shouldSendTeacherOrderNotification?: boolean;
	public preferredRewardsType?: RewardsType;
	public preferShipToHome?: boolean;
	public school_id?: string;
	public defaultClassroomEfairCode?: string;
}

interface SubUser {
	user_id?: string;
	name?: string;
	email?: string;
	grade?: string;
	nickname?: string;
}

class User {
	public _id?: string;
	public providerId?: string;
	public name?: string;
	public email?: string;
	public UserRole?: string;
	public efairDefaults?: EfairDefaults;

	// teacher fields
	public teacherNickName?: string;
	public recommendedItems?: Array<any>;
	public parentEmails?: Array<string>;
	public nextBeeMemberId?: string;
	public schoolNickname?: string;
	public salesforceContactId?: string;

	// admin fields
	public admin?: boolean;
	public created?: Date;
	public updated?: Date;
	public follettUser?: boolean;

	// @TODO: remove these fields
	public school_id?: string;
	public classCode?: string;
	public preferredEfairType?: EfairType;
	public grade?: string;
	public numberOfStudents?: number;
	public settings?: any;
	public schoolwideGrade?: string;
	public schoolwideNumberOfStudents?: number;
	public wishlistBookIds?: Array<string>;
	public cart_id?: string;
	public teacherUser_ids?: Array<string>;
	public selectedTeacherUser_id?: string;


	[key: string]: string|Array<string>|boolean|Date|EfairDefaults|number|Array<SubUser>|User;
}

@Injectable()

class UserService extends BasicCrudService<User> {
	private user: User;
	private teachers: Array<User> = [];

	public onUserLoad: Subject<void> = new Subject();
	public onUserUnLoad: Subject<void> = new Subject();
	public onUpdate: Subject<void> = new Subject();
	public onUserDeleted: Subject<void> = new Subject();

	constructor (http: HttpClient, private bookService: BookService, private bookClubService: BookClubService, private bookAvailabilityService: BookAvailabilityService, private bookDiscountService: BookDiscountService) {
		super(http);
		this.setUrl('/server/api/users');
	}

	public getOrCreate (newUser?: any) : Observable<User> {
		return this.http.post(`${this.url}/getOrCreate?providerId=${encodeURIComponent(this.getLoggedInProviderId())}`, newUser ? { newUser } : undefined).pipe(mergeMap((user: User) => {
			this.user = user;
			return this.populateUserData(this.user);
		}));
	}

	public createGuestUser () : void {
		this.user = { role: UserRole.GUEST, efairDefaults: {} };
	}

	private populateUserData (user: User) : Observable<User> {
		this.user = user;
		return this.bookClubService.populateBookClub().pipe(mergeMap(() => {
			return this.populateBooksFromUserLists(user).pipe(map(() => {
				this.onUserLoad.next();
				return user;
			}));
		}));
	}

	private populateBooksFromUserLists (user: User) : Observable<any> {
		let recSub: Observable<any> = of(true);
		let wishSub: Observable<any> = of(true);
		if (user.role === UserRole.GUARDIAN) {
			if (user.wishlistBookIds) {
				wishSub = this.cacheWishlistBookIds(user._id);
			}
		} else if (user.role === UserRole.TEACHER) {
			recSub = this.cacheRecommendedBooks(user._id);
		}

		return forkJoin([recSub, wishSub]);
	}

	public findAndCacheTeacher (user_id: string) : Observable<User> {
		return this.http.get(`${this.url}/forGuest/${user_id}`).pipe(map((teacher: User) => {
			this.teachers = [teacher];
			return teacher;
		}));
	}

	public cacheRecommendedBooks (user_id: string) : Observable<boolean> {
		return this.http.get(`${this.url}/${user_id}/recommendedItems/books`).pipe(mergeMap((books: Array<Book>) => {
			return this.bookService.cacheBooks(books);
		}));
	}

	private cacheWishlistBookIds (user_id: string) : Observable<boolean> {
		return this.http.get(`${this.url}/${user_id}/wishlist/books`).pipe(mergeMap((books: Array<Book>) => {
			return this.bookService.cacheBooks(books);
		}));
	}

	private getLoggedInProviderId () : string {
		let result: string = null;
		const e2e: any = localStorage.getItem('e2e');
		if (e2e !== null) {
			const e2eObj: any = JSON.parse(e2e);
			result = e2eObj.profile.providerId;
		} else {
			const profile: any = JSON.parse(localStorage.getItem(PROVIDER_PROFILE));
			if (profile) {
				result = profile.providerId;
			}
		}
		return result;
	}

	public getUser () : User {
		return this.user;
	}

	public toggleAdmin (user: User) : Observable<void> {
		return this.http.post(this.url + '/' + encodeURIComponent(user._id) + '/toggleAdmin', user).pipe(map(() => {
			this.onUpdate.next();
		}));
	}

	public updateUser (user: User) : Observable<User> {
		if (!user._id && this.user._id) {
			user._id = this.user._id;
		}
		return this.update(user).pipe(map((updatedUser: User) => {
			if (this.user._id === user._id) {
				this.user = updatedUser;
			}
			this.onUpdate.next();
			return updatedUser;
		}));
	}

	public updateUserEfairDefaults (id: string, efairDefaults: EfairDefaults) : Observable<User> {
		return this.http.patch<User>(this.url + '/' + encodeURIComponent(id) + '/efairDefaults', efairDefaults).pipe(map((updatedUser: User) => {
			if (this.user._id === id) {
				this.user = updatedUser;
			}
			this.onUpdate.next();
			return updatedUser;
		}));
	}

	public isTeacher () : boolean {
		return !!this.user && this.user.role === UserRole.TEACHER;
	}

	public isGuest () : boolean {
		return !!this.user && this.user.role === UserRole.GUEST;
	}

	public isAdmin () : boolean {
		return !!this.user && this.user.admin;
	}

	public getTeacher () : User {
		let result: User;
		if (this.user) {
			if (this.isTeacher()) {
				result = this.user;
			}
		}
		return result || this.teachers[0];
	}

	public getEfairDefaults () : EfairDefaults {
		return this.user ? this.user.efairDefaults : {};
	}

	public updateRecommendedList (recommendedItems: Array<RecommendedItem>) : void {
		this.user.recommendedItems = recommendedItems;
	}

	public unloadUser () : void {
		this.user = null;
		this.teachers = [];
		this.bookClubService.reset();
		this.bookService.reset();
		this.bookDiscountService.reset();
		this.bookAvailabilityService.reset();
		this.onUserUnLoad.next();
	}

	public getUserType () : string {
		return this.isAdmin() ? 'Admin' : (this.isGuest() ? 'Guest' : (this.isTeacher() ? 'Teacher' : 'Unknown'));
	}

	public loadAuthenticatedUser () : Observable<any> {
		if (!!this.getUser()) {
			return of(true);
		} else {
			return this.getOrCreate();
		}
	}

	public getLoginHistory () : Observable<any> {
		return this.http.get(`${this.url}/${this.user._id}/loginHistory`);
	}

	public get (_id: string) : Observable<User> {
		return super.get(_id).pipe(map((user: User) => {
			if (!this.user || this.user._id === user._id) {
				this.user = user;
				this.onUserLoad.next();
			}
			return user;
		}));
	}

	public clearParentEmails () : Observable<any> {
		return this.http.post(`${this.url}/clearParentEmails`, null);
	}


}

export { UserService, EfairDefaults, SubUser, User, SalesforceLoginHistoryItem };
