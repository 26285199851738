import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { CartService, CartItem } from '../../../services/apis/cart/cart.service';
import { BaseComponent } from '../../../base.component';
import { Book } from '../../../services/apis/book/book.service';
import { Displayables } from '../../../utils/displayables';
import { NgForm, Validators } from '@angular/forms';
import { RequiredNonWhiteSpaceValidator } from '../../../shared/required.non.whitespace.validator/required.non.whitespace.validator';
import { timer } from 'rxjs';
import { ViewWishlistService } from '../../../services/apis/wishlist/view.wishlist.service';
import { Staff } from '../../../services/apis/school/school.service';
import { BookClub, BookClubService } from '../../../services/apis/book.club/book.club.service';

const LAST_TEACHER_WISHLIST_FROM_NAME_KEY: string = 'lastTeacherWishlistFromName';

@Component({
	selector: 'add-to-cart-from-teacher-wishlist-modal',
	templateUrl: './add.to.cart.from.teacher.wishlist.modal.html',
	styleUrls: ['./add.to.cart.from.teacher.wishlist.modal.css']
})

class AddToCartFromTeacherWishlistModalComponent extends BaseComponent implements OnInit {
	@ViewChild('firstNameInput', { static: true }) public firstNameInput: ElementRef;
	@ViewChild('addToCartForm', {static: false}) public addToCartForm: NgForm;

	public book: Book;
	public cartItem: CartItem;

	public onCancel: Function;
	public onSuccess: Function;

	public firstName: string;
	public lastName: string;
	public teacherName: Staff;

	public bookClub: BookClub;
	public shipToHome: boolean;

	public addingToCart: boolean;
	public updatingCart: boolean;

	public readonly displayables: typeof Displayables = Displayables;

	constructor (private bsModalRef: BsModalRef, private cartService: CartService, private analyticsService: AnalyticsService,
		private viewWishlistService: ViewWishlistService, private bookClubService: BookClubService) {
		super();
	}

	public ngOnInit () : void {
		this.bookClub = this.bookClubService.getCachedBookClub();
		this.shipToHome = this.bookClub.settings && this.bookClub.settings.shipToHome;

		this.cleanup.push(timer(0).subscribe(() => {
			this.addToCartForm.controls['firstName'].setValidators([Validators.required, RequiredNonWhiteSpaceValidator]);
			this.addToCartForm.controls['lastName'].setValidators([Validators.required, RequiredNonWhiteSpaceValidator]);
			if (this.firstNameInput) {
				this.firstNameInput.nativeElement.focus();
			}
		}));

		if (this.cartItem) {
			this.teacherName = this.cartItem.recipient.teacherName;
			this.firstName = this.cartItem.recipient.firstName;
			this.lastName = this.cartItem.recipient.lastName;
		} else {
			this.teacherName = this.viewWishlistService.getCachedWishlist().teacherWishlistName;
			const lastUsed: { firstName: string, lastName: string} = JSON.parse(localStorage.getItem(LAST_TEACHER_WISHLIST_FROM_NAME_KEY));
			if (lastUsed) {
				this.firstName = lastUsed.firstName;
				this.lastName = lastUsed.lastName;
			}
		}
	}

	public clickCancel () : void {
		this.analyticsService.sendEvent('Add To Cart Cancel');
		this.bsModalRef.hide();
		if (this.onCancel) {
			this.onCancel();
		}
	}

	public clickAddToCart () : void {
		this.addingToCart = true;
		this.analyticsService.sendEvent('Add To Cart');

		this.cleanup.push(this.cartService.addToCart(this.book._id, this.firstName, this.lastName, this.teacherName, true, true).subscribe(() => {
			localStorage.setItem(LAST_TEACHER_WISHLIST_FROM_NAME_KEY, JSON.stringify({ firstName: this.firstName, lastName: this.lastName }));
			if (this.onSuccess) {
				this.onSuccess();
			}
			this.addingToCart = false;
			this.bsModalRef.hide();
		}, () => {
			this.addingToCart = false;
			this.bsModalRef.hide();
		}));
	}

	public updateCartItem () : void {
		this.cartItem.recipient = { firstName: this.firstName, lastName: this.lastName, teacherName: this.teacherName, fromTeacherWishlist: true };
		this.updatingCart = true;
		this.cleanup.push(this.cartService.updateCartItem(this.cartItem).subscribe(() => {
			this.updatingCart = false;
			localStorage.setItem(LAST_TEACHER_WISHLIST_FROM_NAME_KEY, JSON.stringify({ firstName: this.firstName, lastName: this.lastName }));
			if (this.onSuccess) {
				this.onSuccess();
			}
			this.bsModalRef.hide();
		}, () => {
			this.updatingCart = false;
			this.bsModalRef.hide();
		}));
	}
}

export { AddToCartFromTeacherWishlistModalComponent, LAST_TEACHER_WISHLIST_FROM_NAME_KEY };
