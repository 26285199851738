import { state, style, animate, trigger, transition, AnimationTriggerMetadata } from '@angular/animations';

const fade: AnimationTriggerMetadata = trigger('fade', [
	state('in', style({opacity: 0})),
	transition(':leave', [
		style({opacity: 1}),
		animate(`{{ duration }}ms ease-in-out`, style({'opacity': '0'}))
	], { params: { duration: 500 } } ),
	transition(':enter', [
		style({opacity: 0}),
		animate(`{{ duration }}ms ease-in-out`, style({'opacity': '1'}))
	], { params: { duration: 500 } } )
]);

const fadeAndHeight: AnimationTriggerMetadata = trigger('fadeAndHeight', [
	state('in', style({opacity: 0, height: '0'})),
	transition(':leave', [
		style({opacity: '1', height: '*', 'overflow-y': 'hidden'}),
		animate('250ms ease-in-out', style({'opacity': '0', height: '0'}))
	]),
	transition(':enter', [
		style({opacity: '0', height: '0', 'overflow-y': 'hidden'}),
		animate('250ms ease-in-out', style({'opacity': '1', height: '*'}))
	])
]);

const fadeAndHeightAndWidth: AnimationTriggerMetadata = trigger('fadeAndHeightAndWidth', [
	state('in', style({opacity: 0, height: '0', width: '0'})),
	transition(':leave', [
		style({opacity: '1', height: '*', width: '*', 'overflow-y': 'hidden'}),
		animate('250ms ease-in-out', style({'opacity': '0', height: '0', width: '0'}))
	]),
	transition(':enter', [
		style({opacity: '0', height: '0', width: '0', 'overflow-y': 'hidden'}),
		animate('250ms ease-in-out', style({'opacity': '1', height: '*', width: '*'}))
	])
]);

export { fade, fadeAndHeight, fadeAndHeightAndWidth };
