import { EventEmitter, Output, ViewChild, ElementRef, OnInit } from '@angular/core';
import { BookViewType } from '../../book/book';
import { Book } from '../../../services/apis/book/book.service';
import { BaseComponent } from '../../../base.component';
import { MobileService } from '../../../services/helpers/mobile.service';

abstract class AbstractBookCarouselComponent extends BaseComponent implements OnInit {
	@Output() public addToCart: EventEmitter<Book> = new EventEmitter<Book>();
	@Output() public addToWishlist: EventEmitter<Book> = new EventEmitter<Book>();
	@Output() public loaded: EventEmitter<string> = new EventEmitter<string>();
	@ViewChild('carousel', {static: false}) public carouselElement: ElementRef;

	public BookViewType: typeof BookViewType = BookViewType;
	public book_ids: Array<string>;
	public shouldShowCarousel: boolean;
	public isIos: boolean;

	constructor (protected mobileService: MobileService) {
		super();
	}

	public ngOnInit () : void {
		this.isIos = this.mobileService.isIos();
		this.fetchBooks();
	}

	protected abstract fetchBooks () : void;

}

export { AbstractBookCarouselComponent };
