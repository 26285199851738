import { Component, Input } from '@angular/core';
import { CartService, Cart } from '../../../services/apis/cart/cart.service';
import { BaseComponent } from '../../../base.component';
import { STAFF_DISCOUNT_PERCENTAGE } from '../../../constants/staff.discounts';

@Component({
	selector: 'discount-code-entry',
	templateUrl: './discount.code.entry.html',
	styleUrls: ['./discount.code.entry.css']
})

class DiscountCodeEntryComponent extends BaseComponent {
	@Input() public cart: Cart;
	public discountEntry: string;
	public invalidDiscountCode: boolean;
	public updatingDiscount: boolean;

	public firstBookShipping: number;
	public additionalBookShipping: number;
	public freeShippingThreshold: number;
	public readonly STAFF_DISCOUNT_PERCENTAGE: number = STAFF_DISCOUNT_PERCENTAGE;

	constructor (private cartService: CartService) {
		super();
	}

	public applyDiscount () : void {
		this.updatingDiscount = true;
		this.cleanup.push(this.cartService.applyDiscount(this.discountEntry.trim(), true).subscribe(() => {
			this.updatingDiscount = false;
			this.invalidDiscountCode = false;
			this.cart = this.cartService.getCachedCart();
			this.discountEntry = undefined;
		}, () => {
			this.updatingDiscount = false;
			this.invalidDiscountCode = true;
		}));
	}

	public removeDiscount () : void {
		this.updatingDiscount = true;
		this.cleanup.push(this.cartService.applyDiscount(this.cart.discountCode, false).subscribe(() => {
			this.updatingDiscount = false;
			this.cart = this.cartService.getCachedCart();
		}));
	}
}

export { DiscountCodeEntryComponent };
