enum EfairType {
	CLASSROOM = 'classroom',
	SCHOOLWIDE = 'schoolwide',
	COMPANION = 'companion'
}

const SPRING_CUTOFF_MONTH: number = 6;
const WINTER_HOLIDAY_MIN_DATE: string = '12-04';
const WINTER_HOLIDAY_MAX_DATE: string = '12-31';

export { EfairType, SPRING_CUTOFF_MONTH, WINTER_HOLIDAY_MIN_DATE, WINTER_HOLIDAY_MAX_DATE };
