import * as moment from 'moment-timezone';
import { GRADES } from '../constants/grades';
import { Staff } from '../services/apis/school/school.service';

class Displayables {

	public static getDate (date: Date) : string {
		if (!(date instanceof Date)) {
			throw new Error('Invalid Date');
		} else {
			return moment(date).format('MMMM Do YYYY');
		}
	}

	public static getShortDate (date: Date) : string {
		if (!(date instanceof Date)) {
			throw new Error('Invalid Date');
		} else {
			return moment(date).format('MMM Do YYYY');
		}
	}

	public static getShortestDate (date: Date) : string {
		if (!(date instanceof Date)) {
			throw new Error('Invalid Date');
		} else {
			return moment(date).format('MM/DD/YY');
		}
	}

	public static getDateAndTime (date: Date) : string {
		if (!(date instanceof Date)) {
			throw new Error('Invalid Date');
		} else {
			return moment(date).format('MM/DD/YY HH:mm:ss ZZ');
		}
	}

	public static getGrade (grade: string) : string {
		const result: any = GRADES.find((obj: any) => {
			return obj.value === grade;
		});
		return result ? result.displayable : 'unknown';
	}

	public static getIsbn (isbn: string) : string {
		return isbn || '';
	}

	public static getInfo (field: string) : string {
		return field || 'n/a';
	}

	public static removeHTMLFromString (input: string) : string {
		if (input) {
			while (input.indexOf('<') !== -1 && input.indexOf('>') !== -1) {
				const start: number = input.indexOf('<');
				const end: number = input.indexOf('>', start);
				input = input.substring(0, start) + input.substring(end + 1);
			}
		}
		return this.getInfo(input);
	}

	public static removeHTMLFromStringOrBlank (input: string) : string {
		if (input) {
			while (input.indexOf('<') !== -1 && input.indexOf('>') !== -1) {
				const start: number = input.indexOf('<');
				const end: number = input.indexOf('>', start);
				input = input.substring(0, start) + input.substring(end + 1);
			}
		} else {
			input = '';
		}
		return input;
	}

	public static upperCaseFirstLetter (inputValue: string) : string {
		if (!inputValue) {
			return inputValue;
		}
		return inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLocaleLowerCase();
	}

	public static getPriceDisplayable (price: number) : string {
		return `$${price}`;
	}

	public static getGradeDisplayable (grade: number) : string {
		if (grade < 1) {
			return 'K';
		} else {
			return `${Math.floor(grade)}`;
		}
	}

	public static getDifferenceInDays (date1: Date, date2: Date) : number {
		return moment(date2).diff(moment(date1), 'days') + 1;
	}

	public static getDateRangeDisplayable (date1: Date, date2: Date, timezone: string) : string {
		let dateDisplayable: string = Displayables.getDateInTimezone(date1, timezone, 'MMM D') + ' - ';
		const isSameMonth: boolean = new Date(date1).getMonth() === new Date(date2).getMonth();
		dateDisplayable = dateDisplayable + Displayables.getDateInTimezone(date2, timezone, isSameMonth ? 'D' : 'MMM D');
		return dateDisplayable;
	}

	public static getStaffNameDisplayable (staff: Staff, lastFirst?: boolean) : string {
		let result: string = staff.firstName || staff.lastName;
		if (staff.firstName && staff.lastName) {
			result = lastFirst ? `${staff.lastName.trim()}, ${staff.firstName.trim()}` : `${staff.firstName.trim()} ${staff.lastName.trim()}`;
		}
		return result.trim();
	}

	public static getStaffFromSingleStringName (name: string) : Staff {
		const result: Staff = new Staff();
		if (name.indexOf(',') > -1) {
			const names: Array<string> = name.split(',');
			result.lastName = names.splice(0, 1)[0].trim();
			result.firstName = names.join(' ').trim();
		} else if (name.search(/\s/) > -1) {
			const names: Array<string> = name.split(/\s/);
			result.lastName = names.splice(names.length - 1, 1)[0].trim();
			result.firstName = names.join(' ').trim();
		} else {
			result.firstName = name;
			result.lastName = '';
		}
		return result;
	}

	public static getDateInTimezone (date: Date, timezone: string, format: string) : string {
		return moment.tz(date, timezone).format(format);
	}

	public static trimString (value: string) : string {
		value = value.trim();
		while (value.indexOf('  ') > -1) {
			value = value.replace('  ', ' ');
		}
		return value;
	}
}

export { Displayables };
