
class Flyer {
	public isCustomFlyer?: boolean;
	public language?: string;
	public url: string;
	public label: string;
	public thumbUrl: string;
	public _id?: string;
	public description?: string;
}

interface Banner {
	background: string;
	isAnimated?: boolean;
	font?: string;
}

class Assets {
	public static BOOK_FAIRS_LOGO: string = `https://cdn.brandfolder.io/9PA1UQVX/as/qf077f-f22dqw-t0tj1/BF_Logo_Stacked-WHITE.png`;

	public static FOLLETT_BOOK_EFAIRS_LOGO: string = `assets/landing/Book-eFairs-Logo-White.png`;
	public static FOLLETT_BOOK_EFAIRS_LOGO_SM: string = `https://cdn.brandfolder.io/9PA1UQVX/as/q2s6y4-6dubrs-5cqeu8/eFair_Portal_Email_Graphic_-_Book_eFair_Logo_Footer.png`;
	public static FOLLETT_REWARDS_STORE_LOGO: string = `https://cdn.brandfolder.io/9PA1UQVX/as/xjpfn7k7g8wp5sr7qwgxkw/Follett-Rewards-rgb.svg`;
	public static TITLEWAVE_LOGO: string = `https://cdn.brandfolder.io/9PA1UQVX/as/fzhb53mf293pbtnfkw8936j4/Follett-Titlewave-rgb.svg`;

	public static TIPS_VIDEO_CLASSROOM: string = 'https://players.brightcove.net/1464976798001/rJf31dMfQ_default/index.html?videoId=6107549009001';
	public static TIPS_VIDEO_SCHOOLWIDE: string = 'https://players.brightcove.net/1464976798001/default_default/index.html?videoId=6129870760001';
	public static HOW_TO_SHOP_VIDEO: string = 'https://players.brightcove.net/1464976798001/default_default/index.html?videoId=6183300179001';

	public static WISHLIST_SHARE_ICONS: Map<string, string> = new Map<string, string>([
		['PRINT', 'https://cdn.brandfolder.io/9PA1UQVX/as/q5v4tt-cjeens-1p6xh5/Printer_Icon.png'],
		['EMAIL', 'https://cdn.brandfolder.io/9PA1UQVX/as/q5v4tt-cjeens-3p2pfv/Mail_Checkbox_Icon.png'],
		['SHARE', 'https://cdn.brandfolder.io/9PA1UQVX/as/q5v4tt-cjeens-eat9nb/Share_Icon.png']
	]);

	public static LOGO: Flyer = {
		url: `https://cdn.brandfolder.io/9PA1UQVX/as/qawd39-8xvomw-3v3a0x/School_eFair_Logos.zip`,
		label: 'Logo',
		thumbUrl: `https://cdn.brandfolder.io/9PA1UQVX/as/q47j9r-9517gw-46amyn/Book_eFairs_Logo_Color.png`
	};

	public static PRINTED_WISHLIST_HEADER_BACKGROUND: string = `https://cdn.brandfolder.io/9PA1UQVX/as/q86ine-6xrx3s-23dip3/Wishlist_Header_Banner-01.png`;

	public static STAFF_FAQ: string = ` https://www.follettcontent.com/customer-service/faqs/book-efairs`;
	public static EMAIL_SUPPORT_LOGGED_IN_USER: string = `https://www.follettcontent.com/customer-service/submit-ticket/book-efairs-support`;
	public static EMAIL_SUPPORT_GUEST: string = `https://www.follettcontent.com/customer-service/submit-ticket/book-efairs-support`;
	public static SUPPORT_PHONE_NUMBER: string = '888.511.5114';
	public static SALES_PHONE_NUMBER: string = '877.899.8550';

	public static BANNERS: Map<string, Map<string, Banner>> = new Map()
		.set('ES', new Map()
			.set('Fall', {
				background: `https://cdn.brandfolder.io/9PA1UQVX/at/6ctvtmrkg4v8hqtkpb672k/22-07-433425_Portal_Image-1200x480-01.png`,
				font: 'Orbitron'
			})
			.set('Spring', {
				background: `https://cdn.brandfolder.io/9PA1UQVX/as/qd7syo-9msnj4-bo7sr/Fall-Elem-Static.json`,
				isAnimated: true,
			 	font: 'Luckiest Guy'
			})
		)
		.set('MS', new Map()
			.set('Fall', {
				background: `https://cdn.brandfolder.io/9PA1UQVX/at/qsf8gf7sgfj3f3j77tr9hq9w/22-07-484319_MS_Portal_Banner-1200x480-01.png`,
				font: 'Luckiest Guy'
			})
			.set('Spring', {
				background: `https://cdn.brandfolder.io/9PA1UQVX/as/qbb8uz-916ze0-7b5ix0/Dismissible_Banner_-_MS.png`,
				font: 'Luckiest Guy'
			})
		);
}

export { Flyer, Banner, Assets };
