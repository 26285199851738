import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const LAST_URL: string = 'last_url';

@Injectable()
class SearchService {

	public searchExecuted: Subject<string> = new Subject();

}

export { SearchService, LAST_URL };
