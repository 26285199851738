import { formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { GoalType } from '../../constants/goal.types';
import { BookClub, BookClubService, Goal } from '../../services/apis/book.club/book.club.service';

@Component({
	selector: 'goal-progress-wrapper',
	templateUrl: './goal.progress.wrapper.html',
	styleUrls: ['./goal.progress.wrapper.css']
})

class GoalProgressWrapperComponent extends BaseComponent implements OnInit {

	@Input() public fromOrderSummary: boolean;
	@Input() public hasPendingProgress: boolean;

	public imageUrl: string;
	public headerText: string;
	public subheaderText: string;
	public mainText: string;

	constructor (private bookClubService: BookClubService) {
		super();
	}

	public ngOnInit () : void {
		if (this.fromOrderSummary) {
			const bookClub: BookClub = this.bookClubService.getCachedBookClub();
			const goal: Goal = bookClub.goal;
			const goalMet: boolean = goal.goalAmount <= Math.round((goal.goalType === GoalType.DOLLARS ? bookClub.totalSpent : bookClub.booksPurchased) || 0);
			if (goalMet) {
				this.imageUrl = `/assets/emoji/starry.png`;
				this.headerText = `Yay, We Made It!`;
				this.subheaderText = `Although we reached our goal...`;
				this.mainText = `Your support adds even greater value! The more, the merrier!`;
			} else {
				this.imageUrl = `/assets/emoji/cool.png`;
				this.headerText = `You're Awesome!`;
				this.subheaderText = `Because of your support...`;
				this.mainText = `We're now even closer to our goal of ${goal.goalType === GoalType.DOLLARS ? '$' : ''}${formatNumber(goal.goalAmount, 'en-us')} ${goal.goalType === GoalType.DOLLARS ? 'in sales' : 'books sold'}!`;
			}
		} else {
			this.imageUrl = `/assets/emoji/happy.png`;
			this.headerText = `You're Helping!`;
			this.subheaderText = `Every order counts toward our goal.`;
			this.mainText = `Thank you for supporting our school's goal with your purchase!`;
			if (this.hasPendingProgress) {
				this.mainText = this.mainText.concat(' Goal progress will be updated once your order finishes processing.');
			}
		}
	}

}

export { GoalProgressWrapperComponent };
