import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { BookClubService, BookClub } from '../../services/apis/book.club/book.club.service';
import { UserService } from '../../services/apis/user/user.service';
import { SchoolService, School } from '../../services/apis/school/school.service';
import { EfairType } from '../../constants/efairs';
import { Displayables } from '../../utils/displayables';
import { BookClubStatus } from '../../constants/book.club.status';

@Component({
	selector: 'days-remaining',
	templateUrl: './days.remaining.html',
	styleUrls: ['./days.remaining.css']
})

class DaysRemainingComponent extends BaseComponent implements OnInit {
	public created: boolean;
	public started: boolean;
	public daysRemaining: number;
	public displayName: string;
	public startDateDisplayable: string;
	@Input() public bookClub: BookClub;

	constructor (private bookClubService: BookClubService, private userService: UserService, private schoolService: SchoolService) {
		super();
	}

	public ngOnInit () : void {
		if (this.bookClub) {
			this.created = this.bookClub.status === BookClubStatus.CREATED;
			this.started = this.bookClub.status === BookClubStatus.STARTED;
			this.startDateDisplayable = Displayables.getDateInTimezone(this.bookClub.startDate, this.bookClub.timezone, 'M/D');
			this.daysRemaining = this.bookClubService.daysRemaining(this.bookClub);

			if (this.userService.isTeacher()) {
				this.displayName = 'Your';
			} else {
				if (this.bookClub.type === EfairType.SCHOOLWIDE || this.bookClub.type === EfairType.COMPANION) {
					const schoolNickname: string = this.userService.getTeacher().schoolNickname;
					if (schoolNickname) {
						this.displayName = `${this.userService.getTeacher().schoolNickname}'s`;
					} else {
						const school: School = this.schoolService.getCachedSchool();
						this.displayName = `${school.name}'s`;
					}
				} else {
					this.displayName = `${this.userService.getTeacher().teacherNickName}'s`;
				}
			}
		}
	}
}

export { DaysRemainingComponent };
