import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/apis/cart/cart.service';
import { BaseComponent } from '../../base.component';
import { timer } from 'rxjs';


@Component({
	selector: 'cart-is-ready-popup',
	templateUrl: './cart.is.ready.popup.html',
	styleUrls: ['./cart.is.ready.popup.css']
})

class CartIsReadyPopupComponent extends BaseComponent implements OnInit {

	constructor (private cartService: CartService) {
		super();
	}

	public ngOnInit () : void {
		this.cleanup.push(timer(4000).subscribe(() => {
			this.dismiss();
		}));
	}

	public dismiss () : void {
		this.cartService.showCartIsReadyPopup = false;
	}

}

export { CartIsReadyPopupComponent };
