import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

class Review {
	public name: string;
	public date: string;
	public text?: string;
}

class BookReview {
	public titlewave_id: string;
	public reviews: Array<Review>;
	public citations: Array<Review>;
	public lastChecked?: Date;
}

@Injectable()
class BookReviewService extends BasicCrudService<BookReview> {

	private bookReviews: Map<string, BookReview> = new Map();

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/bookReview');
	}

	public findByTitlewave_id (titlewave_id: string) : Observable<BookReview> {
		const existing: BookReview = this.bookReviews.get(titlewave_id);
		if (existing !== undefined) {
			return of(existing);
		} else {
			return super.get(titlewave_id).pipe(map((review: BookReview) => {
				this.bookReviews.set(titlewave_id, review);
				return review;
			}));
		}
	}

}

export { BookReviewService, BookReview, Review };
