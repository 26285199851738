import { Component, OnInit } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { BaseComponent } from '../../base.component';
import { WindowRef } from '../../utils/window.ref';

@Component({
	selector: 'app-out-of-date-modal',
	templateUrl: 'app.out.of.date.modal.html',
	styleUrls: ['app.out.of.date.modal.css']
})

class AppOutOfDateModalComponent extends BaseComponent implements OnInit {
	public timeLeft: number = 60;
	public timerSub: Subscription;
	constructor (private windowRef: WindowRef) {
		super();
	}
	public ngOnInit () : void {
		this.timerSub = timer(1000, 1000).subscribe(() => {
			this.timeLeft--;
			if (this.timeLeft <= 0) {
				this.timerSub.unsubscribe();
				this.reload();
			}
		});
		this.cleanup.push(this.timerSub);
	}

	public reload () : void {
		this.windowRef.getWindow().location.reload();
	}
}

export { AppOutOfDateModalComponent };
