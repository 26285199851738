import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { SystemMessageService } from '../../services/apis/system.message/system.message.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { fadeAndHeight } from '../animations/fade';

@Component({
	selector: 'system-message',
	templateUrl: './system.message.html',
	styleUrls: ['./system.message.css'],
	animations: [fadeAndHeight]
})

class SystemMessageComponent extends BaseComponent implements OnInit {
	public message: SafeHtml;

	constructor (public systemMessageService: SystemMessageService, private sanitizer: DomSanitizer) {
		super();
	}

	public ngOnInit () : void {
		this.cleanup.push(this.systemMessageService.onMessageUpdated.subscribe((message: string) => {
			this.message = this.sanitizer.bypassSecurityTrustHtml(message);
		}));
	}

	public onDismiss () : void {
		this.systemMessageService.dismissed = true;
	}

}

export { SystemMessageComponent };
