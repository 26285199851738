import { Component, Input, OnInit } from '@angular/core';
import { Displayables } from '../../utils/displayables';
import { BookClub, BookClubService } from '../../services/apis/book.club/book.club.service';

@Component({
	selector: 'preorder-ribbon',
	templateUrl: './preorder.ribbon.html',
	styleUrls: ['./preorder.ribbon.css']
})

class PreorderRibbonComponent implements OnInit {
	@Input() public releaseDate: Date;

	public readonly displayables: typeof Displayables = Displayables;
	public bookClubTimeZone: string = '';

	constructor (protected bookClubService: BookClubService) {
	}

	public ngOnInit () : void {
		const bookClub: BookClub = this.bookClubService.getCachedBookClub();
		this.bookClubTimeZone = bookClub ? bookClub.timezone : 'America/Chicago';
	}
}

export { PreorderRibbonComponent };
