import { Component, HostListener, Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { AnalyticsService } from '../../services/helpers/analytics.service';

@Component({
	selector: 'help-menu',
	templateUrl: './help.menu.html',
	styleUrls: ['./help.menu.css']
})

class HelpMenuComponent extends BaseComponent {

	@Input() public floating: boolean;
	@Input() public fromCopyrightFooter: boolean;

	public showDropdown: boolean;

	public constructor (private analyticsService: AnalyticsService) {
		super();
	}

	@HostListener('document:click', ['$event']) public clickedOutside (event: any) : void {
		const found: boolean = event.composedPath().find((item: any) => {
			return item.localName === 'help-menu';
		});
		if (!found) {
			this.showDropdown = false;
		}
	}

	public toggleHelpMenu () : void {
		this.showDropdown = !this.showDropdown;
		this.analyticsService.sendEvent('Help Menu Toggle', this.showDropdown ? 'Open' : 'Close');
	}

	public clickDropdownItem () : void {
		this.showDropdown = false;
	}
}

export { HelpMenuComponent };
