import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Book, BookService } from '../../services/apis/book/book.service';
import { timer } from 'rxjs';
import { UserService } from '../../services/apis/user/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BaseComponent } from '../../base.component';
import { WindowRef } from '../../utils/window.ref';
import { BookFilterService } from '../../services/helpers/book.filter.service';
import { BookViewType } from '../book/book';
import { SearchService, LAST_URL } from '../../services/helpers/search.service';
import { BookClubService, BookClub } from '../../services/apis/book.club/book.club.service';
import { BookDiscountService } from '../../services/apis/book.discount/book.discount.service';

const SORT_OPTIONS: any = {
	relevance: 'Relevance',
	titleAsc: 'Title: A to Z',
	titleDesc: 'Title: Z to A',
	authorAsc: 'Author: A to Z',
	authorDesc: 'Author: Z to A',
	priceAsc: 'Price: Low to High',
	priceDesc: 'Price: High to Low'
};

@Component({
	selector: 'book-search',
	templateUrl: './book.search.html',
	styleUrls: ['./book.search.css']
})

class BookSearchComponent extends BaseComponent implements OnInit {
	public books: Array<Book>;
	public filteredBooks: Array<Book>;
	public unavailableBooks: Array<Book>;
	public busy: boolean = false;
	public searchTerm: string;
	public SORT_OPTIONS: typeof SORT_OPTIONS = SORT_OPTIONS;
	public sortOption: string = SORT_OPTIONS.relevance;
	public isGuest: boolean = false;
	public isTeacher: boolean = false;
	public desc: boolean = true;
	public searchHasBeenInitiated: boolean = false;
	@ViewChild('top', {static: false}) public top: ElementRef;
	public BookViewType: typeof BookViewType = BookViewType;
	public bookClub: BookClub;
	public emptyMessage: string;

	constructor (private bookService: BookService, private userService: UserService, private route: ActivatedRoute,
							private analyticsService: AnalyticsService, private windowRef: WindowRef, private bookFilterService: BookFilterService, private searchService: SearchService,
							private bookClubService: BookClubService, private router: Router, private bookDiscountService: BookDiscountService) {
		super();
	}

	public ngOnInit () : void {
		this.bookClub = this.bookClubService.getCachedBookClub();
		this.cleanup.push(this.route.paramMap.subscribe((params: ParamMap) => {
			this.searchTerm = params.get('searchTerm');
			if (this.userService.isGuest()) {
				this.windowRef.getWindow().history.replaceState({}, 'Search Books', '/guest/search');
			} else {
				this.windowRef.getWindow().history.replaceState({}, 'Search Books', '/teacher/search');
			}
		}));
		this.cleanup.push(this.searchService.searchExecuted.subscribe((searchTerm: string) => {
			this.searchTerm = searchTerm;
			this.doSearch();
		}));
		this.isGuest = this.userService.isGuest();
		this.isTeacher = this.userService.isTeacher();
		if (this.searchTerm && this.searchTerm.length > 0) {
			this.doSearch();
		}

		this.cleanup.push(this.bookFilterService.onFilterChange.subscribe(() => {
			this.cleanup.push(timer(0).subscribe(() => this.filterBooks()));
		}));
	}

	public doSearch () : void {
		this.filteredBooks = [];
		this.unavailableBooks = [];
		this.books = [];
		this.searchHasBeenInitiated = true;
		this.busy = true;

		if (this.bookClub.settings && this.bookClub.settings.onlySearchCuration) {
			this.cleanup.push(timer(0).subscribe(() => {
				this.books = this.bookService.cachedSearch(this.searchTerm);
				this.analyticsService.sendEvent('Search', `term: ${this.searchTerm} results: ${this.books.length}`);
				if (this.books.length === 0) {
					this.setEmptyMessage();
				}
			}));
		} else {
			this.cleanup.push(this.bookService.textSearch(this.searchTerm, true).subscribe((books: Array<Book>) => {
				this.analyticsService.sendEvent('Search', `term: ${this.searchTerm} results: ${books.length}`);
				this.cleanup.push(this.bookService.cacheBooks(books).subscribe(() => {
					this.books = books;
					if (this.books.length === 0) {
						this.setEmptyMessage();
					}
				}));
				// filterbooks gets triggered which eventually sets busy = false, except when there are no results;
			}));
		}
	}

	public filterBooks () : void {
		this.busy = true;
		const filterResult: Array<Book> = [];
		const unavailabilityResult: Array<Book> = [];
		for (const book of this.books) {
			const bookWithAvailability: Book = this.bookService.getCachedBook(book._id);
			if (!this.bookFilterService.shouldShowBookByAvailabilityAndCoverUrl(bookWithAvailability)) {
				unavailabilityResult.push(book);
			} else if (this.bookFilterService.shouldShowBookByFilters(bookWithAvailability)) {
				filterResult.push(book);
			}
		}
		if (this.sortOption !== SORT_OPTIONS.relevance) {
			filterResult.sort((book1: Book, book2: Book) => {
				let sortResult: number;
				if (this.sortOption === SORT_OPTIONS.priceAsc || this.sortOption === SORT_OPTIONS.priceDesc) {
					const book1Price: number = this.bookDiscountService.getPrice(book1);
					const book2Price: number = this.bookDiscountService.getPrice(book2);
					sortResult = book2Price - book1Price;
				} else if (this.sortOption === SORT_OPTIONS.titleAsc || this.sortOption === SORT_OPTIONS.titleDesc) {
					const first: string = (book1.title || '').toLowerCase();
					const second: string = (book2.title || '').toLowerCase();
					sortResult = (second || '').localeCompare(first);
				} else if (this.sortOption === SORT_OPTIONS.authorAsc || this.sortOption === SORT_OPTIONS.authorDesc) {
					const first: string = (book1.author || '').toLowerCase();
					const second: string = (book2.author || '').toLowerCase();
					sortResult = (second || '').localeCompare(first);
				}
				return sortResult;
			});
		}
		if (!this.desc) {
			filterResult.reverse();
		}

		this.filteredBooks = filterResult;
		this.unavailableBooks = unavailabilityResult;
		this.top.nativeElement.getRootNode().getElementsByClassName('wrapper')[0].scrollTo(0, 0);
		this.cleanup.push(timer(0).subscribe(() => {
			this.setEmptyMessage();
		}));
	}

	public changeSortOption (sortOption: string, inputDesc: boolean) : void {
		this.analyticsService.sendEvent('Change Sort', sortOption);
		this.desc = inputDesc;
		this.sortOption = sortOption;
		this.filterBooks();
	}

	public navigateBack () : void {
		this.analyticsService.sendEvent('Search Back Button Clicked');
		const lastUrl: string = localStorage.getItem(LAST_URL);
		if (lastUrl) {
			localStorage.removeItem(LAST_URL);
			this.router.navigate([lastUrl]);
		} else {
			if (this.userService.isTeacher()) {
				this.router.navigate(['teacher/browse']);
			} else {
				this.router.navigate(['guest/home']);
			}
		}
	}

	public setEmptyMessage () : void {
		this.emptyMessage = '';
		if (this.searchHasBeenInitiated && this.filteredBooks.length === 0) {
			const numExcludedBooks: number = this.books.length - this.unavailableBooks.length;
			if (numExcludedBooks === 0) {
				this.emptyMessage = 'No results.';
			} else {
				this.emptyMessage = `No books meet your filters. ${ numExcludedBooks } ${ numExcludedBooks === 1 ? 'was' : 'were' } excluded.`;
			}
		}
		this.busy = false;
	}

}

export { BookSearchComponent, SORT_OPTIONS };
