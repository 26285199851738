import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { UserService, User } from '../user/user.service';
import { map, mergeMap } from 'rxjs/operators';
import { BookService, Book } from '../book/book.service';
import { ConfigUtils } from '../../../utils/config';

interface RecommendedItem {
	book_id?: string;
	note?: string;
	archived?: boolean;
	archivedDate?: Date;
}

@Injectable()
class RecommendedListService extends BasicCrudService<any> {
	public onListUpdate: Subject<Array<RecommendedItem>> = new Subject();

	constructor (http: HttpClient, private userService: UserService, private bookService: BookService) {
		super(http);
		const user: User = this.userService.getUser();
		if (user) {
			this.setUrl(`/server/api/users/${user._id}/recommendedItems`);
		}
		this.userService.onUserLoad.subscribe(() => {
			this.setUrl(`/server/api/users/${this.userService.getUser()._id}/recommendedItems`);
			this.onListUpdate.next(this.getRecommendedList());
		});
	}

	public isInMyRecommendedList (book_id: string) : boolean {
		return !!this.getRecommendedList().find((item: RecommendedItem) => {
			return item.book_id === book_id && !item.archived;
		});
	}

	public addToRecommendedList (book: Book) : Observable<Array<RecommendedItem>> {
		return this.create(book).pipe(map((user: User) => {
			this.userService.updateRecommendedList(user.recommendedItems);
			this.onListUpdate.next(this.getRecommendedList());
			return this.getRecommendedList();
		}));
	}

	public archiveBook (book_id: string) : Observable<Array<RecommendedItem>> {
		const recommendedItem: any = this.getRecommendedList().find((item: RecommendedItem) => {
			return item.book_id === book_id;
		});
		recommendedItem.archived = true;
		return this.updateRecommendedItem(recommendedItem.book_id, recommendedItem);
	}

	public unarchiveBook (book_id: string) : Observable<Array<RecommendedItem>> {
		const recommendedItem: any = this.getRecommendedList().find((item: RecommendedItem) => {
			return item.book_id === book_id;
		});
		recommendedItem.archived = false;
		return this.updateRecommendedItem(recommendedItem.book_id, recommendedItem);
	}

	public updateRecommendedItem (book_id: string, updateItem: RecommendedItem) : Observable<Array<RecommendedItem>> {
		return this.http.patch(this.url + '/' + encodeURIComponent(book_id), updateItem).pipe(map((user: User) => {
			this.userService.updateRecommendedList(user.recommendedItems);
			this.onListUpdate.next(this.getRecommendedList());
			return this.getRecommendedList();
		}));
	}

	public getRecommendedList () : Array<RecommendedItem> {
		const user: User = this.userService.getUser();
		if (user) {
			if (this.userService.isTeacher()) {
				return user.recommendedItems;
			} else {
				return this.userService.getTeacher().recommendedItems;
			}
		} else {
			return [];
		}
	}

	public getRecommendedItem (book_id: string) : RecommendedItem {
		let user: User = this.userService.getUser();
		let result: RecommendedItem = null;
		if (user) {
			if (this.userService.isGuest()) {
				user = this.userService.getTeacher();
			}
			if (user.recommendedItems) {
				result = user.recommendedItems.find((item: RecommendedItem) => {
					return item.book_id === book_id;
				});
			}
		}
		return result;
	}

	public cacheBooks () : Observable<boolean> {
		return this.http.get(`${this.url}/books`).pipe(mergeMap((books: Array<Book>) => {
			return this.bookService.cacheBooks(books);
		}));
	}

	public cacheBooksForGuest (teacherUserId: string) : Observable<boolean> {
		return this.http.get(`${ConfigUtils.getApiHost()}/server/api/users/${teacherUserId}/recommendedItems/books`).pipe(mergeMap((books: Array<Book>) => {
			return this.bookService.cacheBooks(books);
		}));
	}

}

export { RecommendedListService, RecommendedItem };
