import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../../../base.component';

@Component({
	selector: 'cart-unavailable-items-modal',
	templateUrl: './cart.unavailable.items.modal.html',
	styleUrls: ['./cart.unavailable.items.modal.css']
})
class CartUnavailableItemsModalComponent extends BaseComponent {
	public titlesRemoved: Array<string>;
	public titlesUpdated: Map<string, number>;

	constructor (public modal: BsModalRef) {
		super();
	}

	public hideModal () : void {
		this.modal.hide();
	}
}

export { CartUnavailableItemsModalComponent };
