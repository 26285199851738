import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from '../services/apis/user/user.service';
import { GuestGuardService } from '../guest/guest.guard.service';
import { DialogService } from '../services/helpers/dialog.service';
import { BookClubService } from '../services/apis/book.club/book.club.service';
import { AuthService } from '../services/apis/auth/auth.service';
import { CartService } from '../services/apis/cart/cart.service';
import { EditWishlistService } from '../services/apis/wishlist/edit.wishlist.service';
import { ViewWishlistService } from '../services/apis/wishlist/view.wishlist.service';
import { map } from 'rxjs/operators';
import { CategoryImageService } from '../services/apis/category.image/category.image.service';
import { SchoolService } from '../services/apis/school/school.service';

@Injectable()
class SharedGuardService extends GuestGuardService implements CanActivate {

	constructor (authService: AuthService, userService: UserService, bookClubService: BookClubService, dialogService: DialogService, cartService: CartService, editWishlistService: EditWishlistService, viewWishlistService: ViewWishlistService, categoryImageService: CategoryImageService, schoolService: SchoolService) {
		super(authService, userService, bookClubService, dialogService, cartService, editWishlistService, viewWishlistService, categoryImageService, schoolService);
	}

	public canActivate (route: ActivatedRouteSnapshot) : Observable<boolean> {
		if (this.authService.isAuthenticated()) {
			return this.userService.loadAuthenticatedUser().pipe(map(() => {
				return true;
			}));
		} else {
			return super.canActivate(route);
		}
	}
}

export { SharedGuardService };
