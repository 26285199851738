import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, ObservableInput, NEVER, throwError } from 'rxjs';
import { AppVersionService } from '../../services/helpers/app.version.service';
import { map, catchError } from 'rxjs/operators';
import { ErrorCode } from '../../constants/error.codes';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { ErrorHelper } from '../../utils/error.helper';

@Injectable()
class AppVersionInterceptor implements HttpInterceptor {
	constructor (private appVersionService: AppVersionService, private analyticsService: AnalyticsService) {}
	public intercept (request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
		if (this.appVersionService.version) {
			request = request.clone({
				setHeaders: {
					serverVersion: `${this.appVersionService.version}`
				}
			});
		}
		return next.handle(request).pipe(catchError((err: HttpErrorResponse) : ObservableInput<any> => {
			const rootError: any = ErrorHelper.getRootError(err);
			if (rootError.code === ErrorCode.AppOutOfDate) {
				this.analyticsService.sendEvent('App Out of Date');
				this.appVersionService.showAppOutOfDateModal();
				return NEVER;
			} else {
				return throwError(err);
			}
		})).pipe(map((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				const serverVersion: string = event.headers.get('serverVersion');
				if (serverVersion) {
					this.appVersionService.version = serverVersion;
				}
			}
			return event;
	}));
	}
}

export { AppVersionInterceptor };
