import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TitleDetailsService } from '../../../services/helpers/title.details.service';
import { titleDetailsSlide } from '../../../shared/animations/slide';
import { BaseComponent } from '../../../base.component';
import { timer } from 'rxjs';
import { WindowRef } from '../../../utils/window.ref';
import { CartItemRecipient } from '../../../services/apis/cart/cart.service';


enum SLIDE_STATE {
	CURRENT = 'current',
	NEXT = 'next',
	PREVIOUS = 'prev'
}

@Component({
	selector: 'title-details-carousel-lg',
	templateUrl: './title.details.carousel.lg.html',
	styleUrls: ['./title.details.carousel.lg.css'],
	animations: [titleDetailsSlide]
})

class TitleDetailsCarouselLargeComponent extends BaseComponent implements OnInit, OnDestroy {
	public carouselState: string = SLIDE_STATE.CURRENT;
	private containerWidth: number;
	public slideAmount: number;
	private acknowledgeNext: boolean;
	private acknowledgePrev: boolean;
	public showNextPrev: boolean;
	@Input() public defaultCartItemRecipient: CartItemRecipient;
	@Input() public fromTeacherWishlist: boolean;
	@Input() public fromWishlist: boolean;

	constructor (public modal: BsModalRef, private titleDetailsService: TitleDetailsService, private windowRef: WindowRef) {
		super();
		this.containerWidth = 500;
		this.acknowledgeNext = true;
		this.acknowledgePrev = true;
	}

	public ngOnInit () : void {
		this.showNextPrev = this.titleDetailsService.getListLength() > 1;
		this.calculateBookPositions();
	}

	public ngOnDestroy () : void {
		super.ngOnDestroy();
		if (this.showNextPrev) {
			this.titleDetailsService.updateCarousel();
		}
	}

	private calculateBookPositions () : void {
		if (this.windowRef.getWindow().innerWidth >= 1390) {
			this.containerWidth = 1130;
		} else if (this.windowRef.getWindow().innerWidth >= 992) {
			this.containerWidth = 832;
		} else {
			this.containerWidth = 500;
		}
	}

	public next () : void {
		this.slideAmount = -this.containerWidth;
		this.carouselState = SLIDE_STATE.NEXT;
	}

	public prev () : void {
		this.slideAmount = this.containerWidth;
		this.carouselState = SLIDE_STATE.PREVIOUS;
	}

	public getBook_id () : string {
		return this.titleDetailsService.getBook_id();
	}

	public getNextBook_id () : string {
		return this.titleDetailsService.getNextBook_id();
	}

	public getPrevBook_id () : string {
		return this.titleDetailsService.getPrevBook_id();
	}

	public onSlideDone (event: AnimationEvent) : void {
		if (event.toState === SLIDE_STATE.NEXT && this.acknowledgeNext) {
			this.acknowledgeNext = false;
			this.cleanup.push(timer(0).subscribe(() => {
				this.carouselState = SLIDE_STATE.CURRENT;
				this.titleDetailsService.next();
				this.calculateBookPositions();
				this.acknowledgeNext = true;
			}));
		} else if (event.toState === SLIDE_STATE.PREVIOUS && this.acknowledgePrev) {
			this.acknowledgePrev = false;
			this.cleanup.push(timer(0).subscribe(() => {
				this.carouselState = SLIDE_STATE.CURRENT;
				this.titleDetailsService.prev();
				this.calculateBookPositions();
				this.acknowledgePrev = true;
			}));
		}
	}

	public onResize () : void {
		this.calculateBookPositions();
	}

	public getPagePosition () : string {
		if (this.titleDetailsService.getListLength() > 1) {
			return `-${this.containerWidth}px`;
		} else {
			return `0`;
		}
	}

	public getPageContainerWidth () : string {
		const width: number = this.containerWidth;
		if (this.titleDetailsService.getListLength() > 1) {
			return `${width * 3}px`;
		} else {
			return `${width}px`;
		}
	}

	public getCarouselWidth () : string {
		return `${this.containerWidth}px`;
	}

}

export { TitleDetailsCarouselLargeComponent, SLIDE_STATE };
