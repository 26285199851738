import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

class JobSchedule {
	public _id?: string;
	public jobName: string;
	public nextRun: Date;
	public paused: boolean;
	public running: boolean;
	public lastRunStart: Date;
	public lastRunEnd: Date;
	public crontab: string;
	public lastFailedDate?: Date;
	public numFailuresSinceLastSuccess: number;
}

@Injectable()
class JobScheduleService extends BasicCrudService<JobSchedule> {
	constructor (http: HttpClient) {
		super(http);
		this.setUrl(`/server/api/jobSchedules`);
	}

	public runJob (jobName: string) : Observable<void> {
		return this.http.post<void>(`${this.url}/runJob`, { jobName: jobName });
	}

}

export { JobScheduleService, JobSchedule };
