import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { BaseComponent } from '../../../base.component';
import { UserService } from '../../../services/apis/user/user.service';
import { Address } from '../../../services/apis/school/school.service';
import { STATES_ABBREV } from '../../../constants/states';
import { timer } from 'rxjs';
import { AddressResponse, AddressValidationService } from '../../../services/apis/address.validation/address.validation.service';
import { NgControl } from '@angular/forms';
import { ErrorHelper } from '../../../utils/error.helper';
import { BookClub, BookClubService } from '../../../services/apis/book.club/book.club.service';
import { CART_RECENT_RECIPIENT_KEY } from '../../../constants/local.storage';
import { CartItemRecipient } from '../../../services/apis/cart/cart.service';

const USER_NAME: string = 'user_name';
const USER_EMAIL: string = 'user_email';
const USER_SHIPPING_ADDRESS: string = 'user_shipping_address';

@Component({
	selector: 'user-info-prompt',
	templateUrl: './user.info.prompt.html',
	styleUrls: ['./user.info.prompt.css']
})

class UserInfoPromptComponent extends BaseComponent implements OnInit, AfterViewInit {
	public email: string;
	public name: string;
	public onContinue: Function;
	public onCancel: Function;
	public shipToHome: boolean;
	public shippingAddress: Address;
	public correctedAddress: Address;
	public STATES_ABBREV: typeof STATES_ABBREV = STATES_ABBREV;
	public validatingAddress: boolean;
	public addressNotFound: boolean;
	public generalValidationError: boolean;
	public isPOBox: boolean;
	public loading: boolean;
	public addressValidated: boolean;
	public addressConfirmed: boolean;
	public useValidatedAddress: boolean;
	public bookClub: BookClub;
	public useInvoicePayment: boolean;
	public isTeacher: boolean;

	@ViewChild('addressInput', {static: false, read: NgControl }) public addressInput: NgControl;
	@ViewChild('addressInput2', {static: false, read: NgControl }) public addressInput2: NgControl;
	@ViewChild('cityInput', {static: false, read: NgControl }) public cityInput: NgControl;
	@ViewChild('stateInput', {static: false, read: NgControl }) public stateInput: NgControl;
	@ViewChild('zipInput', {static: false, read: NgControl }) public zipInput: NgControl;
	@ViewChild('nameInput', {static: false, read: ElementRef }) public nameInput: ElementRef;

	@ViewChild('continueButton', { static: false }) public continueButton: ElementRef;
	@ViewChild('declineButton', { static: false }) public declineButton: ElementRef;

	constructor (public modal: BsModalRef, private analyticsService: AnalyticsService, private userService: UserService, private addressValidationService: AddressValidationService, private bookClubService: BookClubService) {
		super();
	}

	public ngOnInit () : void {
		this.isTeacher = this.userService.isTeacher();
		this.bookClub = this.bookClubService.getCachedBookClub();
		if (this.bookClub.settings) {
			this.useInvoicePayment = this.bookClub.settings.useInvoicePayment;
		}
		this.loading = true;
		if (this.shipToHome) {
			const shipping: string = localStorage.getItem(USER_SHIPPING_ADDRESS);
			this.shippingAddress = shipping ? JSON.parse(shipping) : new Address();
		}

		if (this.useInvoicePayment) {
			const recentRecipient: CartItemRecipient = JSON.parse(localStorage.getItem(CART_RECENT_RECIPIENT_KEY));
			this.name = `${recentRecipient.firstName} ${recentRecipient.lastName}`;
		} else if (this.isTeacher) {
			this.name = localStorage.getItem(USER_NAME) || this.userService.getUser().name;
			this.email = localStorage.getItem(USER_EMAIL) || this.userService.getUser().email;
		} else {
			this.name = localStorage.getItem(USER_NAME) || '';
			this.email = localStorage.getItem(USER_EMAIL) || '';
		}
	}

	public ngAfterViewInit () : void {
		this.cleanup.push(timer(1).subscribe(() => {
			if (this.nameInput) {
				this.nameInput.nativeElement.focus();
			}
			this.loading = false;
		}));
	}

	public cancel () : void {
		this.analyticsService.sendEvent('User Info Prompt Canceled');
		this.modal.hide();
		if (this.onCancel) {
			this.onCancel();
		}
	}

	public storeInfo () : void {
		if (!this.shipToHome || (this.addressInput.pristine && this.addressInput2.pristine && this.cityInput.pristine && this.stateInput.pristine && this.zipInput.pristine)) {
			this.analyticsService.sendEvent('User Info Prompt Continue');
			if (this.name) {
				localStorage.setItem(USER_NAME, this.name.trim());
			}
			if (this.email) {
				localStorage.setItem(USER_EMAIL, this.email.trim());
			}
			if (this.shippingAddress) {
				localStorage.setItem(USER_SHIPPING_ADDRESS, JSON.stringify(this.shippingAddress));
			}
			this.modal.hide();
			if (this.onContinue) {
				this.onContinue();
			}
		} else {
			this.validateAddress();
		}
	}

	private validateAddress () : void {
		this.useValidatedAddress = true;
		this.shippingAddress.street1 = this.shippingAddress.street1.toUpperCase().trim();
		this.shippingAddress.street2 = this.shippingAddress.street2 ? this.shippingAddress.street2.toUpperCase().trim() : '';
		this.shippingAddress.city = this.shippingAddress.city.toUpperCase().trim();
		this.shippingAddress.zip = this.shippingAddress.zip.toUpperCase().trim();
		if (this.shippingAddress.street1.length > 0 && this.shippingAddress.city.length > 0 && this.shippingAddress.zip.length > 0) {
			this.isPOBox = false;
			this.generalValidationError = false;
			this.addressNotFound = false;
			this.validatingAddress = true;
			this.cleanup.push(this.addressValidationService.validateAddress(this.shippingAddress).subscribe((response: AddressResponse) => {
				this.correctedAddress = response.address;
				if (this.checkPoBox(response.address.street1) || this.checkPoBox(response.address.street2)) {
					this.isPOBox = true;
				} else if (this.addressesAreEqual(this.shippingAddress, this.correctedAddress)) {
					this.addressConfirmed = true;
					this.cleanup.push(timer(1000).subscribe(() => {
						this.confirmAddress();
					}));
				} else if (this.fieldsAreTooLong(response)) { // TODO: this is a band-aid fix until TW/MF removes the text limits on these fields
					this.attemptToFixFields(response);
				} else {
					this.addressValidated = true;
				}
				this.cleanup.push(timer(0).subscribe(() => {
					if (this.continueButton) {
						this.continueButton.nativeElement.focus();
					}
				}));
				this.validatingAddress = false;
			}, (err: any) => {
				this.cleanup.push(timer(0).subscribe(() => {
					if (this.declineButton) {
						this.declineButton.nativeElement.focus();
					}
				}));
				this.useValidatedAddress = false;
				const rootError: any = ErrorHelper.getRootError(err);
				if (rootError.message === 's:Client: Address Not Found - No Match.') {
					this.addressNotFound = true;
					this.addressValidated = true;
				} else if (this.checkPoBox(this.shippingAddress.street1)) {
					this.isPOBox = true;
				} else {
					this.generalValidationError = true;
					this.addressValidated = true;
				}
				this.validatingAddress = false;
			}));
		}
	}

	private checkPoBox (street: string) : boolean {
		return /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/igm.test(street);
	}

	public confirmAddress (useCorrectedAddress?: boolean) : void {
		for (const input of [this.addressInput, this.addressInput2, this.cityInput, this.stateInput, this.zipInput]) {
			input.control.markAsPristine();
		}
		this.addressConfirmed = true;
		this.cleanup.push(timer(1000).subscribe(() => {
			if (useCorrectedAddress) {
				this.shippingAddress = this.correctedAddress;
				this.shippingAddress.isValidated = true;
				this.analyticsService.sendEvent('User Info Prompt Use Validated Address');
			} else {
				this.analyticsService.sendEvent('User Info Prompt Use Unvalidated Address');
			}
			this.storeInfo();
		}));
	}

	public declineAddress () : void {
		this.addressValidated = false;
		this.ngAfterViewInit();
	}

	private addressesAreEqual (address1: Address, address2: Address) : boolean {
		return address1.street1.trim() === address2.street1.trim()
				&& ((!address1.street2 && !address2.street2) || address1.street2.trim() === address2.street2.trim())
				&& address1.city.trim() === address2.city.trim()
				&& address1.state.trim() === address2.state.trim()
				&& address1.zip.trim() === address2.zip.trim();
	}

	public resetPoBoxFlag () : void {
		this.isPOBox = false;
	}

	public fieldsAreTooLong (response: AddressResponse) : boolean {
		let result: boolean = false;
		result = result || response.address.street1.length > 24;
		result = result || response.address.street2 && response.address.street2.length > 20;
		return result;
	}

	public attemptToFixFields (response: AddressResponse) : void {
		const combinedStreetAddress: string = (response.address.street1 + ' ' + (response.address.street2 || '')).trim();
		if (combinedStreetAddress.length > 24) {
			const lastSpaceIndex: number = combinedStreetAddress.substring(0, 24).lastIndexOf(' ');
			const newStreet1: string = combinedStreetAddress.substring(0, lastSpaceIndex);
			const newStreet2: string = combinedStreetAddress.substring(lastSpaceIndex + 1);
			if (newStreet2.length > 20) {
				this.addressNotFound = true;
			} else {
				response.address.street1 = newStreet1;
				response.address.street2 = newStreet2;
			}
		}
		this.addressValidated = true;
	}

}

export { USER_EMAIL, USER_NAME, UserInfoPromptComponent, USER_SHIPPING_ADDRESS };
