import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
class NextbeeService extends BasicCrudService<any> {

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/nextbee');
	}

	public getIframeUrl () : Observable<string> {
		return this.http.get<string>(`${this.url}/iframeUrl`);
	}

}

export { NextbeeService };
