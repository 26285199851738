import { Injectable } from '@angular/core';
import { Assets } from '../../shared/assets';
import { WindowRef } from '../../utils/window.ref';
import { AnalyticsService } from './analytics.service';
import { UtilsService } from './utils.service';

@Injectable()
class LiveChatService {
	public config: any;
	private phone: HTMLAnchorElement;

	constructor (private windowRef: WindowRef, private analyticsService: AnalyticsService, private utilsService: UtilsService) {
		if (!this.utilsService.envIsProd()) {
			this.config = {
				src: 'https://c.la2-c1cs-ia2.salesforceliveagent.com/content/g/js/49.0/deployment.js',
				initUrl: 'https://d.la2-c1cs-ia2.salesforceliveagent.com/chat',
				initArg1: '5722J000000Xfzb',
				initArg2: '00D7A0000004ZuC',
				imageKey: '5732J000000XgLY',
				onlineButtonSrc: 'https://uat-follett.cs44.force.com/eFairsliveagent/resource/1527725988000/ChatOnlineButton',
				offlineButtonSrc: 'https://uat-follett.cs44.force.com/eFairsliveagent/resource/1527725988000/ChatOfflineButton'
			};
		} else {
			this.config = {
				src: 'https://c.la1-c2-ph2.salesforceliveagent.com/content/g/js/45.0/deployment.js',
				initUrl: 'https://d.la1-c2-ph2.salesforceliveagent.com/chat',
				initArg1: '5722J000000Xfzb',
				initArg2: '00D50000000aTkC',
				imageKey: '5732J000000XgLY',
				onlineButtonSrc: 'https://follett.secure.force.com/eFairsliveagent/resource/1527725988000/ChatOnlineButton',
				offlineButtonSrc: 'https://follett.secure.force.com/eFairsliveagent/resource/1527725988000/ChatOfflineButton'
			};
		}

		const window: any = this.windowRef.getWindow();
		LiveChatService.loadScript(window, this.config).then(() => {
			window.liveagent.init(this.config.initUrl, this.config.initArg1, this.config.initArg2);

			if (!window._laq) {
				window._laq = [];
			}

			window._laq.push(() => {
				window.liveagent.showWhenOnline(this.config.imageKey, window.document.getElementById('liveagent_button_online_' + this.config.imageKey));
				window.liveagent.showWhenOffline(this.config.imageKey, window.document.getElementById('liveagent_button_offline_' + this.config.imageKey));
			});
		});
	}

	private static loadScript (window: Window, config: any) : Promise<void> {
		return new Promise((resolve: Function) => {
			const node: any = window.document.createElement('script');
			node.src = config.src;
			node.type = 'text/javascript';
			node.async = false;
			node.charset = 'utf-8';
			node.onload = () => {
				resolve();
			};
			window.document.getElementsByTagName('head')[0].appendChild(node);
		});
	}

	public isOnline () : boolean {
		let result: boolean = false;
		const elements: HTMLCollectionOf<Element> = this.windowRef.getWindow().document.getElementsByClassName('livechat online');
		if (elements && elements.length) {
			const element: HTMLElement = elements[0] as HTMLElement;
			if (element.style.display !== 'none') {
				result = true;
			}
		}
		return result;
	}

	public startChat () : void {
		if (this.isOnline()) {
			(<any>this.windowRef.getWindow()).liveagent.startChat(this.config.imageKey);
		}
	}

	public contactSupport (analyticEvent?: string) : void {
		if (analyticEvent) {
			this.analyticsService.sendEvent(analyticEvent);
		}
		if (!this.phone) {
			this.phone = this.windowRef.getWindow().document.createElement('a');
			this.phone.href = `tel:${Assets.SUPPORT_PHONE_NUMBER}`;
		}
		this.phone.click();
	}

}

export { LiveChatService };
