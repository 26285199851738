enum ErrorCode {
	BadArgument = 'BadArgument',
	InternalError = 'InternalError',
	UnknownError = 'UnknownError',
	Unauthorized = 'Unauthorized',
	Forbidden = 'Forbidden',
	ItemsUnavailable = 'ItemsUnavailable',
	PaymentScenarioNotFound = 'PaymentScenarioNotFound',
	NextBeeError = 'NextBeeError',
	AppOutOfDate = 'AppOutOfDate',
	SchoolAddressPOBoxError = 'SchoolAddressPOBoxError',
	NotFound = 'NotFound',
	DuplicateInvoiceOrderForStudent = 'DuplicateInvoiceOrderForStudent',
	WishlistItemsOverPurchaseLimits = 'WishlistItemsOverPurchaseLimit',
	BookClubSettingChangeNotAllowed = 'BookClubSettingChangeNotAllowed',
	InvalidDiscountCode = 'InvalidDiscountCode',
	StaffNameTooLong = 'StaffNameTooLong',
	PreviewNotAvailable = 'PreviewNotAvailable'
}

export { ErrorCode };
