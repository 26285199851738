import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { timer } from 'rxjs';
import { Displayables } from '../../utils/displayables';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BookViewType } from '../../shared/book/book';
import { BookService, Book } from '../../services/apis/book/book.service';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { RecommendedListService, RecommendedItem } from '../../services/apis/recommended.list/recommended.list.service';
import { BaseComponent } from '../../base.component';
import { MobileService } from '../../services/helpers/mobile.service';

@Component({
	selector: 'book-list-archive-modal',
	templateUrl: './book.list.archive.modal.html',
	styleUrls: ['./book.list.archive.modal.css']
})

class BookListArchiveModalComponent extends BaseComponent implements OnInit, AfterViewInit {
	public archivedBooks: Array<RecommendedItem> = [];
	public loading: boolean = false;
	public filteredBooks: Array<any> = [];
	public searchTerm: string;
	public sortOption: string = 'Date';
	@ViewChild('searchInput', {static: false}) public searchInput: ElementRef;
	public BookViewType: typeof BookViewType = BookViewType;

	constructor (public modal: BsModalRef, private recommendedListService: RecommendedListService, private bookService: BookService, private analyticsService: AnalyticsService, private mobileService: MobileService) {
		super();
	}

	public ngOnInit () : void {
		const recommendedItems: Array<RecommendedItem> = this.recommendedListService.getRecommendedList();
		this.loadItems(recommendedItems);

		this.cleanup.push(this.recommendedListService.onListUpdate.subscribe((items: Array<RecommendedItem>) => {
			this.loadItems(items);
		}));
	}

	private loadItems (recommendedItems: Array<RecommendedItem>) : void {
		this.loading = true;
		this.cleanup.push(this.recommendedListService.cacheBooks().subscribe(() => {
			const loadingBooks: Array<RecommendedItem> = [];
			for (const item of recommendedItems) {
				if (item.archived) {
					loadingBooks.push(item);
				}
			}
			this.archivedBooks = loadingBooks;
			this.loading = false;
			this.filterBooks('');
		}));
	}

	public ngAfterViewInit () : void {
		this.cleanup.push(timer(0).subscribe(() => {
			if (this.searchInput) {
				this.searchInput.nativeElement.focus();
			}
		}));
	}

	public getDateDisplayable (date: Date) : string {
		return Displayables.getDate(new Date(date));
	}

	public filterBooks (searchTerm: string, fromSearchInputChange?: boolean) : void {
		let result: Array<RecommendedItem> = [];
		if (!searchTerm || searchTerm.length === 0) {
			result = this.archivedBooks ? this.archivedBooks.slice(0) || [] : [];
		} else {
			for (const archivedBook of this.archivedBooks) {
				const book: Book = this.bookService.getCachedBook(archivedBook.book_id);
				const titleAuthor: string = book.title + book.author;
				if (titleAuthor.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1) {
					result.push(archivedBook);
				}
			}
		}
		if (fromSearchInputChange) {
			this.analyticsService.sendEvent('Archive Search', '\'' + searchTerm + '\'  Results:  ' + result.length);
		}
		result.sort((archive1: any, archive2: any) => {
			let sortResult: number;
			if (this.sortOption === 'Date') {
				sortResult = new Date(archive2.archivedDate).getTime() - new Date(archive1.archivedDate).getTime();
			} else {
				const book1: Book = this.bookService.getCachedBook(archive1.book_id);
				const book2: Book = this.bookService.getCachedBook(archive2.book_id);
				const first: string = <string>book1[this.sortOption.toLowerCase()];
				const second: string = <string>book2[this.sortOption.toLowerCase()];
				sortResult = (first || '').localeCompare(second);
			}
			return sortResult;
		});
		if (this.mobileService.isMobile()) {
			this.searchInput.nativeElement.blur();
		} else {
			this.searchInput.nativeElement.focus();
		}
		this.filteredBooks = result;
	}

	public changeSortOption (sortOption: string) : void {
		this.analyticsService.sendEvent('Archive Sort', sortOption);
		this.sortOption = sortOption;
		this.filterBooks(this.searchTerm);
	}

}

export { BookListArchiveModalComponent };
