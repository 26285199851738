import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/apis/auth/auth.service';

@Injectable()
class AuthNotRequiredGuardService implements CanActivate {

	constructor (private authService: AuthService) {}

	public canActivate () : boolean {
		if (this.authService.isTeacherSession() && !this.authService.isAuthenticated()) {
			this.authService.clearTeacherLocalStorage();
		}
		return true;
	}
}

export { AuthNotRequiredGuardService };
