import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWishlistService } from '../../services/apis/wishlist/view.wishlist.service';
import { Wishlist } from '../../services/apis/wishlist/edit.wishlist.service';
import { DialogService } from '../../services/helpers/dialog.service';
import { AuthService } from '../../services/apis/auth/auth.service';

@Component({
	selector: 'view-redirect',
	template: ''
})

class ViewRedirectComponent extends BaseComponent implements OnInit {
	constructor (private router: Router, private route: ActivatedRoute, private viewWishlistService: ViewWishlistService, private authService: AuthService, private dialogService: DialogService) {
		super();
	}

	public ngOnInit () : void {
		this.cleanup.push(this.route.params.subscribe((params: any) => {
			const wishlistCode: string = params['code'];

			this.cleanup.push(this.viewWishlistService.findByCode(wishlistCode).subscribe((wishlist: Wishlist) => {
				this.router.navigate(['/guest/viewWishlist'], {queryParams: { wishlistId: wishlist._id}});
			}, () => {
				this.authService.logout();
				this.dialogService.alert('Oops! This wishlist is invalid', `Either the eFair has already ended or you've clicked on an invalid link.`);
			}));
		}));
	}

}

export { ViewRedirectComponent };
