import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
class TitlewaveService extends BasicCrudService<any> {
	constructor (http: HttpClient) {
		super(http);
		this.setUrl(`/server/api/titlewave`);
	}

	public getSchoolwideOffers (date: string, gradeRange: string) : Observable<any> {
		return this.http.get(`${this.url}/schoolwideOffers?date=${date}&gradeRange=${encodeURIComponent(gradeRange)}`);
	}

	public getSalesTax (address: any, subtotal: number) : Observable<any> {
		return this.http.get(`${this.url}/salestax?state=${encodeURIComponent(address.state)}&zip=${encodeURIComponent(address.zip)}&city=${encodeURIComponent(address.city)}&subtotal=${subtotal}`);
	}
}

export { TitlewaveService };
