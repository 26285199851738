import { Component, OnInit, OnChanges } from '@angular/core';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { EditWishlistService } from '../../../services/apis/wishlist/edit.wishlist.service';
import { fade } from '../../animations/fade';
import { ActionButtonsComponent } from '../action.buttons';

@Component({
	selector: 'wishlist-buttons',
	templateUrl: './wishlist.buttons.html',
	styleUrls: ['../action.buttons.css'],
	animations: [fade]
})

class WishlistButtonsComponent extends ActionButtonsComponent implements OnInit, OnChanges {

	constructor (analyticsService: AnalyticsService, private editWishlistService: EditWishlistService) {
		super(analyticsService);
	}

	public ngOnInit () : void {
		this.inList = this.editWishlistService.isInMyWishlist(this.book._id);
		this.cleanup.push(this.editWishlistService.onWishlistUpdate.subscribe(() => {
			this.inList = this.editWishlistService.isInMyWishlist(this.book._id);
		}));
	}

	public ngOnChanges () : void {
		this.inList = this.editWishlistService.isInMyWishlist(this.book._id);
	}

	public addToWishlist (event: Event) : void {
		this.stopProp(event);
		this.adding = true;
		this.analyticsService.sendEvent(`${this.quickButton ? 'Quick Button' : 'Title Details'} Add to Wish List`, this.book.title);
		this.cleanup.push(this.editWishlistService.addToWishlist(this.book._id).subscribe(() => {
			this.adding = false;
			this.showConfirmation = true;
		}));
	}
}

export { WishlistButtonsComponent };
