import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Log } from './app/services/helpers/log.service';
import { environment } from './environments/environment';

const isIe: boolean = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;

if (!isIe) {
	if (environment.production) {
		enableProdMode();
	}

	platformBrowserDynamic().bootstrapModule(AppModule)
		.catch((err: any) => Log.log(err));
}
