import { Input, OnInit } from '@angular/core';
import { Book, BookService } from '../../../services/apis/book/book.service';
import { CartItem, CartService } from '../../../services/apis/cart/cart.service';
import { ModalOptions } from 'ngx-bootstrap';
import { TitleDetailsService } from '../../../services/helpers/title.details.service';
import { TitleDetailsCarouselComponent } from '../../title.details.carousel/title.details.carousel';
import { Displayables } from '../../../utils/displayables';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { BaseComponent } from '../../../base.component';
import { AddToCartModalComponent } from '../../add.to.cart.modal/add.to.cart.modal';
import { UserService } from '../../../services/apis/user/user.service';
import { BookClub } from '../../../services/apis/book.club/book.club.service';
import { AddToCartFromTeacherWishlistModalComponent } from '../../../guest/view.wishlist/add.to.cart.from.teacher.wishlist.modal/add.to.cart.from.teacher.wishlist.modal';
import { BOOK_LANGUAGES, Language } from '../../../constants/book.languages';
import { ModalService } from '../../../services/helpers/modal.service';
import { NotYetPublishedHelper } from '../../../services/helpers/not.yet.published.helper';

abstract class AbstractCartReviewItemComponent extends BaseComponent implements OnInit {
	@Input() public item: CartItem;
	@Input() public bookClub: BookClub;
	@Input() public giftPurchasesEnabled: boolean;
	@Input() public isDonationEfair: boolean;
	@Input() public useInvoicePayment: boolean;

	public book: Book;
	public language: Language;
	public readonly displayables: typeof Displayables = Displayables;
	public updatingQuantity: boolean = false;
	public removing: boolean = false;
	public preventQuantityChange: boolean;
	public hideNypDate: boolean;

	constructor (private bookService: BookService, private titleDetailsService: TitleDetailsService, private modalService: ModalService,
							 protected cartService: CartService, protected analyticsService: AnalyticsService, protected userService: UserService) {
		super();
	}

	public ngOnInit () : void {
		this.book = this.bookService.getCachedBook(this.item.book_id);
		this.hideNypDate = !NotYetPublishedHelper.isNypBook(this.book, this.bookClub);
		this.language = BOOK_LANGUAGES.get(this.book.textLanguage);
		if (this.bookClub && this.bookClub.settings) {
			this.preventQuantityChange = this.bookClub.settings.preventQuantityChange;
		}
	}

	public openTitleDetails () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg title-details-modal'
		};
		this.titleDetailsService.setIndex(0);
		this.titleDetailsService.setBook_ids([this.book._id]);
		this.modalService.show(TitleDetailsCarouselComponent, config);
	}

	public clickRemoveFromCart () : void {
		this.removing = true;
		this.analyticsService.sendEvent('Cart Review Remove Item', 'bookId: ' + this.item.book_id + ', title: ' + this.book.title +  ', quantity: ' + this.item.quantity);
		this.cleanup.push(this.cartService.removeFromCart(this.cartService.getCartItem(this.item.book_id, this.item.recipient)).subscribe(() => {
			this.removing = false;
		}));
	}

	public openEditRecipientModal () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg add-to-cart',
			initialState: {
				book: this.book,
				cartItem: this.item
			}
		};
		if (this.item.recipient.fromTeacherWishlist) {
			this.modalService.show(AddToCartFromTeacherWishlistModalComponent, config);
		} else {
			this.modalService.show(AddToCartModalComponent, config);
		}
	}

	public updateGiftChoice (uiInput: any) : void {
		this.item.gift = uiInput.checked;
		this.cleanup.push(this.cartService.updateCartItem(this.item).subscribe());
	}
}

export { AbstractCartReviewItemComponent };
