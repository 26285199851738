interface SidebarElement {
	title: string;
	routerLink: string;
	icon: string;
	id: string;
	disabled?: boolean;
}

const AdminElements: Array<SidebarElement> = [
	{
		title: 'Reports',
		icon: '/assets/sidebar/file-chart.svg',
		routerLink: '/admin/reports',
		id: 'efMenuReports'
	},
	{
		title: 'Books',
		icon: '/assets/sidebar/book-open-page-variant.svg',
		routerLink: '/admin/books',
		id: 'efMenuBooks'
	},
	{
		title: 'Offers',
		icon: '/assets/sidebar/image-multiple.svg',
		routerLink: '/admin/offers',
		id: 'efMenuOffers'
	},
	{
		title: 'Hidden Categories',
		icon: '/assets/sidebar/format-list-bulleted-type.svg',
		routerLink: '/admin/hiddencategories',
		id: 'efMenuHiddenCategories'
	},
	{
		title: 'eFairs',
		icon: '/assets/sidebar/library.svg',
		routerLink: '/admin/efairs',
		id: 'efMenuEFairs'
	},
	{
		title: 'Schools',
		icon: '/assets/sidebar/school.svg',
		routerLink: '/admin/schools',
		id: 'efMenuSchools'
	},
	{
		title: 'Email',
		icon: '/assets/sidebar/email.svg',
		routerLink: '/admin/emails',
		id: 'efMenuEmail'
	},
	{
		title: 'Users',
		icon: '/assets/sidebar/account.svg',
		routerLink: '/admin/users',
		id: 'efMenuUsers'
	},
	{
		title: 'Orders',
		icon: '/assets/sidebar/currency-usd.svg',
		routerLink: '/admin/orders',
		id: 'efMenuOrders'
	},
	{
		title: 'Marketing Assets',
		icon: '/assets/sidebar/folder-star-multiple-outline.svg',
		routerLink: '/admin/marketingMaterials',
		id: 'efMenuMarketingAssets'
	},
	{
		title: 'Site Settings',
		icon: '/assets/sidebar/toggle-switch.svg',
		routerLink: '/admin/siteSettings',
		id: 'efMenuSiteSettings'
	},
	{
		title: 'Jobs',
		icon: '/assets/sidebar/jobs.svg',
		routerLink: '/admin/jobSchedule',
		id: 'efMenuJobSchedule'
	}, {
		title: 'SystemMessage',
		icon: '/assets/sidebar/alert-octagram-outline.svg',
		routerLink: '/admin/systemMessage',
		id: 'efMenuSysMessage'
	}, {
		title: 'Leave Admin Mode',
		icon: '/assets/sidebar/account-arrow-left.svg',
		routerLink: '/teacher/myEfairs',
		id: 'efMenuLeaveAdminMode'
	},
	{
		title: 'Sign Out',
		icon: '/assets/sidebar/logout-variant.svg',
		routerLink: '/logout',
		id: 'efMenuSignOut'
	}
];

const TeacherElements: Array<SidebarElement> = [
	{
		title: 'My eFairs',
		icon: '/assets/sidebar/my-efairs.svg',
		routerLink: '/teacher/myEfairs',
		id: 'efMenuMyEFairs'
	},
	// {
	// 	title: 'eFair Rewards',
	// 	icon: '/assets/sidebar/rewards.svg',
	// 	routerLink: '/teacher/rewards',
	// 	id: 'efMenuRewards',
	// 	disabled: true,
	// },
	{
		title: 'Settings',
		icon: '/assets/sidebar/gear.svg',
		routerLink: '/teacher/settings',
		id: 'efMenuSettings'
	},
	{
		title: 'Sign Out',
		icon: '/assets/sidebar/logout-flip.svg',
		routerLink: '/logout',
		id: 'efMenuSignOut'
	}
];

const TeacherAdminModeElement: SidebarElement = {
	title: 'Admin Mode',
	icon: '/assets/sidebar/admin.svg',
	routerLink: '/admin',
	id: 'efMenuAdminMode'
};

const GuestSWEElements: Array<SidebarElement> = [
	{
		title: 'Home',
		icon: '/assets/sidebar/home.svg',
		routerLink: '/guest/home',
		id: 'efMenuHome'
	},
	{
		title: 'View Wish Lists',
		icon: '/assets/sidebar/view-wish-list.svg',
		routerLink: '/guest/viewWishlist',
		id: 'efMenuViewWishList'
	},
	{
		title: 'Order Status',
		icon: '/assets/sidebar/order-status.svg',
		routerLink: '/guest/orders/status',
		id: 'efMenuOrderStatus'
	},
	{
		title: 'Exit',
		icon: '/assets/sidebar/logout-flip.svg',
		routerLink: null,
		id: 'efMenuExit'
	}
];

export {SidebarElement, AdminElements, TeacherElements, GuestSWEElements, TeacherAdminModeElement};
