import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { UserService } from '../../../services/apis/user/user.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../base.component';
import { timer } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { WindowRef } from '../../../utils/window.ref';
import { fade } from '../../animations/fade';
import { SearchService, LAST_URL } from '../../../services/helpers/search.service';

@Component({
	selector: 'top-search-small-modal',
	templateUrl: './top.search.small.modal.html',
	styleUrls: ['./top.search.small.modal.css'],
	animations: [fade]
})

class TopSearchSmallModalComponent extends BaseComponent implements AfterViewInit {
	@ViewChild('textInput', { static: false }) public textInput: ElementRef<HTMLInputElement>;
	public searchTerm: string;
	public showClear: boolean;

	constructor (private analyticsService: AnalyticsService, private userService: UserService, private router: Router, private modalRef: BsModalRef, private windowRef: WindowRef, private searchService: SearchService) {
		super();
	}

	public ngAfterViewInit () : void {
		this.cleanup.push(timer(0).subscribe(() => {
			if (this.textInput) {
				this.textInput.nativeElement.focus();
			}
		}));
	}

	public search () : void {
		const currentUrl: string = this.windowRef.getWindow().location.pathname;
		this.searchTerm = this.searchTerm ? this.searchTerm.trim() : null;
		if (this.searchTerm && this.searchTerm.length > 0) {
			this.modalRef.hide();
			this.cleanup.push(timer(200).subscribe(() => {
				if (currentUrl.endsWith('/search') ) {
					this.searchService.searchExecuted.next(this.searchTerm);
				} else {
					localStorage.setItem(LAST_URL, decodeURI(currentUrl));
					this.analyticsService.sendEvent(`Go To Search From Topbar`, this.searchTerm || '');
					this.router.navigate([ `/${this.userService.isTeacher() ? 'teacher' : 'guest'}/search`, { searchTerm: this.searchTerm || '' }]);
				}
			}));
		}
	}

	public clearSearchTerm () : void {
		this.searchTerm = '';
		this.toggleClear(false);
	}

	public toggleClear (isFocusing: boolean) : void {
		this.showClear = isFocusing && !!this.searchTerm && this.searchTerm.length > 0;
	}

	public preventBlur (event: Event) : void {
		event.preventDefault();
	}

}

export { TopSearchSmallModalComponent };
