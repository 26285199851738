import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/apis/auth/auth.service';
import { UserService, User } from '../services/apis/user/user.service';
import { SidebarToggleService } from '../services/helpers/sidebar.toggle.service';
import { BaseComponent } from '../base.component';
import { Router, NavigationEnd, Event } from '@angular/router';
import { MobileService } from '../services/helpers/mobile.service';
import { BookClubService, BookClub } from '../services/apis/book.club/book.club.service';
import { Assets } from '../shared/assets';
import { UtilsService } from '../services/helpers/utils.service';
import { FulfillmentCenter } from '../constants/fulfillment.centers';
import { Cart, CartService } from '../services/apis/cart/cart.service';
import { fade } from '../shared/animations/fade';
import { timer } from 'rxjs';

@Component({
	selector: 'topbar',
	templateUrl: './topbar.html',
	styleUrls: ['./topbar.css'],
	animations: [fade]
})

class TopbarComponent extends BaseComponent implements OnInit {
	@Input() public floating: boolean;
	public showShoppingCart: boolean;
	public showWishlist: boolean = false;
	public showSearch: boolean = false;
	public isTeacher: boolean;
	public isGuest: boolean;
	public user: User;
	public isAuthenticated: boolean;
	public isMobile: boolean;
	public isSidebarOpen: boolean = false;
	public FOLLETT_BOOK_EFAIRS_LOGO: string = Assets.FOLLETT_BOOK_EFAIRS_LOGO;
	public FOLLETT_BOOK_EFAIRS_LOGO_SM: string = Assets.FOLLETT_BOOK_EFAIRS_LOGO_SM;
	public bookClub: BookClub;
	public disableAddToWishlist: boolean;
	public showFC: boolean;
	public cart: Cart;

	constructor (private authService: AuthService, private sidebarToggleService: SidebarToggleService, private userService: UserService, private router: Router,
		private mobileService: MobileService, private bookClubService: BookClubService, private utilsService: UtilsService, public cartService: CartService) {
		super();
	}

	@HostListener('window:scroll', ['$event']) public onScrollEvent () : void {
		if (this.floating) {
			this.cleanup.push(timer(200).subscribe(() => {
				if (window.scrollY > 0) {
					document.querySelector('.logo-follett').classList.remove('scale-down-left-reverse');
					document.querySelector('.logo-follett').classList.add('scale-down-left');

					this.cleanup.push(timer(200).subscribe(() => {
						(<any>document.querySelector('.logo-follett')).style.display = 'none';
						(<any>document.querySelector('.follett-emblem')).style.display = 'grid';
						document.querySelector('.follett-emblem').classList.remove('flip-vertical-right-reverse');
						document.querySelector('.follett-emblem').classList.add('flip-vertical-right');
					}));
				}	else {
					document.querySelector('.follett-emblem').classList.remove('flip-vertical-right');
					document.querySelector('.follett-emblem').classList.add('flip-vertical-right-reverse');
					this.cleanup.push(timer(1).subscribe(() => {
						document.querySelector('.logo-follett').classList.remove('scale-down-left');
						document.querySelector('.logo-follett').classList.add('scale-down-left-reverse');
						(<any>document.querySelector('.logo-follett')).style.display = 'block';
						(<any>document.querySelector('.follett-emblem')).style.display = 'none';
						this.cleanup.push(timer(200).subscribe(() => {
							document.querySelector('.follett-emblem').classList.remove('flip-vertical-right-reverse');
							document.querySelector('.logo-follett').classList.remove('scale-down-left-reverse');
						}));
					}));
				}
			}));
		}
	}

	public ngOnInit () : void {
		this.showShoppingCart = false;
		this.isMobile = this.mobileService.isMobile();

		this.cleanup.push(this.sidebarToggleService.sidebarToggled.subscribe((isOpen: boolean) => {
			this.isSidebarOpen = isOpen;
		}));

		this.cleanup.push(this.userService.onUserUnLoad.subscribe(() => {
			this.isTeacher = false;
			this.isGuest = false;
			this.showSearch = false;
			this.user = {};
		}));

		this.cleanup.push(this.cartService.onCartLoaded.subscribe(() => {
			this.cart = this.cartService.getCachedCart();
			this.setBookClubAndFlags();
		}));

		if (!this.userService.getUser()) {
			this.cleanup.push(this.userService.onUserLoad.subscribe(() => {
				this.init();
			}));
		} else {
			this.init();
		}
		this.isAuthenticated = this.authService.isAuthenticated();
		this.cleanup.push(this.authService.onAuthenticated.subscribe((authenticated: boolean) => {
			this.isAuthenticated = authenticated;
		}));
		this.cleanup.push(this.authService.onGuestLogin.subscribe(() => {
			this.isGuest = true;
			this.isAuthenticated = false;
		}));

		this.setBookClubAndFlags();
		if (!this.bookClub) {
			this.cleanup.push(this.bookClubService.onBookClubLoaded.subscribe(() => {
				this.setBookClubAndFlags();
			}));
		}
		this.cleanup.push(this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd ) {
				this.setBookClubAndFlags();
			}
		}));

	}

	private setBookClubAndFlags () : void {
		this.bookClub = this.bookClubService.getCachedBookClub();
		this.showSearch = !!this.bookClub && !this.sidebarToggleService.isAdmin();
		const hideCart: boolean = this.bookClub && this.bookClub.settings && this.bookClub.settings.hideCart;
		this.disableAddToWishlist = this.bookClub && this.bookClub.settings && this.bookClub.settings.disableAddToWishlist;
		this.showShoppingCart = !!this.cart && !hideCart && (this.isAuthenticated || this.isGuest && !!this.bookClub) && !this.sidebarToggleService.isAdmin();
		this.showWishlist = this.isGuest && !!this.bookClub;
		this.showFC = !this.utilsService.envIsProd() && this.bookClub && this.bookClub.fulfillmentCenter !== FulfillmentCenter.MC;
	}

	private init () : void {
		this.isTeacher = this.userService.isTeacher();
		this.isGuest = this.userService.isGuest();
		this.user = this.userService.getUser();
		this.cart = this.cartService.getCachedCart();
	}

	public toggleSidebar () : void {
		this.sidebarToggleService.toggleSidebar(true);
	}

	public getFollettRouterLink () : string {
		let url: string = '';
		if (this.userService.getUser()) {
			url = this.isTeacher ? 'teacher/myEfairs' : 'guest/home';
		}
		return url;
	}

	public dismissCartIsReadyPopup () : void {
		this.cartService.showCartIsReadyPopup = false;
	}
}

export { TopbarComponent };
