import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { BOOK_WIDTH } from '../../book/book';
import { timer } from 'rxjs';
import { BookList } from '../../../services/apis/book.club/book.club.service';
import { carouselSlide, SLIDE_STATE } from '../../animations/slide';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { BookViewType } from '../../book/book';
import { BaseComponent } from '../../../base.component';
import { WindowRef } from '../../../utils/window.ref';

const NAV_BUTTON_WIDTH: number = 41;  // see .prev-next in abstract.book.carousel.css
@Component({
	selector: 'actual-book-carousel',
	templateUrl: './actual.book.carousel.html',
	styleUrls: ['./actual.book.carousel.css'],
	animations: [carouselSlide]
})

class ActualBookCarouselComponent extends BaseComponent implements AfterViewInit, OnChanges {
	@Input() public book_ids: Array<string>;
	@Input() public bookList: BookList;
	@Input() public viewType: number;
	@Input() public isFilterApplied: boolean;
	@Output() public loaded: EventEmitter<string> = new EventEmitter<string>();
	@ViewChild('carousel', {static: false}) public carouselElement: ElementRef;

	@Input() public showDelete: boolean;
	@Input() public showArchive: boolean;
	public showNextPrev: boolean;
	public showPageIndicator: boolean;
	public prevPageIndexes: Array<number> = [];
	public currentPageIndexes: Array<number> = [];
	public nextPageIndexes: Array<number> = [];
	public pages: Array<number> = [];
	public currentPageNumber: number;
	public currentIndex: number;
	public numBooksToShow: number;
	public containerWidth: number;
	public slideAmount: number;
	public carouselState: string = 'current';
	public BookViewType: typeof BookViewType = BookViewType;
	private desiredIndexFromTd: number;
	private innerWidth: number;
	private acknowledgeNext: boolean = true;
	private acknowledgePrev: boolean = true;

	constructor (private ref: ChangeDetectorRef, private analyticsService: AnalyticsService, private windowRef: WindowRef) {
		super();
	}

	public ngAfterViewInit () : void {
		this.innerWidth = this.windowRef.getWindow().innerWidth;
		this.currentIndex = 0;
		this.calculateNumberOfBooksToShow();
	}

	public ngOnChanges () : void {
		if (this.book_ids) {
			this.populatePages();
		}
	}

	public onResize () : void {
		if (this.windowRef.getWindow().innerWidth !== this.innerWidth) {
			this.innerWidth = this.windowRef.getWindow().innerWidth;
			this.calculateNumberOfBooksToShow();
		}
	}

	private calculateNumberOfBooksToShow () : void {
		this.containerWidth = this.carouselElement.nativeElement.parentElement.parentElement.clientWidth;
		this.numBooksToShow = Math.floor((this.containerWidth - (NAV_BUTTON_WIDTH * 2)) / BOOK_WIDTH);
		if (this.numBooksToShow < 1) {
			this.numBooksToShow = 1;
		}
		this.containerWidth = BOOK_WIDTH * this.numBooksToShow;

		this.populatePages();
	}

	public getPageContainerWidth () : string {
		const width: number = BOOK_WIDTH * this.numBooksToShow * 3;
		return `${width}px`;
	}

	private fillPage (startIndex: number) : Array<number> {
		const page: Array<number> = [];
		for (let i: number = 0; i < this.numBooksToShow; i++) {
			page.push(startIndex);
			startIndex++;
			if (startIndex >= this.book_ids.length) {
				startIndex = 0;
			}
		}
		return page;
	}

	private populatePages () : void {
		if (this.numBooksToShow) {
			if (this.book_ids.length >= this.numBooksToShow) {
				if (this.currentIndex >= this.book_ids.length) {
					this.currentIndex = 0;
				}
				this.currentPageIndexes = this.fillPage(this.currentIndex);
				let index: number = this.currentIndex + this.numBooksToShow;
				if (index >= this.book_ids.length) {
					index = index - this.book_ids.length;
				}
				this.nextPageIndexes = this.fillPage(index);
				index = this.currentIndex - this.numBooksToShow;
				if (index < 0) {
					index = this.book_ids.length + index;
				}
				this.prevPageIndexes = this.fillPage(index);
			} else {
				this.currentPageIndexes = [];
				for (let i: number = 0; i < this.book_ids.length; i++) {
					this.currentPageIndexes.push(i);
				}
			}
			this.cleanup.push(timer(0).subscribe(() => {
				this.showPageIndicator = this.book_ids.length > this.numBooksToShow;
				this.showNextPrev = this.book_ids.length > this.numBooksToShow;
				const numPages: number = Math.ceil(this.book_ids.length / this.numBooksToShow);
				this.pages = Array(numPages).fill(0).map((_x: any, i: any) => i);
				this.currentPageNumber = Math.floor(this.currentIndex / this.numBooksToShow);
				this.loaded.emit();
			}));
			this.ref.detectChanges();
		}
	}

	public getPagePosition () : string {
		if (this.pages.length > 1) {
			return `${(-BOOK_WIDTH * this.numBooksToShow) - 3}px`;
		} else {
			return `${NAV_BUTTON_WIDTH}px`;
		}
	}

	public next (fromTdPage?: boolean) : void {
		let bookListName: string = 'Recommended List';
		if (this.bookList) {
			bookListName = this.bookList.name;
		}
		this.analyticsService.sendEvent(fromTdPage ? 'Category Next Via TitleDetails' : 'Category Next', '\'' + bookListName + '\'From Page: ' + this.currentPageNumber + '/' + this.pages.length);
		const currentEndIndex: number = this.currentIndex + this.numBooksToShow;
		if (currentEndIndex >= this.book_ids.length) {
			this.slideAmount = -(Math.abs(BOOK_WIDTH * (this.book_ids.length - this.currentIndex)));
		} else {
			this.slideAmount = -(Math.abs(BOOK_WIDTH * this.numBooksToShow));
		}
		this.carouselState = SLIDE_STATE.NEXT;
	}

	public prev (fromTdPage?: boolean) : void {
		let bookListName: string = 'Recommended List';
		if (this.bookList) {
			bookListName = this.bookList.name;
		}
		this.analyticsService.sendEvent(fromTdPage ? 'Category Prev Via TitleDetails' : 'Category Prev', '\'' + bookListName + '\'From Page: ' + this.currentPageNumber + '/' + this.pages.length);
		if (this.currentIndex === 0) {
			const lastStartIndex: number = Math.floor((this.book_ids.length - 1)  / this.numBooksToShow) * this.numBooksToShow;
			this.slideAmount = (Math.abs(BOOK_WIDTH * (this.book_ids.length - lastStartIndex)));
		} else {
			this.slideAmount = (Math.abs(BOOK_WIDTH * this.numBooksToShow));
		}
		this.carouselState = SLIDE_STATE.PREVIOUS;
	}

	public onSlideDone (event: AnimationEvent) : void {
		if (event.toState === SLIDE_STATE.NEXT && this.acknowledgeNext) {
			this.acknowledgeNext = false;
			this.cleanup.push(timer(0).subscribe(() => {
				if (this.desiredIndexFromTd) {
					this.currentIndex = this.desiredIndexFromTd;
					this.desiredIndexFromTd = null;
				} else {
					this.currentIndex += Math.abs(this.slideAmount / BOOK_WIDTH);
				}
				if (this.currentIndex >= this.book_ids.length) {
					this.currentIndex = 0;
				}
				this.carouselState = SLIDE_STATE.CURRENT;
				this.populatePages();
				this.acknowledgeNext = true;
			}));
		} else if (event.toState === SLIDE_STATE.PREVIOUS && this.acknowledgePrev) {
			this.acknowledgePrev = false;
			this.cleanup.push(timer(0).subscribe(() => {
				if (this.desiredIndexFromTd) {
					this.currentIndex = this.desiredIndexFromTd;
					this.desiredIndexFromTd = null;
				} else {
					this.currentIndex -= Math.abs(this.slideAmount / BOOK_WIDTH);
				}
				if (this.currentIndex < 0) {
					this.currentIndex = this.book_ids.length + this.currentIndex;
				}
				this.carouselState = SLIDE_STATE.CURRENT;
				this.populatePages();
				this.acknowledgePrev = true;
			}));
		}
	}

	public getCarouselWidth () : string {
		return `${this.containerWidth + (NAV_BUTTON_WIDTH * 2)}px`;
	}

	public goToCarouselPageFromTitleDetails (response: any) : void {
		this.desiredIndexFromTd = Math.floor(response.index / this.numBooksToShow) * this.numBooksToShow;
		let difference: number = this.desiredIndexFromTd - this.currentIndex;
		if (Math.abs(difference) > Math.floor(this.book_ids.length / 2)) {
			difference *= -1;
		}
		if (difference > 0) {
			this.next(true);
		} else if (difference < 0) {
			this.prev(true);
		} else {
			this.desiredIndexFromTd = null;
		}
	}

}

export { ActualBookCarouselComponent, NAV_BUTTON_WIDTH };
