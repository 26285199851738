import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConfigUtils } from '../../../utils/config';

class Configuration {
	public paymentGatewayURL: string;
	public googleAnalyticsID: string;
	public showNextbee: string;
	public authUrl: string;
	// public aaspHost: string;
	// public aaspAuthTenant: string;
	// public clientID: string;
	// public salesforcePublicUrl: string;
}

@Injectable()
class ConfigService {
	public config: Configuration;
	public subject: Subject<any> = new Subject();

	constructor (private http: HttpClient) { }

	public getConfig () : Observable<Configuration> {
		if (!this.config) {
			return this.http.get<Configuration>(ConfigUtils.getApiHost() + '/server/api/config').pipe(map((configuration: Configuration) => {
				this.config = configuration;
				return this.config;
			}), catchError(() => {
				return of(undefined);
			}));
		} else {
			return of(this.config);
		}
	}
}

export { ConfigService, Configuration };
