import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

class CategoryImage {
	public _id?: string;
	public categoryName: string;
	public imageUrl: string;
}

@Injectable()
class CategoryImageService extends BasicCrudService<CategoryImage> {

	public categoryImages: Map<string, string> = new Map();

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/categoryImage');
	}

	public cacheImages () : Observable<void> {
		return this.search().pipe(map((images: Array<CategoryImage>) => {
			this.categoryImages = new Map();
			for (const image of images) {
				this.categoryImages.set(image.categoryName, image.imageUrl);
			}
		}));
	}

}

export { CategoryImageService, CategoryImage };
