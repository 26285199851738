import { Injectable } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { UserService } from '../apis/user/user.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable()
class SidebarToggleService {
	public sidebarToggled: Subject<boolean> = new Subject<boolean>();
	public open: boolean;
	private adminPages: boolean;
	public isOpenViaHamburger: boolean;

	constructor (private userService: UserService, private analyticsService: AnalyticsService, private router: Router) {
		this.open = false;
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd ) {
				this.adminPages = event.url.startsWith('/admin');
			}
		});
	}

	public isOpen () : boolean {
		return this.open;
	}

	public toggleSidebar (preventCloseOnMouseLeave?: boolean) : void {
		this.open = !this.open;
		this.isOpenViaHamburger = preventCloseOnMouseLeave && this.open;
		this.sidebarToggled.next(this.open);

		this.analyticsService.sendEvent((this.isOpen() ? 'Sidebar Expand' : 'Sidebar Collapse'));
	}

	public openSidebar () : void {
		this.open = true;
		this.sidebarToggled.next(this.open);
	}

	public closeSidebar () : void {
		this.open = false;
		this.isOpenViaHamburger = false;
		this.sidebarToggled.next(this.open);
	}

	public isAdmin () : boolean {
		return this.adminPages && this.userService.isAdmin();
	}
}

export { SidebarToggleService };
