import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { pulse } from '../../../shared/animations/pulse';
import { CartService } from '../../../services/apis/cart/cart.service';
import { Router } from '@angular/router';
import { AbstractShoppingCartComponent } from '../abstract.shopping.cart/abstract.shopping.cart';
import { LAST_URL } from '../../../services/helpers/search.service';
import { WindowRef } from '../../../utils/window.ref';

@Component({
	selector: 'shopping-cart-sm',
	templateUrl: './shopping.cart.sm.html',
	styleUrls: ['../abstract.shopping.cart/abstract.shopping.cart.css'],
	animations: [pulse]
})

class ShoppingCartSmComponent extends AbstractShoppingCartComponent implements OnInit {

	constructor (analyticsService: AnalyticsService, cartService: CartService, private router: Router, private windowRef: WindowRef) {
		super(analyticsService, cartService);
	}

	public clickCart () : void {
		const currentUrl: string = this.windowRef.getWindow().location.pathname;
		localStorage.setItem(LAST_URL, decodeURI(currentUrl));
		this.analyticsService.sendEvent('Mobile Cart Preview');
		this.router.navigate(['/cartPreview']);
	}
}

export { ShoppingCartSmComponent };
