import { Injectable } from '@angular/core';
import { ConfigService } from '../apis/config/config.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../apis/user/user.service';
import { CartItem } from '../apis/cart/cart.service';
import { OrderItem } from '../apis/order/order.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Book, BookService } from '../apis/book/book.service';
import { BookDiscountService } from '../apis/book.discount/book.discount.service';
import { Log } from './log.service';
declare const gtag: Function;

class EcommerceItem {
	public id: string;
	public quantity: number;
	public price: string;
	public name: string;
}
@Injectable()
class AnalyticsService {

	public initialized: boolean = false;

	constructor (private configService: ConfigService, private userService: UserService, private bookService: BookService, private bookDiscountService: BookDiscountService) {}

	private initializeGoogleAnalytics () : Observable<void> {
		if (this.initialized) {
			return of(undefined);
		} else {
			return this.configService.getConfig().pipe(map((config: any) => {
				this.initialized = true;
				if (environment.production) {
					gtag('config', config.googleAnalyticsID, { cookie_flags: 'SameSite=None;Secure', 'custom_map': {'dimension1': 'follett_role'}, send_page_view: false });
				}
			}));
	 }
	}

	public sendPageView (url: string, title: string) : void {
		if (environment.production) {
			this.initializeGoogleAnalytics().subscribe(() => {
				const options: any = this.createPageViewOptions(url, title);
				gtag('event', 'page_view', options);
			});
		} else {
			Log.log(`Analytics.sendPageView(${JSON.stringify(this.createPageViewOptions(url, title))})`);
		}
	}

	public sendEvent (action: string, label?: string, value?: any) : void {
		if (typeof label !== 'string') {
			label = JSON.stringify(label);
		}

		const options: any = {
			'follett_role': this.userService.getUserType(),
			'event_category': this.userService.getUserType(),
			'event_label': label,
			'value': value
		};

		if (environment.production) {
			this.initializeGoogleAnalytics().subscribe(() => {
				gtag('event', action, options);
			});
		} else {
			Log.log(`Analytics.sendEvent(${action}, ${JSON.stringify(options)})`);
		}
	}

	public sendException (description: string, fatal: boolean = false) : void {
		const options: any = {
			'description': description,
			'fatal': fatal
		};
		if (environment.production) {
			this.initializeGoogleAnalytics().subscribe(() => {
				gtag('event', 'exception', options);
			});
		} else {
			Log.log(`Analytics.sendException(${JSON.stringify(options)})`);
		}
	}

	public sendEcommerceEvent (action: string, extraOptions: any, cartItems: Array<CartItem | OrderItem>) : void {
		const items: Array<EcommerceItem> = [];
		const bookObs: Array<Observable<void>> = [];

		for (const item of cartItems) {
			let cachedBook: Book = this.bookService.getCachedBook(item.book_id);
			let cachedBookDiscountPrice: string;
			let obs: Observable<Book>;
			if (cachedBook) {
				cachedBookDiscountPrice = this.bookDiscountService.getCachedBookDiscountedPrice(cachedBook.titlewave_id);
				obs = of(cachedBook);
			} else {
				obs = this.bookService.cacheMissingBooks([item.book_id]).pipe(map(() => {
					cachedBook = this.bookService.getCachedBook(item.book_id);
					cachedBookDiscountPrice = this.bookDiscountService.getCachedBookDiscountedPrice(cachedBook.titlewave_id);
					return cachedBook;
				}));
			}

			bookObs.push(obs.pipe(map((book: Book) => {
				items.push({
					id: book.titlewave_id,
					name: book.title,
					quantity: item.quantity,
					price: cachedBookDiscountPrice || book.price
				});
			})));

		}

		forkJoin(bookObs).subscribe(() => {
			const options: any = Object.assign({ items: items }, extraOptions);
			if (environment.production) {
				this.initializeGoogleAnalytics().subscribe(() => {
					gtag('event', action, options);
				});
			} else {
				Log.log(`Analytics.sendEcommerceEvent(${action}, ${JSON.stringify(options)})`);
			}
		});

	}

	private createPageViewOptions (url: string, title: string) : any {
		if (url && url.indexOf('?code=') !== -1) {
			url = url.replace(/\?code=.*/, '');
		}
		const options: any = {
			'follett_role': this.userService.getUserType(),
			'page_location': url,
			'page_title': title
		};
		return options;
	}
}


export { AnalyticsService };
