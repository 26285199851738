import { Input, Output, EventEmitter, OnInit, OnChanges, ViewChild } from '@angular/core';
import { Book, BookService } from '../../services/apis/book/book.service';
import { BookClubService, BookClub } from '../../services/apis/book.club/book.club.service';
import { UserService } from '../../services/apis/user/user.service';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { RecommendedListService, RecommendedItem } from '../../services/apis/recommended.list/recommended.list.service';
import { BaseComponent } from '../../base.component';
import { Displayables } from '../../utils/displayables';
import { CartItemRecipient } from '../../services/apis/cart/cart.service';
import { BookDiscountService } from '../../services/apis/book.discount/book.discount.service';
import { LESS_IMPORTANT_READING_LEVELS } from '../../constants/reading.level.types';
import { BOOK_LANGUAGES, Language } from '../../constants/book.languages';
import { BookReview, BookReviewService } from '../../services/apis/book.review/book.review.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { BookClubStatus } from '../../constants/book.club.status';

abstract class TitleDetailsComponent extends BaseComponent implements OnInit, OnChanges {
	@Input() public book_id: any;
	@Input() public fromTeacherWishlist: boolean;
	@Input() public fromWishlist: boolean;
	@Input() public defaultCartItemRecipient: CartItemRecipient;
	@Output() public hideModal: EventEmitter<any> = new EventEmitter<any>();

	public book: Book;
	public discountedPrice: string;
	public readingLevels: Array<{key: string, value: string}> = new Array();
	public recommended: boolean = false;
	public recNote: string;
	public teacherName: string;
	public cartId: string;
	public clubClosed: boolean;
	public clubStarted: boolean;
	public recommending: boolean = false;
	public addingToCart: boolean = false;
	public addingToWishlist: boolean = false;
	public removingFromWishlist: boolean = false;
	public displayables: any = Displayables;
	public isTeacher: boolean;
	public isGuest: boolean;
	public showTeacherNote: boolean;
	public showAddToCart: boolean;
	public showAddToWishlist: boolean;
	public hideCart: boolean;
	public useInvoicePayment: boolean;
	public disableAddToWishlist: boolean;
	public startDateDisplayable: string;
	public language: Language;
	public bookReview: BookReview;
	public numReviews: number;
	public numAwards: number;
	public bookClubTimeZone: string;

	@ViewChild('reviewsTabs', { static: false }) private reviewsTabs: TabsetComponent;

	constructor (private recommendedListService: RecommendedListService, private analyticsService: AnalyticsService, public userService: UserService,
							 private bookClubService: BookClubService, private bookService: BookService, private readingLevelTypeDefinitions: any,
							 private bookDiscountService: BookDiscountService, private bookReviewService: BookReviewService) {
		super();
	}

	public ngOnChanges () : void {
		this.fetchBookInfo();
		if (this.reviewsTabs) {
			this.reviewsTabs.tabs[0].active = true;
		}
	}

	public populateReadingLevelTypes () : void {
		this.readingLevels = [];
		const preferred: string = this.bookClubService.getReadingLevelType();

		this.readingLevels.push({
			key: this.readingLevelTypeDefinitions[preferred],
			value: this.fetchDisplayableReadingLevelInfo(this.book[preferred])
		});

		for (const thisItem in this.readingLevelTypeDefinitions) {
			if (thisItem !== preferred && !LESS_IMPORTANT_READING_LEVELS.has(thisItem)) {
				// According to S-47890, we should display certain reading level types only if they are teacher's preferred.
				// These reading level types are referred to as "LESS_IMPORTANT_READING_LEVELS"
				this.readingLevels.push({
					key: this.readingLevelTypeDefinitions[thisItem],
					value: this.fetchDisplayableReadingLevelInfo(this.book[thisItem])
				});
			}
		}
	}

	private fetchDisplayableReadingLevelInfo (field: string | boolean | Array<string> | Date | number) : string {
		if (!field || field === null) {
			return('n/a');
		} else {
			return('' + field);
		}
	}

	public fetchBookInfo () : void {
		this.book = this.bookService.getCachedBook(this.book_id);
		this.discountedPrice = this.bookDiscountService.getCachedBookDiscountedPrice(this.book.titlewave_id);
		if (this.book && this.book._id) {
			this.language = BOOK_LANGUAGES.get(this.book.textLanguage);
			this.recommended = this.recommendedListService.isInMyRecommendedList(this.book._id);
			const recItem: RecommendedItem = this.recommendedListService.getRecommendedItem(this.book._id);
			if (recItem) {
				this.recNote = recItem.note;
			}
			this.showTeacherNote = this.isGuest && this.recommended && !!this.recNote;
			this.populateReadingLevelTypes();
			this.populateReviews();
		}
	}

	private populateReviews () : void {
		this.cleanup.push(this.bookReviewService.findByTitlewave_id(this.book.titlewave_id).subscribe((bookReview: BookReview) => {
			this.bookReview = bookReview;
			this.numReviews = this.bookReview && this.bookReview.reviews.length;
			this.numAwards = this.bookReview && this.bookReview.citations.length;
		}));
	}

	public ngOnInit () : void {
		this.isTeacher = this.userService.isTeacher();
		this.isGuest = this.userService.isGuest();
		this.fetchBookInfo();
		if (this.isGuest) {
			this.teacherName = this.userService.getTeacher().teacherNickName;
		} else {
			this.teacherName = this.userService.getUser().teacherNickName;
			this.cleanup.push(this.recommendedListService.onListUpdate.subscribe(() => {
				this.recommended = this.recommendedListService.isInMyRecommendedList(this.book._id);
			}));
		}
		const bookClub: BookClub = this.bookClubService.getCachedBookClub();
		this.clubClosed = !bookClub || bookClub.status !== BookClubStatus.CREATED && bookClub.status !== BookClubStatus.STARTED;
		this.clubStarted = bookClub && bookClub.status === BookClubStatus.STARTED;
		if (bookClub) {
			this.startDateDisplayable = Displayables.getDateInTimezone(bookClub.startDate, bookClub.timezone, 'M/D');
			if (bookClub.settings) {
				this.disableAddToWishlist = bookClub.settings.disableAddToWishlist;
				this.hideCart = bookClub.settings.hideCart;
				this.useInvoicePayment = bookClub.settings.useInvoicePayment;
			}
			this.showAddToCart = this.clubStarted && !this.hideCart;
			this.bookClubTimeZone = bookClub.timezone;
		}
		this.showAddToWishlist = this.isGuest && !this.fromWishlist && !this.disableAddToWishlist;
	}

	public toggleBookRecommendation (book: Book) : void {
		this.recommending = true;
		this.analyticsService.sendEvent('Details Recommend', '\'' + this.book.title + '\' Recommendation Note?:' + !!this.book.recommendationNote);
		this.cleanup.push(this.recommendedListService.addToRecommendedList(book).subscribe(() => {
			this.recommending = false;
		}));
	}

}

export { TitleDetailsComponent };
