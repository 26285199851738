import { Component, HostListener } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

const ESCAPE: number = 27;

@Component({
	selector: 'alert-dialog',
	templateUrl: './alert.dialog.html',
	styleUrls: ['./alert.dialog.css']
})
class AlertDialogComponent {
	public title: string = 'Alert';
	public message: string = 'Are you sure?';
	public okayLabel: string = 'Okay';
	public cancelLabel: string = 'Cancel';
	public showCancel: boolean;
	public titleIconPath: string;
	public items: Array<string>;
	public okayClicked: Function;

	constructor (private bsModalRef: BsModalRef) {}

	@HostListener('window:keydown', ['$event'])
	public onKeyDown (event: KeyboardEvent) : void {
		// tslint:disable-next-line:deprecation
		if (event.keyCode !== ESCAPE) {
			event.preventDefault();
		}
	}

	public clickOkay () : void {
		if (this.okayClicked) {
			this.okayClicked();
		}
		this.bsModalRef.hide();
	}

	public cancel () : void {
		this.bsModalRef.hide();
	}
}

export { AlertDialogComponent };
