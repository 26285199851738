import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { LiveChatService } from '../../services/helpers/live.chat.service';
import { Assets } from '../assets';

@Component({
	selector: 'support-phone-number',
	templateUrl: './support.phone.number.html'
})

class SupportPhoneNumberComponent extends BaseComponent {
	@Input() public origin: string;
	public SUPPORT_PHONE_NUMBER: string = Assets.SUPPORT_PHONE_NUMBER;

	constructor (private liveChatService: LiveChatService) {
		super();
	}

	public contactSupport () : void {
		this.liveChatService.contactSupport(`Contact Support (${this.origin})`);
	}
}

export { SupportPhoneNumberComponent };
