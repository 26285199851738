import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { BookService } from '../book/book.service';
import { AnalyticsService } from '../../helpers/analytics.service';
import { Router } from '@angular/router';
import { ViewWishlistService } from '../wishlist/view.wishlist.service';
import { SiteSettingsService, SiteSettings } from '../site.settings/site.settings.service';
import { DialogService } from '../../helpers/dialog.service';
import { Staff } from '../school/school.service';
import { BookClub, BookClubService } from '../book.club/book.club.service';
import { ErrorHelper } from '../../../utils/error.helper';
import { ErrorCode } from '../../../constants/error.codes';
import { CART_ID_KEY } from '../../../constants/local.storage';
import { EFAIRS_KILL_SWITCH } from '../../../constants/site.settings';

class Cart {
	public _id?: string;
	public bookClub_id: string;
	public cartItems?: Array<CartItem>;
	public discountCode?: string;
	public user_id?: string;
}

class CartItemRecipient {
	public firstName: string;
	public lastName: string;
	public teacherName: Staff;
	public fromTeacherWishlist: boolean;
}

class CartItem {
	public _id?: string;
	public book_id: string;
	public quantity: number;
	public price?: number;
	public discountedPrice?: number;
	public gift?: boolean;
	public wishlist_id?: string;
	public recipient: CartItemRecipient;
}
@Injectable()
class CartService extends BasicCrudService<any> {
	public onCartUpdate: Subject<Array<CartItem>> = new Subject();
	public onCartLoaded: Subject<void> = new Subject();
	public onAddedToCart: Subject<void> = new Subject();
	public addingToCart: boolean;
	public showCartIsReadyPopup: boolean;
	public efairsKillswitch: SiteSettings;
	public preventAddingToCart: boolean;
	public itemCount: number;

	private cachedCart: Cart;

	constructor (http: HttpClient, private userService: UserService, private bookService: BookService, private analyticsService: AnalyticsService, private siteSettingsService: SiteSettingsService,
							 private dialogService: DialogService, private viewWishlistService: ViewWishlistService, private router: Router, private bookClubService: BookClubService) {
		super(http);
		this.setUrl(`/server/api/carts`);
		this.showCartIsReadyPopup = false;
		this.init();
	}

	private init () : void {
		this.siteSettingsService.getOrCreateSiteSettings(EFAIRS_KILL_SWITCH).subscribe((setting: SiteSettings) => {
			this.efairsKillswitch = setting;
		});

		this.bookClubService.onBookClubLoaded.subscribe(() => {
			const lsCart_id: string = localStorage.getItem(CART_ID_KEY);
			let cartSub: Observable<Array<CartItem> | Cart>;
			const club: BookClub = this.bookClubService.getCachedBookClub();

			if (this.userService.isTeacher() && club) {
				cartSub = this.search({ user_id: this.userService.getUser()._id, bookClub_id: club._id });
			} else if (lsCart_id) {
				cartSub = this.get(lsCart_id);
			} else if (club) {
				cartSub = this.create(club._id);
			}
			if (cartSub) {
				cartSub.subscribe(() => {
					this.onCartLoaded.next();
					this.calculatePreventAddingToCart();
				});
			}
		});
	}

	public getCachedCart () : Cart {
		let count: number = 0;
		const cartItems: Array<CartItem> = this.cachedCart ? this.cachedCart.cartItems : [];
		for (const item of cartItems) {
			count += item.quantity;
		}
		this.itemCount = count;
		return this.cachedCart;
	}

	public getCachedCartItems () : Array<CartItem> {
		const cart: Cart = this.getCachedCart();
		return cart ? cart.cartItems : [];
	}

	public calculatePreventAddingToCart () : void {
		const bookClub: BookClub = this.bookClubService.getCachedBookClub();
		const requiredNumberOfBooks: number = bookClub && bookClub.settings && bookClub.settings.requiredNumberOfBooks || 0;
		this.preventAddingToCart = requiredNumberOfBooks > 0 && this.itemCount >= requiredNumberOfBooks;
	}

	public getCachedCart_id () : string {
		return this.cachedCart ? this.cachedCart._id : undefined;
	}

	public isInMyCart (book_id: string) : boolean {
		return this.cachedCart ? !!this.cachedCart.cartItems.find((item: CartItem) => {
			return item.book_id === book_id;
		}) : false;
	}

	public addToCart (book_id: string, firstName: string, lastName: string, teacherName: Staff, gift: boolean, fromTeacherWishlist: boolean) : Observable<any> {
		if (!this.efairsKillswitch.value) {
			const newItem: CartItem = { book_id: book_id, quantity: 1, gift: gift, recipient: { firstName: firstName, lastName: lastName, teacherName: teacherName, fromTeacherWishlist: fromTeacherWishlist } };
			this.analyticsService.sendEcommerceEvent('add_to_cart', null, [newItem]);
			const existingItem: CartItem = this.getCartItem(book_id, newItem.recipient);
			const bookClub: BookClub = this.bookClubService.getCachedBookClub();
			const preventQuantityChange: boolean = bookClub && bookClub.settings && bookClub.settings.preventQuantityChange;
			if (existingItem) {
				if (!preventQuantityChange) {
					existingItem.quantity = existingItem.quantity + 1;
					existingItem.gift = gift;
					return this.updateCartItem(existingItem);
				} else {
					return of(true);
				}
			} else {
				if (this.router.url.startsWith('/guest/viewWishlist')) {
					newItem.wishlist_id = this.viewWishlistService.selectedWishlistId;
				}

				return this.http.post(`${this.url}/${this.cachedCart._id}`, newItem).pipe(map((cart: Cart) => {
					this.cachedCart = cart;
					this.onCartUpdate.next(this.getCachedCartItems());
					this.calculatePreventAddingToCart();
					this.showCartIsReadyPopup = this.preventAddingToCart;
					return this.getCachedCartItems();
				}));
			}
		} else {
			return of(this.dialogService.alert('Sorry', this.efairsKillswitch.customMessage, undefined, true));
		}
	}

	public removeFromCart (cartItem: CartItem) : Observable<any> {
		this.analyticsService.sendEcommerceEvent('remove_from_cart', null, [cartItem]);

		return this.http.delete(this.url + '/' + encodeURIComponent(this.cachedCart._id) + '/' + cartItem._id).pipe(map((cart: Cart) => {
			this.cachedCart = cart;
			this.onCartUpdate.next(this.getCachedCartItems());
			this.calculatePreventAddingToCart();
			this.showCartIsReadyPopup = this.preventAddingToCart;
			return this.getCachedCartItems();
		}));
	}

	public updateCartItem (updatedItem: CartItem) : Observable<any> {
		return this.http.patch(this.url + '/' + encodeURIComponent(this.cachedCart._id) + '/' + encodeURIComponent(updatedItem._id), updatedItem).pipe(map((cart: Cart) => {
			this.cachedCart = cart;
			this.onCartUpdate.next(this.getCachedCartItems());
			this.calculatePreventAddingToCart();
			this.showCartIsReadyPopup = this.preventAddingToCart;
			return this.getCachedCartItems();
		}));
	}

	public updateRecipientForAllCartItems (recipient: CartItemRecipient) : Observable<any> {
		return this.http.patch(this.url + '/' + encodeURIComponent(this.cachedCart._id) + '/updateAllRecipients', { recipient: recipient }).pipe(map((cart: Cart) => {
			this.cachedCart = cart;
			this.onCartUpdate.next(this.getCachedCartItems());
			return this.getCachedCartItems();
		}));
	}

	public emptyCart () : Observable<any> {
		return this.delete(this.cachedCart._id).pipe(map((cart: Cart) => {
			this.cachedCart = cart;
			this.onCartUpdate.next(this.getCachedCartItems());
			this.preventAddingToCart = false;
		}));
	}

	public get (_id: string) : Observable<Array<CartItem>> {
		const bookClub: BookClub = this.bookClubService.getCachedBookClub();
		return super.get(_id).pipe(catchError((err: Error) => {
			const rootError: any = ErrorHelper.getRootError(err);
			if (rootError.code === ErrorCode.NotFound) {
				if (bookClub && bookClub._id) {
					return this.create(bookClub._id);
				} else {
					return of(undefined);
				}
			}
		})).pipe(mergeMap((cart: Cart) => {
			this.cachedCart = cart;
			if (bookClub && bookClub._id === cart.bookClub_id) {
				return this.cacheBooks().pipe(map(() => {
					this.onCartUpdate.next(this.getCachedCartItems());
					this.calculatePreventAddingToCart();
					return this.getCachedCartItems();
				}));
			} else if (bookClub && bookClub._id) {
				return this.create(bookClub._id).pipe(map((newCart: Cart) => {
					this.calculatePreventAddingToCart();
					return newCart.cartItems;
				}));
			} else {
				return of(undefined);
			}
		}));
	}

	public search (query: Partial<Cart>) : Observable<Array<CartItem>> {
		const bookClub: BookClub = this.bookClubService.getCachedBookClub();
		return super.search(query).pipe(catchError((err: Error) => {
			const rootError: any = ErrorHelper.getRootError(err);
			if (rootError.code === ErrorCode.NotFound) {
				if (bookClub && bookClub._id) {
					return this.create(bookClub._id);
				} else {
					return of(undefined);
				}
			}
		})).pipe(mergeMap((cart: Cart) => {
			this.cachedCart = cart;
			if (bookClub && bookClub._id === cart.bookClub_id) {
				return this.cacheBooks().pipe(map(() => {
					this.onCartUpdate.next(this.getCachedCartItems());
					this.calculatePreventAddingToCart();
					return this.getCachedCartItems();
				}));
			} else if (bookClub && bookClub._id) {
				return this.create(bookClub._id).pipe(map((newCart: Cart) => {
					this.calculatePreventAddingToCart();
					return newCart.cartItems;
				}));
			} else {
				return of(undefined);
			}
		}));
	}

	public create (bookClub_id: string) : Observable<Cart> {
		return super.create({ bookClub_id: bookClub_id }).pipe(map((cart: Cart) => {
			this.cachedCart = cart;
			localStorage.setItem(CART_ID_KEY, cart._id);
			this.onCartUpdate.next(this.getCachedCartItems());
			return this.cachedCart;
		}));
	}

	public refresh () : Observable<Array<CartItem>> {
		if (this.cachedCart) {
			return this.get(this.cachedCart._id);
		} else {
			return of([]);
		}
	}

	public reset () : void {
		localStorage.removeItem(CART_ID_KEY);
		this.cachedCart = null;
		this.getCachedCartItems();
		this.preventAddingToCart = false;
		this.onCartUpdate.next();
	}

	public cacheBooks () : Observable<any> {
		const book_ids: Array<string> = [];
		for (const cartItem of this.getCachedCartItems()) {
			book_ids.push(cartItem.book_id);
		}
		return this.bookService.cacheMissingBooks(book_ids);
	}

	public getItemCount () : number {
		return this.itemCount;
	}

	public getCartTotal () : number {
		let result: number = 0;
		if (this.cachedCart && this.cachedCart.cartItems) {
			for (const item of this.cachedCart.cartItems) {
				result = result + (item.discountedPrice || item.price) * item.quantity;
			}
		}
		return result;
	}

	public cartItemsAreTheSameItem (cartItemA: Partial<CartItem>, cartItemB: Partial<CartItem>) : boolean {
		let result: boolean = true;
		result = result && cartItemA.recipient.fromTeacherWishlist === cartItemB.recipient.fromTeacherWishlist;
		result = result && cartItemA.book_id === cartItemB.book_id;
		result = result && cartItemA.recipient.firstName === cartItemB.recipient.firstName;
		result = result && cartItemA.recipient.lastName === cartItemB.recipient.lastName;
		result = result && cartItemA.recipient.teacherName.firstName === cartItemB.recipient.teacherName.firstName;
		result = result && cartItemA.recipient.teacherName.lastName === cartItemB.recipient.teacherName.lastName;
		return result;
	}

	public getCartItem (book_id: string, recipient: CartItemRecipient) : CartItem {
		const cartItems: Array<CartItem> = this.getCachedCartItems();
		return cartItems.find((item: CartItem) => {
			let result: boolean = true;
			result = result && item.recipient.fromTeacherWishlist === recipient.fromTeacherWishlist;
			result = result && item.book_id === book_id;
			result = result && item.recipient.firstName === recipient.firstName;
			result = result && item.recipient.lastName === recipient.lastName;
			result = result && item.recipient.teacherName.firstName === recipient.teacherName.firstName;
			result = result && item.recipient.teacherName.lastName === recipient.teacherName.lastName;
			return result;
		});
	}

	public applyDiscount (discountCode: string, apply: boolean) : Observable<void> {
		return this.http.patch(this.url + '/' + encodeURIComponent(this.cachedCart._id) + '/applyDiscount', { discountCode: discountCode, applyDiscount: apply }).pipe(map((cart: Cart) => {
			this.cachedCart = cart;
			this.onCartUpdate.next(this.getCachedCartItems());
		}));
	}
}

export { Cart, CartService, CartItem, CartItemRecipient };
