import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BaseComponent } from '../../base.component';
import { LiveChatService } from '../../services/helpers/live.chat.service';
import { UserService, User } from '../../services/apis/user/user.service';
import { WindowRef } from '../../utils/window.ref';
import { Assets } from '../assets';
import { BookClub, BookClubService } from '../../services/apis/book.club/book.club.service';
import { interval } from 'rxjs';

@Component({
	selector: 'help-menu-dropdown',
	templateUrl: './help.menu.dropdown.html',
	styleUrls: ['./help.menu.dropdown.css']
})

class HelpMenuDropdownComponent extends BaseComponent implements OnInit {
	@Output() public clickDropdownItem: EventEmitter<any> = new EventEmitter<any>();

	public isLiveChatOnline: boolean;
	public SUPPORT_PHONE_NUMBER: string = Assets.SUPPORT_PHONE_NUMBER;
	public SALES_PHONE_NUMBER: string = Assets.SALES_PHONE_NUMBER;
	public isGuest: boolean;

	constructor (private analyticsService: AnalyticsService, private liveChatService: LiveChatService, private userService: UserService, private windowRef: WindowRef, private bookClubService: BookClubService) {
		super();
	}

	public ngOnInit () : void {
		this.isLiveChatOnline = this.liveChatService.isOnline();
		if (this.userService.getUser()) {
			this.isGuest = this.userService.isGuest();
		} else {
			this.cleanup.push(this.userService.onUserLoad.subscribe(() => {
				this.isGuest = this.userService.isGuest();
			}));
		}

		this.cleanup.push(this.userService.onUserUnLoad.subscribe(() => {
			this.isGuest = this.userService.isGuest();
		}));

		this.cleanup.push(interval(5000).subscribe(() => {
			this.isLiveChatOnline = this.liveChatService.isOnline();
		}));
	}

	public openStaffFaq () : void {
		this.analyticsService.sendEvent('Open Staff FAQ');
		this.windowRef.getWindow().open(Assets.STAFF_FAQ, '_blank');
		this.clickDropdownItem.emit();
	}

	public startLiveChat () : void {
		this.isLiveChatOnline = this.liveChatService.isOnline();
		this.analyticsService.sendEvent('Live Chat Clicked (Online: ' + this.isLiveChatOnline + ')');
		if (this.isLiveChatOnline) {
			this.liveChatService.startChat();
			this.clickDropdownItem.emit();
		}
	}

	public openEmailSupport () : void {
		const isTeacher: boolean = this.userService.isTeacher();
		this.analyticsService.sendEvent('Open Email Support - Logged in?: ' + isTeacher);
		const user: User = this.userService.getUser();
		if (isTeacher && user && user.salesforceContactId) {
			const bookClub: BookClub = this.bookClubService.getCachedBookClub();
			this.windowRef.getWindow().open(`${Assets.EMAIL_SUPPORT_LOGGED_IN_USER}?tfa_22=${user.salesforceContactId || ''}&tfa_15=${bookClub && bookClub.salesforceEfairNumber || ''}`, '_blank');
		} else {
			this.windowRef.getWindow().open(`${Assets.EMAIL_SUPPORT_GUEST}`, '_blank');
		}
		this.clickDropdownItem.emit();
	}

	public openPhoneSupport () : void {
		this.analyticsService.sendEvent('Open Phone Support');
		this.clickDropdownItem.emit();
	}

}

export { HelpMenuDropdownComponent };
