import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/apis/auth/auth.service';
import { AnalyticsService } from '../services/helpers/analytics.service';
import { BaseComponent } from '../base.component';
import { MobileService } from '../services/helpers/mobile.service';
import { ConfigService, Configuration } from '../services/apis/config/config.service';
import { SiteSettings, SiteSettingsService } from '../services/apis/site.settings/site.settings.service';
import { timer } from 'rxjs';
import { ModalService } from '../services/helpers/modal.service';
import { EFAIRS_KILL_SWITCH } from '../constants/site.settings';
import { UserRole } from '../constants/user';

@Component({
	selector: 'splash',
	templateUrl: './splash.html',
	styleUrls: ['./splash.css']
})

class SplashComponent extends BaseComponent implements OnInit, AfterViewInit {
	public readonly ROLE: typeof UserRole = UserRole;
	public efairCode: string;
	public isDesktop: boolean;
	public signUpUrl: string;
	public efairsKillswitch: SiteSettings;

	@ViewChild('codeEntry', {static: false}) public codeEntry: ElementRef;

	constructor (
		private authService: AuthService,
		private analyticsService: AnalyticsService,
		// private router: Router,
		// private dialogService: DialogService
		private mobileService: MobileService,
		private modalService: ModalService,
		private configService: ConfigService,
		private siteSettingsService: SiteSettingsService) {
		super();
	}

	public ngOnInit () : void {
		this.cleanup.push(this.siteSettingsService.getOrCreateSiteSettings(EFAIRS_KILL_SWITCH).subscribe((setting: SiteSettings) => {
			this.efairsKillswitch = setting;
		}));
		if (this.authService.isAuthenticated()) {
			this.authService.navigateToMyEfairsPage();
		}
		this.isDesktop = !this.mobileService.isMobile() && !this.mobileService.isIpad();
		this.configService.getConfig().subscribe((config: Configuration) => {
			this.signUpUrl = `${config.authUrl}/account/login`;
		});
	}

	public ngAfterViewInit () : void {
		if (this.isDesktop) {
			if (this.modalService.getModalsCount() === 0) {
				this.codeEntry.nativeElement.focus({ preventScroll: true });
			} else {
				this.cleanup.push(this.modalService.onHide.subscribe(() => {
					this.codeEntry.nativeElement.focus({ preventScroll: true });
				}));
			}
		}
	}

	public showLogin (role: string) : void {
		this.analyticsService.sendEvent('Click Login');
		this.authService.login(role);
	}

	public openPreview () : void {
		return;
		// if (!this.efairsKillswitch || !this.efairsKillswitch.value) {
		// 	this.authService.clearTeacherLocalStorage(true);
		// 	if (this.efairCode) {
		// 		this.analyticsService.sendEvent('Open Preview');
		// 		const oldEfairCode: string = localStorage.getItem(EFAIR_CODE);
		// 		if (oldEfairCode && oldEfairCode.toUpperCase() !== this.efairCode.toUpperCase()) {
		// 			this.authService.clearLocalStorage();
		// 		}
		// 		localStorage.setItem(EFAIR_CODE, this.efairCode.toUpperCase());
		//
		// 		this.router.navigate([`/guest/home`]);
		// 	}
		// } else {
		// 	this.dialogService.alert('Sorry', this.efairsKillswitch.customMessage, undefined, true);
		// }
	}

	public clickSignUp () : void {
		this.analyticsService.sendEvent('Click Sign Up Link');
	}

	public pasteCode (event: ClipboardEvent) : void {
		const clipboardData: DataTransfer = event.clipboardData;
		const code: string = clipboardData.getData('text').trim().toLocaleLowerCase();
		let matches: Array<string> = code.match(/http.*?classcode=(.*)/);

		if (!matches) {
			matches = code.match(/http.*?efaircode=(.*)/);
		}

		if (matches && matches.length === 2) {
			this.cleanup.push(timer(0).subscribe(() => {
				this.efairCode = matches[1].toLocaleUpperCase();
			}));
		} else {
			this.cleanup.push(timer(0).subscribe(() => {
				this.efairCode = code.toLocaleUpperCase();
			}));
		}
	}
}

export { SplashComponent };
