import { timer, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { fade } from '../../shared/animations/fade';
import { BaseComponent } from '../../base.component';
import { MobileService } from '../../services/helpers/mobile.service';

const TIMER_LENGTH: number = 3;
@Component({
	selector: 'undo-timer',
	templateUrl: './undo.timer.html',
	styleUrls: ['./undo.timer.css'],
	animations: [fade]
})

class UndoTimerComponent extends BaseComponent implements OnChanges {
	@Input() public showTimer: boolean;
	@Input() public tutorial: boolean;
	@Output() public showTimerChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() public deleteBook: EventEmitter<any> = new EventEmitter<any>();
	public isMobile: boolean;
	public time: number;
	private timerObject: Subscription;

	constructor (private analyticsService: AnalyticsService, private mobileService: MobileService) {
		super();
	}

	public ngOnChanges () : void {
		this.isMobile = this.mobileService.isMobile();
		if (this.showTimer) {
			this.startTimer();
		}
	}

	public startTimer () : void {
		this.time = TIMER_LENGTH;
		this.timerObject = timer(1000, 1000).pipe(take(this.time)).subscribe(() => {
			this.time--;
			if (this.time <= 0) {
				this.deleteBook.emit();
				this.showTimerChange.emit(false);
				this.timerObject.unsubscribe();
			}
		});
		this.cleanup.push(this.timerObject);
	}

	public onCancelClicked (event: Event) : void {
		event.stopPropagation();
		if (!this.tutorial) {
			this.analyticsService.sendEvent('Delete Book Cancel', this.time + ' Seconds Remaining.');
			this.showTimerChange.emit(false);
			this.timerObject.unsubscribe();
		}
	}

}

export { UndoTimerComponent };
