import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TitleDetailsService } from '../../../services/helpers/title.details.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { AbstractBookCarouselComponent } from '../abstract.book.carousel/abstract.book.carousel';
import { carouselSlide } from '../../animations/slide';
import { BookListArchiveModalComponent } from '../../../teacher/book.list.archive.modal/book.list.archive.modal';
import { UserService } from '../../../services/apis/user/user.service';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { ActualBookCarouselComponent } from '../actual.book.carousel/actual.book.carousel';
import { RecommendedListService, RecommendedItem } from '../../../services/apis/recommended.list/recommended.list.service';
import { Book, BookService } from '../../../services/apis/book/book.service';
import { BookFilterService } from '../../../services/helpers/book.filter.service';
import { BookViewType } from '../../book/book';
import { MobileService } from '../../../services/helpers/mobile.service';
import { ModalService } from '../../../services/helpers/modal.service';

@Component({
	selector: 'recommended-list-carousel',
	templateUrl: './recommended.list.carousel.html',
	styleUrls: ['../abstract.book.carousel/abstract.book.carousel.css'],
	animations: [carouselSlide]
})

class RecommendedListCarouselComponent extends AbstractBookCarouselComponent implements OnInit {
	public teacherUserId: string;
	public nickname: string;
	public isFilteringBooks: boolean;
	public isFilterApplied: boolean;
	public isTeacher: boolean;
	@Input() public showArchive: boolean;
	@Input() public schoolwide: boolean;
	@ViewChild(ActualBookCarouselComponent, {static: false}) public carousel: ActualBookCarouselComponent;
	public BookViewType: typeof BookViewType = BookViewType;

	constructor (private modalService: ModalService, private titleDetailsService: TitleDetailsService,
							 private analyticsService: AnalyticsService, private userService: UserService, private recommendedListService: RecommendedListService,
							 private bookService: BookService, private bookFilterService: BookFilterService, protected mobileService: MobileService) {
		super(mobileService);
	}

	public ngOnInit () : void {
		this.nickname = this.userService.getTeacher().teacherNickName;
		this.teacherUserId = this.userService.getTeacher()._id;
		this.isTeacher = this.userService.isTeacher();

		if (this.isTeacher) {
			this.cleanup.push(this.recommendedListService.onListUpdate.subscribe((recommendedItems: Array<RecommendedItem>) => {
				this.loadBooks(recommendedItems);
			}));
		}

		this.cleanup.push(this.titleDetailsService.updatePositionInCarousel.subscribe((response: any) => {
			if (!response.id && this.carousel) {
				this.carousel.goToCarouselPageFromTitleDetails(response);
			}
		}));

		this.cleanup.push(this.bookFilterService.onFilterChange.subscribe(() => {
			this.fetchBooks();
		}));

		super.ngOnInit();
	}

	private loadBooks (recommendedItems: Array<RecommendedItem>) : void {
		this.isFilteringBooks = false;
		this.isFilterApplied = this.bookFilterService.isFiltering();
		if (this.userService.isGuest()) {
			this.cleanup.push(this.recommendedListService.cacheBooksForGuest(this.teacherUserId).subscribe(() => {
				const loadingBook_ids: Array<string> = [];
				for (const item of recommendedItems) {
					if (!item.archived) {
						loadingBook_ids.push(item.book_id);
					}
				}
				this.book_ids = loadingBook_ids.filter((book_id: string) => {
					const book: Book = this.bookService.getCachedBook(book_id);
					const shouldShowBook: boolean = this.bookFilterService.shouldShowBookByAvailabilityAndCoverUrl(book);
					return shouldShowBook;
				});

				if (this.book_ids.length === 0) {
					this.loaded.emit();
				}

			}));
		} else {
			this.cleanup.push(this.recommendedListService.cacheBooks().subscribe(() => {
				const loadingBook_ids: Array<string> = [];
				for (const item of recommendedItems) {
					const book: Book = this.bookService.getCachedBook(item.book_id);
					if (!item.archived && book) {
						loadingBook_ids.push(item.book_id);
					}
				}
				// this adds a placeholder book in the front for the 'add book' button
				this.book_ids = loadingBook_ids.filter((book_id: string) => {
					const book: Book = this.bookService.getCachedBook(book_id);
					const shouldShowBook: boolean = this.bookFilterService.shouldShowBook(book);
					this.isFilteringBooks = this.isFilteringBooks || !shouldShowBook;
					return shouldShowBook;
				});

				if (this.book_ids.length === 0) {
					this.loaded.emit();
				}
			}));
		}

	}

	protected fetchBooks () : void {
		const recommendedItems: Array<RecommendedItem> = this.recommendedListService.getRecommendedList();
		this.loadBooks(recommendedItems);
	}

	public showArchiveModal () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg archive-modal'
		};
		this.analyticsService.sendEvent('View Archive');
		this.modalService.show(BookListArchiveModalComponent, config);
	}

}

export { RecommendedListCarouselComponent };
