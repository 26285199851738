import { Component, AfterViewInit } from '@angular/core';
import { BookFilterService } from '../../../services/helpers/book.filter.service';
import { BsModalRef } from 'ngx-bootstrap';
import { Options } from 'ng5-slider';
import { BaseComponent } from '../../../base.component';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { ModalService } from '../../../services/helpers/modal.service';

@Component({
	selector: 'book-filter-modal',
	templateUrl: './book.filter.modal.html',
	styleUrls: ['./book.filter.modal.css']
})

class BookFilterModalComponent extends BaseComponent implements AfterViewInit {
	public priceMin: number;
	public priceMax: number;
	public priceOptions: Options;

	public gradeMin: number;
	public gradeMax: number;
	public gradeOptions: Options;

	public myPriceOptions: Options;
	public myGradeOptions: Options;

	constructor (public modal: BsModalRef, private bookFilterService: BookFilterService, private analyticsService: AnalyticsService, private modalService: ModalService) {
		super();
	}

	public ngAfterViewInit () : void {
		this.priceMax = this.bookFilterService.priceMax || this.priceOptions.ceil;
		this.priceMin = this.bookFilterService.priceMin || this.priceOptions.floor;
		this.myPriceOptions = this.priceOptions;

		this.gradeMax = (this.bookFilterService.gradeMax || this.bookFilterService.gradeMax === 0) ? this.bookFilterService.gradeMax : this.gradeOptions.ceil;
		this.gradeMin = (this.bookFilterService.gradeMin || this.bookFilterService.gradeMin === 0) ? this.bookFilterService.gradeMin : this.gradeOptions.floor;
		this.myGradeOptions = this.gradeOptions;
		this.cleanup.push(this.modalService.onHide.subscribe(() => {
			this.bookFilterService.setPriceFilter(this.priceMin, this.priceMax);
			this.bookFilterService.setGradeFilter(this.gradeMin, this.gradeMax);
			this.bookFilterService.applyFilters();
			this.analyticsService.sendEvent('Filters Modal Apply Filters', `Price: $${this.priceMin}-$${this.priceMax} Grade: ${this.gradeMin}-${this.gradeMax}`);
		}));
	}

	public hideModal () : void {
		this.modal.hide();
	}
}

export { BookFilterModalComponent };
