import { ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import { AnalyticsService } from './services/helpers/analytics.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppVersionService } from './services/helpers/app.version.service';
import { Log } from './services/helpers/log.service';
import { ModalService } from './services/helpers/modal.service';

const chunkFailedMessage: RegExp = /Loading chunk [\d]+ failed/;

@Injectable()
class GlobalErrorHandler extends ErrorHandler {
	constructor (private analyticsService: AnalyticsService, private injector: Injector, private ngZone: NgZone) {
		super();
	}

	public handleError (error: any) : void {
		let handled: boolean = false;

		if (error instanceof HttpErrorResponse) {
			Log.log(`Caught ${error.status} error accessing ${error.url} ${JSON.stringify(error)}`);
			this.analyticsService.sendException(`Caught ${error.status} error accessing ${error.url}`, true);
			const modalService: ModalService = this.injector.get(ModalService);
			modalService.hideAll();

			this.ngZone.run(() => {
				this.injector.get(Router).navigate(['/error']);
			});
			handled = true;
		}
		if (chunkFailedMessage.test(error.message)) {
			this.ngZone.run(() => {
				this.injector.get(AppVersionService).showAppOutOfDateModal();
			});
			handled = true;
		}
		if (!handled) {
			this.analyticsService.sendException('Caught unhandled error: ' + error.stack, true);
			super.handleError(error);
		}
	}
}

export { GlobalErrorHandler };
