import { Injectable } from '@angular/core';

@Injectable()
class WindowRef {

	 public getWindow () : Window {
		 return window;
	 }

}

export { WindowRef };
