import { Component } from '@angular/core';
import { BookClubService } from '../../services/apis/book.club/book.club.service';
import { UserService } from '../../services/apis/user/user.service';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { LiveChatService } from '../../services/helpers/live.chat.service';
import { WindowRef } from '../../utils/window.ref';
import { HelpMenuDropdownComponent } from '../help.menu.dropdown/help.menu.dropdown';

@Component({
	selector: 'copyright-footer',
	templateUrl: './copyright.footer.html',
	styleUrls: ['./copyright.footer.css']
})

class CopyrightFooterComponent extends HelpMenuDropdownComponent {
	constructor (analyticsService: AnalyticsService, liveChatService: LiveChatService, userService: UserService, windowRef: WindowRef, bookClubService: BookClubService) {
		super(analyticsService, liveChatService, userService, windowRef, bookClubService);
	}

	public copyrightYear: number = new Date().getFullYear();
}

export { CopyrightFooterComponent };
