interface Language {
	fullName: string;
	code: string;
}

enum BookLanguageIsoCodes {
	ENGLISH = 'eng',
	SPANISH = 'spa',
	FRENCH = 'fre',
	MULTIPLE = 'mul'
}

const BOOK_LANGUAGES: Map<string, Language> = new Map<string, Language>()
.set(BookLanguageIsoCodes.ENGLISH, {
	fullName: 'English',
	code: 'EN',
})
.set(BookLanguageIsoCodes.SPANISH, {
	fullName: 'Spanish',
	code: 'ES'
})
.set(BookLanguageIsoCodes.FRENCH, {
	fullName: 'French',
	code: 'FR'
})
.set(BookLanguageIsoCodes.MULTIPLE, {
	fullName: 'Multiple',
	code: 'M'
});

export { BOOK_LANGUAGES, BookLanguageIsoCodes, Language };
