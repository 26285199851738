import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order, OrderItem, OrderService } from '../../services/apis/order/order.service';
import { Displayables } from '../../utils/displayables';
import { BaseComponent } from '../../base.component';
import { SharedGuardService } from '../shared.guard.service';
import { AuthService } from '../../services/apis/auth/auth.service';
import { of, Subject, timer } from 'rxjs';
import { BookClubService, BookClub } from '../../services/apis/book.club/book.club.service';
import { Address } from '../../services/apis/school/school.service';
import { EFAIR_CODE } from '../../constants/local.storage';
import { ALASKA_ZIP_RANGE, HAWAII_ZIP_RANGE, MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING, SHIPPING_CHARGE_BASE_AMOUNT, SHIPPING_CHARGE_PER_BOOK } from '../../constants/orders';
import { Book, BookService } from '../../services/apis/book/book.service';
import { DateHelper } from '../../utils/date.helper';
import { NotYetPublishedHelper } from '../../services/helpers/not.yet.published.helper';

@Component({
	selector: 'order-summary',
	templateUrl: './order.summary.html',
	styleUrls: ['./order.summary.css']
})

class OrderSummaryComponent extends BaseComponent implements OnInit {
	public static nypDeliveryRangeStart: number = 10;
	public static nypDeliveryRangeEnd: number = 12;

	public order: Order;
	public loadingMap: Map<string, boolean>;
	public displayables: Displayables = Displayables;
	public dateHelper: DateHelper = DateHelper;
	public bookClub: BookClub;
	public hasTeacherWishlistItems: boolean;
	public noClub: boolean;
	public noUser: boolean;

	public isShipToHomeEfair: boolean;
	public firstBookShipping: number;
	public additionalBookShipping: number;
	public freeShippingThreshold: number;
	public userLoading: Subject<boolean>;

	constructor (private route: ActivatedRoute, private orderService: OrderService, private sharedGuardService: SharedGuardService, private authService: AuthService, private bookClubService: BookClubService,
		private router: Router, private bookService: BookService) {
		super();
	}

	public ngOnInit () : void {
		this.loadingMap = new Map();
		this.bookClub = this.bookClubService.getCachedBookClub();

		if (!this.bookClub) {
			this.loadingMap.set('bookClub', true);
			this.cleanup.push(this.bookClubService.onBookClubLoaded.subscribe((bookClub: BookClub) => {
				this.bookClub = bookClub;
				this.noClub = !bookClub;
				if (this.bookClub) {
					this.isShipToHomeEfair = this.bookClub.settings && this.bookClub.settings.shipToHome;
					this.loadingMap.delete('bookClub');
					this.addNypInfoToOrderItems();
				} else if (this.order) {
					// if the order has not yet loaded, then set noClub so that when the order does load, we can navigate to orderStatus
					// if the order has loaded already, then we can navigate to orderStatus
					if (this.authService.isTeacherSession()) {
						this.cleanup.push((this.userLoading || of(true)).subscribe(() => {
							this.router.navigate([`teacher/orders/status`], { queryParams: { orderNumber: this.order.twResponse.reqid } });
						}));
					} else {
						this.router.navigate([`/orderStatus`], { queryParams: { orderNumber: this.order.twResponse.reqid } });
					}
				}
			}));

		} else {
			this.isShipToHomeEfair = this.bookClub.settings && this.bookClub.settings.shipToHome;
		}
		const efairCode: string = localStorage.getItem(EFAIR_CODE);

		this.cleanup.push(this.route.params.subscribe((params: any) => {
			const order_id: string = params['order_id'];
			this.loadingMap.set('order', true);
			this.cleanup.push(this.orderService.get(order_id).subscribe((order: any) => {
				this.order = order;
				if ((!efairCode && !this.authService.isAuthenticated()) || this.noClub) {
					this.router.navigate([`/orderStatus`], { queryParams: { orderNumber: this.order.twResponse.reqid } });
				}

				this.calculateShipping();

				this.hasTeacherWishlistItems = !!this.order.items.find((item: OrderItem) => {
					return item.fromTeacherWishlist;
				});

				this.loadingMap.delete('order');
			}));
		}));

		if (efairCode || this.authService.isAuthenticated()) {
			this.cleanup.push(timer(0).subscribe(() => {
				this.userLoading = new Subject();
				this.cleanup.push(this.sharedGuardService.canActivate(this.route.snapshot).subscribe(() => {
					this.userLoading.next(true);
				}));
			}));
		}
	}

	public calculateShipping () : void {
		if (this.order.shippingAddress) {
			const address: Address = this.order.shippingAddress;
			const zipCode: number = parseInt(address.zip.substring(0, 5), 10);
			if (address.state === 'AK' || (zipCode >= ALASKA_ZIP_RANGE.MIN && zipCode <= ALASKA_ZIP_RANGE.MAX) || address.state === 'HI' || (zipCode >= HAWAII_ZIP_RANGE.MIN && zipCode <= HAWAII_ZIP_RANGE.MAX)) {
				this.firstBookShipping = SHIPPING_CHARGE_BASE_AMOUNT.ALASKA_HAWAII;
				this.additionalBookShipping = SHIPPING_CHARGE_PER_BOOK.ALASKA_HAWAII;
				this.freeShippingThreshold = MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING.ALASKA_HAWAII;
			} else {
				this.firstBookShipping = SHIPPING_CHARGE_BASE_AMOUNT.CONTINENTAL_US;
				this.additionalBookShipping = SHIPPING_CHARGE_PER_BOOK.CONTINENTAL_US;
				this.freeShippingThreshold = MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING.CONTINENTAL_US;
			}
		}
	}

	public addNypInfoToOrderItems () : void {
		const bookIds: Array<string> = new Array<string>();
		for ( const item of this.order.items ) {
			bookIds.push(item.book_id);
		}
		this.cleanup.push( this.bookService.cacheMissingBooks(bookIds).subscribe(() => {
				for ( const i of this.order.items ) {
					const book: Book = this.bookService.getCachedBook(i.book_id);
					if ( book && NotYetPublishedHelper.isNypBook(book, this.bookClub)) {
						i.isNYP = book.isNYP;
						i.nypDate = book.nypDate;
						i.nypEstimatedDelivery = DateHelper.getDeliveryDate(book.nypDate, OrderSummaryComponent.nypDeliveryRangeStart, OrderSummaryComponent.nypDeliveryRangeEnd);
					}
				}
		}));
	}
}

export { OrderSummaryComponent };
