import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { Observable } from 'rxjs';
import { BookClub, BookClubWithExtras } from '../book.club/book.club.service';
import { Order } from '../order/order.service';

class ShipmentInfo {
	public trackingNumber: string;
	public trackingUrl: string;
	public carrierCode: string;
	public mainframeOrderNumber: string;
	public shipmentDate: Date;
	public isMarketingKit: boolean;
}
class BookClubArchive extends BookClub {
	public totalSpent?: number;
	public totalTax?: number;
	public mainframeFile?: any;
	public recommendedBook_ids?: Array<string>;
	public numberOfStudents?: number;
	public conversionRate?: string;
	public orders?: Array<Order>;
}

interface BookClubArchiveWithExtras extends BookClubWithExtras {
	numOrders?: number;
}
@Injectable()
class BookClubArchiveService extends BasicCrudService<BookClubArchive> {

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/bookclubsArchive');
	}

	public getPrintableDistributionList (bookClub_id: string) : Observable<any> {
		return this.http.get(`${this.url}/distlist/${bookClub_id}`);
	}

	public adminSearch (searchTerm?: any, endDateFrom?: Date) : Observable<Array<BookClub>> {
		const params: Array<string> = [];
		if (searchTerm) {
			params.push(`searchTerm=${encodeURIComponent(searchTerm)}`);
		}
		if (endDateFrom) {
			params.push(`endDateFrom=${encodeURIComponent(endDateFrom.toString())}`);
		}
		let paramString: string;
		if (params.length > 0) {
			paramString = '?' + params.join('&');
		}
		// dh - angular { params: xx } filters out the character '+' for reasons, so i was unable to search by email: fscdwayne2+1@gmail.com
		// https://stackoverflow.com/questions/49438737/how-to-escape-angular-httpparams
		// there are a few solutions. this seemed like the easiest for now.
		return this.http.get<Array<BookClub>>(`${this.url}/adminSearch${paramString || ''}`);
	}

}

export { ShipmentInfo, BookClubArchiveService, BookClubArchive, BookClubArchiveWithExtras };
