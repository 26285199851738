import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { BookService, Book } from '../book/book.service';
import { Wishlist } from './edit.wishlist.service';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SELECTED_WISHLIST_ID, VIEW_WISHLIST_IDS_LOOKUP } from '../../../constants/local.storage';


@Injectable()
class ViewWishlistService extends BasicCrudService<Wishlist> {

	public wishlists: Array<Wishlist> = [];
	public teacherWishlists: Array<Wishlist> = [];
	public selectedWishlistId: string;
	public onWishlistLoaded: Subject<void> = new Subject();

	constructor (http: HttpClient, private bookService: BookService) {
		super(http);
		this.setUrl(`/server/api/wishlists`);
	}

	public findByCode (wishlistCode: string) : Observable<Wishlist> {
		return this.http.get<Wishlist>(`${this.url}?code=${wishlistCode}`);
	}

	public getCachedWishlists () : Array<Wishlist> {
		return this.wishlists;
	}

	public getCachedTeacherWishlists () : Array<Wishlist> {
		return this.teacherWishlists;
	}

	public getCachedWishlist (_id?: string) : Wishlist {
		let result: Wishlist = this.wishlists.find((wishlist: Wishlist) => {
			return wishlist._id === (_id || this.selectedWishlistId);
		});
		if (!result) {
			result = this.teacherWishlists.find((wishlist: Wishlist) => {
				return wishlist._id === (_id || this.selectedWishlistId);
			});
		}
		return result;
	}

	public reset () : void {
		this.wishlists = [];
		this.selectedWishlistId = undefined;
	}

	public cacheWishlists (wishlists: Array<Wishlist>) : void {
		const wishlistMap: Map<string, Wishlist> = new Map();
		this.wishlists.map( (existing: Wishlist) => wishlistMap.set(existing._id, existing) );

		wishlists.map( (update: Wishlist) => wishlistMap.set(update._id, update) );

		this.wishlists = Array.from(wishlistMap.values());

		if (!this.selectedWishlistId && this.wishlists.length > 0) {
			const selectedWishlistIdFromLS: string = localStorage.getItem(SELECTED_WISHLIST_ID);
			if (Array.from(wishlistMap.keys()).includes(selectedWishlistIdFromLS)) {
				this.selectedWishlistId = selectedWishlistIdFromLS;
			} else {
				this.selectedWishlistId = this.wishlists[0]._id;
			}
		}

		localStorage.setItem(VIEW_WISHLIST_IDS_LOOKUP, JSON.stringify(Array.from(wishlistMap.keys())));
	}

	public addWishlist (wishlist: Wishlist) : void {
		this.selectedWishlistId = wishlist._id;
		if (!this.wishlists.find((currentWishlist: Wishlist) => {
			return currentWishlist._id === wishlist._id;
		})) {
			this.wishlists.push(wishlist);
		}

		const localStorageIds: Array<string> = JSON.parse(localStorage.getItem(VIEW_WISHLIST_IDS_LOOKUP));
		const wishlistIds: Set<string> = new Set(localStorageIds);
		wishlistIds.add(wishlist._id);
		localStorage.setItem(VIEW_WISHLIST_IDS_LOOKUP, JSON.stringify(Array.from(wishlistIds.values())));
		this.onWishlistLoaded.next();
	}

	public cacheTeacherWishlists (bookClub_id: string) : Observable<Array<Wishlist>> {
		return this.getTeacherWishlistsForBookClub(bookClub_id).pipe(mergeMap((wishlists: Array<Wishlist>) => {
			this.teacherWishlists = wishlists;
			const selectedWishlistIdFromLS: string = localStorage.getItem(SELECTED_WISHLIST_ID);
			const isInStudentWishlists: boolean = !!this.wishlists.find((wishlist: Wishlist) => wishlist._id === selectedWishlistIdFromLS);
			const isInTeacherWishlists: boolean = !!this.teacherWishlists.find((wishlist: Wishlist) => wishlist._id === selectedWishlistIdFromLS);
			if (!isInStudentWishlists && isInTeacherWishlists) {
				this.selectedWishlistId = selectedWishlistIdFromLS;
			}
			return this.cacheMissingBooksForTeacherWishlists(bookClub_id).pipe(map(() => {
				return wishlists;
			}));
		}));
	}

	public cacheMissingBooksForTeacherWishlists (bookClub_id: string) : Observable<boolean> {
		return this.http.get<Array<Book>>(`${this.url}/teacherWishlists/${bookClub_id}/books`).pipe(mergeMap((books: Array<Book>) => {
			return this.bookService.cacheBooks(books);
		}));
	}

	public cacheMissingBooksForWishlist (wishlist_id: string) : Observable<boolean> {
		return this.http.get<Array<Book>>(`${this.url}/${wishlist_id}/books`).pipe(mergeMap((books: Array<Book>) => {
			return this.bookService.cacheBooks(books);
		}));
	}

	public getTeacherWishlistsForBookClub (bookClub_id: string) : Observable<Array<Wishlist>> {
		return this.http.get<Array<Book>>(`${this.url}/teacherWishlists/${bookClub_id}`);
	}

}

export { ViewWishlistService };
