import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IOrderItemStatus, IOrderStatus, OrderService } from '../../services/apis/order/order.service';
import { Displayables } from '../../utils/displayables';
import { BaseComponent } from '../../base.component';
import { catchError, finalize } from 'rxjs/operators';
import { NEVER, timer } from 'rxjs';
import * as moment from 'moment';
import * as momentBusiness from 'moment-business-days';
import { WindowRef } from '../../utils/window.ref';
import { BookClubStatus } from '../../constants/book.club.status';
import { DateHelper } from '../../utils/date.helper';

@Component({
	selector: 'order-status',
	templateUrl: './order.status.html',
	styleUrls: ['./order.status.css']
})

class OrderStatusComponent extends BaseComponent implements OnInit, AfterViewInit {
	public orderNumber: string;
	public lastOrderNumber: string;
	public order: IOrderStatus;
	public loading: boolean;
	public displayables: Displayables = Displayables;
	public notFound: boolean;
	public editing: boolean = true;
	public orderStatus: string;
	public estimatedDelivery: string;
	public orderDate: string;
	public bookClubEndDate: string;

	@ViewChild('orderNumberInput', { static: false }) public orderNumberInput: ElementRef;

	constructor (private route: ActivatedRoute, private orderService: OrderService, private windowRef: WindowRef) {
		super();
	}

	public ngOnInit () : void {
		this.cleanup.push(this.route.queryParams.subscribe((params: any) => {
			this.orderNumber = params['orderNumber'];
			this.fetchOrder();
		}));

	}

	public ngAfterViewInit () : void {
		this.focusOrderNum();
	}

	public edit () : void {
		this.editing = true;
		if (this.orderNumber) {
			this.lastOrderNumber = this.orderNumber;
			this.orderNumber = null;
		}
		this.focusOrderNum();
	}

	public unEdit () : void {
		if (!this.orderNumber && this.lastOrderNumber) {
			this.orderNumber = this.lastOrderNumber;
		}
		if (this.alreadyLookingAtThisOrder()) {
			this.editing = false;
		}
		this.lastOrderNumber = null;
	}

	public focusOrderNum () : void {
		this.cleanup.push(timer(0).subscribe(() => {
			if (this.orderNumberInput) {
				this.orderNumberInput.nativeElement.focus();
			}
		}));
	}

	public fetchOrder () : void {
		if (this.orderNumber && this.orderNumber.trim().length > 0) {
			this.order = undefined;
			this.notFound = false;
			this.loading = true;
			this.windowRef.getWindow().history.pushState(undefined, undefined, `/orderStatus?orderNumber=${this.orderNumber}`);
			this.cleanup.push(this.orderService.findStatusByOrderNumber(this.orderNumber.trim()).pipe(finalize(() => {
				this.loading = false;
				this.editing = !this.order;
			})).pipe(catchError(() => {
				this.notFound = true;
				return NEVER;
			})).subscribe((order: IOrderStatus) => {
				if (order) {
					this.order = order;
					this.orderDate = moment(this.order.created).format('MMM Do YYYY');
					this.bookClubEndDate = moment(this.order.bookClubEndDate).format('MMM Do YYYY');
					this.figureOutOrderStatus();
					this.figureOutEstimatedDelivery();
				}
			}));
		}
	}

	public alreadyLookingAtThisOrder () : boolean {
		let result: boolean = true;
		result = result && !!this.orderNumber;
		result = result && !!this.order;
		result = result && !!this.order.reqid;
		result = result && this.orderNumber.toUpperCase().trim().replace('EF', '') === this.order.reqid;
		return result;
	}

	public figureOutOrderStatus () : void {
		if (this.order.shipmentInfo && this.order.shipmentInfo.length > 0) {
			this.orderStatus = this.order.shipToHome ? 'Shipped' : 'Shipped to school';
		} else if (this.order.shipToHome) {
			this.orderStatus = this.order.reqid ? 'Processing' : 'Created';
		} else {
			this.orderStatus = this.order.bookClubStatus === BookClubStatus.COMPLETED ? 'Processing' : 'Waiting for eFair to end';
		}
	}

	public figureOutEstimatedDelivery () : void {
		this.estimatedDelivery = undefined;
		if (!this.order.shipmentInfo || this.order.shipmentInfo.length === 0) {
			if ( !this.orderContainsOnlyNypTitles( this.order.items ) ) {
				const startDate: Date = this.order.shipToHome ? this.order.created : this.order.bookClubEndDate;
				const estimatedStart: momentBusiness.Moment = momentBusiness(startDate).businessAdd(10);
				const estimatedEnd: momentBusiness.Moment = momentBusiness(startDate).businessAdd(12);
				this.estimatedDelivery = `${estimatedStart.format('MMM Do')} - ${estimatedEnd.format('MMM Do YYYY')}`;
			}
		}
	}

	public orderContainsOnlyNypTitles ( items: Array<IOrderItemStatus> ) : boolean {
		let onlyNypTitles: boolean = true;
		if ( items && items.length > 0 ) {
			for ( const item of items ) {
				if ( !item.isNYP ) {
					onlyNypTitles = false;
				}
			}
			return onlyNypTitles;
		} else {
			return false;
		}
	}

	public getNypDeliveryDate (item: IOrderItemStatus) : string {
		let deliveryDate: string = null;
		if ( item.isNYP && item.nypDate ) {
			deliveryDate = DateHelper.getDeliveryDate(item.nypDate, 10, 12);
		}
		return deliveryDate;
	}
}

export { OrderStatusComponent };
