import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'error',
	templateUrl: './error.html',
	styleUrls: ['./error.css']
})
class ErrorComponent implements OnInit {

	constructor (private router: Router) {
	}

	public ngOnInit () : void {
	}

	public goHome () : void {
		this.router.navigate(['/']);
	}
}

export { ErrorComponent };
