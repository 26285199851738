import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

const EMAIL_REGEX: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Directive({
	selector: '[appEmail]',
	providers: [{provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true}]
})

class EmailValidatorDirective implements Validator {

	public validate (control: AbstractControl) : {[key: string]: any} | null {
		return this.validateEmail()(control);
	}

	private validateEmail () : ValidatorFn {
		return (control: AbstractControl) : {[key: string]: any} | null => {
			const match: boolean = EMAIL_REGEX.test(control.value);
			return match ? null : {'email': {value: control.value}};
		};
	}
}

export { EmailValidatorDirective };
