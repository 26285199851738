import { Injectable } from '@angular/core';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Address } from '../school/school.service';

class AddressResponse {
	public note: string;
	public address: Address;
}

@Injectable()
class AddressValidationService extends BasicCrudService<any> {
	constructor (http: HttpClient) {
		super(http);
		this.setUrl(`/server/api/addressValidation`);
	}

	public validateAddress (address: Address) : Observable<any> {
		return this.http.post(`${this.url}`, { address: address });
	}
}

export { AddressValidationService, AddressResponse };
