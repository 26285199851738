import { Component, Input } from '@angular/core';

@Component({
	selector: 'recommended-ribbon',
	templateUrl: './recommended.ribbon.html',
	styleUrls: ['./recommended.ribbon.css']
})

class RecommendedRibbonComponent {

	@Input() public small: boolean;
	@Input() public right: boolean;

}

export { RecommendedRibbonComponent };
