import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { CartItemRecipient, CartService } from '../../services/apis/cart/cart.service';
import { BaseComponent } from '../../base.component';
import { BookClubService, BookClub } from '../../services/apis/book.club/book.club.service';
import { Staff } from '../../services/apis/school/school.service';
import { Displayables } from '../../utils/displayables';
import { NgForm, Validators } from '@angular/forms';
import { RequiredNonWhiteSpaceValidator } from '../required.non.whitespace.validator/required.non.whitespace.validator';
import { timer } from 'rxjs';
import { EfairType } from '../../constants/efairs';

@Component({
	selector: 'update-cart-recipient-modal',
	templateUrl: './update.cart.recipient.modal.html',
	styleUrls: ['./update.cart.recipient.modal.css']
})

class UpdateCartRecipientModalComponent extends BaseComponent implements OnInit {
	@ViewChild('firstNameInput', { static: true }) public firstNameInput: ElementRef;
	@ViewChild('updateCartRecipientForm', {static: false}) public updateCartRecipientForm: NgForm;

	public onCancel: Function;
	public onSuccess: Function;

	public bookClub: BookClub;
	public firstName: string;
	public lastName: string;
	public selectedStaffMember: Staff;
	public staffList: Array<Staff>;
	public updatingRecipient: boolean;

	public readonly displayables: typeof Displayables = Displayables;
	public readonly EfairType: typeof EfairType = EfairType;

	constructor (private bsModalRef: BsModalRef, private cartService: CartService, private analyticsService: AnalyticsService, private bookclubService: BookClubService) {
		super();
	}

	public ngOnInit () : void {
		this.bookClub = this.bookclubService.getCachedBookClub();

		this.cleanup.push(timer(0).subscribe(() => {
			if (this.firstNameInput) {
				this.firstNameInput.nativeElement.focus();
			}
			this.updateCartRecipientForm.controls['firstName'].setValidators([Validators.required, RequiredNonWhiteSpaceValidator]);
			this.updateCartRecipientForm.controls['lastName'].setValidators([Validators.required, RequiredNonWhiteSpaceValidator]);
		}));
	}

	public clickCancel () : void {
		this.analyticsService.sendEvent('Update Cart Recipient Cancel');
		this.bsModalRef.hide();
		if (this.onCancel) {
			this.onCancel();
		}
	}

	public updateRecipient () : void {
		this.analyticsService.sendEvent('Update Cart Recipient');
		this.updatingRecipient = true;

		const newRecipient: CartItemRecipient = { firstName: this.firstName, lastName: this.lastName, teacherName: this.selectedStaffMember, fromTeacherWishlist: false };

		this.cleanup.push(this.cartService.updateRecipientForAllCartItems(newRecipient).subscribe(() => {
			this.updatingRecipient = false;
			this.bsModalRef.hide();
			if (this.onSuccess) {
				this.onSuccess();
			}
		}, () => {
			this.updatingRecipient = false;
			this.bsModalRef.hide();
		}));
	}

	public updateStaffMember (teacherName: Staff) : void {
		this.selectedStaffMember = teacherName;
	}
}

export { UpdateCartRecipientModalComponent };
