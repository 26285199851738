import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { Observable, throwError, ObservableInput, NEVER } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../services/apis/auth/auth.service';
import { WindowRef } from '../../utils/window.ref';
import { UserRole } from '../../constants/user';

@Injectable()
class UnauthorizedInterceptor implements HttpInterceptor {
	constructor (private analyticsService: AnalyticsService, private injector: Injector, private windowRef: WindowRef) { }

	public intercept (request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err: HttpErrorResponse) : ObservableInput<any> => {
				if (err.status === 401 && this.windowRef.getWindow().location.pathname !== '/') {
					this.analyticsService.sendEvent('Unauthorized Access');

					// NOTE: Inject authService here as it seems as though if it's injected in the constructor, we'll instantiate a new AuthService
					//       which ends up causing an infinite loop (as it will call out to the server via Angular which creates an HTTP request, which
					//        recreates this interceptor which recreates AuthService, etc., etc)
					const authService: AuthService = this.injector.get(AuthService);
					authService.login(UserRole.TEACHER);
					return NEVER;
				}
				return throwError(err);
			}));
	}
}

export { UnauthorizedInterceptor };
