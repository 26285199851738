// library imports
import { NgModule } from '@angular/core';

import { AddressValidationService } from './apis/address.validation/address.validation.service';
import { AuthService } from './apis/auth/auth.service';
import { BookClubService } from './apis/book.club/book.club.service';
import { BookClubArchiveService } from './apis/book.club.archive/book.club.archive.service';
import { BookService } from './apis/book/book.service';
import { CartService } from './apis/cart/cart.service';
import { ConfigService } from './apis/config/config.service';
import { EmailService } from './apis/email/email.service';
import { NextbeeService } from './apis/nextbee/nextbee.service';
import { OrderService } from './apis/order/order.service';
import { RecommendedListService } from './apis/recommended.list/recommended.list.service';
import { SchoolService } from './apis/school/school.service';
import { TitlewaveService } from './apis/titlewave/titlewave.service';
import { UserService } from './apis/user/user.service';

import { AnalyticsService } from './helpers/analytics.service';
import { DialogService } from './helpers/dialog.service';
import { SidebarToggleService } from './helpers/sidebar.toggle.service';
import { TitleDetailsService } from './helpers/title.details.service';
import { MobileService } from './helpers/mobile.service';
import { BookFilterService } from './helpers/book.filter.service';
import { AppVersionService } from './helpers/app.version.service';
import { LiveChatService } from './helpers/live.chat.service';
import { WhitelistService } from './apis/whitelist/whitelist.service';
import { SearchService } from './helpers/search.service';
import { JobScheduleService } from './apis/job.schedule/job.schedule.service';
import { SystemMessageService } from './apis/system.message/system.message.service';
import { SiteSettingsService } from './apis/site.settings/site.settings.service';
import { EditWishlistService } from './apis/wishlist/edit.wishlist.service';
import { ViewWishlistService } from './apis/wishlist/view.wishlist.service';
import { MarketingMaterialService } from './apis/marketing.material/marketing.material.service';
import { MarketingMaterialCategoryService } from './apis/marketing.material.category/marketing.material.category.service';
import { BookAvailabilityService } from './apis/book.availability/book.availability.service';
import { BookDiscountService } from './apis/book.discount/book.discount.service';
import { ModalService } from './helpers/modal.service';
import { UtilsService } from './helpers/utils.service';
import { CategoryImageService } from './apis/category.image/category.image.service';
import { BookReviewService } from './apis/book.review/book.review.service';
import { ScheduledBookClubService } from './apis/scheduled.book.club/scheduled.book.club.service';
import { NotYetPublishedHelper } from './helpers/not.yet.published.helper';

@NgModule({
	declarations: [
	],
	imports: [
	],
	entryComponents: [
	],
	providers: [
		AddressValidationService,
		AuthService,
		BookClubArchiveService,
		BookClubService,
		BookService,
		CartService,
		CategoryImageService,
		ConfigService,
		EmailService,
		OrderService,
		JobScheduleService,
		RecommendedListService,
		SearchService,
		SchoolService,
		TitlewaveService,
		UserService,
		NextbeeService,
		WhitelistService,
		SiteSettingsService,
		SystemMessageService,
		AnalyticsService,
		DialogService,
		SidebarToggleService,
		TitleDetailsService,
		MobileService,
		BookFilterService,
		AppVersionService,
		LiveChatService,
		EditWishlistService,
		ViewWishlistService,
		MarketingMaterialService,
		MarketingMaterialCategoryService,
		BookAvailabilityService,
		BookDiscountService,
		ModalService,
		UtilsService,
		BookReviewService,
		ScheduledBookClubService,
		NotYetPublishedHelper
	]
})

class ServiceModule { }

export { ServiceModule };
