import { FormControl, ValidatorFn } from '@angular/forms';

const RequiredNonWhiteSpaceValidator: ValidatorFn = (control: FormControl) => {
	let result: any = null;

	if (!control || !control.value || control.value.trim().length === 0) {
		result = { requireNonWhiteSpace: 'Value is empty.' };
	}

	return result;
};

export { RequiredNonWhiteSpaceValidator };
