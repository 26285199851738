enum BookClubStatus {
	CREATED = 'created',
	SCHEDULED = 'scheduled',
	PUBLISHED = 'published',
	STARTED = 'started',
	COMPLETED = 'completed',
	CANCELED_BY_USER = 'canceled_by_user',
	CANCELED_BY_FOLLETT = 'canceled_by_follett',
	AUTO_CANCELED = 'auto_canceled'
}

export { BookClubStatus };
