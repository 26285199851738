import { Component, OnDestroy, AfterViewInit, ViewChild, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TitleDetailsService } from '../../../services/helpers/title.details.service';
import { SwiperConfigInterface, SwiperComponent } from 'ngx-swiper-wrapper';
import { timer } from 'rxjs';
import { BaseComponent } from '../../../base.component';
import { CartItemRecipient } from '../../../services/apis/cart/cart.service';


@Component({
	selector: 'title-details-carousel-sm',
	templateUrl: './title.details.carousel.sm.html',
	styleUrls: ['./title.details.carousel.sm.css']
})

class TitleDetailsCarouselSmallComponent extends BaseComponent implements OnDestroy, AfterViewInit {
	public book_ids: Array<string>;
	public book_index: number;
	public swiperConfig: SwiperConfigInterface;
	@Input() public defaultCartItemRecipient: CartItemRecipient;
	@Input() public fromTeacherWishlist: boolean;
	@Input() public fromWishlist: boolean;
	@ViewChild('swiper', {static: false}) public swiper: SwiperComponent;

	constructor (public modal: BsModalRef, private titleDetailsService: TitleDetailsService) {
		super();
	}

	public ngAfterViewInit () : void {
		this.book_ids = this.titleDetailsService.getBook_ids();
		this.book_index = this.titleDetailsService.getIndex();
		this.swiperConfig = {
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 30,
			preventInteractionOnTransition: true,
			preventClicksPropagation: true
		};
		this.cleanup.push(timer(0).subscribe(() => {
			this.cleanup.push(this.swiper.indexChange.subscribe((index: number) => {
				this.book_index = index;
			}));
		}));
	}

	public ngOnDestroy () : void {
		super.ngOnDestroy();
		if (this.book_ids.length > 1) {
			this.titleDetailsService.setIndex(this.book_index);
			this.titleDetailsService.updateCarousel();
		}
	}

}

export { TitleDetailsCarouselSmallComponent };
