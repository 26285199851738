import { Component, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { AnalyticsService } from '../../../services/helpers/analytics.service';
import { RecommendedListService } from '../../../services/apis/recommended.list/recommended.list.service';
import { fade } from '../../animations/fade';
import { ActionButtonsComponent } from '../action.buttons';

@Component({
	selector: 'recommend-buttons',
	templateUrl: './recommend.buttons.html',
	styleUrls: ['../action.buttons.css'],
	animations: [fade]
})

class RecommendButtonsComponent extends ActionButtonsComponent implements OnInit, OnChanges {
	@Output() public displayCoverNotes: EventEmitter<void> = new EventEmitter<void>();

	constructor (analyticsService: AnalyticsService, private recommendedListService: RecommendedListService) {
		super(analyticsService);
	}

	public ngOnInit () : void {
		this.inList = this.recommendedListService.isInMyRecommendedList(this.book._id);
		this.cleanup.push(this.recommendedListService.onListUpdate.subscribe(() => {
			this.inList = this.recommendedListService.isInMyRecommendedList(this.book._id);
		}));
	}

	public ngOnChanges () : void {
		this.inList = this.recommendedListService.isInMyRecommendedList(this.book._id);
	}

	public recommend (event: Event) : void {
		this.stopProp(event);
		this.adding = true;
		this.analyticsService.sendEvent('Quick Button Recommend', '\'' + this.book.title + '\' Recommendation Note?:' + !!this.book.recommendationNote);
		this.cleanup.push(this.recommendedListService.addToRecommendedList(this.book).subscribe(() => {
			this.adding = false;
			this.showConfirmation = true;
		}));
	}

	public showRecommendationTextArea (event: any) : void {
		if (!this.inList || this.quickButton) {
			this.analyticsService.sendEvent('Book cover show recommendation text area');
			if (event) {
				event.stopPropagation();
			}
			this.displayCoverNotes.emit();
		}
	}

	public hideConfirmation (event: Event) : void {
		super.hideConfirmation(event);
		this.displayCoverNotes.emit();
	}
}

export { RecommendButtonsComponent };
