import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../../../base.component';
import { Displayables } from '../../../utils/displayables';

@Component({
	selector: 'wishlist-items-already-purchased-modal',
	templateUrl: './wishlist.items.already.purchased.modal.html',
	styleUrls: ['./wishlist.items.already.purchased.modal.css']
})
class WishlistItemsAlreadyPurchasedModalComponent extends BaseComponent {
	public items: Array<string>;
	public readonly Displayables: typeof Displayables = Displayables;

	constructor (public modal: BsModalRef) {
		super();
	}

	public hideModal () : void {
		this.modal.hide();
	}
}

export { WishlistItemsAlreadyPurchasedModalComponent };
