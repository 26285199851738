import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartItem, CartService, CartItemRecipient, Cart } from '../../services/apis/cart/cart.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { School, SchoolService, Address } from '../../services/apis/school/school.service';
import { UserInfoPromptComponent, USER_EMAIL, USER_NAME, USER_SHIPPING_ADDRESS } from './user.info.prompt/user.info.prompt';
import { UserService, User } from '../../services/apis/user/user.service';
import { OrderService, Order } from '../../services/apis/order/order.service';
import { BookClubService, BookClub } from '../../services/apis/book.club/book.club.service';
import { DialogService } from '../../services/helpers/dialog.service';
import { ERROR_KEYS } from '../../constants/error.keys';
import { ConfigService, Configuration } from '../../services/apis/config/config.service';
import { TitlewaveService } from '../../services/apis/titlewave/titlewave.service';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { BaseComponent } from '../../base.component';
import { ErrorCode } from '../../constants/error.codes';
import { WindowRef } from '../../utils/window.ref';
import { CartUnavailableItemsModalComponent } from './cart.unavailable.items.modal/cart.unavailable.items.modal';
import { SiteSettingsService, SiteSettings } from '../../services/apis/site.settings/site.settings.service';
import { Displayables } from '../../utils/displayables';
import { ErrorHelper } from '../../utils/error.helper';
import { EfairType } from '../../constants/efairs';
import { ModalService } from '../../services/helpers/modal.service';
import { ALASKA_ZIP_RANGE, HAWAII_ZIP_RANGE, MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING, SHIPPING_CHARGE_BASE_AMOUNT, SHIPPING_CHARGE_PER_BOOK } from '../../constants/orders';
import { ALLOW_DISCOUNTS, EFAIRS_KILL_SWITCH } from '../../constants/site.settings';
import { BookAvailabilityService } from '../../services/apis/book.availability/book.availability.service';
import { UpdateCartRecipientModalComponent } from '../update.cart.recipient.modal/update.cart.recipient.modal';
import { AuthService } from '../../services/apis/auth/auth.service';
import { WishlistItemsAlreadyPurchasedModalComponent } from './wishlist.items.already.purchased.modal/wishlist.items.already.purchased.modal';
import { STAFF_DISCOUNT_PERCENTAGE } from '../../constants/staff.discounts';

@Component({
	selector: 'cart-review',
	templateUrl: './cart.review.html',
	styleUrls: ['./cart.review.css']
})

class CartReviewComponent extends BaseComponent implements OnInit {
	public loading: boolean = false;
	public cart: Cart;
	public cartItems: Array<CartItem> = [];
	private configuration: Configuration;
	public school: School;
	public bookclub: BookClub;
	public subtotal: number;
	public shippingCharge: number;
	public tax: number;
	public total: number;
	private teacher: User;
	public userName: string;
	public userEmail: string;
	public userShippingAddress: Address;
	public errorCalculatingTax: boolean;
	public taxLoading: boolean;
	public checkoutLoading: boolean;
	public schoolwideTeacherName: string;
	public giftPurchasesEnabled: boolean;
	public efairsKillswitch: SiteSettings;
	public allowDiscounts: SiteSettings;
	public cartItemCount: number;
	public isDonationEfair: boolean;
	public isShipToHomeEfair: boolean;
	public isDisableGifts: boolean;
	public amountToSpendForFreeShipping: number;
	public totalDiscount: number;
	public isTeacher: boolean;
	public useInvoicePayment: boolean;
	public taxExempt: boolean;
	public invoiceOrderCompleted: boolean;
	public requiredNumberOfBooks: number;
	public hasRequiredNumberOfBooks: boolean;
	public invoiceRecipient: CartItemRecipient;
	public readonly Displayables: typeof Displayables = Displayables;
	public discountEntry: string;
	public invalidDiscountCode: boolean;
	public updatingDiscount: boolean;

	public firstBookShipping: number;
	public additionalBookShipping: number;
	public freeShippingThreshold: number;
	public readonly STAFF_DISCOUNT_PERCENTAGE: number = STAFF_DISCOUNT_PERCENTAGE;

	constructor (private modalService: ModalService, private router: Router, private cartService: CartService,
							 private userService: UserService, private schoolService: SchoolService, private orderService: OrderService,
							 private bookClubService: BookClubService, private dialogService: DialogService, private configService: ConfigService,
							 private analyticsService: AnalyticsService, private titlewaveService: TitlewaveService, private windowRef: WindowRef,
							 private siteSettingsService: SiteSettingsService, private bookAvailabilityService: BookAvailabilityService,
							 private authService: AuthService) {
		super();
	}

	public ngOnInit () : void {
		this.cleanup.push(this.siteSettingsService.getOrCreateSiteSettings(EFAIRS_KILL_SWITCH).subscribe((setting: SiteSettings) => {
			this.efairsKillswitch = setting;
		}));

		this.cleanup.push(this.siteSettingsService.getOrCreateSiteSettings(ALLOW_DISCOUNTS).subscribe((setting: SiteSettings) => {
			this.allowDiscounts = setting;
		}));

		this.loading = true;
		this.bookclub = this.bookClubService.getCachedBookClub();
		if (this.bookclub.settings) {
			this.isDonationEfair = !!this.bookclub.settings.donationOrgName;
			this.isShipToHomeEfair = this.bookclub.settings.shipToHome;
			this.isDisableGifts = this.bookclub.settings.disableGifts;
			this.useInvoicePayment = this.bookclub.settings.useInvoicePayment;
			this.taxExempt = this.bookclub.settings.taxExempt;
			this.requiredNumberOfBooks = this.bookclub.settings.requiredNumberOfBooks;
		}
		this.isTeacher = this.userService.isTeacher();
		this.giftPurchasesEnabled = !this.isDisableGifts && !this.isDonationEfair && !this.isShipToHomeEfair;

		this.cartItemCount = this.cartService.getItemCount();
		this.hasRequiredNumberOfBooks = !this.requiredNumberOfBooks || this.requiredNumberOfBooks === this.cartItemCount;

		this.teacher = this.userService.getTeacher();
		this.cleanup.push(this.schoolService.get(this.bookclub.school_id).subscribe((school: School) => {
			this.school = school;

			this.cleanup.push(this.configService.getConfig().subscribe((result: Configuration) => {
				this.configuration = result;
				this.cart = this.cartService.getCachedCart();
				this.cartItems = this.cartService.getCachedCartItems();
				if (this.cartItemCount > 0) {
					this.invoiceRecipient = this.cartItems[0].recipient;
					this.loadUserInfo();
				}
				this.calculateTotals();
				this.loading = false;
			}));

			this.cleanup.push(this.cartService.onCartUpdate.subscribe((cartItems: Array<CartItem>) => {
				this.cartItemCount = this.cartService.getItemCount();
				this.hasRequiredNumberOfBooks = !this.requiredNumberOfBooks || this.requiredNumberOfBooks === this.cartItemCount;
				this.cart = this.cartService.getCachedCart();
				this.cartItems = cartItems;
				if (this.cartItemCount > 0) {
					this.invoiceRecipient = this.cartItems[0].recipient;
				}
				this.calculateTotals();
			}));
		}));

	}

	private loadUserInfo () : void {
		this.userName = localStorage.getItem(USER_NAME);
		this.userEmail = localStorage.getItem(USER_EMAIL);
		const shipping: string = localStorage.getItem(USER_SHIPPING_ADDRESS);
		this.userShippingAddress = shipping ? JSON.parse(shipping) : undefined;

		if (!this.userEmail || !this.userName || (this.isShipToHomeEfair && !this.userShippingAddress)) {
			this.openUserInfoPrompt();
		}
	}

	public openUserInfoPrompt (fromClick?: boolean) : void {
		if (fromClick) {
			this.analyticsService.sendEvent('User Info Prompt Edit');
		}
		if (this.isShipToHomeEfair || !this.useInvoicePayment) {
			const config: ModalOptions = {
				animated: true,
				class: 'modal-md user-info-prompt-modal',
				ignoreBackdropClick: true,
				keyboard: false,
				initialState: {
					shipToHome: this.isShipToHomeEfair,
					onContinue: () => {
						this.userName = localStorage.getItem(USER_NAME);
						this.userEmail = localStorage.getItem(USER_EMAIL);
						const shipping: string = localStorage.getItem(USER_SHIPPING_ADDRESS);
						this.userShippingAddress = shipping ? JSON.parse(shipping) : undefined;
						this.calculateTotals();
					},
					onCancel: () => {
						if (!this.userEmail || !this.userName || (!this.userShippingAddress && this.isShipToHomeEfair)) {
							if (this.userService.isGuest()) {
								this.router.navigate(['/guest/home']);
							} else {
								this.router.navigate(['/teacher/browse']);
							}
						}
					}
				}
			};
			this.modalService.show(UserInfoPromptComponent, config);
		} else {
			const recipient: CartItemRecipient = this.cartItems[0].recipient;
			this.userName = `${recipient.firstName} ${recipient.lastName}`;
		}
	}

	private calculateTotals () : void {
		this.subtotal = 0;
		this.shippingCharge = 0;
		this.tax = 0;
		this.total = 0;
		if (((this.bookclub.type !== EfairType.COMPANION && this.school) || (this.bookclub.type === EfairType.COMPANION && this.bookclub.shipToSchool)) && this.cartItems && this.cartItems.length > 0) {
			this.taxLoading = true;
			this.subtotal = this.getOrderAmount();
			let address: Address;
			if (this.bookclub.type === EfairType.COMPANION) {
				address = this.bookclub.shipToSchool.address;
			} else if (this.isShipToHomeEfair && this.userShippingAddress) {
				address = this.userShippingAddress;
			} else {
				address = this.school.address;
			}

			this.calculateShippingAmount(address);

			if (!this.taxExempt) {
				this.cleanup.push(this.titlewaveService.getSalesTax(address, this.subtotal + this.shippingCharge).subscribe((salesTax: any) => {
					this.taxLoading = false;
					this.tax = Number(salesTax);
					this.total = parseFloat((this.subtotal + this.shippingCharge + this.tax).toFixed(2));
					this.errorCalculatingTax = false;
				}, () => {
					this.taxLoading = false;
					this.errorCalculatingTax = true;
				}));
			} else {
				this.taxLoading = false;
				this.total = parseFloat((this.subtotal + this.shippingCharge).toFixed(2));
				this.errorCalculatingTax = false;
			}
		}
	}

	private getOrderAmount () : number {
		let amount: number = 0;
		this.totalDiscount = 0;
		this.cartItems.forEach((item: CartItem) => {
			const value: number = (parseFloat((item.discountedPrice || item.price).toFixed(2)) * item.quantity);
			amount += value;
			amount = parseFloat(amount.toFixed(2));
			if (item.discountedPrice) {
				this.totalDiscount += (parseFloat((item.price - item.discountedPrice).toFixed(2)) * item.quantity);
			}
		});
		return parseFloat(amount.toFixed(2));
	}

	private calculateShippingAmount (address: Address) : void {
		const zipCode: number = parseInt(address.zip.substring(0, 5), 10);
		let region: string;

		if (address.state === 'AK' || (zipCode >= ALASKA_ZIP_RANGE.MIN && zipCode <= ALASKA_ZIP_RANGE.MAX)
		 || address.state === 'HI' || (zipCode >= HAWAII_ZIP_RANGE.MIN && zipCode <= HAWAII_ZIP_RANGE.MAX)) {
			region = 'ALASKA_HAWAII';
			this.firstBookShipping = SHIPPING_CHARGE_BASE_AMOUNT.ALASKA_HAWAII;
			this.additionalBookShipping = SHIPPING_CHARGE_PER_BOOK.ALASKA_HAWAII;
			this.freeShippingThreshold = MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING.ALASKA_HAWAII;
		} else {
			region = 'CONTINENTAL_US';
			this.firstBookShipping = SHIPPING_CHARGE_BASE_AMOUNT.CONTINENTAL_US;
			this.additionalBookShipping = SHIPPING_CHARGE_PER_BOOK.CONTINENTAL_US;
			this.freeShippingThreshold = MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING.CONTINENTAL_US;
		}

		if (this.isShipToHomeEfair && this.subtotal < MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING[region]) {
			this.shippingCharge = parseFloat((SHIPPING_CHARGE_BASE_AMOUNT[region] + (this.cartItemCount - 1) * SHIPPING_CHARGE_PER_BOOK[region]).toFixed(2));
			this.amountToSpendForFreeShipping = MINIMUM_SUBTOTAL_FOR_FREE_SHIPPING[region] - this.subtotal;
		} else {
			this.shippingCharge = 0;
			this.amountToSpendForFreeShipping = 0;
		}
	}

	private handleUnavailableItemsError (err: any) : void {
		const titlesRemoved: Array<string> = [];
		const titlesUpdated: Map<Object, number> = new Map<any, number>();
		for (const detail of err.details) {
			if (detail.code === ErrorCode.ItemsUnavailable && detail.data) {
				const data: any = detail.data;
				const recipient: CartItemRecipient = data.recipientWithReducedQuantity;
				if (recipient) {
					const unavailableTitleData: any = {
						title: data.title
					};
					if (recipient.fromTeacherWishlist) {
						unavailableTitleData.recipient = `${Displayables.getStaffNameDisplayable(recipient.teacherName)} from ${recipient.firstName} ${recipient.lastName}`;
					} else {
						unavailableTitleData.recipient = `${recipient.firstName} ${recipient.lastName} in ${Displayables.getStaffNameDisplayable(recipient.teacherName)}\'s class`;
					}
					titlesUpdated.set(unavailableTitleData, data.quantityRemovedFromCartForRecipient);
				}
				for (const recipientWithTitleRemoved of data.recipientsWithTitleRemoved) {
					const unavailableTitleData: any = {
						title: data.title
					};
					if (recipientWithTitleRemoved.fromTeacherWishlist) {
						unavailableTitleData.recipient = `${Displayables.getStaffNameDisplayable(recipientWithTitleRemoved.teacherName)} from ${recipientWithTitleRemoved.firstName} ${recipientWithTitleRemoved.lastName}`;
					} else {
						unavailableTitleData.recipient = `${recipientWithTitleRemoved.firstName} ${recipientWithTitleRemoved.lastName} in ${Displayables.getStaffNameDisplayable(recipientWithTitleRemoved.teacherName)}\'s class`;
					}
					titlesRemoved.push(unavailableTitleData);
				}
			}
		}

		const config: ModalOptions = {
			animated: true,
			class: 'modal-md',
			initialState: {
				titlesRemoved: titlesRemoved,
				titlesUpdated: titlesUpdated
			}
		};
		this.modalService.show(CartUnavailableItemsModalComponent, config);
		this.cleanup.push(this.bookAvailabilityService.refreshAvailability().subscribe());
		this.cleanup.push(this.cartService.refresh().subscribe());
	}

	public checkout (buttonLocation: string) : void {
		if (!this.efairsKillswitch.value) {
			this.checkoutLoading = true;
			this.analyticsService.sendEvent(buttonLocation + ' Checkout Initialized', 'Total price: $' + this.total);
			this.analyticsService.sendEcommerceEvent('begin_checkout', null, this.cartItems);
			const truncatedShippingAddress: Address = (this.isShipToHomeEfair && !!this.userShippingAddress && {
				street1: this.userShippingAddress.street1,
				street2: this.userShippingAddress.street2 || undefined,
				city: this.userShippingAddress.city.substring(0, 15),
				state: this.userShippingAddress.state,
				zip: this.userShippingAddress.zip.substring(0, 5),
				isValidated: this.userShippingAddress.isValidated
			}) || undefined;
			const payload: any = {
				userName: this.userName || ' ',
				userEmail: this.userEmail || ' ',
				bookClub_id: this.bookclub._id,
				cart_id: this.cartService.getCachedCart_id(),
				shippingAddress: truncatedShippingAddress
			};

			if (!this.useInvoicePayment) {
				this.cleanup.push(this.orderService.create(payload).subscribe({
					next: (order: Order) => {
						const window: Window = this.windowRef.getWindow();
						let chargeUrl: string = this.configuration.paymentGatewayURL + '/charge';
						chargeUrl += '?scenarioid=' + encodeURIComponent(order.paymentScenario_id);
						this.checkoutLoading = false;

						window.open(chargeUrl, '_self');
					},
					error: (err: any) => {
						this.handleError(err);
					}
				}));
			} else {
				this.cleanup.push(this.orderService.createAndComplete(payload).subscribe({
					next: () => {
						this.authService.clearCartLocalStorage();
						this.cleanup.push(this.cartService.get(payload.cart_id).subscribe(() => {
							this.invoiceOrderCompleted = true;
						}));
					},
					error: (err: any) => {
						this.handleError(err);
					}
				}));
			}
		} else {
			this.dialogService.alert('Sorry', this.efairsKillswitch.customMessage, undefined, true);
		}
	}

	public handleError (err: any) : void {
		const rootError: any = ErrorHelper.getRootError(err);
		this.hasRequiredNumberOfBooks = false;
		if (rootError.code === ErrorCode.ItemsUnavailable) {
			this.handleUnavailableItemsError(rootError);
		} else if (rootError.code === ErrorCode.WishlistItemsOverPurchaseLimits) {
			this.handleWishlistItemsAlreadyPurchasedError(rootError);
		} else if (rootError.code === ErrorCode.DuplicateInvoiceOrderForStudent) {
			this.dialogService.alert('Alert', 'It looks like we\'ve already received an order from you. Please ask your teacher for help.', null, true, () => {
				this.redirectUserAfterErrorDialog();
			});
		} else if (rootError.errorKey === ERROR_KEYS.CLUB_CLOSED) {
			this.dialogService.alert('Alert', 'This eFair is now closed. You will no longer be able to purchase books or add anything to your cart.', null, true, () => {
				this.redirectUserAfterErrorDialog();
			});
			this.cleanup.push(this.cartService.emptyCart().subscribe());
		} else {
			this.handleGenericError();
		}
		this.checkoutLoading = false;
	}

	private handleGenericError () : void {
		this.dialogService.alert('Alert', 'We are unable to process your order at this time. Please try again later.', null, true, () => {
			this.redirectUserAfterErrorDialog();
		});
	}

	private redirectUserAfterErrorDialog () : void {
		this.bookClubService.reset();
		this.cleanup.push(this.bookClubService.populateBookClub(this.teacher._id).subscribe(() => {
			if (this.userService.isGuest()) {
				this.router.navigate(['/guest/home']);
			} else {
				this.router.navigate(['/teacher/browse']);
			}
		}));
	}

	public goToBrowse () : void {
		this.analyticsService.sendEvent('Cart Back to Browse Button Clicked');
		if (this.isTeacher) {
			this.router.navigate(['teacher/browse']);
		} else {
			this.router.navigate(['guest/home']);
		}
	}

	public openUpdateCartRecipientModal () : void {
		const config: ModalOptions = {
			animated: true,
			class: 'modal-lg add-to-cart'
		};
		this.modalService.show(UpdateCartRecipientModalComponent, config);
	}

	private handleWishlistItemsAlreadyPurchasedError (err: any) : void {
		if (err.details && err.details.length > 0) {
			const config: ModalOptions = {
				animated: true,
				class: 'modal-md',
				initialState: {
					items: err.details[0].data.items
				}
			};
			this.modalService.show(WishlistItemsAlreadyPurchasedModalComponent, config);
			this.cleanup.push(this.cartService.refresh().subscribe());
		} else {
			this.handleGenericError();
		}
	}
}

export { CartReviewComponent };
