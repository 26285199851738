import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './error/error';
import { CartReviewComponent } from './cart.review/cart.review';
import { OrderSummaryComponent } from './order.summary/order.summary';
import { SharedGuardService } from './shared.guard.service';
import { ManageWishlistComponent } from './manage.wishlist/manage.wishlist';
import { ShareWishlistComponent } from './share.wishlist/share.wishlist';
import { ViewRedirectComponent } from './view.redirect/view.redirect';
import { OrderStatusComponent } from './order.status/order.status';
import { CartComponent } from './cart/cart';
import { AuthNotRequiredGuardService } from './auth.not.required.guard.service';

const ROUTES: Routes = [
	{ path: 'error', component: ErrorComponent },
	{ path: 'cart', component: CartReviewComponent, data: {title: 'Cart'}, canActivate: [SharedGuardService] },
	{ path: 'cartPreview', component: CartComponent, data: {title: 'Cart Preview'}, canActivate: [SharedGuardService] },
	{ path: 'manageWishlist', component: ManageWishlistComponent, data: {title: 'Wish List'}, canActivate: [SharedGuardService] },
	{ path: 'shareWishlist', component: ShareWishlistComponent, data: {title: 'Share Wish List'}, canActivate: [SharedGuardService] },

	{ path: 'orderStatus', component: OrderStatusComponent, data: {title: 'Order Status'}, canActivate: [AuthNotRequiredGuardService] },
	{ path: 'order/:order_id', component: OrderSummaryComponent, data: {title: 'Order Summary'}, canActivate: [AuthNotRequiredGuardService] },
	{ path: 'view/:code', component: ViewRedirectComponent, data: {title: 'Redirect'}, canActivate: [AuthNotRequiredGuardService] }
];

@NgModule({
	imports: [
		RouterModule.forChild(ROUTES)
	],
	exports: [
		RouterModule
	],
	providers: [
		AuthNotRequiredGuardService,
		SharedGuardService
	]
})
class SharedRoutingModule {}

export { ROUTES, SharedRoutingModule };
