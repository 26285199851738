import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicCrudService } from '../basic.crud/basic.crud.service';
import { HttpClient } from '@angular/common/http';
import { FulfillmentCenter } from '../../../constants/fulfillment.centers';
class BookFulfillmentCenterAvailability {
	public titlewave_id: string;
	public availableQuantity: number;
}
class FulfillmentCenterAvailability {
	public availableQuantity: number;
	public lastUpdated: Date;
}

class BookAvailability {
	public _id?: string;
	public titlewave_id: string;
	public MC?: FulfillmentCenterAvailability;
	public DL?: FulfillmentCenterAvailability;
	public IL?: FulfillmentCenterAvailability;
	public NE?: FulfillmentCenterAvailability;
	public OR?: FulfillmentCenterAvailability;
	public RD?: FulfillmentCenterAvailability;
	public lastChecked?: Date;
	[key: string]: string | Date | FulfillmentCenterAvailability;
}

@Injectable()
class BookAvailabilityService extends BasicCrudService<BookAvailability> {

	private bookAvailability: Map<string, number> = new Map();
	private fulfillmentCenter: string = FulfillmentCenter.MC;

	constructor (http: HttpClient) {
		super(http);
		this.setUrl('/server/api/bookAvailability');
	}

	public getAvailability (titlewave_id: string) : number {
		return this.bookAvailability.get(titlewave_id) || 0;
	}

	public setFulfillmentCenter (fulfillmentCenter: string) : void {
		this.fulfillmentCenter = fulfillmentCenter;
	}

	public fetchAvailability (titlewave_ids: Array<string>) : Observable<void> {
		const booksToFetch: Array<string> = [];
		for (const titlewave_id of titlewave_ids) {
			const availability: number = this.bookAvailability.get(titlewave_id);
			if (!availability && availability !== 0) {
				booksToFetch.push(titlewave_id);
			}
		}

		if (booksToFetch.length > 0) {
			return this.fetch(booksToFetch);
		} else {
			return of(null);
		}
	}

	public refreshAvailability () : Observable<void> {
		if (this.bookAvailability) {
			const booksToFetch: Array<string> = Array.from(this.bookAvailability.keys());
			if (booksToFetch.length > 0) {
				return this.fetch(booksToFetch);
			}
		}
		return of(null);
	}

	public reset () : any {
		this.bookAvailability = new Map();
		this.fulfillmentCenter = null;
	}

	public getFullBookAvailability (titlewave_id: string) : Observable<BookAvailability> {
		return this.http.get<BookAvailability>(`${this.url}/${titlewave_id}`);
	}

	public fetch (titlewave_ids: Array<string>) : Observable<void> {
		return this.http.post<Array<BookFulfillmentCenterAvailability>>(`${this.url}`, {titlewave_ids: titlewave_ids, fulfillmentCenter: this.fulfillmentCenter}).pipe(map((availabities: Array<BookFulfillmentCenterAvailability>) => {
			for (const availability of availabities) {
				this.bookAvailability.set(availability.titlewave_id, availability.availableQuantity);
			}
		}));
	}
}

export { BookAvailabilityService, BookAvailability, FulfillmentCenterAvailability, BookFulfillmentCenterAvailability };
