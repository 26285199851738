import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { BaseComponent } from '../../base.component';
import { grow } from '../animations/expand';

@Component({
	selector: 'expandable-text',
	templateUrl: './expandable.text.html',
	styleUrls: ['./expandable.text.css'],
	animations: [grow]
})

class ExpandableTextComponent extends BaseComponent implements AfterViewInit {

	@ViewChild('contentRef', {static: false}) public contentRef: ElementRef;

	public showMore: boolean;
	public expanded: boolean;

	@Input() public content: string;

	public ngAfterViewInit () : void {
		timer(0).subscribe(() => {
			this.expanded = true;
		});
		this.setShowMore();
	}

	public setShowMore () : void {
		this.cleanup.push(timer(250).subscribe(() => {
			this.showMore = this.contentRef.nativeElement.scrollHeight > 300;
			this.expanded = !this.showMore;
		}));
	}

	public toggleExpanded () : void {
		this.expanded = !this.expanded;
	}
}

export { ExpandableTextComponent };
