import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

abstract class BaseComponent implements OnDestroy {
	protected cleanup: Array<Subscription>;

	constructor () {
		this.cleanup = [];
	}

	public ngOnDestroy () : void {
		this.cleanup.forEach((sub: Subscription) => {
			sub.unsubscribe();
		});
		this.cleanup = [];
	}

	public stopProp (event: Event) : void {
		if (event) {
			event.stopPropagation();
		}
	}
}

export { BaseComponent };
