import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { UserService } from '../../services/apis/user/user.service';

@Component({
	selector: 'order-status-link',
	templateUrl: './order.status.link.html',
	styleUrls: ['./order.status.link.css']
})

class OrderStatusLinkComponent extends BaseComponent implements OnInit {

	public isGuest: boolean;
	public isTeacher: boolean;
	public orderStatusSwitch: boolean;

	@Input() public floating: boolean;
	@Input() public fromCopyrightFooter: boolean;

	constructor (private userService: UserService) {
		super();
	}

	public ngOnInit () : void {

		if (this.userService.getUser()) {
			this.isGuest = this.userService.isGuest();
			this.isTeacher = this.userService.isTeacher();
		} else {
			this.cleanup.push(this.userService.onUserLoad.subscribe(() => {
				this.isGuest = this.userService.isGuest();
				this.isTeacher = this.userService.isTeacher();
			}));
		}

		this.cleanup.push(this.userService.onUserUnLoad.subscribe(() => {
			this.isGuest = this.userService.isGuest();
			this.isTeacher = this.userService.isTeacher();
		}));
	}

}

export { OrderStatusLinkComponent };
