import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { timer, Subject, Observable, Subscription } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { AnalyticsService } from '../../services/helpers/analytics.service';
import { UserService } from '../../services/apis/user/user.service';
import { Book } from '../../services/apis/book/book.service';
import { RecommendedListService, RecommendedItem } from '../../services/apis/recommended.list/recommended.list.service';
import { fade } from '../animations/fade';
import { BookClub, BookClubService } from '../../services/apis/book.club/book.club.service';
import { BookClubStatus } from '../../constants/book.club.status';

@Component({
	selector: 'cover-recommendation',
	templateUrl: './cover.recommendation.html',
	styleUrls: ['./cover.recommendation.css'],
	animations: [fade]
})

class CoverRecommendationComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() public book: Book;
	@ViewChild('coverTextAreaElement', {static: false}) public coverTextAreaElement: ElementRef;
	public recNote: string;
	public recNoteSaved: boolean = false;
	public recNoteSaving: boolean = false;
	public isTeacher: boolean;
	public clubClosed: boolean;
	public recommendationNoteChange: Subject<string> = new Subject<string>();
	private newRecommendationNote: string;

	constructor (private analyticsService: AnalyticsService,
		private userService: UserService,
		private bookClubService: BookClubService,
		private recommendedListService: RecommendedListService) {
		super();
	}

	public ngOnInit () : void {
		const recItem: RecommendedItem = this.recommendedListService.getRecommendedItem(this.book._id);
		if (recItem) {
			this.recNote = recItem.note;
		}
		this.isTeacher = this.userService.isTeacher();
		const bookClub: BookClub = this.bookClubService.getCachedBookClub();
		this.clubClosed = !bookClub || bookClub.status !== BookClubStatus.CREATED && bookClub.status !== BookClubStatus.STARTED;
		if (this.isTeacher) {
			this.cleanup.push(this.recommendationNoteChange.pipe(map((newVal: any) => {
				return {newNote: newVal, book: Object.assign({}, this.book)};
			})).pipe(debounceTime(1000)).subscribe((saveObject: { newNote: string, book: Book }) => {
				this.updateRecommendationNote(saveObject.newNote, saveObject.book, false);
			}));
		}
	}

	public ngOnDestroy () : void {
		if (this.newRecommendationNote) {
			this.updateRecommendationNote(this.newRecommendationNote, this.book, true);
		}
		super.ngOnDestroy();
	}

	public ngAfterViewInit () : void {
		this.cleanup.push(timer(0).subscribe(() => {
			this.coverTextAreaElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
			this.cleanup.push(timer(300).subscribe(() => {
				this.coverTextAreaElement.nativeElement.focus();
			}));
		}));
	}

	public persistBookRecommendationNote (book: Book, note: string) : Observable<Array<RecommendedItem>> {
		this.analyticsService.sendEvent('Back Cover update Recommend Text');
		return this.recommendedListService.updateRecommendedItem(book._id, {note: note});
	}

	public updateRecommendationNote (newNote: string, book: Book, calledByNgOnDestroy: boolean) : void {
		this.recNoteSaving = true;
		const sub: Subscription = this.persistBookRecommendationNote(book, newNote).subscribe(() => {
			this.recNoteSaving = false;
			this.recNoteSaved = true;
			sub.unsubscribe();	// Clean this up here. If user has unsaved text and closes text area, remaining text is handled by ngOnDestroy.
			if (!calledByNgOnDestroy) {
				this.cleanup.push(timer(2000).subscribe(() => {
					this.recNoteSaved = false;
				}));
			}
		});
	}

	public saveBookRecommendationNote (inputValue: string) : void {
		this.newRecommendationNote = inputValue;
		this.recommendationNoteChange.next(inputValue);
	}

	public preventFlip (event: Event) : void {
		event.stopPropagation();
	}

}

export { CoverRecommendationComponent };
